(function() {
  "use strict";

  angular.module("marketPlace.listings").factory("Listings", Listings);

  Listings.$inject = ["$resource", "API_URL"];
  function Listings($resource, API_URL) {
    return $resource(
      API_URL + "/api/listings/:controller/",
      { controller: "@controller" },
      {
        get: {
          method: "GET",
          isArray: false
        },
        archive: {
          method: "POST",
          params: {
            controller: "archive"
          }
        }
      }
    );
  }
})();
