interface JsonLdBindings {
  data: number;
}

interface JsonLdController extends JsonLdBindings {
  $onChanges(): void;
}

class JsonLdControllerImpl implements JsonLdController {
  public json: string;
  public data: any;

  constructor() {
    this.data = {};
    this.json = "";
  }

  $onChanges(): void {
    this.json = JSON.stringify(this.data);
  }
}

class JsonLd implements ng.IComponentOptions {
  public bindings: any;
  public controller: any;
  public template: string;

  constructor() {
    this.bindings = {
      data: "<"
    };
    this.controller = JsonLdControllerImpl;
    this.template = '<script type="application/ld+json" ng-bind="$ctrl.json"></script>';
  }
}

angular.module("marketPlace.controls").component("jsonLd", new JsonLd());
