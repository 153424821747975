(function () {
  "use strict";

  interface Image {
    url: string;
  }

  angular.module("marketPlace.preview").controller("PreviewController", previewController);

  previewController.$inject = [
    "$state",
    "$uibModalInstance",
    "$log",
    "images",
    "activeIndex",
    "readOnly",
    "primaryName",
    "Thumbnail",
    "Features",
  ];

  function previewController(
    $state,
    $uibModalInstance,
    $log,
    images: Image[],
    activeIndex: number,
    readOnly: boolean,
    primaryName = "primary",
    Thumbnail,
    Features
  ) {
    const vm = this;

    vm.images = images;
    vm.activeIndex = activeIndex;
    vm.readOnly = readOnly;
    vm.primaryName = primaryName;
    vm.enableShoppingCart = Features.shoppingCart;

    // API
    vm.deleteImage = deleteImage;
    vm.getActiveImage = getActiveImage;
    vm.setPrimaryImage = setPrimaryImage;
    vm.toggleForSale = toggleForSale;
    vm.addToCart = addToCart;
    vm.prev = prev;
    vm.next = next;
    vm.showProducts = false;
    vm.toggleOpenProducts = toggleOpenProducts;

    activate();

    function activate() {
      $log.debug(vm.images);
      if (readOnly) {
        for (let i = 0; i < vm.images.length; i++) {
          if (
            vm.images[i].hasOwnProperty("media_type") &&
            vm.images[i].media_type.startsWith("image") &&
            !vm.images[i].url.endsWith("-1500x1500.jpg")
          ) {
            const imageData = Thumbnail.getThumbnailURI(vm.images[i].url, "-1500x1500", ".jpg", true);
            vm.images[i].url = imageData.url;
            vm.images[i].key = imageData.key;
          }
        }
      }
    }

    function deleteImage(ix: number) {
      vm.images.splice(ix, 1);
      if (vm.images.length === 0) {
        $uibModalInstance.close(true);
      }
    }

    function getActiveImage(): Image {
      return vm.images[vm.activeIndex];
    }

    function prev() {
      if (vm.images.length > 0) {
        if (vm.activeIndex > 0) {
          vm.activeIndex = (vm.activeIndex - 1) % vm.images.length;
        } else {
          vm.activeIndex = vm.images.length - 1;
        }
      }
    }

    function next() {
      if (vm.images.length > 0) {
        if (vm.activeIndex < vm.images.length - 1) {
          vm.activeIndex = (vm.activeIndex + 1) % vm.images.length;
        } else {
          vm.activeIndex = 0;
        }
      }
    }

    function setPrimaryImage(primaryIndex) {
      for (let i = 0; i < vm.images.length; i++) {
        vm.images[i].primary = i === primaryIndex;
      }
    }

    function toggleForSale(index: number) {
      vm.images[index].is_for_sale = !vm.images[index].is_for_sale;
    }

    function addToCart(imageIndex: number) {
      $log.debug(vm.images[imageIndex]);
      const imageKey = vm.images[imageIndex].key;
      $log.debug("Adding image ", imageKey, "to the cart", vm.images[imageIndex]);

      $state.go("home.cart", {
        itemName: "High-Res Image",
        productUrl: vm.images[imageIndex].product,
        quantity: 1,
        options: [{ option: vm.images[imageIndex].product_option, value: imageKey }],
      });
    }

    function toggleOpenProducts() {
      vm.showProducts = !vm.showProducts;
    }
  }
})();
