import { Transition } from "@uirouter/angularjs";
import { IModalService } from "angular-ui-bootstrap";
import { ILogService } from "angular";
import { PreviousService } from "../previous.service";

(function () {
  "use strict";
  angular.module("marketPlace.auth").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider.state("login", {
      url: "/login",
      template: "<div ui-view></div>",
      data: {
        isAuthState: true,
        requiresLogin: false,
      },
      params: {
        showLogin: true,
      },
      resolve: {
        $transition$: "$transition$",
        isAlreadyLoggedIn: [
          "$q",
          "$state",
          "AuthService",
          function ($q, $state, AuthService) {
            const deferred = $q.defer();
            if (AuthService.isAuthenticated()) {
              deferred.reject(false);
              $state.go("home.gallery");
            } else {
              deferred.resolve(true);
            }
            return deferred.promise;
          },
        ],
      },
      onEnter: ["$uibModal", "$log", "PreviousService", "$transition$", createLoginModal],
    });
  }

  function createLoginModal(
    $uibModal: IModalService,
    $log: ILogService,
    PreviousService: PreviousService,
    $transition$: Transition
  ) {
    $log.debug("Creating login modal");

    const modalInstance = $uibModal.open({
      animation: true,
      template: require("./templates/login-modal.html"),
      controller: "LoginModalController",
      controllerAs: "vm",
      size: "mdsm",
      resolve: {
        $transition$: function (): Transition {
          return $transition$;
        },
      },
    });
    PreviousService.invokeModalOnEnter(modalInstance, $transition$, true, true);
    return false; // this is required to stop the gallery from disappearing when the modal is opened
  }
})();
