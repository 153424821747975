(function() {
  "use strict";

  angular.module("marketPlace.gallery").controller("GalleryController", galleryController);

  galleryController.$inject = [
    "$window",
    "$log",
    "$rootScope",
    "$scope",
    "$state",
    "$transition$",
    "$timeout",
    "$anchorScroll",
    "Gallery",
    "GalleryItem",
    "_",
    "user",
    "niches",
    "broadStreetZones",
    "UserSearchService",
    "siteName",
    "AuthService",
    "toastr",
    "initialItem",
    "DATA_EVENTS",
    "GalleryData",
    "Features",
    "GalleryForm",
    "AppConfig",
    "BroadstreetService",
    "MAX_UNAUTHENTICATED_SCROLLS"
  ];

  function galleryController(
    $window,
    $log,
    $rootScope,
    $scope,
    $state,
    $transition$,
    $timeout,
    $anchorScroll,
    Gallery,
    GalleryItem,
    _,
    user,
    niches,
    broadStreetZones,
    UserSearchService,
    siteName,
    AuthService,
    toastr,
    initialItem,
    DATA_EVENTS,
    GalleryData,
    Features,
    GalleryForm,
    AppConfig,
    BroadstreetService,
    MAX_UNAUTHENTICATED_SCROLLS
  ) {
    const vm = this;
    const stateParams = $transition$.params();

    vm.user = user;
    vm.niches = niches;
    vm.siteName = siteName;
    vm.initialItem = initialItem || stateParams.item;
    vm.enableBroadstreet = Features.broadstreet;
    vm.initialItemOpened = false;
    vm.openItemDetailIndex = null; // null = closed; int = index in galleryItems
    vm.showScrollMessage = Features.scrollMessage;

    vm.colsCount = 3;
    vm.galleryCssClasses = "col-sm-4";
    vm.mode = 3; // display mode
    vm.titleLength = 20;
    vm.showDetailsFor = null; // null - closed; int - image index
    vm.colIx = 0;
    vm.detailsIndex = null;
    vm.galleryItems = [];
    vm.galleryItemIds = [];
    vm.categories = {};
    vm.defaultPageSize = 11;
    const pageCountTrigger = vm.defaultPageSize * 2; // Tracks page views from after this many items
    let pageCount = 1; // Tracks page number for analytics calls and registration prompt
    vm.map = {};
    vm.savedState = {};
    vm.loadingMore = false;
    vm.settings = { TILE_DESIGN_SELECT: "card" };
    vm.defaultSearch = null;
    vm.nextParams = null;
    vm.currentState = null;
    vm.showCategories = true;
    vm.main_categories = [];
    vm.main_category = null;
    vm.supportsGrid = false;
    vm.clearCount = 0;
    vm.initialLoad = true;
    vm.disableScroll = false;

    // Broadstreet
    vm.broadstreetKeywords = "";
    vm.zones = broadStreetZones;
    vm.adSpacing = AppConfig.adFrequency;
    vm.adMod = vm.adSpacing - 1;
    vm.adSpacingMobile1 = 3;
    vm.adSpacingMobile2 = 6;
    vm.adTimeOuts = {};

    // Functions
    vm.changeMode = changeMode;
    vm.loadMore = loadMore;
    vm.updateLikeCount = updateLikeCount;
    vm.toggleTileDesign = toggleTileDesign;
    vm.setMainCategory = setMainCategory;
    vm.toggleCategories = toggleCategories;
    vm.toggleOtherCategories = toggleOtherCategories;
    vm.scrollTo = scrollTo;
    vm.getFirstSearchCategory = getFirstSearchCategory;

    vm.openDetail = openDetail;
    vm.closeDetail = closeDetail;
    vm.back = back;
    vm.openDetailWithUrl = openDetailWithUrl;
    vm.getOtherSearchCategories = getOtherSearchCategories;

    vm.baseFontSize = 16;

    vm.typeFilters = Features.typeFilters;

    GalleryData.subscribe($scope, function(event, param) {
      $log.debug("GALLERY DATA Got new data", param);
      // Handle notification
      if (param === "mode") {
        vm.changeMode(GalleryData.get("mode"));
      }

      if (param === "categories") {
        $log.debug("GALLERY DATA Got new categories");
        vm.categories = GalleryData.get("categories");
        vm.main_category = null;
        vm.main_categories = [];
        initCategories();
      }

      if (param === "categoriesOpen") {
        vm.showCategories = GalleryData.get("categoriesOpen");
      }
    });

    activate();

    function getItemSearch(item) {
      const search: { [propName: string]: string } = { niche: item.niche_slug };

      if (angular.isDefined(item.category) && item.category !== null) {
        search.category = item.category;
      }

      if (angular.isDefined(item.caption_slug) && item.caption_slug !== null) {
        search.caption = item.caption_slug;
      }

      if (angular.isDefined(item.gallery_slug) && item.gallery_slug !== null) {
        search.gallery = item.gallery_slug;
      }

      if (angular.isDefined(item.section_slug) && item.section_slug !== null) {
        search.section = item.section_slug;
      }

      return search;
    }

    function loadSearch(item) {
      const search: { [propName: string]: any } = {
        size: vm.defaultPageSize,
        page: 1
      };

      if (item) {
        vm.showCategories = false;
        _.merge(search, getItemSearch(item));
      } else {
        _.merge(search, _.omitBy(stateParams, _.isNil));
      }

      delete search["$$uirouterextrasreload"];

      if (!search.niche && vm.user && vm.user.niche) {
        search.niche = vm.user.niche;
      }

      // there must be a valid niche
      if (!search.niche || (search.niche && !vm.niches.hasOwnProperty(search.niche))) {
        if (vm.niches._order.length > 0) {
          search.niche = vm.niches._order[0];
        }
      }

      $log.debug("search:", search);
      return search;
    }

    function activate() {
      $log.debug("GalleryController:", $state.current.name);

      try {
        const body = $window.document.body;
        vm.baseFontSize = parseInt(
          (body.currentStyle || $window.getComputedStyle(body)).fontSize.replace(/[a-z]+/, "")
        );
      } catch (error) {
        vm.baseFontSize = 16;
      }

      vm.search = loadSearch(vm.initialItem);

      // the gallery view can be loaded behind modals so
      // only need to build it if it is actually a gallery
      // state
      if (!GalleryForm.isGalleryState($state.current.name)) {
        // no more work to do
        return;
      }

      if (vm.niches._order.length === 0) {
        loadEmpty();
        return;
      }

      vm.niches[vm.search.niche].open = true;
      vm.niche = vm.search.niche;

      if ("CSS" in $window && $window.CSS.supports("display: grid") && !$window.CSS.supports("display: -ms-grid")) {
        vm.supportsGrid = true;
      }

      vm.catParams = _.omit(vm.search, ["niche", "page", "prompt", "size"]);

      if (vm.initialItem) {
        vm.initialItemOpened = true;
        vm.galleryItems.push(vm.initialItem);
        vm.nextParams = {}; // set nextParams empty
        saveState("home.gallery", vm.search); // save the item search as previous state
        openDetail(vm.initialItem);
        if (vm.initialItem.item_type !== "ORGANISATION") {
          loadMore(); // will search with getItemSearch params
        } else {
          vm.nextParams = null;
        }
      } else {
        search();
      }

      if (vm.enableBroadstreet) {
        let q = vm.search.q;
        if (!q && $state.params.default && vm.user && vm.user.categories && vm.user.categories.length > 0) {
          q = vm.user.categories[0].name;
        }

        const searchObj = { ...$state.params, q: q };
        vm.broadstreetKeywords = BroadstreetService.getBroadStreetKeywords(searchObj);
        $log.debug("Gallery keywords: " + vm.broadstreetKeywords);
      }

      vm.currentState = $state.current.name;
    }

    $scope.$on(DATA_EVENTS.galleryParamsChange, function(event, toParams) {
      $log.debug("GALLERY params changed", toParams);
      // fix for back button
      if (toParams.rawId === null) {
        closeDetail(true);
      }
    });

    function openDetailWithUrl(url: string) {
      // uses a url to get an item and reloads the state
      const rawParams = url.split("/");
      const params = {
        niche: rawParams[0],
        section: rawParams[1],
        gallery: rawParams[2]
      };
      if (rawParams.length === 4) {
        params["rawId"] = rawParams[3];
      } else {
        params["caption"] = rawParams[4];
        params["rawId"] = rawParams[5];
      }
      $state.reload({ name: "home.gallery", params: params });
    }

    function scrollTo(elementId = "mobile-top") {
      $timeout(function() {
        $log.debug("Timeout scrolling to", elementId);
        $anchorScroll(elementId);
        const el: NodeListOf<HTMLElement> = $window.document.getElementsByClassName("container");
        let marginTop = 128;
        if (angular.isDefined(el) && el !== null && el.length > 0) {
          const currentMarginTop = (el[0].currentStyle || $window.getComputedStyle(el[0])).marginTop;
          if (currentMarginTop.endsWith("px")) {
            marginTop = parseInt(currentMarginTop.replace("px", ""));
          } else {
            marginTop = vm.baseFontSize * parseInt(currentMarginTop.replace(/[a-z]+/, ""));
          }
        }
        $timeout(function() {
          // scroll back to show header
          $log.debug("scroll by", marginTop);
          $window.scrollBy(0, -marginTop);
        });
      });
    }

    function isSearch(search: object) {
      return _.keys(_.pickBy(_.omit(search, ["default", "niche", "page", "size", "sort", "id"]))).length > 0;
    }

    function loadEmpty() {
      loadItems({ results: [], total: 0 });
    }

    function search() {
      $log.debug("SEARCH", vm.search);
      if (isSearch(vm.search)) {
        standardSearch();
      } else {
        if (vm.user && vm.search.default) {
          UserSearchService.getDefault()
            .then(userDefaultSearch)
            .catch(genericDefaultSearch);
        } else {
          genericDefaultSearch();
        }
      }
    }

    function userDefaultSearch(search: object) {
      vm.search = search.params;
      vm.search.size = vm.defaultPageSize;
      standardSearch();
    }

    function genericDefaultSearch() {
      reloadItems(vm.search);
    }

    function standardSearch() {
      delete vm.search["default"];

      reloadItems(vm.search);
    }

    function getItemDetailParams(item) {
      if (!item.name_slug) {
        item.name_slug = item.name ? item.name.slugify() : "";
      }

      const rawId = item.url_item_type + item.id + "-" + item.name_slug;
      $log.debug("RAW ID:", rawId);

      return {
        niche: item.niche_slug,
        section: item.section_slug,
        gallery: item.gallery_slug,
        caption: item.caption_slug,
        rawId: rawId,
        id: item.id,
        itemType: item.url_item_type,
        itemSlug: item.name_slug
      };
    }

    function getItemIndex(item) {
      for (let i = 0; i < vm.galleryItems.length; i++) {
        if (vm.galleryItems[i].id.toString() === item.id.toString()) {
          return i;
        }
      }

      return -1;
    }

    function loadItemDetailError(resp) {
      if (resp.status === 403) {
        // permissionDenied
        vm.galleryItems[vm.openItemDetailIndex].view = "permissionDenied";
      } else if (resp.status === 404) {
        toastr.warning("Sorry that item is not available right now.", "Not Found");
        vm.galleryItems[vm.openItemDetailIndex].view = "tile";
      } else {
        toastr.error("Sorry that item is not available right now.", "Error");
        vm.galleryItems[vm.openItemDetailIndex].view = "tile";
      }
    }

    function restorePreviousState(name: string, params: object, scrollToElement?: string) {
      let reload = false;
      if (
        !name ||
        /register/.exec(name) || // don't go back to register screen
        /login/.exec(name) || // - or login
        /items.*/.exec(name) // - or items.*
      ) {
        params = null;
        reload = true;
      }

      $log.debug("RESTORING (RELOAD", reload, ")", params);
      $state.go("home.gallery", params, { reload: reload, inherit: false });
      if (scrollToElement) {
        $timeout(function() {
          scrollTo(scrollToElement);
        });
      }
    }

    function saveState(name = $state.current.name, params = stateParams) {
      // save state and params
      vm.savedState.name = name;
      const defaultParams = {};
      for (const param in $state.current.params) {
        if ($state.current.params.hasOwnProperty(param) && $state.current.params.param) {
          defaultParams[param] = $state.current.params[param];
        }
      }
      vm.savedState.params = _.merge(defaultParams, _.clone(params));
      delete vm.savedState.params.page;
      delete vm.savedState.params.size;
      $log.debug("SAVED STATE", vm.savedState.name, vm.savedState.params);
    }

    function goToDetail(item, options: { [propName: string]: any } = {}) {
      $log.debug("GO TO DETAIL");
      const routeParams = getItemDetailParams(item);
      const routeName = "home.gallery";

      if (routeParams.rawId !== stateParams.rawId) {
        if (routeParams.rawId === null) {
          routeParams.rawId = routeParams.itemType + routeParams.id + "-" + (routeParams.itemSlug || "");
        }
        const promise = $state.go(routeName, routeParams, options);

        if (options.reload !== false) {
          promise.then(function() {
            const page = $state.href($state.current);
            $log.debug("Changed to detail view " + page);

            $window.gtag('event', 'page_view', {
              page_location: page,
            });
          });
        }
      }
    }

    function loadItemDetail(result) {
      goToDetail(result);

      result.detailLoaded = true;

      result.view = "details";
      if (result.hasOwnProperty("can_open") && !result.can_open) {
        result.view = "permissionDenied";
      }

      vm.openItemTile = angular.copy(vm.galleryItems[vm.openItemDetailIndex]); // save the item's tile view
      vm.galleryItems[vm.openItemDetailIndex] = result; // update the gallery with the item's detail view
      scrollTo(result.id.toString());
    }

    function back() {
      $window.history.back();
    }

    function closeDetail(openingNew = false) {
      if (vm.openItemDetailIndex === null || vm.openItemDetailIndex > vm.galleryItems.length) {
        $log.debug("onBefore skipping... closeDetail");
        return;
      }

      // set back to tile view
      let scrollToElement = "";
      if (angular.isDefined(vm.openItemTile) && vm.openItemTile !== null) {
        vm.galleryItems[vm.openItemDetailIndex] = angular.copy(vm.openItemTile);
        scrollToElement = vm.openItemTile.id;
        delete vm.openItemTile;
      } else {
        scrollToElement = vm.galleryItems[vm.openItemDetailIndex].id;
      }

      vm.galleryItems[vm.openItemDetailIndex].view = "tile";
      vm.openItemDetailIndex = null;
      vm.initialItemOpened = false;

      if (!openingNew) {
        // Restore the saved state
        restorePreviousState(vm.savedState.name, vm.savedState.params, scrollToElement);
      }
    }

    function openDetail(item) {
      $log.debug("GALLERY params OPEN DETAIL", item);
      closeDetail(true); // close any open detail

      if (item.internal_url) {
        if (item.internal_url_target === "_self") {
          $window.location.href = item.internal_url;
        } else {
          $window.open(item.internal_url, item.internal_url_target, "noopener");
        }
        return;
      }

      if (!vm.initialItemOpened && AuthService.shouldPromptRegister($state.current.name, getItemDetailParams(item))) {
        $state.go("login", { showLogin: false });
        closeDetail();
        return;
      }

      vm.openItemDetailIndex = getItemIndex(item); // update openItemDetailIndex variable with item index
      vm.showDetailsFor = item.id;

      if (item.detailLoaded) {
        loadItemDetail(item); // just load item detail
      } else {
        item.view = "loading"; // set item to loading mode
        const func = item.caption_slug ? GalleryItem.get : GalleryItem.noCaption;
        func(getItemDetailParams(item), loadItemDetail, loadItemDetailError);
      }
    }

    function toggleCategories() {
      vm.showCategories = !vm.showCategories;
    }

    function setMainCategory(category) {
      vm.main_category = category;
      if (vm.main_category) {
        vm.sub_cateogry_col_length = Math.max(6, Math.ceil(vm.categories[category].length / 4));
      }
    }

    function toggleOtherCategories() {
      vm.showOtherCategories = !vm.showOtherCategories;
    }

    function getFirstSearchCategory() {
      if (Array.isArray(vm.search.category)) {
        return vm.search.category[0];
      } else {
        return vm.search.category;
      }
    }

    function initCategories() {
      let searchCategory: string = vm.search.category;
      if (Array.isArray(vm.search.category) && vm.search.category.length > 0) {
        searchCategory = vm.search.category[0];
      }
      if (vm.categories) {
        vm.otherCategories = [];
        for (let i = 0; i < vm.categories._order.length; i++) {
          const name = vm.categories._order[i];
          const sub_categories = vm.categories[name];
          if (sub_categories) {
            if (sub_categories.length >= 1) {
              if (name !== "Other" && sub_categories.length <= 4 && vm.categories._order.length > 4) {
                for (let j = 0; j < sub_categories.length; j++) {
                  let sub_cat = sub_categories[j];
                  if (sub_cat.endsWith("- All")) {
                    sub_cat = name;
                  } else {
                    sub_cat = name + " - " + sub_cat;
                  }
                  vm.otherCategories.push(sub_cat);
                }
              } else {
                vm.main_categories.push({
                  name: name,
                  categories: sub_categories
                });
              }
            }
          }
        }

        vm.showOtherCategories = false;
        if (vm.otherCategories.length > 0) {
          vm.otherCategories.sort();
        }

        delete vm.categories["Other"];

        if (vm.main_categories.length > 0 && vm.search.category) {
          setMainCategory(vm.main_categories[0].name);
        }

        if (angular.isDefined(searchCategory) && searchCategory !== null) {
          const categoryParts = searchCategory.split(" - ");
          if (categoryParts.length === 1) {
            vm.parent_category = null;
          } else {
            vm.parent_category = categoryParts.slice(0, -1).join(" - ");
          }
        }
      }
    }

    function getOtherSearchCategories(category: string): string[] {
      if (!Array.isArray(vm.search.category)) {
        return [];
      }

      const index = vm.search.category.indexOf(category);
      if (index >= 0) {
        const arr = vm.search.category.slice();
        arr.splice(index, 1);
        return arr;
      }
    }

    function splitDescription(rawDescription: string): string[] {
      let paragraphs = [];
      if (rawDescription) {
        rawDescription = rawDescription.replace(/\r\n|\r/g, "\n");
        paragraphs = rawDescription.split("\n\n");
      }
      $log.debug(paragraphs);
      return paragraphs;
    }

    function loadItems(response) {
      $log.debug("ITEMS LOADED", response);

      if (vm.galleryItems.length === 0) {
        saveState();
      }

      if (response.item) {
        goToDetail(response.item, { reload: true });
        return;
      }

      if (angular.isUndefined(vm.itemsCount)) {
        // Only need to set count the first time
        vm.itemsCount = response.total;
      }
      vm.nextParams = response.next_params;

      if (vm.galleryItemIds.length > 0) {
        // if last_hit contains an existing url_id it means we are getting dupes from ES
        // update last_hit and reload
        const last_url_id = response.next_params.last_hit[response.next_params.last_hit.length - 1];
        if (vm.galleryItemIds.indexOf(last_url_id) >= 0 && !vm.nextParams.sort) {
          loadMore();
          return;
        }
      }

      vm.description = response.description;
      vm.descriptions = splitDescription(vm.description);
      vm.catParams = _.clone(vm.search);
      delete vm.catParams.page;
      delete vm.catParams.size;
      delete vm.catParams.niche;
      delete vm.catParams.prompt;

      if ((!vm.categories._order || vm.categories._order.length === 0) && response.categories) {
        $log.debug("GALLERY DATA about to set categories");
        vm.categories = response.categories;
        GalleryData.set("categories", response.categories);
        // vm.main_category = null;
        // vm.main_categories = [];
        // initCategories();
      }

      updateGalleryItems(response.results);

      if (vm.galleryItems.length >= vm.itemsCount || response.count === 0) {
        $log.debug("Reached end of gallery - disabling scrolling");
        vm.disableScroll = true;
        $rootScope.$broadcast(DATA_EVENTS.showFooter, true);
      }

      if (vm.galleryItems.length >= pageCountTrigger) {
        const page = $state.href($state.current) + "?scroll=" + pageCount;
        $window.gtag('event', 'page_view', {
          page_location: page,
        });

        pageCount++;
      }

      vm.itemsLoading = false;
      vm.loadingMore = false;

      $log.debug("Showing: ", vm.galleryItems.length, "items out of", vm.itemsCount);
    }

    function errorLoadingItems() {
      $log.error("Error loading items");
      vm.itemsLoading = false;
      vm.galleryItems = [];
      // TODO log to sentry?
    }

    /** Reload items */
    function reloadItems(searchParams: object) {
      vm.itemsLoading = true;
      vm.galleryItems = [];
      vm.galleryItemIds = [];
      vm.categories = {};
      vm.searchSize = 11;
      if (searchParams.niche) {
        if (searchParams.section === "events") {
          searchParams.sort = "date_start"
        }

        $log.debug("Reloading items", searchParams);
        const result = Gallery.query(searchParams, loadItems, errorLoadingItems);
        if (searchParams["#"] || isSearch(searchParams) || searchParams.uid || searchParams.rawId || searchParams.key) {
          result.$promise.finally(function() {
            vm.scrollTo(searchParams["#"]);
          });
        }
      }
    }

    function errorLoadingMoreItems() {
      $log.error("Error loading more items");
      vm.loadingMore = false;
    }

    function shouldPromptRegister() {
      if (
        MAX_UNAUTHENTICATED_SCROLLS !== null &&
        !AuthService.isAuthenticated() &&
        pageCount >= MAX_UNAUTHENTICATED_SCROLLS
      ) {
        return true;
      }
      return false;
    }

    function loadMore() {
      if (!vm.loadingMore && vm.nextParams) {
        vm.loadingMore = true;

        if (shouldPromptRegister()) {
          $state.go("login", { showLogin: false });
        }

        _.merge(vm.search, vm.nextParams);
        vm.search.size = 11; // after initial load load some more
        $log.debug("Loading next page ");
        Gallery.query(vm.search, loadItems, errorLoadingMoreItems);
      }
    }

    function updateGalleryItems(newItems) {
      let adCount = 0;
      const timeoutKey = Math.random();
      vm.adTimeOuts[timeoutKey] = false;

      for (let i = 0; i < newItems.length; i++) {
        // do not allow duplicates
        if (vm.galleryItemIds.indexOf(newItems[i].url_id) >= 0) {
          continue;
        }

        vm.galleryItemIds.push(newItems[i].url_id);
        if (vm.initialItemOpened) {
          if (vm.initialItem.id.toString() === newItems[i].id.toString()) {
            vm.clearCount = 0;
            continue;
          }
        }

        if (!vm.supportsGrid && vm.galleryItems.length > 1 && vm.clearCount % 3 === 0) {
          vm.galleryItems.push({
            id: "clear-" + vm.clearCount,
            view: "clear",
            adClasses: "clear-" + (vm.clearCount % 2)
          });
        }

        if (vm.enableBroadstreet) {
          let ad = null;
          if (vm.galleryItems.length > 0 && vm.galleryItems.length < 6) {
            if (vm.galleryItems.length % vm.adSpacingMobile1 === 0) {
              ad = { zone: vm.zones.galleryMobile1, adClasses: "sm:hidden" };
            }
            if (vm.galleryItems.length % vm.adSpacingMobile2 === 0) {
              ad = { zone: vm.zones.galleryMobile2, adClasses: "sm:hidden" };
            }
          } else if (vm.galleryItems.length % vm.adSpacing === vm.adMod) {
            ad = { zone: vm.zones.gallery, adClasses: "" };
            vm.clearCount += 1;
          }

          if (ad) {
            ad.id = "ad-" + adCount + "-" + newItems[i].id;
            ad.timeout = timeoutKey;
            ad.view = "advertisement";
            vm.galleryItems.push(ad);
            vm.itemsCount++; // increment items count so we can reliably detect end of gallery
            adCount += 1;

            if (!vm.supportsGrid && vm.galleryItems.length > 1 && vm.clearCount % 3 === 0) {
              vm.galleryItems.push({
                id: "clear-" + vm.clearCount,
                view: "clear",
                adClasses: "clear-" + (vm.clearCount % 2)
              });
            }
          }
        }

        newItems[i].view = "tile";
        vm.galleryItems.push(newItems[i]);
        vm.clearCount += 1;
      }

      vm.galleryItemEnd = vm.galleryItemStart + vm.columnCount * vm.visibleRows;

      if (vm.enableBroadstreet) {
        $window.requestAnimFrame(function() {
          vm.adTimeOuts[timeoutKey] = true;
        });
      }
    }

    function changeMode(mode: number, noBroadcast = false) {
      /** Set display mode */

      if (mode !== vm.mode) {
        vm.mode = mode;
        // Close item description pane if it's open
        if ($state.params.id) {
          closeDetail();
        }

        // Set proper layout variables for the chosen display mode
        switch (vm.mode) {
          case 5:
            vm.colsCount = 6;
            vm.titleLength = 13;
            vm.galleryCssClasses = "col-sm-2";
            break;
          case 1:
            vm.colsCount = 1;
            vm.titleLength = 20;
            vm.galleryCssClasses = "col-sm-12";
            break;
          case 3:
          default:
            vm.colsCount = 3;
            vm.titleLength = 20;
            vm.galleryCssClasses = "col-sm-4";
            break;
        }

        if (!noBroadcast) {
          $rootScope.$broadcast(DATA_EVENTS.galleryParamsChange, { mode: vm.mode }, false);
        }
      }
    }

    function updateLikeCount(likeCount: number) {
      if (vm.showDetailsFor) {
        // Find the item being liked in detail and update the count
        const idx = _.findIndex(vm.galleryItems, ["id", vm.showDetailsFor]);
        const item = vm.galleryItems[idx];
        if (item) {
          item.like_count = likeCount;
        }
      }
    }

    function toggleTileDesign() {
      vm.settings.TILE_DESIGN_SELECT = vm.settings.TILE_DESIGN_SELECT === "card" ? "minimal" : "card";
    }
  }
})();
