(function() {
  "use strict";

  angular.module("marketPlace.auth").controller("ResetPasswordController", resetPasswordController);

  resetPasswordController.$inject = [
    "$scope",
    "$uibModalInstance",
    "$transition$",
    "$log",
    "AuthService",
    "flash",
    "SITE_NAME"
  ];

  function resetPasswordController($scope, $uibModalInstance, $transition$, $log, AuthService, flash, SITE_NAME) {
    const vm = this;
    vm.email = $transition$.params().email;
    vm.siteName = SITE_NAME;
    vm.form = {};
    vm.errors = {};
    vm.resetPassword = resetPassword;

    function resetPassword() {
      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }
      AuthService.resetPassword(vm.email).then(
        function() {
          flash.success("Password reset email sent. Please check your email.");
          $uibModalInstance.close(true);
        },
        function(resp) {
          if (resp.data) {
            angular.forEach(resp.data, function(errors, field) {
              $log.debug(field + " : " + errors);
              vm.errors.non_field_errors = errors.join(", ");
            });
          } else {
            vm.errors.non_field_errors = "Sorry there was a problem resetting your password, please try again";
          }
          $scope.$broadcast("show-errors-check-validity");
        }
      );
    }
  }
})();
