import { IHttpResponse, IPromise } from "angular";

(function() {
  "use strict";

  angular.module("marketPlace.auth").factory("AuthService", AuthService);

  AuthService.$inject = [
    "$q",
    "$log",
    "$http",
    "$cookies",
    "$rootScope",
    "User",
    "$window",
    "Organisation",
    "Groups",
    "Registration",
    "API_URL",
    "AUTH_EVENTS",
    "$auth",
    "toastr",
    "$uibModalStack",
    "AppConfig",
    "CacheFactory",
    "MAX_UNAUTHENTICATED_OPENS",
    "Raven",
    "GoogleAnalyticsId"
  ];

  function AuthService(
    $q,
    $log,
    $http,
    $cookies,
    $rootScope,
    User,
    $window,
    Organisation,
    Groups,
    Registration,
    API_URL,
    AUTH_EVENTS,
    $auth,
    toastr,
    $uibModalStack,
    AppConfig,
    CacheFactory,
    MAX_UNAUTHENTICATED_OPENS,
    Raven,
    GoogleAnalyticsId
  ) {
    function PromiseCacheFactory($q) {
      let deferred = {};
      let inProgress = {};

      function makeKey(params) {
        const context = params.context ? params.context : "";
        const varyBy = params.varyBy ? params.varyBy.toString() : "";
        return context + params.fn.toString() + varyBy;
      }

      function create(params) {
        const key = makeKey(params);

        if (inProgress.hasOwnProperty(key)) {
          return deferred[key].promise;
        }

        if (!deferred.hasOwnProperty(key) || params.refresh) {
          deferred[key] = $q.defer();
          inProgress[key] = true;
          params
            .fn()
            .then(function(data) {
              deferred[key].resolve(data);
            })
            .catch(function(data) {
              if (deferred[key]) {
                deferred[key].reject(data);
              }
            })
            .finally(function() {
              delete inProgress[key];
            });
        }
        return deferred[key].promise;
      }

      function clear() {
        // need to explicitly reject all inProgress promises
        // otherwise cache will fill up after being cleared
        for (const p in inProgress) {
          if (inProgress.hasOwnProperty(p)) {
            deferred[p].reject(null);
          }
        }
        inProgress = {};
        deferred = {};
      }

      return {
        makeKey: makeKey,
        create: create,
        clear: clear
      };
    }

    const PromiseCache = PromiseCacheFactory($q);
    if (!CacheFactory.get("routeCount")) {
      CacheFactory.createCache("routeCount", { storageMode: "localStorage" });
    }

    const routeCache = CacheFactory.get("routeCount");

    return {
      hasPermission: hasPermission,
      isOrganisationUser: isOrganisationUser,
      login: login,
      redirectToLandingPage: redirectToLandingPage,
      logout: logout,
      resetPassword: resetPassword,
      resetPasswordConfirm: resetPasswordConfirm,
      activateUser: activateUser,
      resendActivation: resendActivation,
      isAuthenticated: isAuthenticated,
      getCurrentUser: getUser,
      getGroups: getGroups,
      getOrganisations: getOrganisations,
      clearCache: clearCache,
      shouldPromptRegister: shouldPromptRegister,
      clearCachedRoutes: clearCachedRoutes,
      createNewUser: createNewUser,
      register: register,
      socialLogin: socialLogin,
      isAdmin: isAdmin
    };

    function clearCache() {
      PromiseCache.clear();
    }

    function getUser() {
      return PromiseCache.create({
        context: "user",
        fn: function() {
          const promise = User.get().$promise;
          promise.then(function(user) {
            // ensure we set the ga user id when the promise is resolved
            try {
              $window.gtag('config', GoogleAnalyticsId, {
                'user_id': user.id
              });
            } catch (e) {
              $log.error("Unable to set ga user id", e);
            }
          });
          return promise;
        }
      });
    }

    function getOrganisations() {
      return PromiseCache.create({
        context: "organisations",
        fn: function() {
          return Organisation.query().$promise;
        }
      });
    }

    function getGroups() {
      return PromiseCache.create({
        context: "groups",
        fn: function() {
          return Groups.query({ simple: true });
        }
      });
    }

    function hasPermission(permission) {
      const deferred = $q.defer();
      if (isAuthenticated()) {
        getUser().then(
          function(user) {
            let permissionFound = false;
            try {
              for (let i = 0; i < user.groups.length; i++) {
                // search for permission in all groups, breaking out as soon as it's found
                if (user.groups[i].permissions.includes(permission)) {
                  permissionFound = true;
                  break;
                }
              }
            } catch (ex) {
              $log.debug("Unable to query user permissions", ex);
              // need the extra semi-colon as the the debug above will get removed during build leaving an empty catch // eslint-disable-line no-extra-semi
            } finally {
              if (permissionFound) {
                $log.debug("User has permission " + permission);
                deferred.resolve("User has permission " + permission);
              } else {
                $log.debug("User does not have permission " + permission);
                deferred.reject("User does not have permission " + permission);
              }
            }
          },
          function() {
            $log.error("Unable to query user permissions");
            deferred.reject("Unable to query user permissions");
          }
        );
      } else {
        $log.debug("User not authenticated");
        deferred.reject("User not authenticated");
      }
      return deferred.promise;
    }

    function isOrganisationRole(test, role) {
      const deferred = $q.defer();
      if (isAuthenticated()) {
        getOrganisations().then(
          function(organisations) {
            if (organisations.length > 0) {
              const organisation = organisations[0];
              if (test(organisation)) {
                $log.debug("User is", role);
                deferred.resolve("User is", role);
              } else {
                $log.debug("User is not", role);
                deferred.reject("User is not", role);
              }
            } else {
              $log.debug("User has no organisations");
              deferred.reject("User has no organisations");
            }
          },
          function() {
            $log.error("Unable to query user organisations");
            deferred.reject("Unable to query user organisations");
          }
        );
      } else {
        deferred.reject("User not authenticated");
      }
      return deferred.promise;
    }

    function isOrganisationUser() {
      return isOrganisationRole(function(org) {
        return !org.is_admin && !org.is_owner;
      }, "org user");
    }

    function login(user, rememberMe: boolean): IPromise<any> {
      $rootScope.$broadcast(AUTH_EVENTS.loginStarted);
      const deferred = $q.defer();

      $auth
        .login(user)
        .then(function() {
          $log.debug("Successful login");
          toastr.success("You have successfully signed in!");
          
          try {
            $window.gtag("event", "login");
          } catch (e) {
            ;
          }

          Raven.setUserContext({
            email: user.email
          });

          if (rememberMe) {
            const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            $cookies.put("email", user.email, { expires: expires });
          }

          clearCachedRoutes();

          getUser().then(function(loggedInUser) {
            $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, {
              user: loggedInUser
            });
            deferred.resolve(loggedInUser);
          });
        })
        .catch(function(resp: IHttpResponse<any>) {
          $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
          if (resp && resp.data) {
            deferred.reject(resp.data);
          } else {
            deferred.reject();
            $log.debug(resp);
            toastr.error("Sorry there was a problem logging in");
          }
        });

      return deferred.promise;
    }

    function socialLoginSuccess() {
      toastr.success("You have successfully signed in with " + this.provider + "!");

      try {
        $window.gtag("event", "login", {method: this.provider});
      } catch (e) {
        ;
      }

      const payload = $auth.getPayload();
      Raven.setUserContext({
        email: payload.email
      });

      getUser().then(function(loggedInUser) {
        $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, {
          user: loggedInUser
        });
        this.deferred.resolve(loggedInUser);
      });

      clearCachedRoutes();
    }

    function socialError(error) {
      if (error.data && error.status) {
        toastr.error(error.data.message, error.status);
      } else if (!error.message) {
        toastr.error("Sorry there was a problem logging in");
      }
      $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
      this.deferred.reject(error); // reject so whatever calls AuthService.social login can catch
    }

    function socialLogin(provider) {
      const deferred = $q.defer();
      $auth
        .authenticate(provider)
        .then(socialLoginSuccess.bind({ provider: provider, deferred: deferred }))
        .catch(socialError.bind({ deferred: deferred }));
      return deferred;
    }

    function redirectToLandingPage() {
      // really only need this function so that tests
      // can mock it easily
      $log.debug("GOING TO ", AppConfig.LogoutURL);
      $window.location.href = AppConfig.LogoutURL;
    }

    function logout(notify, dismissModals, goToLogoutURL) {
      notify = typeof notify !== "undefined" ? notify : true;
      dismissModals = typeof dismissModals !== "undefined" ? dismissModals : true;
      goToLogoutURL = typeof goToLogoutURL !== "undefined" ? goToLogoutURL : true;

      $log.debug(
        "Logging out: notify - " + notify + ", dismissModals - " + dismissModals + ", goToLogoutURL - " + goToLogoutURL
      );

      const _authService = this;

      // clear the promise cache on logout because we will need to
      // retrieve the user and organisations again
      const deferred = $q.defer();
      $auth.logout().then(function() {
        PromiseCache.clear();
        $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
        Raven.setUserContext();
        try {
          $window.gtag('config', GoogleAnalyticsId, {
            'user_id': null
          });
        } catch (e) {
          ;
        }
        if (notify) {
          toastr.info("You have been logged out");
        }
        if (dismissModals) {
          $uibModalStack.dismissAll();
        }
        if (goToLogoutURL) {
          deferred.promise.then(function() {
            _authService.redirectToLandingPage();
          });
        }
        deferred.resolve();
      });
      return deferred.promise;
    }

    function resetPassword(email) {
      return $http({
        url: API_URL + "/api/auth/password/reset/",
        method: "POST",
        data: {
          email: email
        }
      });
    }

    function resetPasswordConfirm(passwordData) {
      return $http({
        url: API_URL + "/api/auth/password/reset/confirm/",
        method: "POST",
        data: passwordData
      });
    }

    function activateUser(activationData) {
      return $http({
        url: API_URL + "/api/auth/activate/",
        method: "POST",
        data: activationData
      }).then(function() {
        PromiseCache.clear();
      });
    }

    function resendActivation() {
      return $http({
        url: API_URL + "/api/auth/activate/resend/",
        method: "POST"
      });
    }

    function isAuthenticated() {
      return $auth.isAuthenticated();
    }

    function shouldPromptRegister(stateName, stateParams) {
      if (MAX_UNAUTHENTICATED_OPENS !== null && !$auth.isAuthenticated()) {
        const routes = routeCache.get("routes") || [];

        routes.push({ name: stateName, params: stateParams });
        routeCache.put("routes", routes);

        if (routes.length > MAX_UNAUTHENTICATED_OPENS) {
          return true;
        }
      }
      return false;
    }

    function clearCachedRoutes() {
      const routes = routeCache.get("routes") || [];

      if (routes.length > 0) {
        routeCache.remove("routes");
        return routes[routes.length - 1];
      }

      return null;
    }

    function createNewUser(groups) {
      return new Registration({
        groups: groups,
        receive_emails: true,
        accept_terms: true
      });
    }

    function registrationSuccess(resp) {
      $log.debug("Successful registration");
      $log.debug(resp);

      // login automatically after registering by setting token
      $auth.setToken({ data: resp });

      try {
        $window.gtag("event", "sign_up");
      } catch (e) {
        ;
      }

      // Set context for Sentry
      const payload = $auth.getPayload();
      Raven.setUserContext({
        email: payload.email
      });

      return resp;
    }

    function registerError(resp) {
      $log.debug("Could not register");

      if (!resp.data || resp.status === 500) {
        toastr.error("Sorry there was a problem with your registration");
      }
      throw resp.data;
    }

    function register(user) {
      return user.$save(registrationSuccess).catch(registerError);
    }

    function isAdmin() {
      const deferred = $q.defer();
      if (isAuthenticated()) {
        getUser().then(
          function(user) {
            $log.debug("Groups:", user.groups);
            const adminRegex = RegExp("admin", "i");
            let found = false;
            for (let i = 0; i < user.groups.length; i++) {
              // search for admin group, break out as soon as one is found
              if (adminRegex.test(user.groups[i].name)) {
                found = true;
                break;
              }
            }
            if (found) {
              $log.debug("User is admin");
              deferred.resolve("User is admin");
            } else {
              $log.debug("User is not admin");
              deferred.reject("User is not admin");
            }
          },
          function() {
            $log.error("Unable to query user permissions");
            deferred.reject("Unable to query user permissions");
          }
        );
      } else {
        $log.debug("User not authenticated");
        deferred.reject("User not authenticated");
      }
      return deferred.promise;
    }
  }
})();
