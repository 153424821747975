(function() {
  "use strict";

  angular
    .module("marketPlace.organisations")
    .controller("DashboardOrganisationController", DashboardOrganisationController);

  DashboardOrganisationController.$inject = [
    "$scope",
    "$log",
    "$filter",
    "$state",
    "$transition$",
    "$anchorScroll",
    "$q",
    "user",
    "AppConfig",
    "Organisation",
    "CountryName",
    "LinkTypes",
    "Preview",
    "Address",
    "toastr",
    "AuthService",
    "flash",
    "OrganisationForm",
    "SITE_NAME"
  ];

  function DashboardOrganisationController(
    $scope,
    $log,
    $filter,
    $state,
    $transition$,
    $anchorScroll,
    $q,
    user,
    AppConfig,
    Organisation,
    CountryName,
    LinkTypes,
    Preview,
    Address,
    toastr,
    AuthService,
    flash,
    OrganisationForm,
    SITE_NAME
  ) {
    const vm = this;
    let defaultLinkType = "";
    const stateParams = $transition$.params();

    vm.saving = false;
    vm.errors = {};
    vm.organisationImageLimit = AppConfig.organisationImageLimit;
    vm.googleMapsUrl = "//maps.googleapis.com/maps/api/js?key=" + AppConfig.GoogleAPIKey + "&libraries=places";
    vm.organisation = null;
    vm.associations = null;
    vm.linkTypes = null;
    vm.countries = [];
    vm.types = "['address']";
    vm.sponsor = null;
    vm.club = null;
    vm.orgFileQueue = [];
    vm.isLoaded = false;
    vm.created = false;
    vm.showWelcomeMessage = false;

    // pre-resolved dependencies
    vm.user = user;
    vm.siteName = SITE_NAME;

    // functions
    const changeHandlers = OrganisationForm.getChangeHandlers(vm);
    vm.setNiche = changeHandlers.setNiche;
    vm.setSection = changeHandlers.setSection;
    vm.setGallery = changeHandlers.setGallery;
    vm.setCaption = changeHandlers.setCaption;
    vm.setCaptionOption = changeHandlers.setCaptionOption;
    vm.save = save;
    vm.copyAddress = Address.copyAddress;
    vm.deletePostalAddress = deletePostalAddress;
    vm.openPreview = Preview.open;
    vm.addLink = addLink;
    vm.removeLink = removeLink;
    vm.orgPlaceChanged = orgPlaceChanged;
    vm.Address = Address;
    vm.addImage = addImage;

    // forms
    vm.organisationForm = {};
    vm.organisationForm.form = {};

    activate();

    function activate() {
      const orgPromise = Organisation.getOrCreate().$promise.then(function(organisation) {
        vm.organisation = organisation;
        if (stateParams.new && !vm.organisation.published) {
          vm.showWelcomeMessage = true;
        }
        $log.debug("ORGANISATION", vm.organisation);
        if (!organisation.hasOwnProperty("id")) {
          vm.created = true;
        } else {
          vm.copyOrgAddress = !Address.hasPostalAddress(vm.organisation);
        }

        padLinks();
      });

      const nichePromise = OrganisationForm.getWritableNiches().then(function(niches) {
        vm.niches = niches;
      });

      const countryPromise = CountryName.present_day().then(function(countries) {
        vm.countries = countries;
      });

      const linkTypePromise = LinkTypes.query().$promise.then(function(linkTypes) {
        vm.linkTypes = linkTypes;
        if (vm.linkTypes.length > 0) {
          defaultLinkType = vm.linkTypes[0].name;
        }
      });

      $q.all([orgPromise, nichePromise, countryPromise, linkTypePromise]).then(updateLoading);
    }

    function updateLoading() {
      if (vm.created) {
        if (vm.niches._order.length === 1) {
          vm.organisation.niche_option = vm.niches._order[0];
          changeHandlers.setNiche();
        }
      } else {
        // sets select boxes to based on existing caption
        changeHandlers.setCaption();
      }

      vm.isLoaded = true;
    }

    $scope.$watch("vm.files", function() {
      if (vm.files) {
        vm.orgFileQueue = [];
        const currentLimit = vm.organisationImageLimit - vm.organisation.images.length;
        for (let i = 0; i < Math.min(vm.files.length, currentLimit); i++) {
          vm.orgFileQueue.push(vm.files[i]);
        }
        queueOrgImage();
      }
    });

    function save(form, publish: boolean) {
      $log.debug("saving organisation", vm.user.physical_address_country);

      if (form.hasOwnProperty("images") && (!vm.organisation.images || vm.organisation.images.length === 0)) {
        form.images.$setValidity("required", false);
      }

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        if (form.photo && form.photo.$invalid) {
          $log.debug("PHOTO INVALID");
          form.photo.$dirty = true;
        }

        if (form.hasOwnProperty("address")) {
          if (
            !Address.hasPostalAddress(vm.organisation) &&
            !form.address.$invalid &&
            !Address.hasAddress(vm.organisation)
          ) {
            form.address.$error.required = true;
            form.address.$invalid = true;
          }
        }
        $log.debug("form is invalid");
        $anchorScroll();
        return;
      } else {
        vm.errors = {};
      }

      vm.saving = true;

      saveOrganisation(publish)
        .then(
          function() {
            $scope.$broadcast("show-errors-reset");
            if (vm.created) {
              AuthService.clearCache();
              flash.success("Organisation updated");
              if (vm.user.is_profile_complete) {
                $state.go(".", {}, { reload: true });
              } else {
                $state.go("home.dashboard.profile");
              }
            } else {
              toastr.success("Organisation updated");
            }
            form.$setPristine();
          },
          function(resp) {
            $log.debug("Error saving tab");

            if (resp.data && resp.status !== 500) {
              angular.forEach(resp.data, function(errors, field) {
                $log.debug(field + " : " + errors);
                try {
                  form[field].$setValidity("server", false);
                  if (angular.isObject(errors)) {
                    angular.forEach(errors, function(subErrors, field) {
                      vm.errors[field] = subErrors.join(", ");
                    });
                  } else {
                    vm.errors[field] = errors.join(", ");
                  }
                } catch (e) {
                  $log.error(resp.status + ": " + resp.statusText);
                  if (angular.isArray(errors)) {
                    errors = errors.join(", ");
                  }
                  vm.errors.non_field_errors = errors;
                }
              });
            } else {
              $log.error(resp.status + ": " + resp.statusText);
              vm.errors.non_field_errors = "Sorry there was a problem saving your changes, please try again";
            }
            $scope.$broadcast("show-errors-check-validity");
          }
        )
        .finally(function() {
          padLinks();
          vm.saving = false;
        });
    }

    function saveOrganisation(publish: boolean) {
      $log.debug("saving organisation");

      if (publish !== undefined && publish) {
        vm.organisation.published = true;
      }

      vm.organisation.social_media_links = $filter("filter")(vm.organisation.social_media_links, function(value) {
        return !!value.url;
      });

      if (!(vm.organisation.id === null || angular.isUndefined(vm.organisation.id))) {
        return vm.organisation.$update();
      } else {
        return vm.organisation.$save();
      }
    }

    function addLink() {
      vm.organisation.social_media_links.push({
        url: "",
        link_type: defaultLinkType
      });
    }

    function removeLink() {
      vm.organisation.social_media_links.pop();
    }

    function padLinks() {
      // pad links so there is always one showing
      if (vm.organisation && angular.isUndefined(vm.organisation.social_media_links)) {
        vm.organisation.social_media_links = [];
      }

      if (!vm.organisation.social_media_links.length) {
        addLink();
      }
    }

    function queueOrgImage() {
      $log.debug("Queueing org image to crop. " + vm.orgFileQueue.length + " remaining");
      vm.orgPhoto = vm.orgFileQueue.shift();
    }

    function placeChanged(place, obj) {
      const address = Address.convert(place);

      obj.physical_address_line1 = address.line1;
      obj.physical_address_line2 = address.line2;
      obj.physical_address_city = address.city;
      obj.physical_address_state = address.state;
      obj.physical_address_country = address.country;
      obj.physical_address_postcode = address.postcode;
    }

    function orgPlaceChanged() {
      vm.orgPlace = this.getPlace(); // eslint-disable-line angular/controller-as-vm
      placeChanged(vm.orgPlace, vm.organisation);
    }

    function deletePostalAddress(obj) {
      Address.deletePostalAddress(obj);
      obj.postal_address_country = "";
    }

    function addImage() {
      vm.organisationForm.form.images.$setValidity("required", true);
    }
  }
})();
