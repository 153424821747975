import { IHttpService, ILocationService } from "angular";

class Analytics {
  url: string;

  static $inject = ["$http", "$location", "API_URL"];

  constructor(public $http: IHttpService, public $location: ILocationService, API_URL: string) {
    this.url = API_URL + "/api/analytics/";
  }

  getUrl(endpoint: string, objectId: number): string {
    return this.url + endpoint + "/" + objectId;
  }

  share(objectId: number, shareTo: string) {
    const apiUrl = this.getUrl("obj-share-view", objectId);
    const url = this.$location.absUrl().split("?")[0];
    return this.$http.post(apiUrl, { url: url, data: { shared_to: shareTo } });
  }

  view(objectId: number) {
    const apiUrl = this.getUrl("obj-detail-view", objectId);
    // get current url and strip off any query params
    const url = this.$location.absUrl().split("?")[0];
    return this.$http.post(apiUrl, { url: url });
  }
}

angular.module("marketPlace.analytics").service("Analytics", Analytics);
