(function() {
  "use strict";

  angular.module("marketPlace.auth").controller("NotificationController", NotificationController);

  NotificationController.$inject = ["$log", "$window", "Notifications", "moment"];

  function NotificationController($log, $window, Notifications, moment) {
    const vm = this;

    vm.pageChanged = pageChanged;
    vm.getTemplate = getTemplate;
    vm.getAge = getAge;

    activate();

    function activate() {
      vm.currentPage = 1;
      vm.itemsPerPage = 50;
      pageChanged();
    }

    function pageChanged(newPage?: number) {
      if (newPage) {
        vm.currentPage = newPage;
      }
      $log.debug("Page changed to: " + vm.currentPage);
      $window.scrollTo(0, 0);
      vm.loading = true;

      Notifications.query(
        {
          page: vm.currentPage
        },
        function(notifications) {
          vm.notifications = notifications;
          vm.loading = false;
        }
      );
    }

    function getTemplate(type) {
      return "./notifications/templates/" + type + ".html";
    }

    function getAge(notification_date) {
      return moment(notification_date).fromNow();
    }
  }
})();
