(function() {
  "use strict";

  angular.module("marketPlace.social_media_links").factory("LinkTypes", linkType);

  linkType.$inject = ["$resource", "API_URL"];

  function linkType($resource, API_URL) {
    return $resource(API_URL + "/api/link_types/");
  }
})();
