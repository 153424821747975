import { PreviousService } from "../auth/previous.service";

(function() {
  "use strict";
  angular.module("marketPlace.referral").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider
      .state("home.dashboard.referrals", {
        url: "/referrals",
        data: {
          requiresLogin: true,
          permissions: {
            only: "add_referral"
          }
        },
        controller: "ReferralListController",
        controllerAs: "vm",
        template: require("./templates/referrals.html"),
        resolve: {
          referrals: getReferrals
        }
      })
      .state("home.dashboard.referrals.add", {
        url: "/add",
        data: {
          requiresLogin: true,
          permissions: {
            only: "add_referral"
          }
        },
        resolve: {
          $transition$: "$transition$"
        },
        onEnter: createReferralModal
      });
  }

  createReferralModal.$inject = ["$uibModal", "PreviousService", "$log", "$transition$"];
  function createReferralModal($uibModal, PreviousService: PreviousService, $log, $transition$) {
    $log.debug("Creating referral modal");
    const modalInstance = $uibModal.open({
      animation: true,
      template: require("./templates/referral-modal.html"),
      controller: "ReferralController",
      controllerAs: "vm",
      size: "md"
    });

    PreviousService.invokeModalOnEnter(modalInstance, $transition$, false, false, "home.dashboard");
  }

  getReferrals.$inject = ["Referral"];
  function getReferrals(Referral) {
    return Referral.list().$promise;
  }
})();
