(function() {
  "use strict";

  angular.module("marketPlace.dashboard").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.orders", {
      url: "/orders",
      component: "ordersComponent",
      data: {
        requiresLogin: true
      }
    });
  }
})();
