(function() {
  "use strict";

  angular.module("marketPlace.watchdog").controller("WatchdogController", watchdogController);

  watchdogController.$inject = ["$log", "$uibModalInstance", "toastr", "item", "reasons", "Watchdog", "_", "SITE_NAME"];

  function watchdogController($log, $uibModalInstance, toastr, item, reasons, Watchdog, _, SITE_NAME) {
    const vm = this;
    vm.item = item;
    vm.reasons = reasons;
    vm.siteName = SITE_NAME;

    $log.debug("WATCHDOG", vm.item);

    vm.sections = _.reduce(
      vm.reasons,
      function(sections, reason, index) {
        if (sections.length === 0 || reason.section !== sections[sections.length - 1].name) {
          sections.push({
            name: reason.section,
            start: index,
            count: 0
          });
        }
        sections[sections.length - 1].count += 1;
        return sections;
      },
      []
    );

    vm.submit = submit;
    activate();

    function activate() {
      $log.debug("Watchdog controller", vm.sections);
    }

    function submit() {
      const selected = _.map(
        _.filter(vm.reasons, function(reason) {
          return reason.selected;
        }),
        function(reason) {
          return reason.id;
        }
      );

      const data: { [k: string]: any } = { text: vm.text, reasons: selected };
      if (vm.item.item_type === "ITEM") {
        data.item = vm.item.id;
      }
      if (vm.item.item_type === "EVENT") {
        data.event = vm.item.id;
      }
      if (vm.item.item_type === "ORGANISATION") {
        data.organisation = vm.item.id;
      }

      if (!data.item && !data.event) {
        $log.debug("WATCHDOG: no item selected");
        $uibModalInstance.close(true);
        return;
      }

      $log.debug("WATCHDOG", data);

      Watchdog.create(data, function() {
        $uibModalInstance.result.then(function() {
          toastr.success("Watchdog submission created");
        });
        $uibModalInstance.close(true);
      });
    }
  }
})();
