(function() {
  "use strict";

  const plan = {
    template: require("./templates/plan-detail.html"),
    bindings: {
      annualTerm: "<",
      isSubscribed: "<",
      product: "<",
      position: "<",
      subscribing: "<",
      selectProduct: "&",
      close: "&"
    },
    controller: planController
  };

  angular.module("marketPlace.payments").component("planDetail", plan);

  planController.$inject = ["$log"];

  function planController($log) {
    const $ctrl = this;

    $ctrl.$onChanges = function(changes) {
      let shouldChangeProducts = false;
      if (changes.annualTerm && changes.annualTerm.currentValue !== changes.annualTerm.previousValue) {
        $log.debug("planDetail $onChanges annualTerm changed: ", changes);
        shouldChangeProducts = true;
      }

      if (changes.product && changes.product.currentValue !== changes.product.previousValue) {
        $log.debug("planDetail $onChanges product changed: ", changes);
        $ctrl.productNameLower = $ctrl.product.name.toLowerCase();
        shouldChangeProducts = true;
      }

      if (shouldChangeProducts) {
        changeProducts();
      }

      $ctrl.isRecommended =
        ($ctrl.position === 1 && !$ctrl.product.current && !$ctrl.product.disabled && !$ctrl.isFree) ||
        $ctrl.product.recommend;
    };

    function changeProduct(id, price, priceParts) {
      $ctrl.productID = id;
      $ctrl.price = price;
      $ctrl.priceMajor = priceParts[0];
      $ctrl.priceMinor = priceParts[1];
    }

    function changeProducts() {
      if (
        (angular.isDefined($ctrl.product.monthlyPriceParts) && $ctrl.product.monthlyPriceParts.length) ||
        (angular.isDefined($ctrl.product.annualPriceParts) && $ctrl.product.annualPriceParts.length)
      ) {
        if ($ctrl.annualTerm) {
          changeProduct($ctrl.product.annualID, $ctrl.product.annual_price, $ctrl.product.annualPriceParts);
        } else {
          changeProduct($ctrl.product.id, $ctrl.product.monthly_price, $ctrl.product.monthlyPriceParts);
        }
      } else {
        $ctrl.isFree = true;
      }
    }

    $ctrl.select = function() {
      $ctrl.selectProduct({
        isCurrent: $ctrl.product.current,
        productPrice: $ctrl.price,
        productName: $ctrl.product.name,
        productId: $ctrl.productID
      });
    };
  }
})();
