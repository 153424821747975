(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("commentSection", commentSection);

  function commentSection() {
    return {
      template: require("./templates/comment-section.html"),
      scope: {
        comments: "=",
        user: "=",
        parentType: "=",
        parentId: "=",
        parentUserId: "="
      },
      controller: commentsController,
      controllerAs: "vm",
      bindToController: true
    };
  }

  commentsController.$inject = ["$log", "ItemComment", "toastr"];

  function commentsController($log, ItemComment, toastr) {
    const vm = this;
    vm.showComments = false;
    vm.loading = false;
    vm.showLoadMore = true;
    vm.showProfileLink = false;

    vm.addComment = addComment;
    vm.loadMore = loadMore;

    vm.$onInit = function() {
      $log.debug("commentsController");
      $log.debug(vm.comments);
      vm.totalComments = vm.comments.results.length;

      if (vm.totalComments > 0) {
        vm.latestComment = vm.comments.results.shift();
      }

      vm.remainingComments = vm.comments.totalCount - 1;
    };

    function addComment() {
      if (vm.form.$invalid) {
        return;
      }

      if (!vm.user.is_profile_complete) {
        vm.showProfileLink = true;
        return;
      }

      vm.addingComment = true;

      if (vm.parentType === "ITEM") {
        ItemComment.create(vm.parentId, vm.comment)
          .then(
            function(response) {
              $log.debug(response);
              if (response.status === 202) {
                toastr.warning("Your comment has been sent for moderation", "Approval required");
              } else {
                if (angular.isDefined(vm.latestComment) && vm.latestComment !== null) {
                  vm.comments.results.unshift(vm.latestComment);
                }
                vm.latestComment = response.data;
                vm.totalComments += 1;
                vm.remainingComments += 1;
              }
            },
            function(response) {
              $log.debug(response);
            }
          )
          .finally(function() {
            vm.addingComment = false;
          });

      }

      vm.comment = {};
      vm.form.$setPristine();
      vm.form.$setUntouched();
    }

    function loadMore() {
      if (!vm.showComments) {
        // Show the first comments returned when the item was loaded
        vm.showComments = true;
        vm.remainingComments = vm.comments.totalCount - vm.totalComments;
      } else {
        // Load more comments from the api
        vm.loading = true;

        ItemComment.get(vm.parentId, vm.comments.next)
          .then(
            function(response) {
              $log.debug(response);
              vm.comments.results = vm.comments.results.concat(response.data.results);
              vm.comments.next = response.data.next;
              vm.comments.previous = response.data.previous;
              vm.totalComments += response.data.results.length + 1; // Add 1 for latestComment
              vm.remainingComments = vm.comments.totalCount - vm.totalComments;
            },
            function(response) {
              $log.debug(response);
            }
          )
          .finally(function() {
            vm.loading = false;
          });
      }
    }
  }
})();
