(function() {
  "use strict";

  angular.module("marketPlace.lodash").factory("_", lodashFactory);

  lodashFactory.$inject = ["$window"];

  function lodashFactory($window) {
    if (!$window._) {
      // If lodash is not available you can now provide a
      // mock service, try to load it from somewhere else,
      // redirect the user to a dedicated error page, ...
      return _; //eslint-disable-line no-undef
    }
    return $window._;
  }
})();
