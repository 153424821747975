(function() {
  "use strict";

  angular.module("marketPlace.auth").controller("ResetPasswordConfirmController", resetPasswordConfirmController);

  resetPasswordConfirmController.$inject = [
    "$scope",
    "$uibModalInstance",
    "$transition$",
    "$log",
    "AuthService",
    "flash",
    "SITE_NAME"
  ];

  function resetPasswordConfirmController(
    $scope,
    $uibModalInstance,
    $transition$,
    $log,
    AuthService,
    flash,
    SITE_NAME
  ) {
    const vm = this;
    const stateParams = $transition$.params();
    vm.passwordData = {
      uid: stateParams.uid,
      token: stateParams.token
    };
    vm.siteName = SITE_NAME;
    vm.form = {};
    vm.errors = {};
    vm.saving = false;
    vm.resetPasswordConfirm = resetPasswordConfirm;

    function resetPasswordConfirm() {
      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }

      vm.saving = true;

      AuthService.resetPasswordConfirm(vm.passwordData)
        .then(
          function() {
            flash.success("Password reset.");
            $uibModalInstance.close(true);
          },
          function(resp) {
            if (resp.data) {
              angular.forEach(resp.data, function(errors, field) {
                $log.debug(field + " : " + errors);
                vm.errors.non_field_errors = errors.join(", ");
              });
            } else {
              vm.errors.non_field_errors = "Sorry there was a problem resetting your password, please try again";
            }
            $scope.$broadcast("show-errors-check-validity");
          }
        )
        .finally(function() {
          vm.saving = false;
        });
    }
  }
})();
