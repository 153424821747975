import { ILogService } from "angular";

angular.module("marketPlace.home").component("galleryHeader", {
  template: require("./templates/gallery-header.html"),
  bindings: {
    headerData: "<",
    keywords: "<?",
    zones: "<?",
    enableBroadstreet: "<?",
    softKeywords: "<?",
  },
  controller: galleryHeaderController,
});

galleryHeaderController.$inject = ["$log"];

function galleryHeaderController($log: ILogService) {
  const $ctrl = this;

  $ctrl.$onInit = () => {
    $log.debug("galleryHeader $onInit");

    $ctrl.activeBanner = 0;
    $ctrl.activeImages = [0, 0, 0, 0];
    $ctrl.showGalleryHeaderLinks = false;
    if ($ctrl.headerData) {
      $log.debug("GALLERY HEADER INIT");
    }
    $log.debug($ctrl.enableBroadstreet);
    $log.debug($ctrl.zones);
    $log.debug($ctrl.keywords);
  };

  $ctrl.$onChanges = () => {
    $ctrl.showGalleryHeaderLinks = false;
    $ctrl.showGalleryHeaderImages = false;
    if ($ctrl.headerData) {
      for (let i = 0; i < $ctrl.headerData.blocks.length; i++) {
        if ($ctrl.headerData.blocks[i].links.length > 0) {
          $log.debug("GALLERY HEADER links", $ctrl.headerData.blocks[i].links);
          $ctrl.showGalleryHeaderLinks = true;
        }

        if ($ctrl.headerData.blocks[i].images.length > 0) {
          $log.debug("GALLERY HEADER images", $ctrl.headerData.blocks[i].images);
          $ctrl.showGalleryHeaderImages = true;
        }

        if ($ctrl.showGalleryHeaderLinks && $ctrl.showGalleryHeaderImages) {
          break;
        }
      }
    }
  };
}
