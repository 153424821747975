/**
 * Created by carl on 22/03/16.
 */
(function() {
  "use strict";

  angular.module("marketPlace.common").filter("trusted", trusted);

  trusted.$inject = ["$sce"];

  /* @ngInject */
  function trusted($sce) {
    return trustedFilter;

    ////////////////

    function trustedFilter(url) {
      return $sce.trustAsResourceUrl(url);
    }
  }
})();
