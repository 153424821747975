(function() {
  "use strict";

  angular.module("marketPlace.comments").factory("EventComment", eventComment);

  eventComment.$inject = ["$resource", "API_URL"];

  function eventComment($resource, API_URL) {
    return $resource(API_URL + "/api/comments/events/:id/comment", { id: "@id" });
  }
})();
