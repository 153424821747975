class TileController {
  isYoutube = false;
  isVimeo = false;
  enableShoppingCart = false;
  enableAuctions = false;
  youTubeId: string = undefined;
  decimalPlaces: number = undefined;

  static $inject = ["ItemForm", "Features"];

  constructor(public ItemForm: any, public Features: any, public tileItem: any, public mode: number) {}

  $onInit() {
    this.enableShoppingCart = this.Features.shoppingCart;
    this.enableAuctions = this.Features.auctions;
    if (angular.isDefined(this.tileItem.image_type) && this.tileItem.image_type !== null) {
      this.isYoutube = this.tileItem.image_type.indexOf("youtube") === 0;
      this.isVimeo = this.tileItem.image_type.indexOf("vimeo") === 0;
    }

    if (this.isYoutube) {
      const { id } = this.ItemForm.parseVideo(this.tileItem.image_thumbnail);
      this.youTubeId = id;
    }

    if (this.tileItem.item_type === "ITEM" && !this.tileItem.category) {
      this.tileItem.sub_title = "";
    }

    if (!this.tileItem.description && angular.isDefined(this.tileItem.formatted_descriptions)) {
      if (
        this.tileItem.formatted_descriptions.length > 0 &&
        this.tileItem.formatted_descriptions[0].hasOwnProperty("description") &&
        this.tileItem.formatted_descriptions[0].description
      ) {
        this.tileItem.description = this.tileItem.formatted_descriptions[0].description;
      }
    }

    if (this.tileItem.sku && this.tileItem.price && this.tileItem.currency) {
      const price = parseFloat(this.tileItem.price);
      if (price >= 100 && price % 1 === 0) {
        this.decimalPlaces = 0;
      }
    }
  }
}

angular.module("marketPlace.controls").component("tile", {
  bindings: {
    tileItem: "<",
    mode: "<",
  },
  template: require("./templates/tile.html"),
  controller: TileController,
  controllerAs: "vm",
});
