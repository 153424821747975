import { ILogService } from "angular";

angular.module("marketPlace.auth").component("userType", {
  template: require("./templates/user-type.html"),
  bindings: {
    chooseOption: "&"
  },
  controller: userTypeController
});

userTypeController.$inject = ["$log"];

function userTypeController($log: ILogService) {
  const $ctrl = this;

  $ctrl.$onInit = function() {
    $log.debug("userType $onInit");
    $ctrl.individualType = "I";
    $ctrl.businessType = "B";
  };
}
