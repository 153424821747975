(function() {
  "use strict";

  angular.module("marketPlace.moderation").controller("ApproveItemController", approveItemController);

  approveItemController.$inject = ["$log", "$transition$", "$state", "Moderation", "flash"];

  function approveItemController($log, $transition$, $state, Moderation, flash) {
    activate();

    function activate() {
      $log.debug("ApproveItemController", $transition$.params());

      Moderation.approveItem($transition$.params()).then(
        function(response) {
          flash.success("Item approved");
          $state.go("home.detail-deprecated", {
            id: response.id,
            itemType: "item"
          });
        },
        function() {
          $state.go("home.invalid-token");
        }
      );
    }
  }
})();
