(function() {
  "use strict";

  angular.module("marketPlace.payments").factory("Payment", paymentService);

  paymentService.$inject = ["$resource", "API_URL"];

  function paymentService($resource, API_URL) {
    return $resource(
      API_URL + "/api/payments/:service/",
      { service: "@service" },
      {
        getToken: {
          method: "GET",
          isArray: false,
          params: {
            service: "client_token"
          }
        },
        subscribe: {
          method: "POST",
          isArray: false,
          params: {
            service: "subscribe"
          }
        },
        subscription: {
          method: "GET",
          isArray: false,
          params: {
            service: "subscription"
          }
        },
        updateSubscription: {
          method: "POST",
          isArray: false,
          params: {
            service: "update_subscription"
          }
        },
        getPrice: {
          method: "GET",
          isArray: false,
          params: {
            service: "price"
          }
        },
        products: {
          method: "GET",
          isArray: true,
          params: {
            service: "subscription_products"
          }
        }
      }
    );
  }
})();
