(function() {
  "use strict";
  angular.module("marketPlace.currency").factory("Currency", currency);

  currency.$inject = ["$resource", "API_URL"];

  function currency($resource, API_URL) {
    const r = $resource(API_URL + "/api/currency/");

    // Remove extra actions - currency is query only
    delete r.prototype.$get;
    delete r.prototype.$save;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
