(function() {
  "use strict";

  angular.module("marketPlace.events").controller("EventsAddController", EventsAddController);

  EventsAddController.$inject = [
    "$log",
    "$scope",
    "$uibModalInstance",
    "Event",
    "user",
    "moment",
    "PermAuthorization",
    "PermPermissionMap",
    "EventForm",
    "Preview",
    "CountryName",
    "AppConfig",
    "toastr",
    "SITE_NAME",
    "modalTitle",
    "ItemForm",
    "writableNiches"
  ];

  function EventsAddController(
    $log,
    $scope,
    $uibModalInstance,
    Event,
    user,
    moment,
    PermAuthorization,
    PermPermissionMap,
    EventForm,
    Preview,
    CountryName,
    AppConfig,
    toastr,
    SITE_NAME,
    modalTitle,
    ItemForm,
    writableNiches
  ) {
    const vm = this;

    // pre-resolved dependencies
    vm.eventExternalImageLimit = AppConfig.itemExternalImageLimit;
    vm.googleMapsUrl = "//maps.googleapis.com/maps/api/js?key=" + AppConfig.GoogleAPIKey + "&libraries=places";
    vm.modalTitle = modalTitle;
    vm.niches = writableNiches;
    vm.repeat = EventForm.getRepeat();
    vm.siteName = SITE_NAME;
    vm.user = user;

    vm.saving = false;
    vm.errors = {};
    vm.uploadProgressPercentage = 0;
    vm.niche = null;
    vm.gallery = null;
    vm.accept = "image/*";
    vm.pattern = "image/*";
    vm.price = 0;
    vm.paymentAmounts = [];
    vm.hasWhereAddress = false;
    vm.userCanAddURL = false;

    // Date controls
    vm.dateOptions = {
      formatYear: "yy",
      startingDay: 1
    };
    vm.dateControlsState = {
      dateStartOpen: false,
      dateEndOpen: false
    };

    // forms
    vm.eventForm = {};
    vm.eventForm.form = {};

    // event form methods
    const changeHandlers = EventForm.getChangeHandlers(vm);
    vm.save = save;
    vm.saveAndClose = saveAndClose;
    vm.getCountry = getCountry;
    vm.openPreview = Preview.open;
    vm.setNiche = changeHandlers.setNiche;
    vm.setSection = changeHandlers.setSection;
    vm.setGallery = changeHandlers.setGallery;
    vm.setGalleryOption = changeHandlers.setGalleryOption;
    vm.getPriceString = ItemForm.getPriceString;
    vm.next = next;
    vm.back = back;
    vm.create = create;
    vm.saveWithPayment = saveWithPayment;
    vm.onPaymentError = onPaymentError;
    vm.whereChanged = changeHandlers.whereChanged;
    vm.showPayment = showPayment;

    activate();
    function activate() {
      initializeNewEvent();
      if (vm.niches._order.length === 1) {
        vm.event.niche_option = vm.niches._order[0];
        changeHandlers.setNiche();
      }

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_event_video"] }))
        .then(function() {
          vm.accept = "image/*,video/*,video/mp4";
          vm.pattern = "image/*,video/*";
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_event_url"] }))
        .then(function() {
          vm.userCanAddURL = true;
        })
        .catch(angular.noop);
    }

    // watchers
    $scope.$watch("vm.files", function() {
      EventForm.upload(vm, $scope, vm.files);
    });

    // Looks like typeahead bug - model field is not
    // being cleared when the input is empty
    $scope.$watch("vm.event.country_name", function(newValue) {
      if (newValue === "") {
        delete vm.event.country_name;
      }
    });

    function getCountry(val: string) {
      return CountryName.present_day({ q: val }).$promise;
    }

    function save(form, files, close = false) {
      $log.debug("saving");
      $log.debug("form: " + form);
      $log.debug("files: " + files);

      let promise;

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        $log.debug("Form invalid");
        $log.debug(form.$error);
        return;
      }

      vm.saving = true;

      if (angular.isUndefined(vm.event.name)) {
        vm.event.name = vm.event.category.name;
      }

      // convert dates back to utc before saving
      const date_start = moment(vm.event.start.date).utc();
      const time_start = moment(vm.event.start.time).utc();
      const date_end = moment(vm.event.end.date).utc();
      const time_end = moment(vm.event.end.time).utc();
      date_start.hour(time_start.hour()).minute(time_start.minute())
      date_end.hour(time_end.hour()).minute(time_end.minute());

      vm.event.date_start = date_start.format();
      vm.event.date_end = date_end.format();
      vm.event.timezone = "UTC";

      if (vm.is_repeat && vm.repeat.frequency) {
        vm.event.repeat = vm.repeat[vm.repeat.frequency];
      }

      $log.debug("saving event");
      promise = vm.event.$save();

      $log.debug(promise);
      promise
        .then(
          function() {
            $scope.$broadcast("show-errors-reset");
            if (close) {
              $uibModalInstance.close(true);
            } else {
              form.$setPristine();
              const savedEvent = vm.event;
              initializeNewEvent();
              vm.event.gallery_option = savedEvent.gallery_option;
              vm.setGallery();
              vm.paying = false;
            }
          },
          function(resp) {
            $log.debug("Error saving tab");

            if (resp.data && resp.status === 402) {
              // show payment screen
              vm.paying = true;
              $log.debug(resp.data);
              vm.errors.payment_error = "Sorry there was a problem with your payment:" + resp.data.detail;
            } else if (resp.data && resp.status !== 500) {
              angular.forEach(resp.data, function(errors, field) {
                $log.debug(field + " : " + errors);
                try {
                  form[field].$setValidity("server", false);
                  if (angular.isArray(errors)) {
                    vm.errors[field] = errors.join(", ");
                  } else {
                    angular.forEach(errors, function(nested_errors) {
                      vm.errors[field] = nested_errors.join(", ");
                    });
                  }
                } catch (e) {
                  $log.error(resp.status + ": " + resp.statusText);
                  if (angular.isArray(errors)) {
                    errors = errors.join(", ");
                  }
                  vm.errors.non_field_errors = errors;
                }
              });
              vm.paying = false;
            } else {
              $log.error(resp.status + ": " + resp.statusText);
              vm.errors.non_field_errors = "Sorry there was a problem with your new listing, please try again";
              vm.paying = false;
            }
            $scope.$broadcast("show-errors-check-validity");
          }
        )
        .finally(function() {
          padCategories();
          vm.saving = false;
          vm.uploadFile = null;
        });
    }

    // TODO
    function padCategories() {
      while (vm.user.categories.length < 2) {
        vm.user.categories.push({ name: "" });
      }
    }

    function saveAndClose(form, files) {
      save(form, files, true);
    }

    function initializeNewEvent() {
      vm.event = new Event({
        category: {},
        where: {},
        sessions: [],
        external_images: []
      });

      //noinspection EqualityComparisonWithCoercionJS
      if (vm.user != null) {
        vm.event.user = vm.user.id;
      }

      vm.event.start = {
        date: moment().toDate(),
        time: moment().toDate()
      };

      vm.event.end = {
        date: moment().toDate(),
        time: moment().toDate()
      };
    }

    function getPaymentAmounts() {
      const amounts = [];
      if (vm.listing && vm.listing.amount > 0) {
        amounts.push({
          amount: vm.listing.amount,
          name: "Listing: " + vm.listing.name,
          feesIncluded: true
        });
      }

      $log.debug(amounts);

      return amounts;
    }

    function next(form, files, duplicate = false) {
      if (!vm.event.external_images || vm.event.external_images.length === 0) {
        form.external_images.$setValidity("required", false);
      } else {
        form.external_images.$setValidity("required", true);
      }

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        $log.debug("Form invalid");
        $log.debug(form.$error);
        return;
      }

      vm.eventForm.form = form;
      vm.files = files;

      if (!isPaymentRequired()) {
        return create(duplicate);
      }

      vm.paying = true;
      vm.paymentAmounts = getPaymentAmounts();
      vm.listingPrice = vm.listing && vm.listing.amount ? vm.listing.amount : 0;
    }

    function back() {
      vm.paying = false;
    }

    function saveWithPayment(payment) {
      if (payment) {
        vm.event.payment = payment;
      }
      save(vm.eventForm.form, vm.files, !vm.duplicate);
    }

    function onPaymentError(error) {
      toastr.error(error);
      vm.saving = false;
    }

    function create(duplicate = false) {
      vm.saving = true; // triggers payment directive save
      vm.duplicate = duplicate;

      if (!isPaymentRequired()) {
        save(vm.eventForm.form, vm.files, !vm.duplicate);
      }

      // payment directive will eventually call saveWithPayment
    }

    function isPaymentRequired() {
      return vm.listing.amount > 0;
    }

    function showPayment() {
      // Always show payment info when creating an event
      return true;
    }
  }
})();
