(function() {
  "use strict";
  angular.module("marketPlace.profile").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider
      .state("home.dashboard.profile", {
        url: "/profile",
        template: require("./templates/profile.html"),
        controller: "DashboardProfileController",
        controllerAs: "vm",
        data: {
          requiresLogin: true
        },
        resolve: {
          user: getUser,
          $transition$: "$transition$"
        }
      })

      .state("home.dashboard.clubs", {
        url: "/clubs",
        template: require("./templates/clubs.html"),
        controller: "ClubsController",
        controllerAs: "vm",
        data: {
          requiresLogin: true
        },
        resolve: {
          user: getUser
        }
      })

      .state("home.dashboard.associations", {
        url: "/associations",
        template: require("./templates/associations.html"),
        controller: "AssociationsController",
        controllerAs: "vm",
        data: {
          requiresLogin: true
        },
        resolve: {
          user: getUser
        }
      });
  }

  getUser.$inject = ["AuthService"];
  function getUser(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getCurrentUser();
    }
  }
})();
