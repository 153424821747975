import { IScope, ILogService } from "angular";

(function() {
  "use strict";

  angular.module("marketPlace.dashboard").factory("DashboardTable", dashboardTable);

  dashboardTable.$inject = ["$log"];

  function dashboardTable($log: ILogService) {
    function pageChanged(refreshCallback: Function, newPage: number) {
      if (newPage) {
        this.currentPage = newPage;
      }

      $log.debug("Page changed to: " + this.currentPage);
      this.loading = true;
      if (angular.isUndefined(this.search)) {
        this.search = { page: this.currentPage };
      } else {
        this.search.page = this.currentPage;
      }

      if (this.currentSort) {
        this.search.ordering = this.currentSort;
      }

      refreshCallback(this.search);
    }

    function sortChanged(refreshCallback: Function, sortValue: string) {
      if (this.currentSort === sortValue) {
        this.currentSort = "-" + sortValue;
      } else if (this.currentSort === "-" + sortValue) {
        this.currentSort = "";
      } else {
        this.currentSort = sortValue;
      }

      angular.bind(this, pageChanged, refreshCallback, 1)();
    }

    function expandAll($scope: IScope, expanded: boolean) {
      $scope.$broadcast("onExpandAll", {
        expanded: expanded
      });
    }

    return {
      sortChanged: sortChanged,
      pageChanged: pageChanged,
      expandAll: expandAll
    };
  }
})();
