(function() {
  "use strict";

  angular.module("marketPlace.gallery").factory("GalleryItem", galleryItem);

  galleryItem.$inject = ["$resource", "API_URL"];
  function galleryItem($resource, API_URL) {
    return $resource(
      API_URL + "/api/gallery/:section/:gallery/:caption/:itemType:id-:itemSlug",
      {
        section: "@section",
        gallery: "@gallery",
        caption: "@caption",
        id: "@id",
        itemType: "@itemType",
        itemSlug: "@itemSlug"
      },
      {
        noCaption: {
          method: "GET",
          isArray: false,
          url: API_URL + "/api/gallery/:section/:gallery/:itemType:id-:itemSlug",
          params: {
            item_type: "@item_type"
          }
        },
        deprecated: {
          method: "GET",
          isArray: false,
          url: API_URL + "/api/gallery/item/:item_type/:id",
          params: {
            item_type: "@item_type"
          }
        },
        preview: {
          url: API_URL + "/api/gallery/item/:item_type/:id",
          method: "GET",
          isArray: false,
          params: {
            item_type: "preview"
          }
        }
      }
    );
  }
})();
