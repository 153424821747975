(function() {
  "use strict";

  angular.module("marketPlace.moderation").controller("ApproveCommentController", approveCommentController);

  approveCommentController.$inject = ["$log", "$transition$", "$state", "Moderation", "flash"];

  function approveCommentController($log, $transition$, $state, Moderation, flash) {
    activate();

    function activate() {
      $log.debug("ApproveCommentController", $transition$.params());

      Moderation.approveComment($transition$.params()).then(
        function(response) {
          flash.success("Comment approved");
          $state.go("home.detail-deprecated", {
            id: response.item,
            itemType: "item"
          });
        },
        function() {
          $state.go("home.invalid-token");
        }
      );
    }
  }
})();
