(function() {
  "use strict";

  angular.module("marketPlace.referral").controller("ReferralListController", referralListController);

  referralListController.$inject = ["$log", "$state", "referrals", "Referral"];

  function referralListController($log, $state, referrals, Referral) {
    /*jshint validthis: true */
    const vm = this;
    vm.referrals = referrals;

    vm.pageChanged = pageChanged;

    activate();

    function activate() {
      vm.currentPage = 1;
      vm.itemsPerPage = 50;
      $log.debug("ReferralController");
    }

    function pageChanged() {
      $log.debug("Page changed to: " + vm.currentPage);
      $log.debug("Querying referrals made");
      Referral.list({}, function(referrals) {
        vm.referrals = referrals;
      });
    }
  }
})();
