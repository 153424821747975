(function() {
  "use strict";

  angular
    .module("marketPlace.content")
    .factory("Request", requestFactory)
    .factory("RequestTypes", requestTypeFactory);

  requestFactory.$inject = ["$resource", "API_URL"];
  function requestFactory($resource, API_URL) {
    // requests are create only
    const r = $resource(API_URL + "/api/requests/");

    // Remove extra actions
    delete r.prototype.$get;
    delete r.prototype.$query;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }

  requestTypeFactory.$inject = ["$resource", "API_URL"];
  function requestTypeFactory($resource, API_URL) {
    // requests types are query only
    const r = $resource(API_URL + "/api/request_types/");

    // Remove extra actions
    delete r.prototype.$get;
    delete r.prototype.$save;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
