import { StateService } from "@uirouter/angularjs";
import { IHttpResponse, IIntervalService, ILogService, IPromise, IScope, ITimeoutService } from "angular";
import { IToastrService } from "angular-toastr";
import { LotService } from "./lot.service";

interface AuctionSectionBindings {
  item: any;
  user: any;
}

interface Bid {
  amount: string;
}

interface AuctionSectionController extends AuctionSectionBindings {
  $onInit(): void;
}

class AuctionSectionControllerImpl implements AuctionSectionController {
  item: any;
  user: any;
  lot: any;
  hasBids: boolean;
  defaultCurrency: string;
  auctionOpen: boolean;
  stopCheck: IPromise<any>;
  stopRefresh: IPromise<any>;
  bids: Array<any>;
  auction_search_key: string;
  outBidAmount: string;
  form: any = {};
  bid: Bid = { amount: undefined };
  saving = false;
  loading = true;
  showBids = false;
  hasLeadingBid = false;
  timeoutInterval = 0;
  biddingStatus = "bidding";
  watchlist: any;
  inWatchlist: boolean;

  static $inject = [
    "$interval",
    "$log",
    "$scope",
    "$state",
    "$timeout",
    "toastr",
    "Lot",
    "AppConfig",
    "moment",
  ];

  constructor(
    public $interval: IIntervalService,
    public $log: ILogService,
    public $scope: IScope,
    public $state: StateService,
    public $timeout: ITimeoutService,
    public toastr: IToastrService,
    public Lot: LotService,
    public AppConfig: any,
    public moment,
  ) {
    this.defaultCurrency = AppConfig.defaultCurrency;
  }

  $onInit(): void {
    this.getLotDetails();
    if (this.item.hasOwnProperty("search_keys") && this.item.search_keys.length > 0) {
      for (let key of this.item.search_keys) {
        if (!key.match(/U[0-9]+A[0-9]+/i)) {
          this.auction_search_key = key;
          break;
        }
      }
    }
  }

  getLotDetails = () => {
    this.loading = true;
    this.stopLotRefresh();
    this.Lot.get(this.item.id)
      .then(
        function (response: IHttpResponse<any>) {
          this.lot = response.data;

          this.setBids(response.data.bids);

          if (this.lot.auto_bid) {
            // set minimum bid amount to $1 higher than current auto bid
            this.bid.amount = this.lot.auto_bid + 1;
          } else {
            this.bid.amount = Number(this.lot.next_bid);
          }

          const now = this.moment().utc();
          this.auctionStarted = this.hasAuctionStarted(now);
          this.auctionClosed = this.hasAuctionClosed(now);
          this.auctionOpen = this.isAuctionOpen(now);
          if (now.isBefore(this.lot.closes)) {
            this.startOpenCheck();
          }
          this.closing = this.moment(this.lot.closes).fromNow();
          this.$log.debug("Lot:", this.lot);
          if (this.auctionOpen) {
            this.startLotRefresh();
          }
        }.bind(this),
        function (error: IHttpResponse<any>) {
          if (error.status !== 404) {
            this.toastr.error("Error getting lot details");
            this.$log.debug(error);
          }
        }.bind(this)
      )
      .finally(
        function () {
          this.$timeout(
            function () {
              this.loading = false;
              this.timeoutInterval = 950;
            }.bind(this),
            this.timeoutInterval
          );
        }.bind(this)
      );
  };

  setBids = (bids) => {
    this.hasBids = !!bids.length;

    if (this.user) {
      let foundBid = false;
      this.hasLeadingBid = false;
      for (let i = 0; i < bids.length; i++) {
        if (bids[i].user.id === this.user.id) {
          if (i === 0) {
            this.hasLeadingBid = true;
            bids[i]["bidRowClasses"] = "font-normal text-green-dark";
          } else if (foundBid) {
            bids[i]["bidRowClasses"] = "font-normal text-black";
          } else {
            bids[i]["bidRowClasses"] = "text-red-dark";
          }
          foundBid = true;
        }
      }
    }
    this.bids = bids;
  };

  isAuctionOpen = (now = this.moment().utc()) => now.isBetween(this.lot.opens, this.lot.closes);
  hasAuctionStarted = (now = this.moment().utc()) => now.isAfter(this.lot.opens);
  hasAuctionClosed = (now = this.moment().utc()) => now.isAfter(this.lot.closes);

  startOpenCheck(): void {
    if (angular.isDefined(this.stopCheck)) return;

    this.stopCheck = this.$interval(
      function () {
        const isOpen = this.isAuctionOpen();
        if (this.auctionOpen && !isOpen) {
          this.toastr.info("Auction is now closed");
          this.stopOpenCheck();
          this.stopLotRefresh();
        } else if (!this.auctionOpen && isOpen) {
          this.toastr.info("Auction is now open");
        }
        this.auctionOpen = isOpen;
      }.bind(this),
      1000,
      0,
      false
    );
  }

  stopOpenCheck(): void {
    if (angular.isDefined(this.stopCheck)) {
      this.$interval.cancel(this.stopCheck);
      this.stopCheck = undefined;
    }
  }

  startLotRefresh(): void {
    if (angular.isDefined(this.stopRefresh)) return;

    this.stopRefresh = this.$interval(
      function () {
        if (!this.loading) {
          this.getLotDetails();
        }
      }.bind(this),
      30000
    );
  }

  stopLotRefresh(): void {
    if (angular.isDefined(this.stopRefresh)) {
      this.$interval.cancel(this.stopRefresh);
      this.stopRefresh = undefined;
    }
  }

  $onDestroy() {
    this.stopOpenCheck();
    this.stopLotRefresh();
  }

  confirmBid(autoBid = false): void {
    this.biddingStatus = autoBid ? "confirm_auto_bid" : "confirm_bid";
  }

  cancelBid() {
    this.biddingStatus = "bidding";
    // cancel
  }

  placeBid(autoBid = false): void {
    if (this.saving) {
      return;
    }

    this.biddingStatus = "bidding";

    if (!this.user) {
      this.$state.go("login", { showLogin: false }, { reload: true, inherit: false });
      return;
    }

    this.$scope.$broadcast("show-errors-check-validity");

    if (this.form.$invalid) {
      return;
    }

    this.saving = true;

    this.Lot.placeBid(this.item.id, this.bid.amount, autoBid)
      .then(
        function (response: IHttpResponse<any>) {
          this.toastr.success("Bid placed");
          this.$log.debug(response.data);
          this.hasBids = true;
          this.bid.amount = Number(response.data.next_bid);
          if (this.user && response.data.user && response.data.user !== this.user.id) {
            this.toastr.info("You have been out bid");
          }
          this.getLotDetails();
        }.bind(this),
        function (error: IHttpResponse<any>) {
          this.$log.debug(error);
          if (error.status === 400) {
            if (error.data.hasOwnProperty("non_field_errors") && error.data.non_field_errors) {
              for (let errorMsg of error.data.non_field_errors) {
                this.toastr.warning(errorMsg);
              }
            } else if (error.data.hasOwnProperty("amount") && error.data.amount) {
              // display the server error message if it's about the amount
              this.toastr.warning(error.data.amount[0]);
            } else {
              // generic 400 error message
              this.toastr.error("Error placing bid");
            }
          } else if (error.status === 403) {
            if (error.data.hasOwnProperty("detail") && error.data.detail) {
              // if the server sent a message with the 403 display it
              this.toastr.error(error.data.detail);
            } else {
              // generic 403 error message
              this.toastr.error("You do not have permission to place this bid");
            }
          } else {
            // All other errors
            this.toastr.error("Error placing bid");
          }

          this.hasBids = !!this.bids.length;
          this.bid.amount = Number(this.lot.next_bid);
        }.bind(this)
      )
      .finally(
        function () {
          this.saving = false;
        }.bind(this)
      );
  }

  userHasBeenOutBid = () => {
    if (this.user && !this.lot.auto_bid && !this.hasLeadingBid) {
      for (let bid of this.bids) {
        if (bid.user.id === this.user.id) {
          this.outBidAmount = bid.amount;
          return true;
        }
      }
    }
    return false;
  };

  reserveMet = () => {
    const reserveStatus = this.lot.reserve_status.toLowerCase();
    if (reserveStatus.startsWith("reserve met") || reserveStatus.startsWith("no reserve")) {
      return true;
    }
    return false;
  };

  reserveStatusClass = () => {
    if (this.reserveMet()) {
      return ["text-green-dark"];
    } else {
      return ["text-red-dark"];
    }
  };
}

class AuctionSection implements ng.IComponentOptions {
  public bindings: any;
  public controller: any;
  public template: string;

  constructor() {
    this.bindings = {
      item: "<",
      user: "<",
    };
    this.controller = AuctionSectionControllerImpl;
    this.template = require("./templates/auction-section.html");
  }
}

angular.module("marketPlace.auction").component("auctionSection", new AuctionSection());
