/**
 * Created by carl on 16/01/17.
 */
(function() {
  "use strict";

  angular.module("marketPlace.payments").controller("ChangePaymentMethodController", changePaymentMethodController);

  changePaymentMethodController.$inject = [
    "$log",
    "$scope",
    "$uibModalInstance",
    "toastr",
    "Payment",
    "PaymentMethod",
    "paymentMethod"
  ];

  function changePaymentMethodController(
    $log,
    $scope,
    $uibModalInstance,
    toastr,
    Payment,
    PaymentMethod,
    paymentMethod
  ) {
    const vm = this;
    vm.submit = submit;
    vm.cancel = onCancel;
    vm.onFormLoaded = onFormLoaded;
    vm.onError = onError;
    vm.loadingHostedFields = true;
    vm.processing = false;

    activate();

    function activate() {
      $log.debug("ChangePaymentMethodController");
    }

    function submit(payload) {
      vm.processing = true;

      const newPaymentMethod = new PaymentMethod({
        nonce: payload.nonce,
        token: paymentMethod.token
      });

      newPaymentMethod.$save(
        function() {
          $uibModalInstance.close(true);
          toastr.success("Payment method changed successfully");
        },
        function(response) {
          if (response.hasOwnProperty("data") && response.data.hasOwnProperty("detail")) {
            onError("Error updating payment method: " + response.data.detail);
          } else {
            onError("Error updating payment method, please try again.");
          }
        }
      );
    }

    function onCancel() {
      $uibModalInstance.dismiss("cancel payment");
    }

    function onFormLoaded() {
      vm.loadingHostedFields = false;
    }

    function onError(error) {
      $log.debug("Payment error: ", error);
      toastr.error(error);
      vm.processing = false;
    }
  }
})();
