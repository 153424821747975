(function() {
  "use strict";

  angular.module("marketPlace.auth").factory("Notifications", Notifications);

  Notifications.$inject = ["$resource", "API_URL"];

  function Notifications($resource, API_URL) {
    return $resource(API_URL + "/api/auth/user/notifications/:notificationId", null, {
      ack: { method: "PUT" },
      query: { method: "GET", isArray: false }
    });
  }
})();
