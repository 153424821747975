(function() {
  "use strict";

  angular.module("marketPlace.referral").factory("Referral", referral);

  referral.$inject = ["$resource", "API_URL"];

  function referral($resource, API_URL) {
    return $resource(
      API_URL + "/api/referrals/:token/",
      { token: "@token" },
      {
        list: {
          method: "GET",
          isArray: false
        }
      }
    );
  }
})();
