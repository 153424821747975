interface Source {
  section: string;
  gallery: string;
  caption: string;
  q: string;
  category: string | string[];
}

class BroadstreetService {
  static addKeyword(keywordList: string[], keyword: string) {
    const val = keyword.toLowerCase();
    if (keywordList.indexOf(val) === -1) {
      keywordList.push(val);
    }
  }

  getBroadStreetKeywords(source: Source): string[] | undefined {
    if (angular.isUndefined(source)) {
      return undefined;
    }
    let keywordSources = [source.section, source.gallery, source.caption, source.q];
    if (source.category) {
      if (Array.isArray(source.category)) {
        keywordSources = [...keywordSources, ...source.category];
      } else {
        keywordSources.push(source.category);
      }
    }
    const keywords = [];
    for (const value of keywordSources) {
      if (value) {
        const terms = value
          .toLowerCase()
          .replace("'", "")
          .split(/\W+/);
        BroadstreetService.addKeyword(keywords, value);

        for (const term of terms) {
          BroadstreetService.addKeyword(keywords, term);
        }
      }
    }
    return keywords.length ? keywords : undefined;
  }
}

angular.module("marketPlace.broadstreet").service("BroadstreetService", BroadstreetService);
