(function() {
  "use strict";

  angular.module("marketPlace.games").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.my-results", {
      url: "/game/my-results",
      template: require("./templates/my-results.html"),
      controller: "MyResultsController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      }
    });
    $stateProvider.state("home.dashboard.game-results", {
      url: "/game/:id/results",
      template: require("./templates/game-results.html"),
      controller: "GameResultsController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      }
    });
    $stateProvider.state("home.dashboard.leaderboard", {
      url: "/leaderboard",
      template: require("./templates/leaderboard.html"),
      controller: "LeaderboardController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      }
    });
  }
})();
