import { IHttpService, IRootScopeService } from "angular";

(function () {
  "use strict";

  angular.module("marketPlace.cart").factory("Cart", Cart);

  Cart.$inject = ["$http", "$rootScope", "DATA_EVENTS", "API_URL"];

  function Cart(
    $http: IHttpService,
    $rootScope: IRootScopeService,
    DATA_EVENTS,
    API_URL: string,
  ) {
    async function add(name: string, product: string, quantity = 1, options = []) {
      const data = {
        url: product,
        quantity: quantity,
        options: options,
      };
      return await $http({
        url: API_URL + "/api/shop/basket/add-product/",
        method: "POST",
        data: data,
      });
    }

    function getCart() {
      return $http({
        url: API_URL + "/api/shop/basket/",
        method: "GET"
      });
    }

    async function removeLine(line) {
      await $http({
        url: line.url,
        method: "DELETE"
      });
      $rootScope.$broadcast(DATA_EVENTS.cartUpdated);
    }

    function checkout(cart) {
      return $http({
        url: API_URL + "/api/shop/checkout/",
        method: "POST",
        data: cart
      });
    }

    function getShippingMethods(shippingAddress?) {
      if (shippingAddress) {
        shippingAddress.country = getCountryUrl(shippingAddress.country);
        return $http({
          url: API_URL + "/api/shop/basket/shipping-methods/",
          method: "POST",
          data: shippingAddress
        });
      }
      return $http({
        url: API_URL + "/api/shop/basket/shipping-methods/",
        method: "GET"
      });
    }

    function getUserAddresses() {
      return $http({
        url: API_URL + "/api/shop/useraddresses/",
        method: "GET"
      });
    }

    function getCountryUrl(code: string) {
      if (code.length === 2) {
        return API_URL + "/api/shop/countries/" + code + "/";
      }

      return code;
    }

    function getOrders() {
      return $http({
        url: API_URL + "/api/shop/orders/",
        method: "GET"
      });
    }

    function getSourceTypes() {
      return $http({
        url: API_URL + "/api/shop/source-types/",
        method: "GET"
      });
    }

    function isAvailable(productUrl: string) {
      if (productUrl.indexOf("http") !== 0) {
        productUrl = API_URL + productUrl;
      }
      return $http({
        url: productUrl + "availability/",
        method: "GET"
      });
    }

    function addAddress(address) {
      return $http({
        url: API_URL + "/api/shop/useraddresses/",
        method: "POST",
        data: address
      });
    }

    return {
      isAvailable: isAvailable,
      add: add,
      get: getCart,
      removeLine: removeLine,
      checkout: checkout,
      getShippingMethods: getShippingMethods,
      getCountryUrl: getCountryUrl,
      getOrder: getOrders,
      getSourceTypes: getSourceTypes,
      getUserAddresses: getUserAddresses,
      addAddress: addAddress
    };
  }
})();
