(function() {
  "use strict";

  angular.module("marketPlace.listings").controller("EventListingController", eventListingController);

  eventListingController.$inject = ["$log", "$window", "Event"];

  function eventListingController($log, $window, Event) {
    const vm = this;
    vm.loading = false;
    vm.currentSort = "";

    vm.sortChanged = sortChanged;
    vm.pageChanged = pageChanged;
    vm.archive = archive;
    vm.resetSearch = resetSearch;

    activate();

    function activate() {
      vm.currentPage = 1;
      vm.itemsPerPage = 50;
      pageChanged();
    }

    function sortChanged(value) {
      if (vm.currentSort === value) {
        vm.currentSort = "";
      } else if (vm.currentSort === "-" + value) {
        vm.currentSort = value;
      } else {
        vm.currentSort = "-" + value;
      }

      pageChanged(1);
    }

    function pageChanged(newPage?: number) {
      if (newPage) {
        vm.currentPage = newPage;
      }
      $log.debug("Page changed to: " + vm.currentPage);
      $window.scrollTo(0, 0);
      vm.loading = true;
      const params = {
        user_org: true,
        page: vm.currentPage
      };

      if (vm.keyword) {
        params["q"] = vm.keyword;
      }

      if (vm.currentSort) {
        params["sort"] = vm.currentSort;
      }

      Event.query(params, function(listings) {
        vm.listings = listings;
        vm.loading = false;
      });
    }

    function archive(listing) {
      $log.debug("archiving...");
      const isArchived = listing.status === "archived";
      Event.archive({ id: listing.id, archive: !isArchived }, function() {
        if (listing.searchable) {
          listing.status = "archived";
          listing.searchable = false;
        } else {
          listing.status = "active";
          listing.searchable = true;
        }
      });
    }

    function resetSearch() {
      vm.keyword = "";
      vm.currentSort = "";
      vm.currentPage = 1;
      pageChanged();
    }
  }
})();
