(function() {
  "use strict";

  angular.module("marketPlace.email").controller("EmailController", emailController);

  emailController.$inject = [
    "$log",
    "$state",
    "email_notifications",
    "Searches",
    "Email",
    "EmailSchedules",
    "toastr",
    "$uibModal",
    "$anchorScroll"
  ];

  function emailController(
    $log,
    $state,
    email_notifications,
    Searches,
    Email,
    EmailSchedules,
    toastr,
    $uibModal,
    $anchorScroll
  ) {
    const vm = this;
    vm.email_notifications = email_notifications;
    vm.schedules = [];

    vm.deleteNotification = deleteNotification;
    vm.addNotification = addNotification;
    vm.performSearch = performSearch;

    activate();

    function activate() {
      vm.currentPage = 1;
      vm.itemsPerPage = 50;

      EmailSchedules.get(function(data) {
        vm.schedules = data.results;
      });
    }

    function reloadNotifications() {
      Email.get(function(data) {
        vm.email_notifications = data;
      });
    }

    function performSearch(params) {
      $log.debug("search: ", params);
      $state.go("home.gallery", params, { inherit: false, reload: true });
    }

    function deleteNotification(email_notification) {
      Email.delete({ id: email_notification.id }, function() {
        reloadNotifications();
        toastr.success("Email notification stopped");
      });
    }

    function getSchedules() {
      return EmailSchedules.get().$promise;
    }

    function getSearches() {
      return Searches.get().$promise;
    }

    function addNotification() {
      const modalInstance = $uibModal.open({
        animation: true,
        template: require("./templates/addEmailNotification.html"),
        controller: "AddEmailNotificationModalController",
        controllerAs: "vm",
        size: "mdsm",
        resolve: {
          searches: getSearches,
          schedules: getSchedules
        }
      });

      modalInstance.result.then(function() {
        reloadNotifications();
      }, angular.noop);

      $anchorScroll();
    }
  }
})();
