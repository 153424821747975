(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("galleryLink", galleryLink);

  function galleryLink() {
    const directive = {
      restrict: "E",
      replace: true,
      template: require("./templates/gallery-link.html"),
      scope: {
        params: "<",
        category: "=?",
        parentCategory: "=?",
        toggleParam: "@",
        icon: "@",
        text: "@",
        classes: "@"
      },
      link: linkFunc
    };
    return directive;

    function linkFunc(scope) {
      const stateParams = {};
      scope.css_classes = scope.classes.slice();
      scope.css_icon = scope.icon ? scope.icon.slice() : null;

      for (const k in scope.params) {
        if (scope.params.hasOwnProperty(k) && k !== "category") {
          stateParams[k] = scope.params[k];
        }
      }

      if (scope.parentCategory && scope.parentCategory !== "Other") {
        stateParams.category = scope.parentCategory + " - " + scope.category;
      } else {
        stateParams.category = scope.category;
      }

      if (scope.toggleParam) {
        if (stateParams.hasOwnProperty(scope.toggleParam)) {
          delete stateParams[scope.toggleParam];
          scope.css_classes = scope.classes + " bg-blue text-white"
          scope.css_icon = 'close';
        } else {
          stateParams[scope.toggleParam] = 1;
          scope.css_classes = scope.classes + " text-blue"
          scope.css_icon = "";
        }
      }
      scope.stateParams = stateParams;
    }
  }
})();
