(function () {
  "use strict";

  angular.module("marketPlace.items").factory("ItemForm", ItemForm);

  ItemForm.$inject = [
    "$log",
    "AppConfig",
    "Niches",
    "Categories",
    "$timeout",
    "Upload",
    "Item",
    "_",
    "Payment",
    "toastr",
    "AvailableTags",
  ];

  function ItemForm($log, AppConfig, Niches, Categories, $timeout, Upload, Item, _, Payment, toastr, AvailableTags) {
    function getSections(niches, niche_id: number) {
      return niches[niche_id].sections;
    }

    function getGalleries(sections, section_id: number) {
      return sections[section_id].galleries;
    }

    function getCaptions(galleries, gallery_id: number) {
      return galleries[gallery_id].captions;
    }

    function getCategories(val: string) {
      return Categories.query({ q: val, all: 1 }).$promise;
    }

    function getWritableNiches() {
      return Niches.write.query({ item_type: "ITEM" }).$promise;
    }

    function getAvailableTags(vm, niche, section) {
      AvailableTags.get({ niche: niche, section: section }, function (response) {
        if (response.available_tags) {
          vm.availableTags = response.available_tags;
          $log.debug("AVAILABLE TAGS", vm.availableTags);
        }
        vm.tagLabel = response.tag_label ? response.tag_label : "Tags";
      });
    }

    function getChangeHandlers(vm) {
      const hiddenFields = [
        "external_url",
        "video_url",
        "price",
        "currency",
        "realisation_price",
        "realisation_currency",
        "realisation_date",
        "category",
      ];
      for (const hiddenField of hiddenFields) {
        vm["show_" + hiddenField] = false;
      }
      // special case category is shown initially
      vm.show_category = true;

      function getPrice(): void {
        // reset values
        vm.listing = null;
        vm.price = null;

        Payment.getPrice(
          {
            niche: vm.item.niche_option,
            section: vm.item.section_option,
            gallery: vm.item.gallery_option,
            caption: vm.caption_id,
          },
          function (data) {
            $log.debug("Got price", data);
            vm.listing = data;
            vm.price = data.amount;
            if (!vm.initialListing) {
              vm.initialListing = data;
            }
            if (!vm.initialPrice) {
              vm.initialPrice = data.amount;
            }
            $log.debug(
              "price for: ",
              vm.item.niche_option,
              vm.item.section_option,
              vm.item.gallery_option,
              vm.caption_id,
              "is",
              vm.price
            );
          },
          function () {
            const msg = "Error getting listing price";
            $log.debug(msg);
            toastr.error(msg, {
              closeButton: true,
              closeHtml: '<button><i class="mdi mdi-close"></i></button>',
            });
          }
        );
      }

      function priceDecorator(fn: () => void): () => void {
        return function () {
          fn();
          getPrice();
        };
      }

      function setGallery(): void {
        $log.debug("setGallery");
        vm.caption_id = null;
        vm.captions = getCaptions(vm.galleries, vm.item.gallery_option);
        if (vm.captions.length === 1) {
          $log.debug("Single caption");
          vm.item.caption_option = vm.captions[0];
          vm.caption_id = vm.item.caption_option.id;
        }

        vm.gallery_for_sale = vm.galleries[vm.item.gallery_option].product;
        if (vm.gallery_for_sale) {
          vm.show_price = true;
        }
      }

      function setSection(): void {
        $log.debug("setSection");
        vm.item.gallery_option = null;
        vm.galleries = getGalleries(vm.sections, vm.item.section_option);
        getAvailableTags(vm, vm.niches[vm.item.niche_option].slug, vm.sections[vm.item.section_option].slug);

        vm.show_category = vm.sections[vm.item.section_option].display_type !== "game";

        if (vm.galleries._order.length === 1) {
          $log.debug("Single gallery");
          vm.item.gallery_option = vm.galleries._order[0];
          setGallery();
        }
      }
      function setNiche(): void {
        $log.debug("setNiche");
        vm.item.section_option = null;
        vm.sections = getSections(vm.niches, vm.item.niche_option);
        if (vm.sections._order.length === 1) {
          $log.debug("Single section");
          vm.item.section_option = vm.sections._order[0];
          setSection();
        }
      }
      function setCaptionOption(): void {
        vm.item.caption_option = {
          id: vm.caption_id,
          niche: { id: vm.item.niche_option },
          section: { id: vm.item.section_option },
          gallery: { id: vm.item.gallery_option },
        };
        setHiddenFields();
      }
      function setHiddenFields(): void {
        for (const caption of vm.captions) {
          if (caption.id === vm.caption_id) {
            for (const hiddenField of hiddenFields) {
              const hiddenFieldLabel = "show_" + hiddenField;
              vm[hiddenFieldLabel] = !caption.hide.includes(hiddenField);
              $log.debug("Setting " + hiddenFieldLabel + "=" + vm[hiddenFieldLabel]);
            }
          }
        }
      }
      function setCaption(): void {
        $log.debug("setCaption");
        if (vm.item.caption_option) {
          vm.item.niche_option = vm.item.caption_option.niche.id;
          $log.debug("niche_option: ", vm.item.niche_option);
          try {
            setNiche();
          } catch (e) {
            $log.debug(e);
            vm.item.niche_option = null;
            return;
          }
          if (!vm.item.section_option) {
            vm.item.section_option = vm.item.caption_option.section.id;
            try {
              setSection();
            } catch (e) {
              $log.debug(e);
              vm.item.section_option = null;
              return;
            }
          }
          $log.debug("section_option: ", vm.item.section_option);

          if (!vm.item.gallery_option) {
            vm.item.gallery_option = vm.item.caption_option.gallery.id;
            try {
              setGallery();
            } catch (e) {
              $log.debug(e);
              vm.item.gallery_option = null;
              return;
            }
          }
          $log.debug("gallery_option: ", vm.item.gallery_option);

          if (!vm.caption_id) {
            vm.caption_id = vm.item.caption_option.id;
            setCaptionOption();
          } else {
            setHiddenFields();
          }
          $log.debug("caption_id: ", vm.caption_id);
        }
      }
      return {
        setNiche: priceDecorator(setNiche),
        setSection: priceDecorator(setSection),
        setGallery: priceDecorator(setGallery),
        setCaption: priceDecorator(setCaption),
        setCaptionOption: priceDecorator(setCaptionOption),
      };
    }

    function upload(vm, $scope, files) {
      if (files && files.length) {
        for (let i = 0; i < Math.min(files.length, vm.itemExternalImageLimit); i++) {
          const file = files[i];
          if (!file.$error) {
            vm.uploadFileName = file.name;
            const cleanFileName = file.name.replace(/[^A-Za-z0-9.]+/g, "-");
            const s3key = "images/" + vm.user.id + "_" + Math.random().toString(36).substr(2, 5) + "_" + cleanFileName;
            file.upload = Upload.upload({
              url: AppConfig.S3Config.s3url,
              method: "POST",
              data: {
                key: s3key,
                AWSAccessKeyId: AppConfig.S3Config.AWSAccessKeyId,
                acl: AppConfig.S3Config.acl,
                policy: AppConfig.S3Config.policy,
                signature: AppConfig.S3Config.signature,
                "Content-Type": file.type != "" ? file.type : "application/octet-stream",
                "Cache-Control": "max-age=315360000",
                Expires: "Thu, 31 Dec 2037 23:55:55 GMT",
                filename: cleanFileName,
                file: file,
              },
              skipAuthorization: true,
            }).then(
              function (resp) {
                vm.uploadProgressPercentage = 0;
                $timeout(function () {
                  $log.debug("Image: " + resp.config.data.file.name + ", Response: " + angular.toJson(resp.data));
                  const uploadedImage = {
                    url: AppConfig.S3Config.s3url + encodeURIComponent(s3key),
                    caption: "",
                    dealer_viewable: true,
                    public_viewable: true,
                    media_type: resp.config.data["Content-Type"],
                    primary: vm.item.external_images.length === 0,
                  };
                  vm.item.external_images.push(uploadedImage);
                  $scope.$broadcast("show-errors-reset");
                  vm.itemForm.form.external_images.$setValidity("server", true);
                });
              },
              function (data, status, headers) {
                $log.debug("ERROR", data, status, headers);
                vm.uploadProgressPercentage = 0;
              },
              function (evt) {
                const progressPercentage = Math.floor((100.0 * evt.loaded) / evt.total);
                $log.debug("Progress: " + progressPercentage + "% " + evt.config.data.file.name);
                vm.uploadProgressPercentage = Math.min(100, progressPercentage);
              }
            );
          }
        }
      }
    }

    /**
     * Create a new item and initialize some default values
     * @constructor
     * @param {object} user - The user to associate the item with
     * @return {Item} The new item.
     */
    function newItem(user) {
      const item = new Item({
        price_option: "standard",
        forsale: true,
        external_images: [],
      });

      //noinspection EqualityComparisonWithCoercionJS
      if (user != null) {
        item.user = user.id;
        // TODO user.physical_address_country is a country code not name
        // item.country_name = user.physical_address_country;
        if (user.default_currency) {
          item.currency = user.default_currency;
          item.realisation_currency = user.default_currency;
        }
      }
      return item;
    }

    /**
     * Copy an item
     * @constructor
     * @param {Item} item - an Item to copy
     * @return {Item} The new item.
     */
    function copyItem(item) {
      const itemCopy = angular.fromJson(angular.toJson(item));
      delete itemCopy.id;
      delete itemCopy.permissions;
      delete itemCopy.listed;
      delete itemCopy.name;
      itemCopy.user = item.user.id;
      if (item.price) {
        itemCopy.price = Number(item.price);
      }
      if (item.realisation_price) {
        itemCopy.realisation_price = Number(item.realisation_price);
      }
      if (item.realisation_date) {
        // for some reason realisation_date is not copied properly
        itemCopy.realisation_date = item.realisation_date;
      }
      return new Item(itemCopy);
    }

    function getItem(id: number) {
      return Item.get({ id: id }).$promise;
    }

    /**
     * Parse a Vimeo or YouTube url and return a videoUrl object
     * @constructor
     * @param {string} url
     * @return {object} type and id
     */
    function parseVideo(url: string) {
      url.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([^#&?]*).*/
      );

      let type;
      if (RegExp.$3.indexOf("youtu") > -1) {
        type = "youtube";
      } else if (RegExp.$3.indexOf("vimeo") > -1) {
        type = "vimeo";
      }

      return {
        media_type: type,
        id: RegExp.$6,
      };
    }

    /**
     * Finds a caption object in a list of captions by id
     * @constructor
     * @param {Object[]} captions - list of caption objects
     * @param {int} caption_id - id of caption to find
     * @return {object} Caption
     */
    function findCaption(captions, caption_id: number) {
      return _.find(captions, { id: caption_id });
    }

    /**
     * Given a caption id and a list of captions returns whether that caption is a realisation
     * @constructor
     * @param {Object[]} captions - list of caption objects
     * @param {int} caption_id - id of caption to test
     * @return {boolean} - true/false caption has realisation in the name
     */
    function isRealisation(captions, caption_id: number): boolean {
      const caption = findCaption(captions, caption_id);
      if (angular.isDefined(caption) && caption.hasOwnProperty("name")) {
        return /realisation/i.test(caption.name);
      }
      return false;
    }

    function initializeVideoUrl(vm): void {
      for (let i = 0; i < vm.item.external_images.length; i++) {
        if (vm.item.external_images[i].media_type == "video") {
          const videoObject = ItemForm.parseVideo(vm.item.external_images[i].url);
          vm.item.external_images[i] = { ...vm.item.external_images[i], ...videoObject };
          vm.videoUrl = vm.item.external_images[i];
          break;
        }
      }

      if (angular.isUndefined(vm.videoUrl)) {
        vm.videoUrl = {
          url: "",
          media_type: "video",
        };
      }
    }

    function changePublishAfter(): void {
      let newDate: Date;
      if (this.publishAfterDate) {
        newDate = new Date(this.publishAfterDate);
      } else {
        newDate = this.publishAfterDate = new Date();
      }
      if (!this.publishAfterTime) {
        this.publishAfterTime = new Date();
      }
      newDate.setHours(this.publishAfterTime.getHours());
      newDate.setMinutes(this.publishAfterTime.getMinutes());
      this.item.publish_after = newDate;
    }

    function clearPublishAfter(): void {
      delete this.publishAfterDate;
      delete this.publishAfterTime;
      this.item.publish_after = null;
    }

    function getPriceString(price: number, listing?): string {
      if (price === null) {
        return "Checking price...";
      }

      if (
        angular.isDefined(listing) &&
        listing !== null &&
        listing.hasOwnProperty("num_free_per_billing_cycle") &&
        angular.isDefined(listing.num_free_per_billing_cycle) &&
        listing.num_free_per_billing_cycle !== null
      ) {
        const listingsUsedStr = "listings used";
        const listingType = listing.category_name ? listing.category_name + " " + listingsUsedStr : listingsUsedStr;
        const msg =
          "(" +
          listing.free_transaction_count +
          " / " +
          listing.num_free_per_billing_cycle +
          " free " +
          listingType +
          ")";
        if (price === 0) {
          return "Free " + msg;
        } else {
          return "Total: $" + price;
        }
      } else {
        if (price === 0) {
          return "Free listing";
        }
      }

      return "Total: $" + price;
    }

    return {
      getCategories: getCategories,
      getWritableNiches: getWritableNiches,
      getChangeHandlers: getChangeHandlers,
      upload: upload,
      setDefaultCurrency: function (vm) {
        if (vm.user.default_currency) {
          if (!vm.item.price) {
            vm.item.currency = vm.user.default_currency;
          }

          if (!vm.item.realisation_price) {
            vm.item.realisation_currency = vm.user.default_currency;
          }
        }
      },
      newItem: newItem,
      copyItem: copyItem,
      getItem: getItem,
      parseVideo: parseVideo,
      isRealisation: isRealisation,
      initializeVideoUrl: initializeVideoUrl,
      changePublishAfter: changePublishAfter,
      clearPublishAfter: clearPublishAfter,
      getPriceString: getPriceString,
    };
  }
})();
