(function() {
  "use strict";
  angular.module("marketPlace.gallery", ["marketPlace.common", "marketPlace.lodash"]).run([
    "$templateCache",
    function($templateCache) {
      // Put in cache the files that are ng-include'd in templates
      $templateCache.put("./gallery/templates/ordering-toolbar.html", require("./templates/ordering-toolbar.html"));
    }
  ]);
})();
