(function() {
  "use strict";
  angular
    .module("marketPlace.controls", [
      "marketPlace.auction",
      "marketPlace.common",
      "marketPlace.constants",
      "marketPlace.games",
      "marketPlace.lodash",
      "marketPlace.preview",
      "marketPlace.comments"
    ])
    .run([
      "$templateCache",
      function($templateCache) {
        // Put in cache the files that are ng-include'd in templates
        $templateCache.put(
          "./detailpane/templates/includes/sharing-buttons.html",
          require("./detailpane/templates/includes/sharing-buttons.html")
        );
        $templateCache.put(
          "./detailpane/templates/includes/organisation-footer.html",
          require("./detailpane/templates/includes/organisation-footer.html")
        );
        $templateCache.put(
          "./detailpane/templates/includes/member-footer.html",
          require("./detailpane/templates/includes/member-footer.html")
        );
        $templateCache.put(
          "./detailpane/templates/includes/directory-footer.html",
          require("./detailpane/templates/includes/directory-footer.html")
        );
        $templateCache.put(
          "./detailpane/templates/carousel/carousel-no-controls.html",
          require("./detailpane/templates/carousel/carousel-no-controls.html")
        );
      }
    ]);
})();
