(function() {
  "use strict";

  angular.module("marketPlace.organisations").controller("OrganisationUsersController", OrganisationUsersController);

  OrganisationUsersController.$inject = ["$log", "$transition$", "OrganisationUsers", "users"];

  function OrganisationUsersController($log, $transition$, OrganisationUsers, users) {
    const vm = this;
    vm.users = users;
    vm.pageChanged = pageChanged;

    activate();

    function activate() {
      vm.currentPage = 1;
      vm.itemsPerPage = 50;
    }

    function pageChanged() {
      $log.debug("Page changed to: " + vm.currentPage);
      OrganisationUsers.list(
        {
          organisation_id: $transition$.params().organisationId,
          page: vm.currentPage
        },
        function(users) {
          vm.users = users;
        }
      );
    }
  }
})();
