(function() {
  "use strict";

  const upgradeSubModal = {
    template: require("./templates/upgrade-sub-modal.html"),
    bindings: {
      resolve: "<",
      close: "&",
      dismiss: "&"
    },
    controller: upgradeSubModalCtrl
  };

  angular.module("marketPlace.payments").component("upgradeSubModal", upgradeSubModal);

  upgradeSubModalCtrl.$inject = ["$log"];
  function upgradeSubModalCtrl($log) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
      $ctrl.user = $ctrl.resolve.user;
      $ctrl.currentSubscription = $ctrl.resolve.currentSubscription;
      $ctrl.showGroupChoices = !!$ctrl.resolve.showGroupChoices;
      $ctrl.groupId = $ctrl.resolve.groupId;
      if (angular.isDefined($ctrl.resolve.includeFree)) {
        $ctrl.includeFree = !!$ctrl.resolve.includeFree;
      }
      $ctrl.allowClose = $ctrl.resolve.allowClose;
    };

    $ctrl.chooseGroup = function(groupId) {
      $log.debug("groupId: ", groupId);
      $ctrl.groupId = groupId;
      $ctrl.showGroupChoices = false;
    };
  }
})();
