import { ICacheObject, IHttpService } from "angular";
import ICacheFactory = angular.cache.ICacheFactory;

class Pages {
  pageCache: ICacheObject;
  url: string;

  static $inject = ["$http", "CacheFactory", "API_URL"];

  constructor(public $http: IHttpService, CacheFactory: ICacheFactory, API_URL: string) {
    const cacheName = "pageCache";

    if (!CacheFactory.get(cacheName)) {
      CacheFactory.createCache(cacheName, {
        maxAge: 5 * 60 * 1000, // Items added to this cache expire after 5 minutes.
        cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour.
        deleteOnExpire: "aggressive",
        storageMode: "localStorage"
      });
    }
    this.pageCache = CacheFactory.get(cacheName);
    this.url = API_URL + "/api/pages/";
  }

  get(slug: string) {
    return this.$http.get(this.url + slug + "/", { cache: this.pageCache });
  }

  query() {
    return this.$http.get(this.url, { cache: this.pageCache });
  }
}

angular.module("marketPlace.content").service("Pages", Pages);
