(function() {
  "use strict";

  angular.module("marketPlace.events").factory("Event", Event);

  Event.$inject = ["$resource", "$http", "API_URL"];
  function Event($resource, $http, API_URL) {
    const setPrimaryImage = function(obj) {
      // Set at least one image as primary
      if (obj.external_images.length > 0) {
        for (let i = 0; i < obj.external_images.length; i++) {
          if (obj.external_images[i].primary) {
            return obj;
          }
        }
        obj.external_images[0].primary = true;
      }
      return obj;
    };

    return $resource(
      API_URL + "/api/events/:id/:controller/",
      { id: "@id", controller: "@controller" },
      {
        get: {
          method: "GET",
          isArray: false
        },
        query: {
          method: "GET",
          isArray: false
        },
        update: {
          method: "PUT",
          transformRequest: [setPrimaryImage].concat($http.defaults.transformRequest)
        },
        save: {
          method: "POST",
          transformRequest: [setPrimaryImage].concat($http.defaults.transformRequest)
        },
        like: {
          method: "POST",
          params: {
            controller: "like"
          }
        },
        archive: {
          method: "POST",
          params: {
            controller: "archive"
          }
        },
        promote: {
          method: "POST",
          params: {
            controller: "promote"
          }
        }
      }
    );
  }
})();
