(function() {
  "use strict";

  angular.module("marketPlace.cms").factory("CMSMenu", cmsMenu);

  cmsMenu.$inject = ["$resource", "$http", "CMS_URL", "AppConfig", "CacheFactory"];

  function cmsMenu($resource, $http, CMS_URL, AppConfig, CacheFactory) {
    if (!CacheFactory.get("menuCache")) {
      CacheFactory.createCache("menuCache", {
        capacity: 1,
        maxAge: 5 * 60 * 1000, // Items added to this cache expire after 5 minutes.
        cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour.
        deleteOnExpire: "aggressive",
        storageMode: "localStorage"
      });
    }

    const menuCache = CacheFactory.get("menuCache");

    const r = $resource(CMS_URL + "/api/v2/pages/?child_of=" + AppConfig.CMSHomePageID + "&show_in_menus=true", null, {
      query: {
        method: "GET",
        isArray: false,
        cache: menuCache
      }
    });

    // Remove extra actions
    delete r.prototype.$get;
    delete r.prototype.$save;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
