(function() {
  "use strict";

  angular.module("marketPlace.auth").controller("ChangePasswordModalController", ChangePasswordModalController);

  ChangePasswordModalController.$inject = ["$scope", "$uibModalInstance", "$log", "$http", "API_URL", "toastr"];

  function ChangePasswordModalController($scope, $uibModalInstance, $log, $http, API_URL, toastr) {
    const vm = this;

    vm.passwordInfo = {
      password: "",
      newPassword: "",
      confirmNewPassword: ""
    };
    vm.form = {};
    vm.errors = {};
    vm.saving = false;

    vm.changePassword = changePassword;
    vm.cancel = cancel;

    function changePassword() {
      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }

      vm.saving = true;

      $http({
        url: API_URL + "/api/auth/password/",
        method: "POST",
        data: {
          current_password: vm.passwordInfo.password,
          new_password: vm.passwordInfo.newPassword
        }
      }).then(
        function(resp) {
          $log.debug("Password change SUCCESS", resp);
          vm.saving = false;
          $uibModalInstance.result.then(function() {
            toastr.success("Password changed");
          });
          $uibModalInstance.close(true);
        },
        function(resp) {
          $log.debug("Password change FAIL", resp);
          vm.saving = false;
          angular.forEach(resp.data, function(fieldErrors) {
            angular.forEach(fieldErrors, function(value) {
              vm.errors.non_field_errors = value;
            });
          });
        }
      );
    }

    function cancel() {
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.dismiss("cancel");
    }
  }
})();
