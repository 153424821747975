/**
 * Created by carl on 1/12/15.
 */

(function() {
  "use strict";
  angular.module("marketPlace.home", ["marketPlace.cms", "marketPlace.lodash"]).run([
    "$templateCache",
    function($templateCache) {
      // Put in cache the files that are ng-include'd in templates
      $templateCache.put("./home/templates/email_activation.html", require("./templates/email_activation.html"));
    }
  ]);
})();
