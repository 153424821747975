import { IQService, ILogService, IHttpService } from "angular";


class GroupFactory {
  groups = [];

  static $inject = ["API_URL", "$log", "$http", "$q"];
  constructor(public API_URL: string, public $log: ILogService, public $http: IHttpService, public $q: IQService) { }

  query(params: any = null) {
    const deferred = this.$q.defer();

    if (this.groups.length === 0 || params) {
      this.$http({
        url: this.API_URL + "/api/groups/",
        method: "GET",
        params: params
      }).then(function(groups) {
        this.$log.debug("GROUPS GOT ", groups)
        this.groups = groups.data
        deferred.resolve(groups.data);
      }.bind(this), function(data) {
        deferred.reject(data)
      });
    } else {
      deferred.resolve(this.groups);
    }

    return deferred.promise;
  }

  $get() {
    return this.query();
  }
}


angular.module("marketPlace.auth").service("Groups", GroupFactory);
