(function() {
  "use strict";
  angular.module("marketPlace.gallery").directive("breadcrumb", breadCrumb);

  breadCrumb.$inject = [];

  function breadCrumb() {
    return {
      template: require("./templates/breadcrumb.directive.html"),
      scope: {
        niches: "=",
        niche: "=",
        section: "=?",
        gallery: "=?",
        caption: "=?"
      },
      link: linkFunc
    };

    function linkFunc(scope) {
      if (scope.section) {
        const section = scope.niches[scope.niche].sections[scope.section];
        if (angular.isDefined(section)) {
          scope.sectionName = section.name;
          if (scope.gallery) {
            const gallery = section.galleries[scope.gallery];
            if (angular.isDefined(gallery)) {
              scope.galleryName = gallery.name;
              if (scope.caption) {
                try {
                  scope.captionName = gallery.captions[scope.caption].name;
                } catch (e) {
                  // pass
                }
              }
            }
          }
        }
      }
    }
  }
})();
