import { ILogService, IScope } from "angular";

class AddressController {
  titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];

  static $inject = ["$log", "$scope"];
  constructor(
    public $log: ILogService,
    public $scope: IScope,
    public address: { [key: string]: any },
    public showErrors: boolean
  ) {}
}

angular.module("marketPlace.home").component("address", {
  bindings: {
    address: "<",
    showErrors: "<?"
  },
  template: require("./templates/address.html"),
  controller: AddressController,
  controllerAs: "vm"
});
