(function () {
  "use strict";

  angular.module("marketPlace.items").controller("ItemsEditController", itemsEditController);

  itemsEditController.$inject = [
    "$scope",
    "$uibModalInstance",
    "$log",
    "CountryName",
    "AppConfig",
    "user",
    "organisations",
    "item",
    "Item",
    "ItemForm",
    "_",
    "PermAuthorization",
    "PermPermissionMap",
    "countries",
    "Preview",
    "writableNiches",
    "currencies",
    "toastr",
    "GroupCategories",
    "SITE_NAME",
    "modalTitle",
    "Cart",
  ];

  function itemsEditController(
    $scope,
    $uibModalInstance,
    $log,
    CountryName,
    AppConfig,
    user,
    organisations,
    item,
    Item,
    ItemForm,
    _,
    PermAuthorization,
    PermPermissionMap,
    countries,
    Preview,
    writableNiches,
    currencies,
    toastr,
    GroupCategories,
    SITE_NAME,
    modalTitle,
    Cart
  ) {
    const vm = this;

    vm.saving = false;
    vm.errors = {};
    vm.uploadProgressPercentage = 0;

    // pre-resolved dependencies
    vm.user = user;
    vm.organisation = organisations.length > 0 ? organisations[0] : null;
    vm.niches = writableNiches;
    vm.countries = countries;
    vm.currency_options = currencies;
    vm.siteName = SITE_NAME;
    vm.modalTitle = modalTitle;

    vm.sections = {};
    vm.galleries = {};
    vm.item = item;
    const initial_caption_id: number = vm.item.caption_option.id;
    vm.itemExternalImageLimit = AppConfig.itemExternalImageLimit;
    vm.categoryLabel = AppConfig.categoryLabel;

    vm.accept = "image/*";
    vm.pattern = "image/*";
    vm.price = 0;
    vm.paymentAmounts = [];

    const changeHandlers = ItemForm.getChangeHandlers(vm);

    // item form methods
    vm.save = save;
    vm.saveAndClose = saveAndClose;
    vm.getCountry = getCountry;
    vm.openPreview = Preview.open;
    vm.addVideo = addVideo;
    vm.getCategories = ItemForm.getCategories;
    vm.isRealisation = ItemForm.isRealisation;
    vm.setNiche = changeHandlers.setNiche;
    vm.setSection = changeHandlers.setSection;
    vm.setGallery = changeHandlers.setGallery;
    vm.setCaptionOption = changeHandlers.setCaptionOption;
    vm.showField = showField;
    vm.groupPreferredCountries = groupPreferredCountries;
    vm.getPriceString = ItemForm.getPriceString;
    vm.archive = archive;
    vm.next = next;
    vm.back = back;
    vm.create = create;
    vm.saveWithPayment = saveWithPayment;
    vm.onPaymentError = onPaymentError;
    vm.changePublishAfter = angular.bind(vm, ItemForm.changePublishAfter);
    vm.clearPublishAfter = angular.bind(vm, ItemForm.clearPublishAfter);
    vm.setPrimaryImage = setPrimaryImage;
    vm.deleteImage = deleteImage;
    vm.showPayment = showPayment;

    // forms
    vm.itemForm = {};
    vm.itemForm.form = {};

    // Date controls
    vm.dateOptions = {
      formatYear: "yy",
      startingDay: 1,
    };
    vm.dateControlsState = {
      dateFromOpen: false,
      dateToOpen: false,
      realisationDateOpen: false,
    };

    vm.userCanAddURL = false;
    vm.userCanAddPrice = false;
    vm.userCanAddVideoURL = false;

    activate();
    function activate() {
      ItemForm.initializeVideoUrl(vm);
      changeHandlers.setCaption();
      ItemForm.setDefaultCurrency(vm);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["change_item_video"] }))
        .then(function () {
          vm.accept = "image/*,video/*,video/mp4";
          vm.pattern = "image/*,video/*";
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["change_item_url"] }))
        .then(function () {
          vm.userCanAddURL = true;
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["change_item_price"] }))
        .then(function () {
          vm.userCanAddPrice = true;
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["change_item_video_url"] }))
        .then(function () {
          vm.userCanAddVideoURL = true;
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_photo_for_sale"] }))
        .then(function () {
          vm.userCanMakePhotoForSale = true;
        })
        .catch(angular.noop);

      vm.groupCats = GroupCategories.query();
      vm.imageProducts = Item.imageProducts();

      // initialize separate models for date and time pickers
      if (vm.item.publish_after) {
        vm.publishAfterDate = vm.publishAfterTime = new Date(vm.item.publish_after);
      }

      if (vm.item.products && vm.item.products.length > 0) {
        $log.debug("Querying stock availability");
        Cart.isAvailable(vm.item.products[0].url).then(function (response) {
          $log.debug("Availability response: ", response);
          if (response.data.hasOwnProperty("num_available") && response.data.num_available != null) {
            vm.item.num_in_stock = response.data.num_available;
          }
        });
      }
    }

    // watchers
    $scope.$watch("vm.files", function () {
      ItemForm.upload(vm, $scope, vm.files);
    });

    function setPrimaryImage(index) {
      for (let i = 0; i < vm.item.external_images.length; i++) {
        vm.item.external_images[i].primary = i === index;
      }
    }

    function deleteImage(index) {
      vm.item.external_images.splice(index, 1);
    }

    // Looks like typeahead bug - model field is not
    // being cleared when the input is empty
    $scope.$watch("vm.item.country_name", function (newValue) {
      if (newValue === "") {
        delete vm.item.country_name;
      }
    });

    function getCountry(val) {
      return CountryName.all({ q: val });
    }

    function archive() {
      Item.archive({ id: vm.item.id, archive: true }, function () {
        $uibModalInstance.result.then(function () {
          toastr.success("Item archived");
        });
        $uibModalInstance.close(true);
      });
    }

    function save(form, files, close = false) {
      if (!vm.item.external_images || vm.item.external_images.length === 0) {
        form.external_images.$setValidity("required", false);
      }

      $log.debug("saving");
      $log.debug("form: " + form);
      $log.debug("files: " + files);

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        $log.debug("Form invalid");
        $log.debug(vm.item, form.$error);
        return;
      }

      vm.saving = true;

      Item.update(
        { id: vm.item.id },
        vm.item,
        function () {
          $scope.$broadcast("show-errors-reset");
          if (close) {
            $uibModalInstance.result.then(function () {
              toastr.success("Item updated");
            });
            $uibModalInstance.close(true);
          } else {
            form.$setPristine();
          }
          vm.saving = false;
        },
        function (resp) {
          $log.debug("Error saving item");

          if (resp.data && resp.status === 402) {
            // show payment screen
            vm.paying = true;
            $log.debug(resp.data);
            toastr.error("Sorry there was a problem with your payment: " + resp.data.detail);
          } else if (resp.data && resp.status !== 500) {
            angular.forEach(resp.data, function (errors, field) {
              $log.debug(field + " : " + errors);
              try {
                form[field].$setValidity("server", false);
                if (angular.isArray(errors)) {
                  vm.errors[field] = errors.join(", ");
                } else {
                  angular.forEach(errors, function (nested_errors) {
                    vm.errors[field] = nested_errors.join(", ");
                  });
                }
              } catch (e) {
                $log.error(resp.status + ": " + resp.statusText);
                if (angular.isArray(errors)) {
                  errors = errors.join(", ");
                  vm.errors.non_field_errors = errors;
                } else if (angular.isObject(errors)) {
                  angular.forEach(errors, function (nested_errors, field) {
                    try {
                      form[field].$setValidity("server", false);
                      if (angular.isArray(nested_errors)) {
                        vm.errors[field] = nested_errors.join(", ");
                      } else {
                        angular.forEach(nested_errors, function (more_nested_errors) {
                          vm.errors[field] = more_nested_errors.join(", ");
                        });
                      }
                    } catch (e) {
                      vm.errors.non_field_errors = errors;
                    }
                  });
                } else {
                  vm.errors.non_field_errors = errors;
                }
              }
            });
            vm.paying = false;
          } else {
            $log.error(resp.status + ": " + resp.statusText);
            vm.errors.non_field_errors = "Sorry there was a problem saving your item, please try again";
            vm.paying = false;
          }
          $scope.$broadcast("show-errors-check-validity");
          vm.saving = false;
        }
      );
    }

    function saveAndClose(form, files) {
      save(form, files, true);
    }

    function addVideo() {
      vm.item.external_images = _.dropWhile(vm.item.external_images, {
        media_type: "video",
      });
      if (vm.videoUrl.url) {
        const videoObject = ItemForm.parseVideo(vm.videoUrl.url);
        vm.videoUrl = { ...vm.videoUrl, ...videoObject };
        if (vm.videoUrl.id) {
          vm.item.external_images.push(vm.videoUrl);
        } else {
          vm.itemForm.form.video_url.$setValidity("url", false);
          $scope.$broadcast("show-errors-check-validity");
        }
      }
    }

    function groupPreferredCountries(country) {
      if (country.priority) {
        return undefined;
      }
      return "--";
    }

    function getPaymentAmounts() {
      const amounts = [];
      if (vm.listing && vm.listing.amount > 0) {
        amounts.push({
          amount: vm.listing.amount,
          name: "Listing: " + vm.listing.name,
          feesIncluded: true,
        });
      }

      $log.debug(amounts);

      return amounts;
    }

    function next(form, files, duplicate = false) {
      if (!vm.item.external_images || vm.item.external_images.length === 0) {
        form.external_images.$setValidity("required", false);
      } else {
        form.external_images.$setValidity("required", true);
      }

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        $log.debug("Form invalid");
        $log.debug(vm.item, form.$error);
        return;
      }

      vm.itemForm.form = form;
      vm.files = files;

      if (!isPaymentRequired()) {
        return create(duplicate);
      }

      vm.paying = true;
      vm.paymentAmounts = getPaymentAmounts();
      vm.listingPrice = vm.listing && vm.listing.amount ? vm.listing.amount : 0;
    }

    function back() {
      vm.paying = false;
    }

    function saveWithPayment(payment) {
      if (payment) {
        vm.item.payment = payment;
      }
      save(vm.itemForm.form, vm.files, !vm.duplicate);
    }

    function onPaymentError(error) {
      toastr.error(error);
      vm.saving = false;
    }

    function create(duplicate = false) {
      vm.saving = true; // triggers payment directive save
      vm.duplicate = duplicate;

      if (!isPaymentRequired()) {
        save(vm.itemForm.form, vm.files, !vm.duplicate);
      }

      // payment directive will eventually call saveWithPayment
    }

    function isPaymentRequired() {
      return vm.listing.amount > 0;
    }

    function showField(field: string) {
      $log.debug(vm.item.caption_option, vm.captions);
      if (vm.item.caption_option && vm.caption_index) {
        $log.debug(vm.captions[vm.caption_index]);
        return vm.item.caption_option && vm.captions && vm.captions[vm.caption_index].hide.indexOf(field) === -1;
      }
      return false;
    }

    function showPayment() {
      // show payment info if the caption has changed and the new price is more than the initial price
      return initial_caption_id !== vm.item.caption_option.id && vm.initialPrice < vm.price;
    }
  }
})();
