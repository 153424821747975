import { IHttpResponse } from "angular";

class VimeoController {
  videoIframe: string = undefined;
  url: string = undefined;

  static $inject = ["VimeoAPI", "$sce"];

  constructor(public VimeoAPI: any, public $sce: any) {}

  $onInit() {}

  $onChanges(): void {
    this.VimeoAPI.oEmbed(this.url).then(
      function (response: IHttpResponse<any>) {
        console.debug(response.data);
        this.videoIframe = this.$sce.trustAsHtml(response.data.html);
      }.bind(this),
      function (error: IHttpResponse<any>) {
        if (error.status !== 404) {
          this.toastr.error("Error getting vimeo details");
          this.$log.debug(error);
        }
      }.bind(this)
    );
  }
}

angular.module("marketPlace.controls").component("vimeoEmbed", {
  bindings: {
    url: "<",
  },
  template: "<div ng-bind-html='vm.videoIframe'></div>",
  controller: VimeoController,
  controllerAs: "vm",
});
