(function() {
  "use strict";
  angular.module("marketPlace.invites").controller("InvitesController", InvitesController);

  InvitesController.$inject = ["$scope", "$uibModalInstance", "$log", "Invite", "toastr", "SITE_NAME"];

  function InvitesController($scope, $uibModalInstance, $log, Invite, toastr, SITE_NAME) {
    const vm = this;
    vm.siteName = SITE_NAME;
    vm.sendInvite = sendInvite;
    vm.cancel = cancel;
    vm.invite = new Invite({ is_admin: false });
    vm.form = {};
    vm.errors = {};

    function sendInvite() {
      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }

      vm.invite.$save(
        function() {
          closeModal();
          $uibModalInstance.result.then(function() {
            toastr.success("Invite sent");
          });
        },
        function(resp) {
          $log.debug("Could not send invite");

          if (resp.data && resp.status !== 500) {
            angular.forEach(resp.data, function(errors, field) {
              $log.debug(field + " : " + errors);
              try {
                vm.form[field].$setValidity("server", false);
                vm.errors[field] = errors.join(", ");
              } catch (e) {
                $log.error(resp.status + ": " + resp.statusText);
                if (angular.isArray(errors)) {
                  errors = errors.join(", ");
                }
                vm.errors.non_field_errors = errors;
              }
            });
          } else {
            $log.error(resp.status + ": " + resp.statusText);
            vm.errors.non_field_errors = "Could not send invite, please try again";
          }

          $scope.$broadcast("show-errors-check-validity");
        }
      );
    }

    function closeModal() {
      $log.debug("CLOSING INVITE MODAL");
      vm.saving = false;
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.close(true);
    }

    function cancel() {
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.dismiss("cancel");
    }
  }
})();
