(function() {
  "use strict";
  angular.module("marketPlace.common").filter("isoCurrency", isoCurrency);

  isoCurrency.$inject = ["$filter"];

  /**
   * Maps currency code to symbol
   * Formats a number as a currency using the correct symbol (ie $1,234.56, £10, €2,000).
   * When no currency symbol is provided, the default symbol is the dollar sign ($)
   * (Look up symbols here https://www.xe.com/symbols.php)
   *
   * @param amount Input to filter
   * @param currencyCode ISO 4217 currency code to map
   * @returns formatted string showing symbol and amount
   *
   **/

  const currencyMapping = {
    GBP: "£",
    EUR: "€"
  };
  const defaultSymbol = "$";

  function isoCurrency($filter) {
    return function(amount: number, currencyCode: string, decimalPlaces?: number) {
      if (currencyMapping.hasOwnProperty(currencyCode)) {
        return $filter("currency")(amount, currencyMapping[currencyCode], decimalPlaces);
      }
      return $filter("currency")(amount, defaultSymbol, decimalPlaces);
    };
  }
})();
