(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("permissionDeniedPane", permissionDeniedPane);

  permissionDeniedPane.$inject = [];

  function permissionDeniedPane() {
    return {
      restrict: "E",
      replace: "true",
      template: require("./templates/template.html"),
      scope: {
        item: "=",
        close: "&onClose",
        user: "=?"
      },
      controller: PermissionDeniedController,
      controllerAs: "vm",
      bindToController: true
    };
  }

  PermissionDeniedController.$inject = ["$log", "$location", "moment"];

  function PermissionDeniedController($log, $location, moment) {
    const vm = this;

    vm.$onInit = function() {
      $log.debug("PermissionDeniedController");
      $log.debug("Obj:", vm.item);

      vm.age = moment(vm.item.created_date).fromNow();
      vm.url = $location.protocol() + "://" + $location.host() + vm.item.absolute_url;
    };
  }
})();
