import { PreviousService } from "../auth/previous.service";

(function() {
  "use strict";

  angular.module("marketPlace.items").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider
      .state("items", {
        template: "<div ui-view></div>",
        abstract: true,
        data: {
          requiresLogin: true,
          permissions: {
            redirectTo: "home.gallery"
          }
        },
        resolve: {
          user: getUser,
          organisations: getOrganisations,
          writableNiches: getWritableNiches,
          countries: getCountries,
          currencies: getCurrencies,
          settings: getSettings
        }
      })
      .state("items.add", {
        url: "/add",
        data: {
          permissions: {
            only: "ITEM_CREATOR",
            redirectTo: [
              "flash",
              function(flash) {
                flash.info("Upgrade required");
                return "home.dashboard.billing.upgrade";
              }
            ]
          }
        },
        resolve: {
          $transition$: "$transition$"
        },
        params: {
          section: {
            type: "string",
            value: ""
          },
          id: null
        },
        onEnter: [
          "$log",
          "$uibModal",
          "PreviousService",
          "user",
          "organisations",
          "writableNiches",
          "countries",
          "currencies",
          "settings",
          "$transition$",
          function(
            $log,
            $uibModal,
            PreviousService: PreviousService,
            user,
            organisations,
            writableNiches,
            countries,
            currencies,
            settings,
            $transition$
          ) {
            PreviousService.setTransitionInvoked($transition$);
            const modalInstance = $uibModal.open({
              animation: true,
              backdrop: "static",
              template: require("./templates/item-modal.html"),
              size: "lg",
              controller: "ItemsAddController",
              controllerAs: "vm",
              resolve: {
                user: function() {
                  return user;
                },
                organisations: function() {
                  return organisations;
                },
                writableNiches: function() {
                  return writableNiches;
                },
                countries: function() {
                  return countries;
                },
                currencies: function() {
                  return currencies;
                },
                settings: function() {
                  return settings;
                },
                $transition$: function() {
                  return $transition$;
                },
                modalTitle: function() {
                  return "Create Listing";
                }
              }
            });

            modalInstance.result.finally(function() {
              $log.debug("Add modal dismissed going to previous state");
              PreviousService.goToPreviousState($transition$.to().name, { reload: true }, "home.gallery", {}, [
                "upgrade"
              ]);
            });

            return false;
          }
        ]
      })
      .state("items.edit", {
        url: "/edit/{id:int}",
        data: {
          permissions: {
            only: "change_item"
          }
        },
        resolve: {
          $transition$: "$transition$",
          item: [
            "$transition$",
            "Item",
            function($transition$, Item) {
              return Item.get({ id: $transition$.params().id }).$promise;
            }
          ],
          canEdit: [
            "$q",
            "$log",
            "$state",
            "user",
            "item",
            "PermAuthorization",
            "PermPermissionMap",
            "toastr",
            function($q, $log, $state, user, item, PermAuthorization, PermPermissionMap, toastr) {
              const deferred = $q.defer();
              if (item.user.id === user.id) {
                deferred.resolve(true);
              } else {
                PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["change_any_item"] }))
                  .then(function() {
                    deferred.resolve(true);
                  })
                  .catch(function() {
                    $log.debug("Permission denied for user " + user.id + " to edit item owned by user " + item.user.id);
                    toastr.error("Permission denied");
                    $state.go("home.gallery");
                    deferred.reject("Permission denied");
                  });
              }
              return deferred.promise;
            }
          ]
        },
        onEnter: [
          "$uibModal",
          "$transition$",
          "PreviousService",
          "user",
          "organisations",
          "writableNiches",
          "countries",
          "currencies",
          "settings",
          "item",
          function(
            $uibModal,
            $transition$,
            PreviousService: PreviousService,
            user,
            organisations,
            writableNiches,
            countries,
            currencies,
            settings,
            item
          ) {
            const modalInstance = $uibModal.open({
              animation: true,
              template: require("./templates/item-modal.html"),
              size: "lg",
              controller: "ItemsEditController",
              controllerAs: "vm",
              resolve: {
                user: function() {
                  return user;
                },
                item: function() {
                  return item;
                },
                organisations: function() {
                  return organisations;
                },
                writableNiches: function() {
                  return writableNiches;
                },
                countries: function() {
                  return countries;
                },
                currencies: function() {
                  return currencies;
                },
                settings: function() {
                  return settings;
                },
                modalTitle: function() {
                  return "Edit Listing";
                }
              }
            });

            PreviousService.invokeModalOnEnter(modalInstance, $transition$, true);

            return false;
          }
        ]
      });
  }

  getUser.$inject = ["AuthService"];
  function getUser(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getCurrentUser();
    }
  }

  getOrganisations.$inject = ["AuthService"];
  function getOrganisations(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getOrganisations();
    }
    return [];
  }

  getWritableNiches.$inject = ["Niches"];
  function getWritableNiches(Niches) {
    return Niches.write.query({ item_type: "ITEM" }).$promise;
  }

  getCountries.$inject = ["CountryName"];
  function getCountries(CountryName) {
    return CountryName.all().$promise;
  }

  getCurrencies.$inject = ["Currency"];
  function getCurrencies(Currency) {
    return Currency.query().$promise;
  }

  getSettings.$inject = ["Settings"];
  function getSettings(Settings) {
    return Settings.query();
  }
})();
