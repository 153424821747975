import { ILogService } from "angular";
import { StateService } from "@uirouter/angularjs";

class RegisterSearchController {
  registerGroups: string[] = [];
  user: any = null;
  savedSearch: any = {};
  showSavedSearch = true;
  showRegister = false;
  checkSavedSearch = false;

  static $inject = ["$log", "$state", "UserSearchService"];
  constructor(
    public $log: ILogService,
    public $state: StateService,
    public UserSearchService: any,
    public resolve: any
  ) {
    this.$log.debug("RegisterSearchController", this.resolve);
  }

  $onInit() {
    this.registerGroups = [this.resolve.groups[0].id];
    this.savedSearch = {
      params: this.resolve.search,
      is_default: true,
      isNew: true
    };
  }

  onSaveSearchFormSave = (valid: boolean) => {
    if (valid) {
      this.showSavedSearch = false;
      this.showRegister = true;
    }
  };

  onRegister(user) {
    this.user = user;
    this.UserSearchService.create(this.savedSearch.name, this.savedSearch.params).then(
      function() {
        this.$state.go("home.gallery", this.savedSearch.params, { inherit: false, reload: true });
        this.close();
      }.bind(this)
    );
  }

  onSaveSearch() {}
}

angular.module("marketPlace.home").component("registerSearch", {
  bindings: {
    resolve: "<",
    close: "&",
    dismiss: "&"
  },
  template: require("./templates/register-search.html"),
  controller: RegisterSearchController,
  controllerAs: "vm"
});
