(function() {
  "use strict";
  angular.module("marketPlace.games").factory("Game", game);

  game.$inject = ["$log", "$http", "$q", "API_URL"];

  function game($log, $http, $q, API_URL) {
    const GAME_URL = API_URL + "/api/game/";
    return {
      get: function(id) {
        const deferred = $q.defer();

        const url = GAME_URL + "item/" + id + "/";
        $http.get(url).then(
          function(response) {
            $log.debug(response);
            if (response.status === 200) {
              deferred.resolve(response.data);
            } else {
              deferred.reject();
            }
          },
          function(response) {
            $log.debug(response);
            deferred.reject();
          }
        );
        return deferred.promise;
      },
      entry: function(entryObj) {
        return $http.post(GAME_URL + "entry/", entryObj);
      },
      results: function(params) {
        let config = null;
        if (angular.isDefined(params) && params !== null) {
          config = { params: params };
        }
        return $http.get(GAME_URL + "result/", config).then(function(response) {
          $log.debug(response);
          return response.data;
        });
      },
      leaderboard: function(params) {
        let config = null;
        if (angular.isDefined(params) && params !== null) {
          config = { params: params };
        }
        return $http.get(GAME_URL + "leaderboard/", config).then(function(response) {
          $log.debug(response);
          return response.data;
        });
      },
      list: function(params) {
        if (angular.isUndefined(params) || params === null) {
          params = {};
        }
        return $http.get(GAME_URL + "games/", { params: params }).then(function(response) {
          $log.debug(response);
          return response.data;
        });
      },
      leaderboardUsers: function(params) {
        let config = null;
        if (angular.isDefined(params) && params !== null) {
          config = { params: params };
        }
        return $http.get(GAME_URL + "leaderboard-users/", config).then(function(response) {
          $log.debug(response);
          return response.data;
        });
      },
      gameTypes: function(params) {
        let config = null;
        if (angular.isDefined(params) && params !== null) {
          config = { params: params };
        }
        return $http.get(GAME_URL + "game-types/", config).then(function(response) {
          $log.debug(response);
          return response.data;
        });
      }
    };
  }
})();
