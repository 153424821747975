(function() {
  "use strict";

  const upgradeSubscription = {
    template: require("./templates/upgrade-subscription.html"),
    bindings: {
      user: "<",
      currentSubscription: "<",
      close: "&",
      dismiss: "&",
      groupId: "<?",
      includeFree: "<?",
      allowClose: "<?"
    },
    controller: upgradeSubController
  };

  angular.module("marketPlace.payments").component("upgradeSubscription", upgradeSubscription);

  upgradeSubController.$inject = ["$log", "Payment", "toastr", "AuthService", "AppConfig"];

  function upgradeSubController($log, Payment, toastr, AuthService, AppConfig) {
    const $ctrl = this;

    $ctrl.selectProduct = selectProduct;
    $ctrl.upgrade = upgrade;
    $ctrl.subscribe = subscribe;
    $ctrl.onPaymentError = onPaymentError;
    $ctrl.selectCustom = selectCustom;
    $ctrl.backToAllProducts = backToAllProducts;
    $ctrl.onPaymentFormLoaded = onPaymentFormLoaded;

    $ctrl.$onInit = function() {
      $log.debug("upgradeSubController $onInit");
      $ctrl.maxUpgrade = false;
      $ctrl.paymentAmounts = [];
      $ctrl.showPayment = false;
      $ctrl.showMonthlyAnnualToggle = true;
      $ctrl.allowClose = angular.isDefined($ctrl.allowClose) && $ctrl.allowClose !== null ? !!$ctrl.allowClose : true;
      $ctrl.showBackButton = false;
      $ctrl.annualTerm = false;
      $ctrl.paymentFormLoaded = false;
      $ctrl.includeFree = angular.isDefined($ctrl.includeFree)
        ? $ctrl.includeFree
        : parseInt($ctrl.currentSubscription.price) === 0 || !$ctrl.currentSubscription.status;
      $ctrl.defaultCurrency = AppConfig.defaultCurrency;

      Payment.products({
        group_id: $ctrl.groupId,
        include_free: $ctrl.includeFree
      }).$promise.then(function(resp) {
        $log.debug("All products: ", resp);
        const products = resp;
        const productsToRemove = [];
        $ctrl.customProducts = [];

        function splitPrice(price) {
          if (angular.isDefined(price) && parseInt(price) !== 0) {
            const priceParts = price.toString().split(".");
            if (priceParts[1] === "00") {
              priceParts[1] = null;
            }
            return priceParts;
          }
        }

        // Put custom products into their own array, and format prices on remaining products
        let currentIndex;
        for (let j = 0; j < products.length; j++) {
          const product = products[j];

          if (product.custom_pricing) {
            $ctrl.customProducts.push(angular.copy(product));
            productsToRemove.push(j);
          } else if (!$ctrl.includeFree && parseInt(product.monthly_price) === 0) {
            productsToRemove.push(j);
          } else {
            product.monthlyPriceParts = splitPrice(product.monthly_price);
            product.annualPriceParts = splitPrice(product.annual_price);
          }

          if (product.current) {
            currentIndex = j;
          }
        }

        $log.debug("currentIndex: ", currentIndex);
        $log.debug("customProducts: ", $ctrl.customProducts);

        if (angular.isDefined(currentIndex) && currentIndex >= products.length - 2 && $ctrl.customProducts.length > 0) {
          // special case to only show platinum plan if we're already on platinum or gold
          $ctrl.products = $ctrl.customProducts;
          $ctrl.showCustom = false;
          $ctrl.showFree = false;
          $ctrl.showMonthlyAnnualToggle = false;
        } else {
          // Remove free and custom products
          for (let k = productsToRemove.length - 1; k >= 0; k--) {
            products.splice(productsToRemove[k], 1);
          }

          if (products.length > 0) {
            $ctrl.products = products;
            $ctrl.showCustom = $ctrl.customProducts.length > 0;
          } else if ($ctrl.customProducts.length > 0) {
            selectCustom();
          }
        }

        $log.debug("Products: ", $ctrl.products);
      }, angular.noop);
    };

    function upgrade(productId) {
      $ctrl.processing = true;
      Payment.updateSubscription(
        { product_id: productId, group_id: $ctrl.groupId },
        function() {
          toastr.success("Subscription successful");
          AuthService.clearCache();
          $ctrl.close({ $value: "ok" });
        },
        function(response) {
          $log.debug(response);
          if (response.hasOwnProperty("data") && response.data.hasOwnProperty("detail")) {
            toastr.error("Error creating subscription: " + response.data.detail);
          } else {
            toastr.error("Error creating subscription, please try again.");
          }
          $ctrl.processing = false;
        }
      );
    }

    function swapProducts(alternateProducts) {
      $ctrl.products = alternateProducts;
      $ctrl.showCustom = !$ctrl.showCustom;
      $ctrl.showFree = !$ctrl.showFree;
      $ctrl.showMonthlyAnnualToggle = !$ctrl.showMonthlyAnnualToggle;
    }

    function selectCustom() {
      $ctrl.originalProducts = angular.copy($ctrl.products);
      swapProducts($ctrl.customProducts);
      $ctrl.showBackButton = true;
    }

    function backToAllProducts() {
      swapProducts($ctrl.originalProducts);
      $ctrl.showBackButton = false;
    }

    function selectProduct(isCurrent, productPrice, productName, productId) {
      if ($ctrl.subscribing || $ctrl.processing) {
        return;
      }

      if (isCurrent) {
        $ctrl.close({ $value: "ok" });
        return;
      }

      let amount = parseFloat(productPrice);
      if ($ctrl.annualTerm) {
        amount = amount * 12;
      }

      $ctrl.paymentAmounts[0] = {
        amount: amount,
        name: productName,
        product_id: productId,
        billing_frequency: $ctrl.annualTerm ? 1 : 12,
        feesIncluded: true
      };
      $log.debug("$ctrl.paymentAmounts: ", $ctrl.paymentAmounts[0]);

      $ctrl.showPayment = true;
    }

    function onPaymentError(error) {
      $log.debug("Payment error: ", error);
      toastr.error(error);
      $ctrl.subscribing = false;
    }

    function onPaymentFormLoaded() {
      $log.debug("onPaymentFormLoaded: enabling payment button");
      $ctrl.paymentFormLoaded = true;
    }

    function subscribe(payment) {
      // This is called as a callback after payment info has been added
      $log.debug("Got payment info; about to subscribe", $ctrl.paymentAmounts);
      if ($ctrl.paymentAmounts.length > 0) {
        payment.product_id = $ctrl.paymentAmounts[0].product_id;
      }
      if (angular.isDefined($ctrl.groupId)) {
        payment.group_id = $ctrl.groupId;
      }

      Payment.subscribe(
        payment,
        function() {
          toastr.success("Subscription successful");
          AuthService.clearCache();
          $ctrl.close({ $value: "ok" });
        },
        function(response) {
          $log.debug(response);
          if (response.hasOwnProperty("data") && response.data.hasOwnProperty("detail")) {
            toastr.error("Error creating subscription: " + response.data.detail);
          } else {
            toastr.error("Error creating subscription, please try again.");
          }
          $ctrl.subscribing = false;
        }
      );
    }

    $ctrl.cancel = function() {
      $ctrl.dismiss({ $value: "cancel" });
    };
  }
})();
