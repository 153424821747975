/**
 * @desc directive that is used to compare form fields (e.g. password and confirmation)
 * @example   <input type="password" id="confirmNewPassword" name="confirmNewPassword" ng-model="passwordInfo.confirmNewPassword" class="form-control" placeholder="Confirm new password" ng-minlength="8" ng-maxlength="128" required compare-to="passwordInfo.newPassword">
 */

(function() {
  "use strict";

  angular.module("marketPlace.auth").directive("compareTo", compareTo);

  function compareTo() {
    return {
      require: "ngModel",
      scope: {
        otherModelValue: "=compareTo"
      },
      link: function(scope, element, attributes, ngModel) {
        ngModel.$validators.compareTo = function(modelValue) {
          return modelValue == scope.otherModelValue;
        };

        scope.$watch("otherModelValue", function() {
          ngModel.$validate();
        });
      }
    };
  }
})();
