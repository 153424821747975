import { ILogService, IScope, IWindowService } from "angular";

(function() {
  "use strict";
  angular.module("marketPlace.games").controller("MyResultsController", myResultsController);

  myResultsController.$inject = ["$log", "$scope", "$window", "DashboardTable", "Game", "user"];

  function myResultsController($log: ILogService, $scope: IScope, $window: IWindowService, DashboardTable, Game, user) {
    const vm = this;
    vm.user = user;
    vm.loading = true;
    vm.allExpanded = false;
    vm.currentPage = 1;
    vm.itemsPerPage = 50;

    vm.sortChanged = angular.bind(vm, DashboardTable.sortChanged, refreshData);
    vm.pageChanged = angular.bind(vm, DashboardTable.pageChanged, refreshData);
    vm.expandAll = angular.bind(vm, DashboardTable.expandAll, $scope);

    activate();

    function activate(): void {
      $log.debug("ResultsController");

      Game.list().then(function(response) {
        vm.games = response.results;
      });

      Game.gameTypes().then(function(response) {
        vm.gameTypes = response;
      });

      refreshData();
    }

    function refreshData(params = {}): void {
      angular.extend(params, { expanded: true });
      Game.results(params)
        .then(function(results) {
          vm.results = results;
          $window.scrollTo(0, 0);
          if (vm.results.count === 1) {
            vm.allExpanded = true;
          }
        })
        .finally(function() {
          vm.loading = false;
        });
    }
  }
})();
