(function() {
  "use strict";

  angular.module("marketPlace.email").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.email", {
      url: "/email",
      template: require("./templates/email.html"),
      controller: "EmailController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      },
      resolve: {
        email_notifications: getEmailNotifications
      }
    });
  }

  getEmailNotifications.$inject = ["Email"];
  function getEmailNotifications(Email) {
    return Email.get().$promise;
  }
})();
