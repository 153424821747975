import { ILogService } from "angular";

(function() {
  "use strict";

  angular.module("marketPlace.organisations").factory("OrganisationForm", OrganisationForm);

  OrganisationForm.$inject = ["$log", "Niches"];

  function OrganisationForm($log: ILogService, Niches) {
    function getSections(niches, niche_id: number) {
      return niches[niche_id].sections;
    }

    function getGalleries(sections, section_id: number) {
      return sections[section_id].galleries;
    }

    function getCaptions(galleries, gallery_id: number) {
      return galleries[gallery_id].captions;
    }

    function getWritableNiches() {
      return Niches.write.query({ item_type: "ORGANISATION" }).$promise;
    }

    function getChangeHandlers(vm) {
      function setGallery() {
        $log.debug("setGallery");
        vm.caption_id = null;
        vm.captions = getCaptions(vm.galleries, vm.organisation.gallery_option);
        if (vm.captions.length === 1) {
          $log.debug("Single caption");
          vm.organisation.caption = vm.captions[0].id;
        }
      }

      function setSection() {
        $log.debug("setSection");
        vm.organisation.gallery_option = null;
        vm.galleries = getGalleries(vm.sections, vm.organisation.section_option);
        if (vm.galleries._order.length === 1) {
          $log.debug("Single gallery");
          vm.organisation.gallery_option = vm.galleries._order[0];
          setGallery();
        }
      }
      function setNiche() {
        $log.debug("setNiche");
        vm.organisation.section_option = null;
        vm.sections = getSections(vm.niches, vm.organisation.niche_option);
        if (vm.sections._order.length === 1) {
          $log.debug("Single section");
          vm.organisation.section_option = vm.sections._order[0];
          setSection();
        }
      }
      function setCaption() {
        $log.debug("setCaption");
        if (vm.organisation.caption_option) {
          vm.organisation.niche_option = vm.organisation.caption_option.niche.id;
          $log.debug("niche_option: ", vm.organisation.niche_option);
          try {
            setNiche();
          } catch (e) {
            $log.debug(e);
            vm.organisation.niche_option = null;
            return;
          }
          if (!vm.organisation.section_option) {
            vm.organisation.section_option = vm.organisation.caption_option.section.id;
            try {
              setSection();
            } catch (e) {
              $log.debug(e);
              vm.organisation.section_option = null;
              return;
            }
          }
          $log.debug("section_option: ", vm.organisation.section_option);

          if (!vm.organisation.gallery_option) {
            vm.organisation.gallery_option = vm.organisation.caption_option.gallery.id;
            try {
              setGallery();
            } catch (e) {
              $log.debug(e);
              vm.organisation.gallery_option = null;
              return;
            }
          }
          $log.debug("gallery_option: ", vm.organisation.gallery_option);
        }
      }
      return {
        setNiche: setNiche,
        setSection: setSection,
        setGallery: setGallery,
        setCaption: setCaption
      };
    }

    return {
      getWritableNiches: getWritableNiches,
      getChangeHandlers: getChangeHandlers
    };
  }
})();
