(function() {
  "use strict";
  angular.module("marketPlace.invites").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider.state("home.dashboard.invites", {
      url: "/invite",
      data: {
        requiresLogin: true,
        permissions: {
          only: "add_organisationuser",
          except: "ORG_USER"
        }
      },
      onEnter: createInviteModal
    });
  }

  createInviteModal.$inject = ["$uibModal"];
  function createInviteModal($uibModal) {
    $uibModal.open({
      animation: true,
      template: require("./templates/invites-modal.html"),
      controller: "InvitesController",
      controllerAs: "vm",
      size: "md"
    });

    return false;
  }
})();
