(function() {
  "use strict";
  angular.module("marketPlace.common", []).run([
    "$templateCache",
    function($templateCache) {
      // Put in cache the files that are ng-include'd in templates
      $templateCache.put("app/common/templates/danger-alert.html", require("./templates/danger-alert.html"));
      $templateCache.put("app/common/templates/datepickerPopup.html", require("./templates/datepickerPopup.html"));
      $templateCache.put(
        "app/common/templates/datepicker/datepicker.html",
        require("./templates/datepicker/datepicker.html")
      );
      $templateCache.put("app/common/templates/datepicker/day.html", require("./templates/datepicker/day.html"));
      $templateCache.put("app/common/templates/datepicker/month.html", require("./templates/datepicker/month.html"));
      $templateCache.put("app/common/templates/datepicker/year.html", require("./templates/datepicker/year.html"));
      $templateCache.put(
        "app/common/templates/pagination/pagination.html",
        require("./templates/pagination/pagination.html")
      );
      $templateCache.put("app/common/templates/timepicker.html", require("./templates/timepicker.html"));
      $templateCache.put(
        "app/common/templates/accordion/accordion.html",
        require("./templates/accordion/accordion.html")
      );
      $templateCache.put(
        "app/common/templates/accordion/accordion-group.html",
        require("./templates/accordion/accordion-group.html")
      );
    }
  ]);
})();
