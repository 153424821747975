(function() {
  "use strict";

  angular.module("marketPlace.payments").factory("PaymentForm", PaymentForm);

  PaymentForm.$inject = ["$log", "toastr", "Payment", "PaymentMethod", "braintreeClient", "braintreeHostedFields"];

  function PaymentForm($log, toastr, Payment, PaymentMethod, braintreeClient, braintreeHostedFields) {
    function getHostedForm($scope) {
      const form = this;
      let hostedFieldsInstance;
      form.storedCard = null;
      form.method = "new-card";
      form.loadingHostedFields = true;
      form.processing = false;
      form.total = 0;
      form.amount = 0;
      form.creditCardFee = 0;
      form.transactionFee = 0.45;
      form.transactionRate = 0.029;
      form.transactionRatePercentage = form.transactionRate * 100 || 2.9;

      form.updateTransactionTotals = function(amount, feesIncluded) {
        let transactionFee = form.transactionFee;

        if (amount <= 0) {
          transactionFee = 0;
          amount = 0;
        }

        form.amount = amount;

        if (feesIncluded) {
          form.total = amount;
          form.creditCardFee = amount * form.transactionRate;
        } else {
          form.total = (amount + transactionFee) / (1 - form.transactionRate);
          form.creditCardFee = form.total * form.transactionRate;
        }
      };

      form.loadStoredCard = function(reload) {
        if (!form.storedCard || reload) {
          PaymentMethod.query(
            function(card) {
              form.storedCard = card;
            },
            function() {
              angular.noop();
            }
          );
        }
      };

      form.setPaymentMethod = function(method) {
        form.method = method;
        form.loadStoredCard(false);
      };

      form.activate = function() {
        $log.debug("PaymentForm");
        form.processing = false;
        form.loadStoredCard(false);

        Payment.getToken(function(clientToken) {
          braintreeClient.create(
            {
              authorization: clientToken.token
            },
            function(clientErr, clientInstance) {
              if (clientErr) {
                // Handle error in client creation
                toastr.error(clientErr);
                return;
              }

              braintreeHostedFields.create(
                {
                  client: clientInstance,
                  styles: {
                    input: {
                      "font-size": "14pt"
                    },
                    "input.invalid": {
                      color: "red"
                    },
                    "input.valid": {
                      color: "green"
                    }
                  },
                  fields: {
                    number: {
                      selector: "#card-number",
                      placeholder: "4111 1111 1111 1111"
                    },
                    cvv: {
                      selector: "#cvv",
                      placeholder: "123"
                    },
                    expirationDate: {
                      selector: "#expiration-date",
                      placeholder: "10/2019"
                    }
                  }
                },
                function(hostedFieldsErr, instance) {
                  if (hostedFieldsErr) {
                    // Handle error in Hosted Fields creation
                    toastr.error(hostedFieldsErr);
                    return;
                  }

                  hostedFieldsInstance = instance;

                  // Enable button and trigger digest
                  $scope.$apply(function() {
                    form.loadingHostedFields = false;
                  });
                }
              );
            }
          );
        });
      };

      form.onSubmit = function() {
        $log.debug("Payment form submitted");
        form.processing = true;
        hostedFieldsInstance.tokenize(function(tokenizeErr, payload) {
          $log.debug(payload);
          if (tokenizeErr) {
            // Handle error in Hosted Fields tokenization
            $log.debug(tokenizeErr);
            toastr.error(tokenizeErr.message);
          } else {
            $scope.$emit("payments:nonce-received", { nonce: payload.nonce });
          }

          form.processing = false;
        });
      };

      form.onCancel = function() {
        $scope.$emit("payments:payment-cancelled");
      };

      return form;
    }

    return {
      get: getHostedForm
    };
  }
})();
