(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("loadingPane", loadingPane);

  loadingPane.$inject = [];

  function loadingPane() {
    return {
      restrict: "E",
      replace: "true",
      template: require("./templates/loadingPane.html"),
      scope: {
        close: "&onClose",
        user: "=?"
      }
    };
  }
})();
