/* eslint-disable @typescript-eslint/no-var-requires */
const itemTemplate = require("./templates/item-detail.html");
const eventTemplate = require("./templates/event-detail.html");
const orgTemplate = require("./templates/organisation-detail.html");
/* eslint-enable */

(function () {
  "use strict";
  angular.module("marketPlace.controls").directive("detailpane", detailPane);

  detailPane.$inject = [];

  function detailPane() {
    const directive = {
      restrict: "E",
      replace: "true",
      template: function (tElement, tAttrs) {
        switch (tAttrs.itemType) {
          case "ITEM":
            return itemTemplate;
          case "EVENT":
            return eventTemplate;
          case "ORG":
            return orgTemplate;
          default:
            return "<div></div>";
        }
      },
      scope: {
        item: "=",
        updateLikeCount: "&",
        close: "&onClose",
        user: "=?",
        placeholder: "=?",
        savedState: "=",
        initialItem: "=",
      },
      link: linkFunc,
      controller: DetailController,
      controllerAs: "vm",
      bindToController: true, // because the scope is isolated
    };
    return directive;

    function linkFunc(scope, elem) {
      elem.bind("mouseover", function () {
        elem.css("cursor", "pointer");
      });
    }
  }

  DetailController.$inject = [
    "$log",
    "$state",
    "$rootScope",
    "$element",
    "$timeout",
    "DATA_EVENTS",
    "moment",
    "Item",
    "$location",
    "$document",
    "toastr",
    "Event",
    "Organisation",
    "Following",
    "SITE_NAME",
    "AppConfig",
    "Thumbnail",
    "PermAuthorization",
    "PermPermissionMap",
    "broadStreetZones",
    "Cart",
    "Features",
    "Analytics",
    "socialMediaLinks",
    "AuthService",
    "ItemForm",
    "_",
    "VimeoAPI",
    "Searches",
    "UserSearchService",
  ];

  function DetailController(
    $log,
    $state,
    $rootScope,
    $element,
    $timeout,
    DATA_EVENTS,
    moment,
    Item,
    $location,
    $document,
    toastr,
    Event,
    Organisation,
    Following,
    SITE_NAME,
    AppConfig,
    Thumbnail,
    PermAuthorization,
    PermPermissionMap,
    broadStreetZones,
    Cart,
    Features,
    Analytics,
    socialMediaLinks,
    AuthService,
    ItemForm,
    _,
    VimeoAPI,
    Searches,
    UserSearchService,
  ) {
    const vm = this;
    vm.siteName = SITE_NAME;
    vm.zones = broadStreetZones;
    vm.enableGames = Features.games;
    vm.enableAuctions = Features.auctions;
    vm.enableBroadstreet = Features.broadstreet;
    vm.showScrollMessage = Features.scrollMessage;
    vm.hashtags = socialMediaLinks.hashtags;
    vm.emailSubject = socialMediaLinks.emailSubject;

    vm.age = "";
    vm.activeSlide = 0;
    vm.saving = false;
    vm.showContactUs = false;
    vm.keywords = "";
    vm.availability = {};

    vm.like = like;
    vm.follow = follow;
    vm.isFollowing = isFollowing;
    vm.archive = archive;
    vm.activateComment = activateComment;
    vm.addToCart = addToCart;
    vm.showMore = showMore;
    vm.shareEvent = shareEvent;
    vm.addToWatchList = addToWatchList;
    vm.removeFromWatchList = removeFromWatchList;

    vm.$onInit = function () {
      $log.debug("DetailController");
      $log.debug("ITEM:", vm.item);

      vm.placeholder = angular.isDefined(vm.placeholder) ? vm.name : "/assets/images/noimagesupplied.jpg";
      Analytics.view(vm.item.url_id);

      vm.item.read_count += 1;

      createMapUrl();

      if (vm.item.user.organisation.url) {
        const rawParams = vm.item.user.organisation.url.split("/");
        $log.debug("URL PARAMS", rawParams);
        const params = {
          niche: rawParams[1],
          section: rawParams[2],
          gallery: rawParams[3],
        };
        if (rawParams.length === 5) {
          params["rawId"] = rawParams[4];
        } else {
          params["caption"] = rawParams[4];
          params["rawId"] = rawParams[5];
        }
        vm.item.user.organisation.url_params = params;
      }

      vm.age = moment(vm.item.created_date).fromNow();
      $log.debug("URL:", vm.item, vm.item.item_type);
      vm.url = $location.protocol() + "://" + $location.host() + vm.item.absolute_url;

      if (angular.isDefined(vm.item.groups)) {
        vm.showContactUs = vm.item.groups.indexOf("Dealer") >= 0 || vm.item.groups.indexOf("Auction House") >= 0;
        vm.showSharingButtons = vm.item.groups.indexOf("Public") >= 0;
      }
      setCanEditPermission();

      // if there is an auction description check if there is a search_key
      // for the auction
      if (vm.item.search_keys) {
        for (let j = 0; j < vm.item.search_keys.length; j++) {
          if (vm.item.search_keys[j].match(/U[0-9]+A[0-9]+/i)) {
            vm.auction_search_key = vm.item.search_keys[j];
          }
        }
      }

      vm.keywords = [vm.item.section_slug, vm.item.gallery_slug];
      if (vm.item.caption_slug) {
        vm.keywords.push(vm.item.caption_slug);
      }

      if (vm.item.products && vm.item.products.length > 0) {
        vm.checkingCartAvailability = true;
        Cart.isAvailable(vm.item.products[0].url).then(function (response) {
          vm.availability = response.data;
          if (vm.user) {
            cartUpdated();
            $rootScope.$on(DATA_EVENTS.cartUpdated, cartUpdated);
          } else {
            vm.checkingCartAvailability = false;
            vm.canAddToCart = vm.availability.is_available_to_buy;
          }
        });
      }

      vm.showDescription = false;
      vm.showExpandDescription = false;
      $timeout(function () {
        angular.forEach($element.find("div"), function (el) {
          if (
            (el.className.indexOf("md:unexpanded") !== -1 && el.scrollHeight > el.clientHeight) ||
            el.scrollWidth > el.clientWidth
          ) {
            vm.showExpandDescription = true;
          }
        });
      });

      setViewsPermission();
      vm.jsonLd = createJsonLd(vm.item, vm.url);

      getWatchlist();
    };

    function getWatchlist() {
      Searches.query(
        { name: "watchlist" },
        function (resp) {
          $log.debug(resp);
          vm.watchlist = resp.results[0];
          $log.debug(vm.watchlist);
          $log.debug(vm.item.id);
          if (vm.watchlist.items.indexOf(parseInt(vm.item.id, 10)) !== -1) {
            $log.debug("Item in watchlist");
            vm.inWatchlist = true;
          } else {
            vm.inWatchlist = false;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );
    };

    function cartUpdated() {
      vm.checkingCartAvailability = true;
      vm.canAddToCart = false;
      vm.isInCart = false;
      if (vm.item.products.length > 0) {
        $log.debug("CHECKING BASKET");
        Cart.get().then(
          function (response) {
            $log.debug("BASKET", response.data);
            const basket = response.data;
            for (let line = 0; line < basket.lines.length; line++) {
              $log.debug("BASKET LINE", basket.lines[line].product, vm.item.products[0].url);
              if (basket.lines[line].product.indexOf(vm.item.products[0].url) > -1) {
                vm.checkingCartAvailability = false;
                vm.isInCart = true;
                vm.canAddToCart = false;
                return;
              }
            }
            vm.isInCart = false;
            vm.checkingCartAvailability = false;
            vm.canAddToCart = vm.availability.is_available_to_buy;
          },
          function () {
            vm.checkingCartAvailability = false;
          }
        );
      }
    }

    function addToCart() {
      $log.debug("PRODUCTS", vm.item.products);
      if (vm.item.products.length > 0) {
        const product = vm.item.products[0];
        $state.go("home.cart", {
          itemName: vm.item.name,
          productUrl: product.url,
          quantity: 1,
          options: [],
        });
      }
    }

    function createMapUrl() {
      function generateMap(size, address) {
        const mapOptions = [
          size,
          "zoom=12",
          "center=" + address,
          "markers=color:red|" + address,
          "scale=2",
          "style=feature:landscape|saturation:-100|lightness:65|visibility:on",
          "style=feature:poi|saturation:-100|lightness:51|visibility:simplified",
          "style=feature:road.highway|saturation:-100|visibility:simplified",
          "style=feature:road.arterial|saturation:-100|lightness:30|visibility:on",
          "style=feature:road.local|saturation:-100|lightness:40|visibility:on",
          "style=feature:transit|saturation:-100|visibility:simplified",
          "style=feature:administrative.province|visibility:off",
          "style=feature:water|element:labels|saturation:-100|lightness:-25|visibility:on",
          "style=feature:water|element:geometry|saturation:-97|lightness:-25|hue: 0xffff00|visibility:on",
          "key=" + AppConfig.GoogleAPIKey,
        ];

        return encodeURI("//maps.googleapis.com/maps/api/staticmap?" + mapOptions.join("&"));
      }

      if (vm.item.addresses.length > 0) {
        vm.mapUrl = generateMap("size=144x144", vm.item.addresses[0].geoaddress);

        if (vm.item.item_type === "EVENT") {
          if (vm.item.addresses.length > 1 && vm.item.addresses[1].display) {
            vm.eventMapUrl = generateMap("size=360x135", vm.item.addresses[1].geoaddress);
          }
        }
      }
    }

    function like() {
      // Like the item and set it's count and the tile count with the result from the api
      if (vm.item.item_type === "ITEM") {
        Item.like({ id: vm.item.id }, function (result) {
          vm.item.like_count = result.like_count;
          vm.updateLikeCount({ likeCount: result.like_count });
        });
      } else if (vm.item.item_type === "EVENT") {
        Event.like({ id: vm.item.id }, function (result) {
          vm.item.like_count = result.like_count;
          vm.updateLikeCount({ likeCount: result.like_count });
        });
      } else if (vm.item.item_type === "ORGANISATION") {
        Organisation.like({ id: vm.item.id }, function (result) {
          vm.item.like_count = result.like_count;
          vm.updateLikeCount({ likeCount: result.like_count });
        });
      }
    }

    function isFollowing() {
      if (angular.isDefined(vm.user)) {
        if (vm.item.item_type === "ORGANISATION") {
          return vm.user.following.organisations.indexOf(vm.item.id) >= 0;
        }

        if (angular.isDefined(vm.item.user)) {
          if (vm.item.user.organisation.id) {
            return vm.user.following.organisations.indexOf(vm.item.user.organisation.id) >= 0;
          }
          return vm.user.following.users.indexOf(vm.item.user.public_uid) >= 0;
        }
      }
      return false;
    }

    function follow() {
      const data = {};

      if (vm.item.item_type === "ORGANISATION") {
        data["to_organisation"] = vm.item.id;
      } else if (vm.item.user.organisation.id) {
        data["to_organisation"] = vm.item.user.organisation.id;
      } else {
        data["to_user"] = vm.item.user.public_uid;
      }

      if (isFollowing()) {
        $log.debug("About to unfollow", data);
        Following.un_follow(data, function () {
          $rootScope.$broadcast(DATA_EVENTS.followingChanged);
          if (data["to_organisation"]) {
            vm.user.following.organisations.splice(vm.user.following.organisations.indexOf(data["to_organisation"]), 1);
          } else {
            vm.user.following.users.splice(vm.user.following.users.indexOf(data["to_user"]), 1);
          }
        });
      } else {
        $log.debug("About to follow", data);
        Following.follow(data, function () {
          $rootScope.$broadcast(DATA_EVENTS.followingChanged);
          if (data["to_organisation"]) {
            vm.user.following.organisations.push(data["to_organisation"]);
          } else {
            vm.user.following.users.push(data["to_user"]);
          }
        });
      }
    }

    function archive() {
      if (vm.item.item_type === "ITEM") {
        Item.archive({ id: vm.item.id, archive: true }, function () {
          vm.item.archived = !vm.item.archived;
          toastr.success("Item " + (vm.item.archived ? "archived" : "published"));
        });
      } else if (vm.item.item_type === "EVENT") {
        Event.archive({ id: vm.item.id, archive: true }, function () {
          vm.item.archived = !vm.item.archived;
          toastr.success("Event " + (vm.item.archived ? "archived" : "published"));
        });
      }
    }

    function activateComment() {
      const comments = $document[0].querySelector("#comment");
      if (comments) {
        comments.focus();
      }
    }

    function setCanEditPermission() {
      if (angular.isDefined(vm.user)) {
        $log.debug("User is defined");
        if (vm.user.id === vm.item.user.id) {
          $log.debug("User is item owner and can edit");
          vm.canEdit = true;
        } else {
          let change_any_permission;
          if (vm.item.item_type === "ITEM") {
            change_any_permission = "change_any_item";
          } else if (vm.item.item_type === "EVENT") {
            change_any_permission = "change_any_event";
          } else {
            vm.canEdit = false;
            return;
          }

          PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: [change_any_permission] }))
            .then(function () {
              $log.debug("User has '" + change_any_permission + "' permission and can edit");
              vm.canEdit = true;
            })
            .catch(function () {
              $log.debug("User is not item owner and can not edit");
              vm.canEdit = false;
            });
        }
      } else {
        $log.debug("User not defined no editing");
        vm.canEdit = false;
      }
    }

    function showMore() {
      if (!AppConfig.showMoreUserOnly || AuthService.isAuthenticated()) {
        vm.showDescription = true;
        vm.showExpandDescription = false;
      } else {
        $state.go("login", { showLogin: false });
      }
    }

    function setViewsPermission() {
      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["ADMIN"] }))
        .then(function () {
          $log.debug("User is admin and can see views");
          vm.viewsEnabled = true;
          vm.sharesEnabled = true;
        })
        .catch(function () {
          $log.debug("User is not admin and can't see views");
          vm.viewsEnabled = false;
          vm.sharesEnabled = false;
        });
    }

    function shareEvent(shareTo: string): void {
      Analytics.share(vm.item.url_id, shareTo);
    }

    function createJsonLd(item, url: string): object {
      if (item.item_type !== "ITEM") {
        return;
      }
      const jsonLd = {
        "@context": "http://schema.org",
        "@type": "Thing",
        name: item.name,
        description: "",
        image: [],
        extraData: [],
      };

      if (item.hasOwnProperty("formatted_descriptions") && item.formatted_descriptions) {
        for (const desc of item.formatted_descriptions) {
          if (desc.auction_details) {
            jsonLd.description += desc.auction_details + "\n";
          }
          jsonLd.description += desc.description + "\n";
        }
      }

      function getImage(imageUrl: string, mediaType: string): string {
        if (mediaType.startsWith("youtube")) {
          const { id } = ItemForm.parseVideo(imageUrl);
          return "http://www.youtube.com/embed/" + id + "?rel=0&wmode=transparent";
        } else {
          return Thumbnail.getThumbnailURI(imageUrl, "-1500x1500", ".jpg");
        }
      }

      if (item.hasOwnProperty("image_url") && item.image_url) {
        console.log(item);
        if (!item.image_type.startsWith("vimeo")) {
          jsonLd.image.push(getImage(item.image_url, item.image_type));
        } else {
          VimeoAPI.oEmbed(item.image_url).then(
            function (response) {
              jsonLd.image.push(response.data.thumbnail_url);
            },
            function (error) {
              if (error.status !== 404) {
                $log.debug(error);
              }
            }
          );
        }
      }

      if (item.hasOwnProperty("images") && item.images) {
        for (const image of item.images) {
          const imageUrl = getImage(image.url, image.media_type);
          if (!_.includes(jsonLd.image, imageUrl)) {
            jsonLd.image.push(imageUrl);
          }
        }
      }

      if (item.hasOwnProperty("products") && item.products && item.products.length > 0) {
        jsonLd["@type"] = "Product";
        jsonLd["sku"] = item.sku;
        jsonLd["offers"] = {
          "@type": "Offer",
          url: url,
          priceCurrency: item.currency,
          price: item.price,
          availability: "https://schema.org/InStock",
          seller: {
            "@type": "Organization",
            name: item.user.organisation.name,
          },
        };
      }

      if (item.hasOwnProperty("extra_data") && item.extra_data) {
        for (const extra of item.extra_data) {
          if (extra.name === "ISBN") {
            jsonLd["@type"] = "Book";
            jsonLd["gtin13"] = extra.value;
            jsonLd["productID"] = "isbn:" + extra.value;
            jsonLd["isbn"] = extra.value;
          } else if (extra.name === "Page Extent") {
            jsonLd["numberOfPages"] = parseInt(extra.value);
          } else if (extra.name === "Format") {
            jsonLd["bookFormat"] = extra.value;
          } else if (extra.name === "Author") {
            jsonLd["author"] = extra.value;
          } else if (extra.name === "Make") {
            jsonLd["brand"] = extra.value;
          } else {
            jsonLd.extraData.push({
              "@type": "PropertyValue",
              name: extra.name,
              value: extra.value,
            });
          }
        }
      }

      return jsonLd;
    }

    function addToWatchList() {
      if (!vm.user) {
        $state.go("login", { showLogin: false });
        return;
      }

      $log.debug("Adding to watchlist: ", vm.item.id);
  
      vm.watchlist.items.push(vm.item.id);
      $log.debug(this.watchlist);
  
      UserSearchService.update(vm.watchlist)
        .then(
          function () {
            vm.inWatchlist = true;
          }
        )
        .catch(
          function (error) {
            $log.debug(error);
            toastr.error("Error adding to watchlist.");
          }.bind(this)
        );
    }
  
    function removeFromWatchList() {
      const itemIndex = vm.watchlist.items.indexOf(parseInt(vm.item.id, 10));
      if (itemIndex !== -1) {
        $log.debug("Removing from watchlist: ", vm.item.id);
        vm.watchlist.items.splice(itemIndex, 1);
  
        $log.debug(vm.watchlist);
  
        UserSearchService.update(vm.watchlist)
          .then(
            function () {
              vm.inWatchlist = false;
            }
          )
          .catch(
            function (error) {
              $log.debug(error);
              toastr.error("Error removing from watchlist.");
            }
          );
      }
    }
  }
})();
