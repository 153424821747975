import { IHttpService, IQService } from "angular";

class CountryName {
  basePath: string;

  static $inject = ["API_URL", "$http", "$q"];

  constructor(public API_URL: string, public $http: IHttpService, public $q: IQService) {
    this.basePath = API_URL + "/api/country/search/";
  }

  request(suffix: string, params: { [key: string]: any }) {
    const deferred = this.$q.defer();
    this.$http.get(this.basePath + suffix + "/", { params: params }).then(
      function(response) {
        deferred.resolve(response.data);
      },
      function() {
        deferred.resolve([]);
      }
    );
    return deferred.promise;
  }

  all(params: { [key: string]: any }) {
    return this.request("all", params);
  }

  present_day(params: { [key: string]: any }) {
    return this.request("presentday", params);
  }
}

angular.module("marketPlace.home").service("CountryName", CountryName);
