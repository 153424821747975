(function() {
  "use strict";
  angular.module("marketPlace.home").controller("AdvancedSearchController", advancedSearchController);

  advancedSearchController.$inject = [
    "$log",
    "$scope",
    "$state",
    "$filter",
    "$timeout",
    "Niches",
    "_",
    "moment",
    "CountryName",
    "EmailSchedules",
    "schedules",
    "DATA_EVENTS",
    "displayTypes",
    "user",
    "niches",
    "Categories",
    "UserSearchService",
    "toastr",
    "AUTH_EVENTS",
    "Features",
    "AppConfig"
  ];

  function advancedSearchController(
    $log,
    $scope,
    $state,
    $filter,
    $timeout,
    Niches,
    _,
    moment,
    CountryName,
    EmailSchedules,
    schedules,
    DATA_EVENTS,
    displayTypes,
    user,
    niches,
    Categories,
    UserSearchService,
    toastr,
    AUTH_EVENTS,
    Features,
    AppConfig
  ) {
    const vm = this;
    vm.niches = niches;
    vm.savedSearches = [];
    vm.savedSearchIndex = null;
    vm.showSavedSearches = true;
    vm.schedules = [];
    vm.showSchedules = false;
    vm.user = user;
    vm.section_options = [];
    vm.gallery_options = [];
    vm.caption_options = [];
    vm.category_options = [];
    vm.display_type_options = displayTypes;
    vm.dateFrom = null;
    vm.dateTo = null;
    vm.itemType = null;
    vm.toggleDirection = AppConfig.advancedSearchToggle ? "up" : "down";
    vm.form = {};

    vm.setTypeFilter = setTypeFilter;

    vm.editingSavedSearches = [];

    vm.search = initializeSearch();

    vm.typeFilters = Features.typeFilters;

    function setTypeFilter(active, filterParams) {
      const search = {};
      for (const param in vm.search) {
        search[param] = vm.search[param];
      }
      for (const filter in vm.typeFilters) {
        for (const param in vm.typeFilters[filter].params) {
          search[param] = null;
        }
      }

      for (const filterParam in filterParams) {
        if (!active) {
          search[filterParam] = filterParams[filterParam];
        }
      }

      vm.search = search;
    }

    function initializeSearch() {
      return {
        niche: "",
        section: "",
        min_date: null,
        max_date: null,
        min_price: null,
        max_price: null,
        country: null,
        uid: null,
        q: null,
        country_code: null,
        city: null,
        category: [],
        sort: null,
        display_type: null,
        for_sale: null,
        sold: null,
        show_and_tell: null,
        prompt: true
      };
    }

    vm.dateOptions = {
      formatYear: "yy",
      startingDay: 1
    };

    vm.searchControlsState = {
      dateFromOpen: false,
      dateToOpen: false
    };

    vm.filteredCategories = [];

    vm.selectNiche = selectNiche;
    vm.selectSection = selectSection;
    vm.selectGallery = selectGallery;
    vm.performSearch = performSearch;
    vm.performSavedSearch = search;
    vm.getPresentDayCountry = getPresentDayCountry;
    vm.getCountry = getCountry;
    vm.getCategories = getCategories;
    vm.resetSearch = resetSearch;
    // vm.toggleSavedSearches = toggleSavedSearches;
    vm.saveCurrentSearch = saveCurrentSearch;
    vm.deleteSavedSearch = deleteSavedSearch;
    vm.openEditSavedSearch = openEditSavedSearch;
    vm.toggleEditSavedSearch = toggleEditSavedSearch;
    vm.isEditingSavedSearch = isEditingSavedSearch;
    vm.updateSearch = updateSearch;
    vm.toggleDefault = toggleDefault;
    vm.toggle = toggle; // hide / show on desktop
    vm.toggleSavedSearches = toggleSavedSearches; // hide / show on desktop
    vm.toggleSavedSearchesDirection = "down";

    /** Setup watchers */
    $scope.$watch("vm.dateFrom", function(newVal, oldVal) {
      if (newVal !== oldVal) {
        vm.search.min_date = $filter("date")(newVal, "yyyy-MM-dd");
      }
    });

    $scope.$watch("vm.dateTo", function(newVal, oldVal) {
      if (newVal !== oldVal) {
        vm.search.max_date = $filter("date")(newVal, "yyyy-MM-dd");
      }
    });

    $scope.$on(DATA_EVENTS.galleryParamsChange, function(event, toParams) {
      // ensure advanced search reflects home search
      if (vm.niches) {
        $log.debug("AdvancedSearchController state change", toParams);
        initDropdowns(toParams);
      }
    });

    $scope.$on(AUTH_EVENTS.loginSuccess, function() {
      $log.debug("AdvancedSearch LOGIN SUCCESS");
      loadNiches();
      loadSchedules();
      UserSearchService.clear(); // will trigger loadSearches()
    });

    $scope.$on(DATA_EVENTS.followingChanged, function() {
      $log.debug("AdvancedSearch FOLLOWING CHANGED");
      UserSearchService.clear(); // will trigger loadSearches()
    });

    $scope.$on(DATA_EVENTS.userSearchesChanged, function() {
      $log.debug("AdvancedSearch USER SEARCHES CHANGED");
      loadSearches();
    });

    activate();

    function activate() {
      $log.debug("AdvancedSearchController", vm.niches, schedules);

      if (
        angular.isDefined(schedules) &&
        schedules !== null &&
        schedules.hasOwnProperty("results") &&
        angular.isDefined(schedules.results) &&
        schedules.results !== null
      ) {
        vm.schedules = schedules.results;
        if (
          schedules.results.length > 0 &&
          angular.isDefined(schedules.results[0]) &&
          schedules.results[0] !== null &&
          schedules.results[0].hasOwnProperty("id")
        ) {
          vm.search.schedule = schedules.results[0].id;
        }
      }

      if (!vm.niches || vm.niches._order.length === 0) {
        loadNiches();
      } else {
        initNiches(vm.niches);
      }

      if (vm.user) {
        $log.debug("AdvancedSearch about to load searches from activate");
        loadSearches();
      }
    }

    function toggle() {
      vm.toggleDirection = vm.toggleDirection === "down" ? "up" : "down";
    }

    function toggleSavedSearches() {
      $log.debug("toggleSavedSearches", vm.toggleSavedSearchesDirection);
      vm.toggleSavedSearchesDirection = vm.toggleSavedSearchesDirection === "down" ? "up" : "down";
      if (vm.toggleSavedSearchesDirection === "down") {
        loadSearches();
      }
    }

    function loadNiches() {
      const num = Math.random();
      $log.debug("AdvancedSearch loading niches", num);
      Niches.read.query(function(results) {
        $log.debug("AdvancedSearch, Got niches", num, results);
        initNiches(results);
      });
    }

    function setSavedSearches(searches) {
      vm.savedSearches = searches;
      vm.savedSearchIndex = {};
      for (let i = 0; i < searches.length; i++) {
        vm.savedSearchIndex[searches[i].id] = i;
      }
    }

    function loadSearches() {
      $log.debug("AdvancedSearch loading searches");
      UserSearchService.get()
        .then(function(results) {
          const latest = angular.copy(results); // enable reload without re-get
          if (vm.savedSearchIndex !== null) {
            for (let i = 0; i < latest.length; i++) {
              if (!vm.savedSearchIndex.hasOwnProperty(latest[i].id)) {
                latest[i].isNew = true;
              }
            }
          }
          setSavedSearches(latest);

          $timeout(function() {
            for (let i = 0; i < vm.savedSearches.length; i++) {
              vm.savedSearches[i].isNew = false;
            }
          }, 2000);
          $log.debug(vm.savedSearches);
        })
        .catch(function() {
          vm.showSavedSearches = false;
        });
    }

    function loadSchedules() {
      $log.debug("AdvancedSearch loading schedules");
      EmailSchedules.get(
        function(schedules) {
          if (
            angular.isDefined(schedules) &&
            schedules !== null &&
            schedules.hasOwnProperty("results") &&
            angular.isDefined(schedules.results) &&
            schedules.results !== null
          ) {
            vm.schedules = schedules.results;
            if (
              schedules.results.length > 0 &&
              angular.isDefined(schedules.results[0]) &&
              schedules.results[0] !== null &&
              schedules.results[0].hasOwnProperty("id")
            ) {
              vm.search.schedule = schedules.results[0].id;
            }
          }
          $log.debug("AdvancedSearch got", vm.schedules);
        },
        function() {
          vm.showSavedSearches = false;
        }
      );
    }

    function getFirstNiche() {
      return vm.niches._order[0];
    }

    function initDropdowns(stateParams) {
      vm.search.niche = stateParams["niche"] || vm.search.niche || getFirstNiche();
      vm.search.section = stateParams["section"] || null;
      vm.search.gallery = stateParams["gallery"] || null;
      vm.search.caption = stateParams["caption"] || null;
      vm.search.display_type = stateParams["display_type"];
      vm.search.q = stateParams["q"] || null;
      vm.search.category = stateParams["category"] || null;
      vm.search.sort = stateParams["sort"] || null;
      buildNiches();
      selectNiche();
      vm.category_options = [];
      if (vm.search.category) {
        if (Array.isArray(vm.search.category)) {
          for (let i = 0; i < vm.search.category.length; i++) {
            vm.category_options.push({ name: vm.search.category[i] });
          }
        } else {
          vm.category_options.push({ name: vm.search.category });
        }
      }
    }

    function initNiches(niches) {
      $log.debug("AdvancedSearchController.initNiches", niches);
      if (niches._order.length === 0) {
        vm.niches = null;
      } else {
        vm.niches = niches;
        initDropdowns($state.params);
        vm.dateFrom = $state.params.min_date ? moment($state.params.min_date, "YYYY-MM-DD").toDate() : null;
        vm.dateTo = $state.params.max_date ? moment($state.params.max_date, "YYYY-MM-DD").toDate() : null;
        vm.search.min_price = isNaN($state.params.min_price) ? null : parseFloat($state.params.min_price);
        vm.search.max_price = isNaN($state.params.max_price) ? null : parseFloat($state.params.max_price);
        vm.search.country = $state.params.country || null;
        vm.search.uid = $state.params.uid || null;
        vm.search.q = $state.params.q || null;
        vm.search.city = $state.params.city || null;
        vm.search.country_code = $state.params.country_code || null;
        vm.search.sort = $state.params.sort || null;
        vm.search.for_sale = $state.params.for_sale || null;
        vm.search.sold = $state.params.sold || null;
        vm.search.show_and_tell = $state.params.show_and_tell || null;
      }
    }

    function buildNiches() {
      vm.niche_options = [];
      _.map(vm.niches._order, function(item) {
        if (vm.niches.hasOwnProperty(item)) {
          const niche = vm.niches[item];
          vm.niche_options.push({ name: niche.name, slug: item });
        }
      });
    }

    function buildSections(niche) {
      vm.section_options = [];
      if (niche && vm.niches.hasOwnProperty(niche)) {
        const sections = vm.niches[niche].sections;
        _.map(sections._order, function(item) {
          const section = sections[item];
          vm.section_options.push({ name: section.name, slug: item });
        });
      }
    }

    function buildGalleries(niche, section) {
      vm.gallery_options = [];
      if (niche && vm.niches.hasOwnProperty(niche) && section && vm.niches[niche].sections.hasOwnProperty(section)) {
        const galleries = vm.niches[niche].sections[section].galleries;
        _.map(galleries._order, function(item) {
          const gallery = galleries[item];
          vm.gallery_options.push({ name: gallery.name, slug: item });
        });
      }
    }

    function buildCaptions(niche, section, gallery) {
      vm.caption_options = [];
      if (
        niche &&
        vm.niches.hasOwnProperty(niche) &&
        section &&
        vm.niches[niche].sections.hasOwnProperty(section) &&
        gallery &&
        vm.niches[niche].sections[section].galleries.hasOwnProperty(gallery)
      ) {
        const captions = vm.niches[niche].sections[section].galleries[gallery].captions;
        _.map(captions._order, function(item) {
          const caption = captions[item];
          vm.caption_options.push({ name: caption.name, slug: item });
        });
      }
    }

    function selectNiche() {
      buildSections(vm.search.niche);
      selectSection();
    }

    function selectSection() {
      buildGalleries(vm.search.niche, vm.search.section);
      selectGallery();
      if (
        vm.search.niche &&
        vm.niches.hasOwnProperty(vm.search.niche) &&
        vm.search.section &&
        vm.niches[vm.search.niche].sections.hasOwnProperty(vm.search.section)
      ) {
        vm.itemType = vm.niches[vm.search.niche].sections[vm.search.section].item_type;
      } else {
        vm.itemType = null;
      }
      $log.debug("SelectSection", vm.itemType);
    }

    function selectGallery() {
      buildCaptions(vm.search.niche, vm.search.section, vm.search.gallery);
    }

    function getPresentDayCountry(val) {
      /* selects from Present Day Countries */
      return CountryName.present_day({ q: val }).$promise;
    }

    function getCountry(val) {
      /* selects from all Countries */
      return CountryName.all({ q: val });
    }

    function getCategories($select) {
      const val = $select.search;
      if (!val || val.length < 3) {
        vm.filteredCategories = [];
        return;
      }
      const params = { q: val };
      if (vm.search.section) {
        params["section"] = vm.search.section;
      }
      if (vm.search.gallery) {
        params["gallery"] = vm.search.gallery;
      }
      if (vm.search.caption) {
        params["caption"] = vm.search.caption;
      }
      return Categories.query(params).$promise.then(function(categories) {
        vm.filteredCategories = categories;
      });
    }

    function performSearch() {
      search(vm.search);
    }

    function search(params) {
      $log.debug("search: ", params);

      if (vm.category_options) {
        vm.search.category = [];
        for (let i = 0; i < vm.category_options.length; i++) {
          vm.search.category.push(vm.category_options[i].name);
        }
      }

      const stateParams = _.clone(params);
      if (
        !stateParams.hasOwnProperty("display_type") ||
        angular.isUndefined(stateParams.display_type) ||
        stateParams.display_type === "all"
      ) {
        stateParams.display_type = null;
      }
      stateParams.prompt = true;
      $log.debug("STATE PARAMS", stateParams, params);
      $state.go("home.gallery", stateParams, { inherit: false, reload: true });
    }

    function resetSearch() {
      vm.search = initializeSearch();
      vm.search.niche = getFirstNiche();
      vm.dateFrom = null;
      vm.dateTo = null;
      vm.category_options = [];
      selectNiche();
      search(vm.search);
    }

    function saveCurrentSearch() {
      $log.debug("Saving:", vm.search);
      UserSearchService.create(vm.savedSearchName, vm.search)
        .then(function() {
          toastr.success("Search saved");
          vm.savedSearchName = "";
        })
        .catch(function() {
          toastr.error("Failed to save search.");
        });
    }

    function deleteSavedSearch(savedSearch) {
      // optimistically remove
      const id = savedSearch.id;
      let index = -1;
      for (let i = 0; i < vm.savedSearches.length; i++) {
        if (vm.savedSearches[i].id === id) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        vm.savedSearches.splice(index, 1);
      }
      UserSearchService.remove(savedSearch)
        .then(function() {
          let idx = null;
          if (savedSearch.organisation) {
            idx = vm.user.following.organisations.indexOf(savedSearch.organisation);
            if (idx >= 0) {
              vm.user.following.organisations.splice(idx, 1);
            }
          }
          if (savedSearch.uid) {
            idx = vm.user.following.users.indexOf(savedSearch.uid);
            if (idx >= 0) {
              vm.user.following.users.splice(idx, 1);
            }
          }
          toastr.success("Search deleted.");
        })
        .catch(function() {
          loadSearches();
          toastr.error("Could not remove search at this time.");
        });
    }

    function isEditingSavedSearch(savedSearch) {
      return vm.editingSavedSearches.indexOf(savedSearch.id) !== -1;
    }

    function openEditSavedSearch(savedSearch) {
      if (!isEditingSavedSearch(savedSearch)) {
        vm.editingSavedSearches.push(savedSearch.id);
      }
    }

    function toggleEditSavedSearch(savedSearch) {
      $log.debug("EDITING", savedSearch);
      const idx = vm.editingSavedSearches.indexOf(savedSearch.id);
      if (idx !== -1) {
        vm.editingSavedSearches.splice(idx, 1);
      } else {
        vm.editingSavedSearches.push(savedSearch.id);
      }
    }

    function updateSearch(savedSearch, clear) {
      $log.debug("UPDATING: ", savedSearch);

      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }

      UserSearchService.update(savedSearch)
        .then(function() {
          if (clear) {
            UserSearchService.clear();
          }
          toastr.success("Search updated");
        })
        .catch(function() {
          toastr.error("Failed to save search.");
        });
    }

    function toggleDefault(savedSearch) {
      savedSearch.is_default = !savedSearch.is_default;
      updateSearch(savedSearch, true);
    }
  }
})();
