import { ILogService } from "angular";
import { IModalService, IModalInstanceService } from "angular-ui-bootstrap";

class OrderController {
  static $inject = ["$log", "$uibModalInstance", "Thumbnail", "order"];
  constructor(
    public $log: ILogService,
    public $uibModalInstance: IModalInstanceService,
    public Thubmnail: any,
    public order: { [key: string]: any }
  ) {
    this.$log.debug(order);
  }

  getLineImg(line: { [key: string]: any }) {
    if (line.product.image) {
      return this.Thubmnail.getThumbnailURI(line.product.image, "-116x116");
    }
  }

  getTaxAmount() {
    const totalIncl = parseFloat(this.order.total_incl_tax);
    const totalExcl = parseFloat(this.order.total_excl_tax);
    return totalExcl - totalIncl;
  }

  cancel() {
    this.$uibModalInstance.dismiss("closed");
  }
}

angular.module("marketPlace.dashboard").controller("OrderController", OrderController);

class OrderListController {
  year: number;
  month: number;
  orders: { [key: string]: any }[];
  ordersLoading = true;
  hasError = false;

  static $inject = ["$log", "$uibModal", "DashboardAPI"];

  constructor(public $log: ILogService, public $uibModal: IModalService, public DashboardAPI: any) {
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1; // months are 0 based
    this.loadOrders();
  }

  loadOrders() {
    this.ordersLoading = false;
    this.hasError = false;

    this.DashboardAPI.getOrders().then(this.ordersLoaded.bind(this), this.ordersError.bind(this));
  }

  ordersLoaded(response: any) {
    this.$log.debug("GOT ORDERS", response);
    this.orders = response.data;
    this.ordersLoading = false;
  }

  ordersError(response: any) {
    this.$log.debug("ERROR loading orders", response);
    this.ordersLoading = false;
    this.hasError = true;
  }

  showYear(date: string) {
    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(5, 7));
    return year !== this.year && month < this.month;
  }

  openOrder(order: { [key: string]: any }) {
    this.$uibModal.open({
      animation: true,
      template: require("./templates/order.html"),
      controller: "OrderController",
      controllerAs: "vm",
      size: "md",
      resolve: { order: order }
    });
  }
}

angular.module("marketPlace.dashboard").component("ordersComponent", {
  template: require("./templates/orders.html"),
  controller: OrderListController,
  controllerAs: "vm"
});
