/**
 * Infinite scroll directive
 *
 * @usage
 * <div infinite-scroll infinite-scroll-load="vm.loadMore()" infinite-scroll-disabled="vm.loadingMore" infinite-scroll-destroy="vm.disableScroll">
 */

(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("infiniteScroll", infiniteScroll);

  infiniteScroll.$inject = ["$window", "$log", "_"];

  function infiniteScroll($window, $log, _) {
    return {
      restrict: "A",
      scope: {
        load: "&infiniteScrollLoad",
        disabled: "<infiniteScrollDisabled",
        destroy: "<infiniteScrollDestroy"
      },
      link: function($scope, element) {
        const raw = element[0],
          windowEl = angular.element($window);

        function unBind() {
          windowEl.unbind("scroll", listener);
          windowEl.unbind("resize", listener);
        }

        const listener = _.throttle(function() {
          if ($scope.disabled) {
            return;
          }

          if ($scope.destroy) {
            return unBind();
          }

          const rect = raw.getBoundingClientRect();
          if (rect.top < 0 && rect.bottom - $window.innerHeight < 500) {
            // $log.debug($window.innerHeight, rect.top, rect.bottom, $window.innerHeight - rect.bottom);
            // $log.debug("Trigger infiniteScroll...");
            $scope.load();
          }
        }, 250);

        windowEl.bind("scroll", listener);
        windowEl.bind("resize", listener);

        $scope.$on("$destroy", function() {
          unBind();
        });
      }
    };
  }
})();
