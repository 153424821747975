(function() {
  "use strict";
  angular.module("marketPlace.auth").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider.state("activate", {
      url: "/auth/activate/:uid/:token",
      template: "<div ui-view></div>",
      onEnter: checkActivation,
      data: {
        isAuthState: true,
        requiresLogin: false
      }
    });
  }

  checkActivation.$inject = ["$transition$", "AuthService", "$state", "toastr"];
  function checkActivation($transition$, AuthService, $state, toastr) {
    const stateParams = $transition$.params(),
      activationData = {
        uid: stateParams.uid,
        token: stateParams.token
      };

    AuthService.activateUser(activationData)
      .then(
        function() {
          toastr.success("Activation successful!");
        },
        function() {
          toastr.error("Activation unsuccessful!");
        }
      )
      .finally(function() {
        $state.go("home.gallery");
      });
  }
})();
