(function() {
  "use strict";

  angular.module("marketPlace.payments").factory("PaymentMethod", paymentMethodService);

  paymentMethodService.$inject = ["$resource", "API_URL"];

  function paymentMethodService($resource, API_URL) {
    const r = $resource(API_URL + "/api/payment-methods/", null, {
      query: { method: "GET", isArray: false }
    });
    // Remove extra actions
    delete r.prototype.$get;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
