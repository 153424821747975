import { cookies, ICacheObject, IHttpService, IQService, ILogService } from "angular";
import ICacheFactory = angular.cache.ICacheFactory;
import ICookiesService = cookies.ICookiesService;

class Settings {
  cacheName: string;
  SETTINGS_URL: string;
  settingsCache: ICacheObject;

  static $inject = ["API_URL", "$http", "$cookies", "$q", "$log", "CacheFactory"];

  constructor(
    public API_URL: string,
    public $http: IHttpService,
    public $cookies: ICookiesService,
    public $q: IQService,
    public $log: ILogService,
    CacheFactory: ICacheFactory
  ) {
    this.cacheName = "settingsCache";
    this.SETTINGS_URL = API_URL + "/api/settings/";

    const $this = this;

    if (!CacheFactory.get(this.cacheName)) {
      CacheFactory.createCache(this.cacheName, {
        capacity: 1,
        maxAge: 15 * 60 * 1000, // Items added to this cache expire after 15 minutes.
        cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour.
        recycleFreq: 5 * 60 * 1000, // 5 minutes
        deleteOnExpire: "aggressive",
        storageMode: "localStorage",
        storeOnResolve: true,
        onExpire: function(key) {
          $this.settingsCache.put(key, $this.$http.get(key));
        }
      });
    }

    this.settingsCache = CacheFactory.get(this.cacheName);
  }

  onExpire(key: string) {
    this.settingsCache.put(key, this.$http.get(key));
  }

  mergeCookies(settings: { [key: string]: any }) {
    return angular.merge({}, settings, this.$cookies.getObject("settings"));
  }

  query() {
    const $log = this.$log;
    $log.debug("Gettings SETTINGS");
    const $cookies = this.$cookies;
    const mergeCookies = function(settings) {
      return angular.merge({}, settings, $cookies.getObject("settings"));
    };
    $log.debug("Gettings SETTINGS");
    const deferred = this.$q.defer();

    this.$http
      .get(this.SETTINGS_URL, {
        cache: this.settingsCache
      })
      .then(
        function(response) {
          $log.debug("Got Settings", response);
          const settings = mergeCookies(response.data);
          $log.debug("SETTINGS ARE", settings);
          deferred.resolve(settings);
        },
        function() {
          // don't care about errors so resolve here instead of reject
          // returning the default settings merged with settings in cookies
          deferred.resolve(
            mergeCookies({
              // Add default settings here
            })
          );
        }
      );

    return deferred.promise;
  }
}

angular.module("marketPlace.home").service("Settings", Settings);
