(function() {
  "use strict";
  angular.module("marketPlace.searches").factory("Searches", searches);

  searches.$inject = ["$http", "$resource", "API_URL"];

  function searches($http, $resource, API_URL: string) {
    const convertToBooleans = function(obj) {
      obj.for_sale = !!+obj.for_sale;
      obj.sold = !!+obj.sold;
      obj.show_and_tell = !!+obj.show_and_tell;
      return obj;
    };

    const convertResultsFromBooleans = function(obj) {
      for (let i = 0; i < obj.results.length; i++) {
        obj.results[i].for_sale = obj.results[i].for_sale ? "1" : null;
        obj.results[i].sold = obj.results[i].sold ? "1" : null;
        obj.results[i].show_and_tell = obj.results[i].show_and_tell ? "1" : null;
      }
      return obj;
    };

    return $resource(
      API_URL + "/api/searches/:id/",
      { id: "@id" },
      {
        get: {
          method: "GET",
          transformResponse: $http.defaults.transformResponse.concat([convertResultsFromBooleans])
        },
        query: {
          method: "GET",
          transformResponse: $http.defaults.transformResponse.concat([convertResultsFromBooleans])
        },
        update: {
          method: "PUT",
          transformRequest: [convertToBooleans].concat($http.defaults.transformRequest)
        },
        save: {
          method: "POST",
          transformRequest: [convertToBooleans].concat($http.defaults.transformRequest)
        }
      }
    );
  }
})();
