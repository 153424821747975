import { ILogService, IScope, cookies } from "angular";
import ICookiesService = cookies.ICookiesService;

class LoginController {
  user: any = {};
  rememberMe = false;
  form: any = {};
  errors: any = {};
  saving = false;
  socialLoginInProgress = false;

  static $inject = ["$log", "$scope", "$cookies", "AuthService"];

  constructor(
    public $log: ILogService,
    public $scope: IScope,
    public $cookies: ICookiesService,
    public AuthService: any,
    public onLogin: Function,
    public hideEmail: boolean,
    public hideSocial: boolean
  ) {
    this.$log.debug("LOGIN CONTROLLER");
  }

  $onInit(): void {
    this.user.email = this.$cookies.get("email");
  }

  socialSuccess(user) {
    this.$log.debug("Successful social login", user);
    this.socialLoginInProgress = false;
    this.onLogin({ user: user });
    //this.triggerOnLogin(user);
    //this.AuthService.getCurrentUser().then(this.triggerOnLogin.bind(this));
  }

  socialError() {
    this.socialLoginInProgress = false;
  }

  loginSuccess(user) {
    this.$log.debug("Successful login component login", user, this.onLogin);
    this.saving = false;
    this.onLogin({ user: user });
    // this.AuthService.getCurrentUser().then(this.triggerOnLogin.bind(this));
  }

  triggerOnLogin(user) {
    this.$log.debug();
    this.onLogin({ user: user });
  }

  loginError(error?: { [key: string]: any }) {
    if (error) {
      angular.forEach(
        error,
        function(errors, field) {
          this.$log.debug(field + " : " + errors);
          this.errors.non_field_errors = errors.join(", ");
        }.bind(this)
      );
      this.$scope.$broadcast("show-errors-check-validity");
    }
    this.saving = false;
  }

  login() {
    this.$scope.$broadcast("show-errors-check-validity");

    if (this.form.$invalid) {
      return;
    }

    this.saving = true;
    this.$log.debug("login function called");

    this.AuthService.login(this.user, this.rememberMe)
      .then(this.loginSuccess.bind(this))
      .catch(this.loginError.bind(this));
  }

  clearNonFieldErrors() {
    this.errors.non_field_errors = "";
  }

  forgotPassword() {}

  authenticate(provider: string) {
    this.socialLoginInProgress = true;
    this.$log.debug(provider, "authenticate function called");

    this.AuthService.socialLogin(provider)
      .then(this.socialSuccess.bind(this))
      .catch(this.socialError.bind(this));
  }
}

angular.module("marketPlace.home").component("login", {
  bindings: {
    onLogin: "&",
    hideEmail: "<?",
    hideSocial: "<?"
  },
  template: require("./templates/login.html"),
  controller: LoginController,
  controllerAs: "vm"
});
