(function() {
  "use strict";

  const businessGroupChoice = {
    template: require("./templates/business-group-choice.html"),
    bindings: {
      chooseGroup: "&",
      dismiss: "&"
    },
    controller: businessGroupChoiceCtrl
  };

  angular.module("marketPlace.payments").component("businessGroupChoice", businessGroupChoice);

  businessGroupChoiceCtrl.$inject = ["Groups"];
  function businessGroupChoiceCtrl(Groups) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
      const businessType = "B";
      Groups.query({ type: businessType, simple: true }).then(function (groups) {
        $ctrl.groups = groups;
      }, angular.noop);
    };

    $ctrl.cancel = function() {
      $ctrl.dismiss({ $value: "cancel" });
    };
  }
})();
