(function () {
  "use strict";
  angular.module("marketPlace.dashboard").controller("DashboardController", DashboardController);

  DashboardController.$inject = [
    "$state",
    "$log",
    "organisations",
    "PermAuthorization",
    "PermPermissionMap",
    "SITE_NAME",
    "Game",
    "Features",
    "Searches",
    "_",
  ];

  function DashboardController(
    $state,
    $log,
    organisations,
    PermAuthorization,
    PermPermissionMap,
    SITE_NAME,
    Game,
    Features,
    Searches,
    _
  ) {
    const vm = this;

    vm.siteName = SITE_NAME;
    vm.enableGames = Features.games;
    vm.enableAuctions = Features.auctions;
    vm.watchlist;

    vm.tabData = [
      {
        heading: "Your Profile",
        route: "home.dashboard.profile",
        class: "home-dashboard-profile",
      },
      {
        heading: "Orders",
        route: "home.dashboard.orders",
        class: "home-dashboard-orders",
      },
      {
        heading: "Notifications",
        route: "home.dashboard.notifications",
        class: "home-dashboard-notifications",
      },
      {
        heading: "Upgrade Membership",
        route: "home.dashboard.billing",
        class: "home-dashboard-billing",
      },
      {
        heading: "Saved Searches",
        route: "home.dashboard.email",
        class: "home-dashboard-email",
      },
    ];

    vm.orgTabData = [];
    vm.gameTabData = [
      {
        heading: "My results",
        route: "home.dashboard.my-results",
        class: "home-dashboard-myresults",
      },
      {
        heading: "Leaderboard",
        route: "home.dashboard.leaderboard",
        class: "home-dashboard-leaderboard",
      },
    ];
    vm.currentGameTabData = [];
    vm.auctionTabData = [
      {
        heading: "Lots",
        route: "home.dashboard.my-auction-lots",
        class: "home-dashboard-myauctionlots",
      },
    ];

    vm.goTo = goTo;
    vm.isActive = isActive;

    activate();

    function activate() {
      $log.debug("DashboardController", $state);

      const organisation = organisations.length > 0 ? organisations[0] : null;

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_item"] }))
        .then(function () {
          vm.tabData.push({
            heading: "Listings",
            route: "home.dashboard.listings",
            class: "home-dashboard-listings",
          });
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_event"] }))
        .then(function () {
          vm.tabData.push({
            heading: "Events",
            route: "home.dashboard.event-listings",
            class: "home-dashboard-event-listings",
          });
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_pledge", "change_pledge"] }))
        .then(function () {
          vm.tabData.push({
            heading: "Pledges Made",
            route: "home.dashboard.pledges-made",
            class: "home-dashboard-pledges-made",
          });
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_creditor", "change_creditor"] }))
        .then(function () {
          vm.tabData.push({
            heading: "Pledges Received",
            route: "home.dashboard.pledges-received",
            class: "home-dashboard-pledges-received",
          });
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_referral"] }))
        .then(function () {
          vm.tabData.push({
            heading: "Referrals",
            route: "home.dashboard.referrals",
            class: "home-dashboard-referrals",
          });
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_club"] }))
        .then(function () {
          // Splice clubs into the second position (index=1)
          vm.tabData.splice(1, 0, {
            heading: "Your Clubs",
            route: "home.dashboard.clubs",
            class: "home-dashboard-clubs",
          });
        })
        .catch(angular.noop);

      PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_organisation"] }))
        .then(function () {
          vm.orgTabData.push({
            heading: "Create / Edit Directory Listing",
            route: "home.dashboard.edit-organisation",
            class: "home-dashboard-edit-organisation",
          });

          if (organisation) {
            PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["add_association"] }))
              .then(function () {
                vm.orgTabData.push({
                  heading: "Associations",
                  route: "home.dashboard.associations",
                  class: "home-dashboard-associations",
                });
              })
              .catch(angular.noop);

            vm.orgTabData.push({
              heading: "Organisation Users",
              route: "home.dashboard.organisation-users",
              class: "home-dashboard-organisation-users",
              params: {
                organisationId: organisation.id,
              },
            });

            if (organisation.is_charity) {
              vm.orgTabData.push({
                heading: "Donations Received",
                route: "home.dashboard.organisation-donations",
                class: "home-dashboard-organisation-donations",
              });
            }
          }
        })
        .catch(angular.noop);

      if (vm.enableGames) {
        Game.list({ current: true }).then(function (response) {
          for (let i = 0; i < response.results.length; i++) {
            const currentGame = response.results[i];
            vm.currentGameTabData.push({
              heading: currentGame.name,
              route: "home.dashboard.game-results({id: " + currentGame.id + "})",
              class: "home-dashboard-gameresults",
            });
          }
        });
      }

      if (vm.enableAuctions) {
        Searches.query(
          { name: "watchlist" },
          function (resp) {
            vm.watchlist = _.pick(resp.results[0], ["items"]);
            $log.debug(vm.watchlist);
          },
          function (error) {
            $log.debug(error);
          }
        );
      }
    }

    function goTo(tab) {
      $state.go(tab.route, tab.params);
    }

    function isActive(route) {
      return $state.current.name === route;
    }
  }
})();
