(function() {
  "use strict";

  angular.module("marketPlace.watchdog").factory("Watchdog", Watchdog);

  Watchdog.$inject = ["$resource", "API_URL"];
  function Watchdog($resource, API_URL) {
    return $resource(
      API_URL + "/api/watchdog/:controller/",
      { controller: "@controller" },
      {
        create: {
          method: "POST"
        },
        reasons: {
          method: "GET",
          isArray: true,
          params: {
            controller: "reasons"
          }
        }
      }
    );
  }
})();
