(function() {
  "use strict";

  angular.module("marketPlace.address").factory("Address", addressService);

  addressService.$inject = ["_"];

  function addressService(_) {
    const postalAddressFields = [
      "postal_address_line1",
      "postal_address_line2",
      "postal_address_city",
      "postal_address_state",
      "postal_address_postcode",
      "postal_address_country"
    ];

    const physicalAddressFields = [
      "physical_address_line1",
      "physical_address_line2",
      "physical_address_city",
      "physical_address_state",
      "physical_address_postcode",
      "physical_address_country"
    ];

    /**
     * Creates an object from the address_components array of a google places detail object
     * @param {object} place - a Google Places Detail object
     * @returns {object} componentLookup - type as the key and an object with the short_name, long_name fields for the value
     */
    function _createLookup(place) {
      const componentLookup = {};
      _.each(place.address_components, function(component) {
        componentLookup[component.types[0]] = { short: component.short_name, long: component.long_name };
      });
      return componentLookup;
    }

    /**
     * Convert a Google Places Detail object to an address object
     * @param {object} place - a Google Places Detail object
     * @returns {object} address - an Address object
     */
    function convert(place) {
      const address = {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        postcode: ""
      };
      const componentLookup = _createLookup(place);

      if (componentLookup.hasOwnProperty("route")) {
        if (componentLookup.hasOwnProperty("street_number")) {
          address.line1 = componentLookup.street_number.short + " " + componentLookup.route.short;
        } else {
          address.line1 = componentLookup.route.short;
        }
      }

      if (componentLookup.hasOwnProperty("sublocality_level_1")) {
        address.line2 = componentLookup.sublocality_level_1.long;
      }

      if (componentLookup.hasOwnProperty("neighborhood")) {
        address.line2 = componentLookup.neighborhood.long;
      }

      if (componentLookup.hasOwnProperty("locality")) {
        address.city = componentLookup.locality.long;
      }

      if (componentLookup.hasOwnProperty("administrative_area_level_1")) {
        address.state = componentLookup.administrative_area_level_1.short;
      }

      if (componentLookup.hasOwnProperty("country")) {
        address.country = componentLookup.country.short;
      }

      if (componentLookup.hasOwnProperty("postal_code")) {
        address.postcode = componentLookup.postal_code.short;
      }

      return address;
    }

    function copyAddress(obj) {
      const fields = ["city", "country", "line1", "line2", "postcode", "state"];
      fields.forEach(function(field) {
        obj["postal_address_" + field] = obj["physical_address_" + field];
      });
    }

    function deletePostalAddress(obj) {
      const fields = ["city", "line1", "line2", "postcode", "state"];
      fields.forEach(function(field) {
        obj["postal_address_" + field] = "";
      });
      obj.postal_address_country = null;
    }

    function _hasAny(obj, fields) {
      for (let i = 0; i < fields.length; i++) {
        if (obj.hasOwnProperty(fields[i]) && obj[fields[i]]) {
          return true;
        }
      }
      return false;
    }

    function hasAddress(obj) {
      if (obj) {
        return _hasAny(obj, physicalAddressFields);
      }
      return false;
    }

    function hasPostalAddress(obj) {
      return _hasAny(obj, postalAddressFields);
    }

    return {
      convert: convert,
      copyAddress: copyAddress,
      deletePostalAddress: deletePostalAddress,
      hasAddress: hasAddress,
      hasPostalAddress: hasPostalAddress,
      _createLookup: _createLookup
    };
  }
})();
