(function() {
  "use strict";

  angular.module("marketPlace.common").factory("GalleryData", galleryData);

  galleryData.$inject = ["$rootScope", "$log"];
  function galleryData($rootScope, $log) {
    const data = {
      mode: 1,
      categories: [],
      categoriesOpen: true
    };

    function subscribe(scope, callback) {
      const handler = $rootScope.$on("gallery-data-changed", callback);
      scope.$on("$destroy", handler);
    }

    function notify(param) {
      $log.debug("GALLERY DATA Notifying about", param);
      $rootScope.$emit("gallery-data-changed", param);
    }

    return {
      get: function(param) {
        return data[param];
      },
      set: function(param, value) {
        $log.debug("GALLERY DATA setting", param, "to", value);
        data[param] = value;
        notify(param);
      },
      subscribe: subscribe,
      notify: notify
    };
  }
})();
