(function() {
  "use strict";

  angular.module("marketPlace.auth").factory("Registration", Registration);

  Registration.$inject = ["$resource", "API_URL"];

  function Registration($resource, API_URL) {
    return $resource(API_URL + "/api/auth/register/", null, {
      save: { method: "POST", skipAuthorization: true }
    });
  }
})();
