(function() {
  "use strict";

  angular.module("marketPlace.gallery").factory("Gallery", gallery);

  gallery.$inject = ["$resource", "API_URL"];
  function gallery($resource, API_URL) {
    return $resource(API_URL + "/api/gallery/:niche/:section", null, {
      query: {
        method: "GET",
        isArray: false
      }
    });
  }
})();
