import { ILogService } from "angular";

class DashboardNavController {
  readonly up = 0;
  readonly down = 1;
  toggleDirection: number;

  static $inject = ["$log"];

  constructor(public $log: ILogService) {
    this.$log.debug("DashboardNavController");
    this.toggleDirection = this.down;
  }

  toggle = () => {
    this.toggleDirection = this.toggleDirection === this.down ? this.up : this.down;
  };
}

angular.module("marketPlace.dashboard").component("dashboardNav", {
  transclude: true,
  bindings: {
    heading: "<",
    icon: "<"
  },
  template: require("./templates/nav.html"),
  controller: DashboardNavController
});
