import { IModalService } from "angular-ui-bootstrap";
import { ILogService } from "angular";
import { Transition } from "@uirouter/angularjs";
import { PreviousService } from "../previous.service";

(function() {
  "use strict";
  angular.module("marketPlace.auth").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider.state("register", {
      url: "^/sign-up?email&token&id",
      template: "<div ui-view></div>",
      data: {
        isAuthState: true,
        requiresLogin: false
      },
      resolve: {
        $transition$: "$transition$",
        isAlreadyLoggedIn: [
          "$q",
          "$state",
          "AuthService",
          function($q, $state, AuthService): Promise<boolean> {
            const deferred = $q.defer();
            if (AuthService.isAuthenticated()) {
              deferred.reject(false);
              $state.go("home.gallery");
            } else {
              deferred.resolve(true);
            }
            return deferred.promise;
          }
        ],
        groups: getGroups,
        settings: getSettings
      },
      onEnter: createRegistrationModal
    });

    $stateProvider.state("register.accept", {
      url: "/:token",
      data: { isAuthState: true }
    });

    $stateProvider.state("register.business", {
      url: "/business",
      data: { isAuthState: true }
    });
  }

  createRegistrationModal.$inject = [
    "$uibModal",
    "$log",
    "groups",
    "$transition$",
    "$state",
    "settings",
    "AuthService",
    "PreviousService"
  ];
  function createRegistrationModal(
    $uibModal: IModalService,
    $log: ILogService,
    groups,
    $transition$: Transition,
    $state,
    settings,
    AuthService,
    PreviousService: PreviousService
  ) {
    $log.debug("Creating registration modal", settings);

    const modalInstance = $uibModal.open({
      animation: true,
      component: "registerModal",
      size: "mdsm",
      resolve: {
        $transition$: function(): Transition {
          return $transition$;
        },
        groups: function() {
          return groups;
        },
        settings: function() {
          return settings;
        }
      }
    });

    PreviousService.invokeModalOnEnter(modalInstance, $transition$, true, true);
    return false; // this is required to stop the gallery from disappearing when the modal is opened
  }

  getGroups.$inject = ["Groups"];
  function getGroups(Groups) {
    return Groups.query({ simple: true });
  }

  getSettings.$inject = ["Settings"];
  function getSettings(Settings) {
    return Settings.query();
  }
})();
