import { PreviousService } from "../auth/previous.service";

(function() {
  "use strict";

  angular.module("marketPlace.watchdog").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.watchdog", {
      url: "/watchdog",
      data: {
        requiresLogin: true
      },
      params: {
        item: null
      },
      onEnter: [
        "$transition$",
        "$uibModal",
        "PreviousService",
        function($transition$, $uibModal, PreviousService: PreviousService) {
          const modalInstance = $uibModal.open({
            animation: true,
            backdrop: "static",
            template: require("./templates/watchdog-modal.html"),
            size: "lg",
            controller: "WatchdogController",
            controllerAs: "vm",
            resolve: {
              item: function() {
                return $transition$.params().item;
              },
              reasons: getReasons
            }
          });

          PreviousService.invokeModalOnEnter(modalInstance, $transition$);
        }
      ]
    });
  }

  getReasons.$inject = ["Watchdog"];
  function getReasons(Watchdog) {
    return Watchdog.reasons().$promise;
  }
})();
