(function() {
  "use strict";

  angular.module("marketPlace.payments").factory("braintreeClient", braintreeClientFactory);

  braintreeClientFactory.$inject = ["$window"];

  function braintreeClientFactory($window) {
    return $window.braintreeClient;
  }
})();

(function() {
  "use strict";

  angular.module("marketPlace.payments").factory("braintreeHostedFields", braintreeHostedFieldsFactory);

  braintreeHostedFieldsFactory.$inject = ["$window"];

  function braintreeHostedFieldsFactory($window) {
    return $window.braintreeHostedFields;
  }
})();
