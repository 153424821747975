import { ILogService, IAngularEvent } from "angular";

class TypeFilterController {
  srefParams: any = {};
  active = true;

  static $inject = ["$log"];

  constructor(
    public $log: ILogService,
    public searchParams: any,
    public filterParams: any,
    public label: string,
    public first: boolean = false,
    public callback: function = null,
    public excludeParams: [string] = ["for_sale", "sold", "show_and_tell", "social", "game", "display_type"]
  ) { }

  $onInit() {
    //this.setup();
  }

  $doCheck() {
    this.setup();
  }

  setup() {
    // load filters with other active params
    const expected = Object.keys(this.filterParams);
    const selected = [];

    for (const param in this.searchParams) {
      if (this.excludeParams.indexOf(param) === -1) {
        this.srefParams[param] = this.searchParams[param];
      } else if (
        this.searchParams[param] === this.filterParams[param] ||
        (typeof this.searchParams[param] === "boolean" && this.searchParams[param] && this.filterParams[param])
      ) {
        selected.push(param);
      }
    }

    this.active = true;
    for (let i = 0; i < expected.length && this.active; i++) {
      this.active = selected.indexOf(expected[i]) >= 0;
    }

    if (!this.active) {
      for (const filterParam in this.filterParams) {
        this.srefParams[filterParam] = this.filterParams[filterParam];
      }
    }
  }

  onClick($event: IAngularEvent): boolean {
    if (this.callback) {
      $event.preventDefault();
      $event.stopPropagation();
      this.callback(this.active, this.filterParams);
      return false;
    }

    return true;
  }
}

angular.module("marketPlace.gallery").component("typeFilter", {
  bindings: {
    searchParams: "<",
    filterParams: "<",
    label: "<",
    first: "<",
    callback: "<?",
    excludeParams: "<?"
  },
  template: require("./templates/type-filter.html"),
  controller: TypeFilterController,
  controllerAs: "vm"
});
