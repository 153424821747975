(function () {
  "use strict";
  angular.module("marketPlace.controls").directive("mediaSection", mediaSection);

  mediaSection.$inject = ["Preview", "$log"];

  function mediaSection(Preview, $log) {
    return {
      template: require("./templates/media-section.html"),
      scope: {
        images: "=",
        alt: "=",
        primaryImage: "=?",
        primaryImageType: "=?",
        keywords: "=?",
      },
      link: linkFunc,
      controller: mediaController,
      controllerAs: "vm",
      bindToController: true,
    };

    function linkFunc(scope) {
      /** Set selected image
       * @param idx
       */
      scope.setSelected = function (idx) {
        scope.vm.selectedImage = scope.vm.imagesForGallery[idx];
        scope.vm.magnify = scope.vm.selectedImage.media_type.startsWith("image");
        scope.vm.currentIndex = idx;
        if (scope.vm.selectedImage.is_for_sale) {
          scope.vm.buyImageParams = {
            itemName: "High-Res Image",
            productUrl: scope.vm.selectedImage.product,
            quantity: 1,
            options: [{ option: scope.vm.selectedImage.product_option, value: scope.vm.selectedImage.key }],
          };
        } else {
          scope.vm.buyImageParams = {};
        }
        $log.debug("IMAGE PARAMS", scope.vm.buyImageParams);
      };

      scope.openPreview = function (msg = "image") {
        $log.debug("Opened from", msg);
        Preview.open(angular.copy(scope.vm.images), scope.vm.currentIndex, true);
      };
    }
  }

  mediaController.$inject = [
    "$log",
    "ItemForm",
    "Thumbnail",
    "_",
    "Features",
    "broadStreetZones",
    "VimeoAPI",
    "toastr",
  ];

  function mediaController($log, ItemForm, Thumbnail, _, Features, broadStreetZones, VimeoAPI, toastr) {
    const vm = this;
    vm.currentIndex = 0;
    vm.enableShoppingCart = Features.shoppingCart;
    vm.enableBroadstreet = Features.broadstreet;
    vm.zones = broadStreetZones;
    vm.showProducts = false;
    vm.toggleOpenProducts = toggleOpenProducts;

    vm.$onInit = function () {
      $log.debug("mediaController");
      vm.placeholder = angular.isDefined(vm.placeholder) ? vm.name : "/assets/images/noimagesupplied.jpg";
      transformImages();
    };

    function toggleOpenProducts() {
      vm.showProducts = !vm.showProducts;
    }

    function transformImages() {
      const imagesForGallery = [];
      const allImages = angular.copy(vm.images);
      $log.debug("Original images", allImages);

      // Combine primary image back into images array
      if (
        angular.isDefined(vm.primaryImage) &&
        vm.primaryImage !== null &&
        angular.isDefined(vm.primaryImageType) &&
        vm.primaryImageType !== null
      ) {
        const primaryIndex = _.findIndex(allImages, function (img) {
          return img.url === vm.primaryImage;
        });

        if (primaryIndex > 0) {
          // Move primary image to first index
          const primaryImageList = allImages.splice(primaryIndex, 1);
          allImages.unshift(...primaryImageList);
        } else if (primaryIndex === -1) {
          // Add the primary image if it doesn't exist
          allImages.unshift({
            url: vm.primaryImage,
            media_type: vm.primaryImageType,
          });
        }
        vm.images = allImages;

        $log.debug("All images with primary", vm.images);
      }

      for (const image of allImages) {
        const galleryImage = {
          id: image.id,
          small: undefined,
          thumb: undefined,
          key: undefined,
          large: Thumbnail.getThumbnailURI(image.url, "-1500x1500", ".jpg"),
          media_type: image.media_type,
          is_for_sale: image.is_for_sale,
          product: image.product,
          product_option: image.product_option,
          products: image.products,
        };

        if (image.media_type.startsWith("image")) {
          const thumb = Thumbnail.getThumbnailURI(image.url, "-116x116", ".png", true);
          galleryImage.thumb = thumb.url;
          galleryImage.key = thumb.key;
          galleryImage.small = Thumbnail.getThumbnailURI(image.url, "-393x393");
        } else if (image.media_type.startsWith("youtube")) {
          const { id } = ItemForm.parseVideo(image.url);
          galleryImage.large = "http://www.youtube.com/embed/" + id + "?rel=0&wmode=transparent";
          galleryImage.thumb = "https://img.youtube.com/vi/" + id + "/default.jpg";
          galleryImage.small = "https://img.youtube.com/vi/" + id + "/hqdefault.jpg";
        } else if (image.media_type.startsWith("vimeo")) {
          VimeoAPI.oEmbed(image.url).then(
            function (response) {
              galleryImage.large = response.data.thumbnail_url;
              galleryImage.small = response.data.thumbnail_url_with_play_button;
            },
            function (error) {
              if (error.status !== 404) {
                toastr.error("Error fetching vimeo thumbnails");
                $log.debug(error);
              }
            }
          );
        }
        imagesForGallery.push(galleryImage);
      }
      vm.imagesForGallery = imagesForGallery;
      $log.debug("All images (imagesForGallery)", vm.imagesForGallery);
      vm.selectedImage = vm.imagesForGallery[0];
      vm.magnify = vm.selectedImage.media_type.startsWith("image");
      if (vm.selectedImage.key && vm.selectedImage.products) {
        for (let idx = 0; idx < vm.selectedImage.products.length; idx++) {
          vm.selectedImage.products[idx].params = {
            itemName: "High-Res Image",
            productUrl: vm.selectedImage.products[idx].url,
            quantity: 1,
            options: [{ option: vm.selectedImage.products[idx].option, value: vm.selectedImage.key }],
          };
          $log.debug("Product params:", vm.selectedImage.products[idx].params);
        }
      }
    }
  }
})();
