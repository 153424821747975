import { PreviousService } from "../auth/previous.service";

(function() {
  "use strict";

  angular.module("marketPlace.events").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider
      .state("events", {
        template: "<div ui-view></div>",
        url: "^/events",
        abstract: true,
        data: {
          requiresLogin: true,
          permissions: {
            redirectTo: "home.gallery"
          }
        },
        resolve: {
          user: getUser,
          writableNiches: getWritableNiches,
          settings: getSettings
        }
      })
      .state("events.add", {
        url: "/add",
        data: {
          permissions: {
            only: "EVENT_CREATOR",
            redirectTo: [
              "flash",
              function(flash) {
                flash.info("Upgrade required");
                return "home.dashboard.billing.upgrade";
              }
            ]
          }
        },
        resolve: {
          $transition$: "$transition$"
        },
        onEnter: [
          "$uibModal",
          "PreviousService",
          "$transition$",
          "user",
          "writableNiches",
          "settings",
          function($uibModal, PreviousService: PreviousService, $transition$, user, writableNiches, settings) {
            const modalInstance = $uibModal.open({
              animation: true,
              backdrop: "static",
              template: require("./templates/event-modal.html"),
              size: "lg",
              controller: "EventsAddController",
              controllerAs: "vm",
              resolve: {
                user: function() {
                  return user;
                },
                writableNiches: function() {
                  return writableNiches;
                },
                settings: function() {
                  return settings;
                },
                modalTitle: function() {
                  return "Create Event";
                }
              }
            });

            PreviousService.invokeModalOnEnter(modalInstance, $transition$);
          }
        ]
      })
      .state("events.edit", {
        url: "^/events/edit/{id:int}",
        data: {
          permissions: {
            only: "change_event"
          }
        },
        resolve: {
          event: [
            "$transition$",
            "Event",
            function($transition$, Event) {
              return Event.get({ id: $transition$.params().id }).$promise;
            }
          ],
          canEdit: [
            "$q",
            "$log",
            "$state",
            "user",
            "event",
            "PermAuthorization",
            "PermPermissionMap",
            "toastr",
            function($q, $log, $state, user, event, PermAuthorization, PermPermissionMap, toastr) {
              const deferred = $q.defer();
              if (event.user.id === user.id) {
                deferred.resolve(true);
              } else {
                PermAuthorization.authorizeByPermissionMap(new PermPermissionMap({ only: ["change_any_event"] }))
                  .then(function() {
                    deferred.resolve(true);
                  })
                  .catch(function() {
                    $log.debug(
                      "Permission denied for user " + user.id + " to edit item owned by user " + event.user.id
                    );
                    toastr.error("Permission denied");
                    $state.go("home.gallery");
                    deferred.reject("Permission denied");
                  });
              }
              return deferred.promise;
            }
          ]
        },
        onEnter: [
          "$uibModal",
          "$transition$",
          "PreviousService",
          "user",
          "writableNiches",
          "event",
          function($uibModal, $transition$, PreviousService: PreviousService, user, writableNiches, event) {
            const modalInstance = $uibModal.open({
              animation: true,
              template: require("./templates/event-modal.html"),
              size: "lg",
              controller: "EventsEditController",
              controllerAs: "vm",
              resolve: {
                user: function() {
                  return user;
                },
                niches: function() {
                  return writableNiches;
                },
                event: function() {
                  return event;
                },
                modalTitle: function() {
                  return "Edit Event";
                }
              }
            });

            PreviousService.invokeModalOnEnter(modalInstance, $transition$);
          }
        ]
      });
  }

  getUser.$inject = ["AuthService"];
  function getUser(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getCurrentUser();
    }
  }

  getWritableNiches.$inject = ["Niches"];
  function getWritableNiches(Niches) {
    return Niches.write.query({ item_type: "EVENT" }).$promise;
  }

  getSettings.$inject = ["Settings"];
  function getSettings(Settings) {
    return Settings.query();
  }
})();
