(function() {
  "use strict";
  angular.module("marketPlace.dashboard").directive("expand", expand);

  function expand() {
    function link(scope) {
      scope.$on("onExpandAll", function(event, args) {
        scope.expanded = args.expanded;
      });
    }

    return {
      link: link
    };
  }
})();
