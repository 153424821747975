/* eslint-disable */
(function(angular, Headroom) {
  /***
   * This is a customized version of angular.headroom.js which adds a watcher for offset.
   *
   * There is an open pull request for the same functionality from 2016 which doesn't look
   * like it's getting merged any time soon :(
   * https://github.com/WickyNilliams/headroom.js/pull/248
   */

  if (!angular || !Headroom) {
    console.log("no headroom"); //eslint-disable-line no-console
    return;
  }

  function headroom(HeadroomService) {
    return {
      scope: {
        tolerance: "=",
        offset: "=",
        classes: "=",
        scroller: "@"
      },
      link: function($scope, $element) {
        var options = {};
        var opts = HeadroomService.options;
        for (var prop in opts) {
          options[prop] = $scope[prop] || opts[prop];
        }
        if ($scope.scroller) {
          options.scroller = document.querySelector($scope.scroller);
        }
        const headroom = new HeadroomService($element[0], options).init();

        $scope.$watch("offset", function(newValue, oldValue) {
          if (newValue !== oldValue) {
            headroom.offset = newValue;
          }
        });
      }
    };
  }

  headroom.$inject = ["HeadroomService"];

  function HeadroomService() {
    return Headroom;
  }

  angular
    .module("headroom", [])
    .directive("headroom", headroom)
    .factory("HeadroomService", HeadroomService);
})(window.angular, window.Headroom);
