(function() {
  "use strict";

  angular.module("marketPlace.auth").controller("LoginModalController", loginModalController);

  loginModalController.$inject = [
    "$rootScope",
    "$scope",
    "$state",
    "$transition$",
    "$uibModalInstance",
    "$log",
    "$cookies",
    "$auth",
    "$filter",
    "$window",
    "AuthService",
    "toastr",
    "SITE_NAME",
    "AUTH_EVENTS",
    "Raven",
    "AppConfig"
  ];

  function loginModalController(
    $rootScope,
    $scope,
    $state,
    $transition$,
    $uibModalInstance,
    $log,
    $cookies,
    $auth,
    $filter,
    $window,
    AuthService,
    toastr,
    SITE_NAME,
    AUTH_EVENTS,
    Raven,
    AppConfig
  ) {
    const stateParams = $transition$.params();
    const vm = this;
    vm.user = {};
    vm.form = {};
    vm.errors = {};
    vm.rememberMe = false;
    vm.siteName = SITE_NAME;
    vm.socialLoginInProgress = false;
    vm.saving = false;

    // functions
    vm.login = login;
    vm.cancel = cancel;
    vm.clearNonFieldErrors = clearNonFieldErrors;
    vm.authenticate = authenticate;
    vm.forgotPassword = forgotPassword;
    vm.goToLogin = goToLogin;
    vm.smallBanner = "/assets/images/logo-small-banner.png";

    activate();

    function activate() {
      $log.debug(stateParams);
      vm.showLogin = stateParams.showLogin;
      if (vm.showLogin) {
        AuthService.clearCachedRoutes();
      }
      vm.user.email = $cookies.get("email");
      if (AppConfig.S3Config.s3url && AppConfig.customSmallBanner) {
        vm.smallBanner = AppConfig.S3Config.s3url + AppConfig.customSmallBanner;
        $log.debug("customSmallBanner: ", vm.smallBanner);
      }
    }

    function login() {
      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }

      vm.saving = true;
      $log.debug("login function called");

      AuthService.login(vm.user, vm.rememberMe).then(
        function() {
          toastr.success("You have successfully signed in!");
          vm.saving = false;
          $uibModalInstance.close(true);
        },
        function(error?: { [key: string]: any }) {
          if (error) {
            angular.forEach(error, function(errors, field) {
              $log.debug(field + " : " + errors);
              vm.errors.non_field_errors = errors.join(", ");
            });
            $scope.$broadcast("show-errors-check-validity");
          }
          vm.saving = false;
        }
      );
    }

    function authenticate(provider) {
      vm.socialLoginInProgress = true;
      $auth
        .authenticate(provider)
        .then(function() {
          $log.debug("Successful login");
          toastr.success("You have successfully signed in with " + $filter("titleCase")(provider) + "!");

          $window.gtag("event", "login", {method: provider});

          const payload = $auth.getPayload();
          Raven.setUserContext({
            email: payload.email
          });

          $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
          $uibModalInstance.close(true);
        })
        .catch(function(error) {
          if (error.data && error.status) {
            toastr.error(error.data.message, error.status);
          } else if (!error.message) {
            toastr.error("Sorry there was a problem logging in");
          }
          $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
        })
        .finally(function() {
          vm.socialLoginInProgress = false;
        });
    }

    function cancel() {
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.dismiss("cancel");
    }

    function forgotPassword() {
      $uibModalInstance.close({ noTransition: true });
      $state.go("reset-password", { email: vm.user.email });
    }

    function goToLogin() {
      vm.showLogin = true;
    }

    function clearNonFieldErrors() {
      vm.errors.non_field_errors = "";
    }
  }
})();
