(function() {
  "use strict";
  angular.module("marketPlace.following").factory("Following", following);

  following.$inject = ["$resource", "API_URL"];

  function following($resource, API_URL) {
    return $resource(
      API_URL + "/api/following/:controller/",
      { controller: "@controller" },
      {
        un_follow: {
          method: "POST",
          params: {
            controller: "un_follow"
          }
        },
        follow: {
          method: "POST"
        }
      }
    );
  }
})();
