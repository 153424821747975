(function() {
  "use strict";
  angular.module("marketPlace.association").factory("Association", Association);

  Association.$inject = ["$resource", "API_URL"];

  function Association($resource, API_URL) {
    return $resource(
      API_URL + "/api/references/:type/",
      { type: "@type" },
      {
        associations: {
          method: "GET",
          isArray: true,
          params: {
            type: "associations"
          }
        },
        clubs: {
          method: "GET",
          isArray: true,
          params: {
            type: "clubs"
          }
        }
      }
    );
  }
})();
