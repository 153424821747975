(function() {
  "use strict";
  angular.module("marketPlace.items", [
    "marketPlace.category",
    "marketPlace.controls",
    "marketPlace.home",
    "marketPlace.lodash",
    "marketPlace.niches",
    "marketPlace.payments",
    "marketPlace.preview"
  ]);
})();
