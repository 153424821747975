interface ImageThumbnailBindings {
  url: string;
}

interface ImageThumbnailController extends ImageThumbnailBindings {
  $onChanges(): void;
}

class ImageThumbnailControllerImpl implements ImageThumbnailController {
  public thumbnailUrl: string;
  public url: string;

  static $inject = ["Thumbnail"];

  constructor(public Thumbnail: any) {}

  $onChanges(): void {
    if (this.url) {
      this.thumbnailUrl = this.Thumbnail.getThumbnailURI(this.url, "-262x262");
    }
  }
}

class ImageThumbnail implements ng.IComponentOptions {
  public bindings: any;
  public controller: any;
  public template: string;

  constructor() {
    this.bindings = {
      url: "<"
    };
    this.controller = ImageThumbnailControllerImpl;
    this.template =
      '<img class="img-responsive mx-auto lazyload" \
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="\
            data-ng-src="{{ $ctrl.thumbnailUrl }}"/>';
  }
}

angular.module("marketPlace.preview").component("imageThumbnail", new ImageThumbnail());
