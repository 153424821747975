import { ILogService, IScope } from "angular";

class RegisterController {
  user: any = {};
  form: any = {};
  errors: any = {};
  errorCodes: any;
  saving = false;
  collectsLabel: string;
  collectsRequired: any;

  static $inject = ["$log", "$scope", "AuthService", "AppConfig"];

  constructor(
    public $log: ILogService,
    public $scope: IScope,
    public AuthService: any,
    public AppConfig: { [key: string]: any },
    public onRegister: Function,
    public groups: [string],
    public hideSocial: boolean
  ) {
    this.$log.debug("Register Component Controller");
  }

  $onInit(): void {
    this.user = this.AuthService.createNewUser(this.groups);
    this.collectsLabel = this.AppConfig.collectsLabel;
    this.collectsRequired = this.AppConfig.collectsRequired;
  }

  triggerOnRegister(user) {
    // ensure onRegister signature matches:
    //<simple-register on-register="vm.onRegister(user)"></simple-register>
    this.onRegister({ user: user });
  }

  success() {
    this.AuthService.getCurrentUser().then(this.triggerOnRegister.bind(this));
  }

  handleFieldError(errors: any, field: string) {
    this.$log.debug(field + " : " + errors);
    try {
      this.form[field].$setValidity("server", false);
      if (this.errorCodes.indexOf("unclaimed_user") > 0) {
        this.errors[field] =
          "This email is registered but unclaimed, check your email for a code or email support@madonc.com";
      } else {
        this.errors[field] = errors.join(", ");
      }
    } catch (e) {
      if (angular.isArray(errors)) {
        errors = errors.join(", ");
      }
      this.errors.non_field_errors = errors;
    }
  }

  error(error) {
    this.$log.debug(error);
    if (error) {
      this.errorCodes = [];
      if (error.hasOwnProperty("error_codes")) {
        this.errorCodes = error["error_codes"];
        delete error["error_codes"];
      }

      angular.forEach(error, this.handleFieldError.bind(this));
    }
  }

  register() {
    this.$log.debug("GROUPS", this.groups);
    this.$scope.$broadcast("show-errors-check-validity");

    if (this.form.$invalid) {
      return;
    }

    this.saving = true;
    this.AuthService.register(this.user)
      .then(this.success.bind(this))
      .catch(this.error.bind(this));
  }

  authenticate(provider: string) {
    this.AuthService.authenticate(provider, this.user)
      .then(this.success.bind(this))
      .catch(this.error.bind(this));
  }
}

angular.module("marketPlace.home").component("simpleRegister", {
  bindings: {
    onRegister: "&",
    groups: "<?",
    hideSocial: "<?"
  },
  template: require("./templates/simple-register.html"),
  controller: RegisterController,
  controllerAs: "vm"
});
