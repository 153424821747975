import { ILogService, IWindowService } from "angular";
import { Transition } from "@uirouter/angularjs";

(function() {
  "use strict";
  angular.module("marketPlace.games").controller("GameResultsController", gameResultsController);

  gameResultsController.$inject = ["$log", "$transition$", "$window", "DashboardTable", "Game", "Groups", "user"];

  function gameResultsController(
    $log: ILogService,
    $transition$: Transition,
    $window: IWindowService,
    DashboardTable,
    Game,
    Groups,
    user
  ) {
    const vm = this;
    const stateParams = $transition$.params();
    vm.user = user;
    vm.loading = true;
    vm.currentPage = 1;
    vm.itemsPerPage = 50;

    vm.sortChanged = angular.bind(vm, DashboardTable.sortChanged, refreshData);
    vm.pageChanged = angular.bind(vm, DashboardTable.pageChanged, refreshData);
    vm.refreshUsers = refreshUsers;

    activate();

    function activate(): void {
      $log.debug("ResultsController");
      Groups.query().then(function(groups) {
        vm.groups = groups;
      }, angular.noop);
      refreshData();
    }

    function refreshData(params = {}): void {
      angular.extend(params, { game_id: stateParams.id });
      Game.results(params)
        .then(function(results) {
          vm.results = results;
          $window.scrollTo(0, 0);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function refreshUsers(user: string) {
      const params = { game_id: stateParams.id, display_name: user };
      return Game.leaderboardUsers(params).then(function(results) {
        vm.users = results;
      });
    }
  }
})();
