(function() {
  "use strict";

  angular.module("marketPlace.organisations").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.organisation-users", {
      url: "/organisation-users/:organisationId",
      template: require("./templates/organisation-users.html"),
      controller: "OrganisationUsersController",
      controllerAs: "vm",
      resolve: {
        $transition$: "$transition$",
        users: getOrganisationUsers
      }
    });

    $stateProvider.state("home.dashboard.edit-organisation", {
      url: "/organisation?new",
      template: require("./templates/organisation-edit.html"),
      controller: "DashboardOrganisationController",
      controllerAs: "vm",
      resolve: {
        $transition$: "$transition$",
        user: getUser
      }
    });
  }

  getOrganisationUsers.$inject = ["$transition$", "OrganisationUsers"];
  function getOrganisationUsers($transition$, OrganisationUsers) {
    return OrganisationUsers.list({ organisationId: $transition$.params().organisationId }).$promise;
  }

  getUser.$inject = ["AuthService"];
  function getUser(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getCurrentUser();
    }
  }
})();
