(function() {
  "use strict";

  const broadstreetAdComponent = {
    template: require("./templates/broadstreet-ad-component.html"),
    bindings: {
      zoneId: "<",
      keywords: "<?",
      softKeywords: "<?",
      altZoneId: "<?"
    },
    controller: broadstreetAdController
  };

  angular.module("marketPlace.broadstreet").component("broadstreetAd", broadstreetAdComponent);

  broadstreetAdController.$inject = ["$log", "$document", "broadstreet"];

  function broadstreetAdController($log, $document, broadstreet) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
      $log.debug("broadstreetAd $onInit zone: " + $ctrl.zoneId);
      $ctrl.keywords = formatKeywords($ctrl.keywords);
    };

    $ctrl.$onChanges = function(changes) {
      if (changes.keywords.isFirstChange()) {
        $log.debug("broadstreetAd $onChanges (" + $ctrl.zoneId + ") First change: ", changes);
        return;
      }
      if (changes.keywords && changes.keywords.currentValue != changes.keywords.previousValue) {
        $log.debug("broadstreetAd $onChanges (" + $ctrl.zoneId + ") keywords changed: ", changes);
        $ctrl.keywords = formatKeywords($ctrl.keywords);
        reloadZone();
      }
    };

    function formatKeywords(keywords?: string | string[]): string | void {
      if (keywords) {
        if (Array.isArray(keywords)) {
          const keywordsCopy = [ ...keywords ];
          const formattedKeywords = keywordsCopy.join(",");
          return formattedKeywords ? formattedKeywords : undefined;
        }
        return keywords;
      }
    }

    function reloadZone() {
      // TODO is there a better way to get the child of this component
      const adZones = $document[0].querySelectorAll('broadstreet-zone[zone-id="' + $ctrl.zoneId + '"]');
      $log.debug("reloadZone (" + $ctrl.zoneId + "): ", adZones);

      for (let i = 0; i < adZones.length; i++) {
        const element = adZones[i];
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
        broadstreet.loadZone(element);
      }
    }
  }
})();
