import { ILogService, IWindowService } from "angular";

(function() {
  "use strict";
  angular.module("marketPlace.games").controller("LeaderboardController", leaderBoardController);

  leaderBoardController.$inject = ["$log", "$window", "DashboardTable", "Game", "Groups"];

  function leaderBoardController($log: ILogService, $window: IWindowService, DashboardTable, Game, Groups) {
    const vm = this;
    vm.currentPage = 1;
    vm.itemsPerPage = 50;
    vm.loading = true;

    vm.sortChanged = angular.bind(vm, DashboardTable.sortChanged, refreshData);
    vm.pageChanged = angular.bind(vm, DashboardTable.pageChanged, refreshData);
    vm.refreshUsers = refreshUsers;

    activate();

    function activate(): void {
      $log.debug("LeaderboardController");

      Game.gameTypes().then(function(response) {
        vm.gameTypes = response;
      });

      Groups.query().then(function(groups) {
        vm.groups = groups;
      }, angular.noop);

      refreshData();
    }

    function refreshData(params = {}): void {
      Game.leaderboard(params)
        .then(function(results) {
          vm.users = results;
          $window.scrollTo(0, 0);
        })
        .finally(function() {
          vm.loading = false;
        });
    }

    function refreshUsers(user: string) {
      const params = { display_name: user };
      return Game.leaderboardUsers(params).then(function(results) {
        vm.userList = results;
      });
    }
  }
})();
