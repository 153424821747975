(function() {
  "use strict";

  angular.module("marketPlace.content").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider
      .state("home.content", {
        url: "",
        abstract: true,
        data: {
          requiresLogin: false
        },
        views: {
          "app@": {
            template: require("./templates/base.html")
          },
          "header@home.content": {
            template: require("../home/templates/header.html"),
            controller: "HeaderController",
            controllerAs: "vm"
          },
          "advanced-search@home.content": {
            template: require("../home/templates/advanced-search.html"),
            controller: "AdvancedSearchController",
            controllerAs: "vm"
          }
        }
      })
      .state("home.content.contact", {
        url: "/contact?subject&requestType",
        views: {
          content: "contactUs"
        }
      })
      .state("home.content.page", {
        url: "^/content/{slug:[-a-z0-9]+}",
        views: {
          content: "contentPage"
        }
      });
  }
})();
