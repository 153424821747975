(function() {
  "use strict";

  angular.module("marketPlace.listings").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.listings", {
      url: "/listings?q",
      template: require("./templates/listings.html"),
      controller: "ListingController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      },
      params: {
        q: ""
      }
    });
    $stateProvider.state("home.dashboard.event-listings", {
      url: "/event-listings",
      template: require("./templates/event-listings.html"),
      controller: "EventListingController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      }
    });
  }
})();
