class GalleryForm {
  isGalleryState(state: string): boolean {
    return (
      [
        "home.gallery",
        "home.detail.deprecated",
        "home.detail-item-preview",
        "home.section.deprecated",
        "home.section.gallery.deprecated",
        "home.section.caption.deprecated"
      ].indexOf(state) >= 0
    );
  }
}

angular.module("marketPlace.gallery").service("GalleryForm", GalleryForm);
