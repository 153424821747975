(function() {
  "use strict";

  angular.module("marketPlace.profile").controller("ClubsController", clubsController);

  clubsController.$inject = ["$filter", "$log", "$scope", "$q", "_", "toastr", "user", "Association"];

  function clubsController($filter, $log, $scope, $q, _, toastr, user, Association) {
    const vm = this;

    vm.saving = false;
    vm.errors = {};

    // pre-resolved dependencies
    vm.user = user;
    vm.clubs = null;
    vm.club = null;

    // functions
    vm.save = save;
    vm.addClub = addClub;
    vm.removeClub = removeClub;
    vm.tagTransform = tagTransform;

    // forms
    vm.clubForm = {};
    vm.clubForm.form = {};

    activate();

    function activate() {
      getClubs().then(function(clubs) {
        vm.clubs = clubs;
        updateLoading();
      });
    }

    function updateLoading() {
      vm.isLoaded = vm.clubs !== null;
    }

    function getClubs() {
      if (vm.user.categories.length > 0) {
        $log.debug("User has categories");
        const deferred = $q.defer(),
          categoryNames = [];

        vm.user.categories.forEach(function(category) {
          categoryNames.push(category.name);
        });

        Association.clubs({ category: categoryNames.join() }, function(initialResults) {
          if (initialResults.length < 6) {
            // get more associations
            Association.clubs(function(allResults) {
              deferred.resolve(_.unionBy(initialResults, allResults, "id"));
            });
          } else {
            deferred.resolve(initialResults);
          }
        });
        return deferred.promise;
      } else {
        $log.debug("User has no categories");
        return Association.clubs().$promise;
      }
    }

    function save(form) {
      $log.debug("saving associations");

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        $log.debug("form is invalid");
        return;
      } else {
        vm.errors = {};
      }

      vm.saving = true;
      vm.user.clubs = _.compact(vm.user.clubs);
      vm.user
        .$patch(
          ["clubs"],
          function() {
            $scope.$broadcast("show-errors-reset");
            toastr.success("Clubs updated");
            form.$setPristine();
          },
          function(resp) {
            $log.debug("Error saving tab");

            if (resp.data && resp.status !== 500) {
              angular.forEach(resp.data, function(errors, field) {
                $log.debug(field + " : " + errors);
                try {
                  form[field].$setValidity("server", false);
                  if (angular.isObject(errors)) {
                    angular.forEach(errors, function(subErrors, field) {
                      vm.errors[field] = subErrors.join(", ");
                    });
                  } else {
                    vm.errors[field] = errors.join(", ");
                  }
                } catch (e) {
                  $log.error(resp.status + ": " + resp.statusText);
                  if (angular.isArray(errors)) {
                    errors = errors.join(", ");
                  }
                  vm.errors.non_field_errors = errors;
                }
              });
            } else {
              if (vm.user.associations.length > 0 && !vm.user.associations[vm.user.associations.length - 1].name) {
                vm.user.associations.pop();
              }

              if (vm.user.clubs.length > 0 && !vm.user.clubs[vm.user.clubs.length - 1].name) {
                vm.user.clubs.pop();
              }

              $log.error(resp.status + ": " + resp.statusText);
              vm.errors.non_field_errors =
                "Sorry there was a problem saving your clubs and references, please try again";
            }
            $scope.$broadcast("show-errors-check-validity");
          }
        )
        .finally(function() {
          vm.saving = false;
        });
    }

    function addClub() {
      vm.user.clubs.push(angular.copy(vm.club));
      vm.club = null;
    }

    function removeClub(index) {
      vm.user.clubs.splice(index, 1);
    }

    function tagTransform(newTag) {
      return {
        name: newTag
      };
    }
  }
})();
