(function() {
  "use strict";

  angular.module("marketPlace.items").factory("Item", Item);

  Item.$inject = ["$resource", "$http", "$log", "API_URL", "moment"];

  function Item($resource, $http, $log, API_URL, moment) {
    const convertDates = function(obj) {
      if (obj) {
        const item = angular.copy(obj);
        if (item.manufacture_date_from != null) {
          item.manufacture_date_from = moment(item.manufacture_date_from).toDate();
        }

        if (item.manufacture_date_to != null) {
          item.manufacture_date_to = moment(item.manufacture_date_to).toDate();
        }

        if (item.realisation_date != null) {
          item.realisation_date = moment(item.realisation_date).toDate();
        }
        return item;
      }
      return obj;
    };

    const convertFloats = function(obj) {
      if (obj) {
        const item = angular.copy(obj);
        if (item.price != null) {
          item.price = parseFloat(item.price);
        }

        if (item.realisation_price != null) {
          item.realisation_price = parseFloat(item.realisation_price);
        }
        return item;
      }
      return obj;
    };

    const setItemName = function(obj) {
      if (obj && angular.isUndefined(obj.name)) {
        obj.name = obj.category.name;
      }
      return obj;
    };

    const setPrimaryImage = function(obj) {
      // Set at least one image as primary
      if (obj && obj.external_images.length > 0) {
        for (let i = 0; i < obj.external_images.length; i++) {
          if (obj.external_images[i].primary) {
            return obj;
          }
        }
        obj.external_images[0].primary = true;
      }
      return obj;
    };

    return $resource(
      API_URL + "/api/items/:id/:controller/",
      { id: "@id", controller: "@controller" },
      {
        get: {
          method: "GET",
          isArray: false,
          transformResponse: $http.defaults.transformResponse.concat([convertDates, convertFloats])
        },
        save: {
          method: "POST",
          transformRequest: [setItemName, setPrimaryImage].concat($http.defaults.transformRequest)
        },
        query: {
          method: "GET",
          isArray: false
        },
        update: {
          method: "PUT",
          transformRequest: [setItemName, setPrimaryImage].concat($http.defaults.transformRequest)
        },
        like: {
          method: "POST",
          params: {
            controller: "like"
          }
        },
        archive: {
          method: "POST",
          params: {
            controller: "archive"
          }
        },
        relist: {
          method: "POST",
          params: {
            controller: "relist"
          }
        },
        promote: {
          method: "POST",
          params: {
            controller: "promote"
          }
        },
        imageProducts: {
          method: "GET",
          params: {
            controller: "image_products"
          },
          isArray: true
        },
        share: {
          method: "POST",
          params: {
            controller: "share"
          }
        }
      }
    );
  }
})();
