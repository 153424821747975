import { ILogService, IScope } from "angular";
import { StateService } from "@uirouter/angularjs";
import { IToastrService } from "angular-toastr";

export interface Search {
  uid: string;
  niche: string;
  section: string;
  min_date: string;
  max_date: string;
  min_price: number;
  max_price: number;
  country: string;
  q: string;
  country_code: string;
  city: string;
  category: [string];
  sort: string;
  display_type: string;
  for_sale: boolean;
  sold: boolean;
  show_and_tell: boolean;
}

interface SavedSearch {
  params: Search;
  name: string;
  id: number;
  schedule: number;
  isNew: boolean;
  is_default: boolean;
}

class SaveSearchController {
  schedules: any[] = [];
  editing = false;
  form: any = {};

  static $inject = ["$log", "$scope", "$state", "toastr", "UserSearchService"];

  constructor(
    public $log: ILogService,
    public $scope: IScope,
    public $state: StateService,
    public toastr: IToastrService,
    public UserSearchService: any,
    public user: any,
    public savedSearch: SavedSearch = null,
    public showControls: boolean = true,
    public saveForm: boolean = false,
    public onFormSave: Function
  ) {}

  $onInit() {
    this.UserSearchService.getSchedules().then(
      function(schedules) {
        this.schedules = schedules;

        if (angular.isDefined(schedules) && schedules !== null && schedules.length > 0) {
          if (angular.isDefined(schedules[0]) && schedules[0] !== null && schedules[0].hasOwnProperty("id")) {
            this.savedSearch.schedule = schedules[0].id;
          }
        }
      }.bind(this)
    );

    if (!this.showControls) {
      this.editing = true;

      this.$log.debug(this.savedSearch);
      this.savedSearch.name = this.getName();
    }
  }

  getName(): string {
    if (this.savedSearch.params.q) {
      return this.savedSearch.params.q;
    }

    if (this.savedSearch.params.category) {
      if (Array.isArray(this.savedSearch.params.category)) {
        return this.savedSearch.params.category.join(" ");
      } else {
        return this.savedSearch.params.category;
      }
    }

    if (this.savedSearch.params.for_sale) {
      return "For Sale";
    }

    if (this.savedSearch.params.show_and_tell) {
      return "Items of Interest";
    }

    if (this.savedSearch.params.sold) {
      return "Sold";
    }

    return "My Search";
  }

  search() {
    this.$state.go("home.gallery", this.savedSearch.params, { inherit: false, reload: true });
  }

  toggleEditing() {
    this.editing = !this.editing;
  }

  toggleDefault() {
    this.savedSearch.is_default = !this.savedSearch.is_default;
  }

  update(clear = false) {
    this.UserSearchService.update(this.savedSearch)
      .then(
        function() {
          if (clear) {
            this.UserSearchService.clear();
          }
          this.toastr.success("Search updated");
        }.bind(this)
      )
      .catch(
        function() {
          this.toastr.error("Failed to save search.");
        }.bind(this)
      );
  }

  remove() {
    this.UserSearchService.remove(this.savedSearch);
  }

  validateForm() {
    this.$scope.$broadcast("show-errors-check-validity");
    this.onFormSave()(this.form.$valid);
    this.saveForm = false;
  }

  $onChanges(changes) {
    if (changes.saveForm) {
      this.$log.debug("saveSearch $onChanges :", changes);
      this.validateForm();
    }
  }
}

angular.module("marketPlace.home").component("saveSearch", {
  bindings: {
    user: "<",
    schedules: "<",
    search: "<",
    savedSearch: "=",
    showControls: "<",
    saveForm: "<?",
    onFormSave: "&"
  },
  template: require("./templates/save-search.html"),
  controller: SaveSearchController,
  controllerAs: "vm"
});
