(function() {
  "use strict";

  angular.module("marketPlace.organisations").factory("Organisation", Organisation);

  Organisation.$inject = ["$resource", "$http", "API_URL"];

  function Organisation($resource, $http, API_URL) {
    const setPrimaryImage = function(obj) {
      // Set at least one image as primary
      if (obj.images.length > 0) {
        for (let i = 0; i < obj.images.length; i++) {
          if (obj.images[i].primary) {
            return obj;
          }
        }
        obj.images[0].primary = true;
      }
      return obj;
    };

    var Org = $resource(
      API_URL + "/api/organisations/:id/:controller/",
      { id: "@id", controller: "@controller" },
      {
        save: {
          method: "POST",
          transformRequest: [setPrimaryImage].concat($http.defaults.transformRequest)
        },
        update: {
          method: "PUT",
          transformRequest: [setPrimaryImage].concat($http.defaults.transformRequest)
        },
        getOrCreate: {
          method: "GET",
          isArray: false,
          transformResponse: $http.defaults.transformResponse.concat(function(data) {
            if (data && data.length > 0) {
              return new Org(data[0]);
            } else {
              return new Org({ images: [], social_media_links: [] });
            }
          })
        },
        upgrade: {
          method: "POST",
          params: {
            controller: "upgrade_request"
          }
        },
        like: {
          method: "POST",
          params: {
            controller: "like"
          }
        },
        promote: {
          method: "POST",
          params: {
            controller: "promote"
          }
        }
      }
    );
    return Org;
  }
})();
