import { ILogService } from "angular";

angular.module("marketPlace.auth").component("organisationType", {
  template: require("./templates/organisation-type.html"),
  bindings: {
    groups: "<",
    chooseOption: "&"
  },
  controller: organisationTypeController
});

organisationTypeController.$inject = ["$log", "_"];

function organisationTypeController($log: ILogService, _) {
  const $ctrl = this;
  const businessType = "B";

  $ctrl.$onInit = function() {
    $log.debug("organisationType $onInit");

    $ctrl.groupOptions = _.filter($ctrl.groups, { type: businessType });
  };
}
