(function() {
  "use strict";

  angular.module("marketPlace.email").factory("Email", Email);

  Email.$inject = ["$resource", "API_URL"];
  function Email($resource, API_URL) {
    return $resource(
      API_URL + "/api/marketing/:id/",
      { controller: "@id" },
      {
        query: {
          method: "GET",
          isArray: false
        }
      }
    );
  }
})();
