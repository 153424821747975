(function () {
  "use strict";

  angular.module("marketPlace.gallery").config(config);

  config.$inject = ["$stateProvider"];

  const searchParams =
    "?q&min_date&max_date&min_price&max_price&country&uid&city&country_code&category&sort&page&size&organisation&display_type&tags&sold&for_sale&show_and_tell&key";

  function config($stateProvider) {
    $stateProvider.state("home.gallery", {
      url:
        "^/collectables/{section:[-a-z0-9]+}/{gallery:[-a-z0-9]+}/{caption:[-a-z0-9]+}/{rawId:[0-2]{1}[0-9]+[-a-z0-9]*}" +
        searchParams,
      params: {
        default: true,
        niche: "collectables",
        section: {
          dynamic: true,
          value: null,
          squash: true,
        },
        gallery: {
          dynamic: true,
          value: null,
          squash: true,
        },
        caption: {
          dynamic: true,
          value: null,
          squash: true,
        },
        rawId: {
          dynamic: true,
          value: null,
          squash: true,
        },
        itemType: {
          dynamic: true,
          value: null,
          squash: true,
        },
        id: {
          dynamic: true,
          value: null,
          squash: true,
        },
        itemSlug: {
          dynamic: true,
          value: null,
          squash: true,
        },
        prompt: {
          dynamic: true,
          value: false,
          squash: true,
        },
        // multiple items for watchlist
        items: {
          dynamic: true,
          value: null,
          squash: true,
        },
      },
      views: {
        "content@home": {
          template: require("./templates/gallery.html"),
          controller: "GalleryController",
          controllerAs: "vm",
        },
      },
      resolve: {
        $transition$: "$transition$",
        siteName: [
          "$transition$",
          "SITE_NAME",
          function ($transition$, SITE_NAME) {
            let params = $transition$.params(),
              name = SITE_NAME;
            if (params.section) {
              name = deSlugify(params.section) + " | " + name;
            }
            return name;
          },
        ],
        initialItem: ["$log", "$transition$", "GalleryItem", getItem],
      },
      data: { isGallery: true },
      onEnter: [
        "$uibModal",
        "$transition$",
        "user",
        function ($uibModal, $transition$, user) {
          const params = $transition$.params();
          if (
            params.prompt &&
            !user &&
            !params.itemSlug &&
            !params.rawId &&
            !params.id &&
            (params.q || params.sold || params.for_sale || params.show_and_tell || params.category)
          ) {
            $uibModal
              .open({
                animation: true,
                component: "registerSearch",
                size: "mdsm",
                resolve: {
                  groups: getGroups,
                  search: function () {
                    return params;
                  },
                },
              })
              .result.catch(angular.noop);
          }
        },
      ],
    });

    $stateProvider.state("home.detail-deprecated", {
      url: "^/{itemType:(?:item|event|organisation)}/{id:int}",
      views: {
        "content@home": {
          template: require("./templates/gallery.html"),
          controller: "GalleryController",
          controllerAs: "vm",
        },
      },
      resolve: {
        $transition$: "$transition$",
        item: ["$log", "$transition$", "GalleryItem", getItemDeprecated],
      },
      redirectTo: function (trans) {
        const resolveItem = trans.injector().getAsync("item");
        return resolveItem.then(
          function (item) {
            const slug = (item.name || item.category || item.caption_slug).slugify();
            const rawId = item.url_item_type + item.id + "-" + slug;

            return trans.router.stateService.target(
              "home.gallery",
              {
                id: item.id,
                itemType: item.url_item_type,
                itemSlug: slug,
                rawId: rawId,
                section: item.section_slug,
                gallery: item.gallery_slug,
                caption: item.caption_slug,
                separator: "-",
                item: item,
              },
              { reload: true }
            );
          },
          function () {
            return "home.not-found";
          }
        );
      },
      params: {
        item: null,
        savedState: null,
        previousId: null,
        replaceUrl: true,
      },
    });

    $stateProvider.state("home.section.deprecated", {
      url: "^/gallery/:niche/:section" + searchParams,
      redirectTo: "home.gallery",
    });

    $stateProvider.state("home.section.gallery.deprecated", {
      url: "^/gallery/:niche/:section/:gallery" + searchParams,
      redirectTo: "home.gallery",
    });

    $stateProvider.state("home.section.caption.deprecated", {
      url: "^/gallery/:niche/:section/:gallery/:caption" + searchParams,
      redirectTo: "home.caption",
    });

    $stateProvider.state("home.detail-item-preview", {
      url: "^/preview/{id:int}/:token",
      views: {
        "content@home": {
          template: require("./templates/gallery.html"),
          controller: "GalleryController",
          controllerAs: "vm",
        },
      },
      data: {
        requiresLogin: false,
      },
      resolve: {
        $transition$: "$transition$",
        siteName: [
          "SITE_NAME",
          function (SITE_NAME) {
            return SITE_NAME;
          },
        ],
        initialItem: ["$transition$", "GalleryItem", "$q", getItemWithToken],
      },
      params: {
        savedState: null,
        previousId: null,
      },
    });
  }

  function deSlugify(slug) {
    return slug
      .split("-")
      .map(function (val) {
        return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
      })
      .join(" ");
  }

  function getItemIdParts(rawId) {
    const parts = rawId.split("-");
    const idParts = parts.shift();
    return {
      itemType: idParts[0],
      id: idParts.substring(1),
      itemSlug: parts.join("-"),
    };
  }

  function getItem($log, $transition$, GalleryItem) {
    $log.debug("GETTING INITIAL ITEM", $transition$.to(), $transition$.params("to"));
    const stateParams = $transition$.params();
    if (stateParams.item) {
      $log.debug("Using item from stateParams");
      return stateParams.item;
    }

    if (!stateParams.rawId) {
      $log.debug("No rawId");
      return null;
    }

    const params = getItemIdParts(stateParams.rawId);
    params.section = stateParams.section;
    params.gallery = stateParams.gallery;

    if (stateParams.caption) {
      params.caption = stateParams.caption;
      return GalleryItem.get(params).$promise;
    } else {
      return GalleryItem.noCaption(params).$promise;
    }
  }

  function getItemDeprecated($log, $transition$, GalleryItem) {
    const stateParams = $transition$.params();
    if (stateParams.item) {
      return stateParams.item;
    }
    $log.debug("gallery.routes getItemDeprecated");
    return GalleryItem.deprecated({
      id: stateParams.id,
      item_type: stateParams.itemType.toUpperCase(),
    }).$promise;
  }

  function getItemWithToken($transition$, GalleryItem, $q) {
    const deferred = $q.defer();
    const stateParams = $transition$.params();
    const tokenData = {
      id: stateParams.id,
      token: stateParams.token,
    };

    GalleryItem.preview(
      tokenData,
      function (resp) {
        deferred.resolve(resp);
      },
      function () {
        deferred.reject({
          redirectTo: "home.invalid-token",
          redirectParams: tokenData,
        });
      }
    );
    return deferred.promise;
  }

  getGroups.$inject = ["Groups"];
  function getGroups(Groups) {
    return Groups.query();
  }
})();
