// pilfered from https://hassantariqblog.wordpress.com/2016/07/27/angularjs-textarea-auto-resize-directive/

(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("autoResize", autoResize);

  autoResize.$inject = ["$timeout"];

  function autoResize($timeout) {
    return {
      restrict: "A",
      link: function autoResizeLink(scope, element) {
        element.css({ height: "34px", "overflow-y": "hidden" });
        $timeout(function() {
          element.css("height", element[0].scrollHeight + "px");
        }, 100);

        element.on("input", function() {
          element.css({ height: "34px", "overflow-y": "hidden" });
          element.css("height", element[0].scrollHeight + "px");
        });
      }
    };
  }
})();
