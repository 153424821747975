(function() {
  "use strict";

  angular.module("marketPlace.listings").controller("ListingReListController", listingReListController);

  listingReListController.$inject = [
    "$log",
    "$scope",
    "$uibModalInstance",
    "user",
    "listing",
    "Item",
    "toastr",
    "Payment"
  ];

  function listingReListController($log, $scope, $uibModalInstance, user, listing, Item, toastr, Payment) {
    const vm = this;
    vm.user = user;
    vm.loading = true;
    vm.listing = listing;
    vm.paymentRequired = false;
    vm.paymentAmounts = [];
    vm.saving = false;

    vm.cancel = cancel;
    vm.reList = reList;
    vm.saveWithPayment = saveWithPayment;
    vm.onPaymentError = onPaymentError;

    activate();

    function activate() {
      $log.debug("ListingReListController", vm.listing);
      vm.categories = vm.listing.categories;
      Payment.getPrice(
        {
          niche: vm.categories.niche.id,
          section: vm.categories.section.id,
          gallery: vm.categories.gallery.id
        },
        function(result) {
          $log.debug("Price", result);
          vm.listingData = result;
          vm.price = result.amount;
          if (vm.price > 0) {
            vm.paymentRequired = true;
            vm.paymentAmounts = [
              {
                amount: vm.price,
                name: "Re-listing: " + vm.listing.name,
                feesIncluded: true
              }
            ];
          }
          vm.loading = false;
        }
      );
    }

    function reList() {
      vm.saving = true; // triggers payment directive save
      if (!vm.paymentRequired) {
        Item.relist({ id: vm.listing.id }, { payment: {} }, function() {
          close("Item has been re-listed");
        });
      }
    }

    function close(successText?: string) {
      $scope.$broadcast("show-errors-reset");
      if (successText) {
        $uibModalInstance.result.then(function() {
          toastr.success(successText);
        });
      }
      $uibModalInstance.close(true);
    }

    function cancel() {
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.dismiss("cancel");
    }

    function saveWithPayment(payment) {
      $log.debug("saveWithPayment", payment);
      Item.relist(
        { id: vm.listing.id },
        { payment: payment },
        function() {
          close();
        },
        function(error) {
          $log.debug(error);
          onPaymentError("Re-list failed. Please contact support to re-list this item.");
        }
      );
    }

    function onPaymentError(error) {
      toastr.error(error);
      vm.saving = false;
    }
  }
})();
