(function() {
  "use strict";

  angular.module("marketPlace.payments").controller("PaymentController", paymentController);

  paymentController.$inject = ["$log", "$scope", "toastr", "Payment", "braintreeClient", "braintreeHostedFields"];

  function paymentController($log, $scope, toastr, Payment, braintreeClient, braintreeHostedFields) {
    const vm = this;
    vm.submit = onSubmit;
    vm.cancel = onCancel;
    vm.loadingHostedFields = true;
    vm.processing = false;

    let hostedFieldsInstance;

    activate();

    function activate() {
      $log.debug("PaymentController", vm, $scope);

      Payment.getToken(function(clientToken) {
        braintreeClient.create(
          {
            authorization: clientToken.token
          },
          function(clientErr, clientInstance) {
            if (clientErr) {
              // Handle error in client creation
              toastr.error(clientErr);
              return;
            }

            braintreeHostedFields.create(
              {
                client: clientInstance,
                styles: {
                  input: {
                    "font-size": "14pt"
                  },
                  "input.invalid": {
                    color: "red"
                  },
                  "input.valid": {
                    color: "green"
                  }
                },
                fields: {
                  number: {
                    selector: "#card-number",
                    placeholder: "4111 1111 1111 1111"
                  },
                  cvv: {
                    selector: "#cvv",
                    placeholder: "123"
                  },
                  expirationDate: {
                    selector: "#expiration-date",
                    placeholder: "10/2019"
                  }
                }
              },
              function(hostedFieldsErr, instance) {
                if (hostedFieldsErr) {
                  // Handle error in Hosted Fields creation
                  toastr.error(hostedFieldsErr);
                  return;
                }

                hostedFieldsInstance = instance;

                // Enable button and trigger digest
                $scope.$apply(function() {
                  vm.loadingHostedFields = false;
                });
              }
            );
          }
        );
      });
    }

    function onSubmit() {
      $log.debug("Payment form submitted");
      vm.processing = true;
      hostedFieldsInstance.tokenize(function(tokenizeErr, payload) {
        $log.debug(payload);
        if (tokenizeErr) {
          // Handle error in Hosted Fields tokenization
          $log.debug(tokenizeErr);
          toastr.error(tokenizeErr.message);
        } else {
          $scope.$emit("payments:nonce-received", { nonce: payload.nonce });
        }

        vm.processing = false;
      });
    }

    function onCancel() {
      $scope.$emit("payments:payment-cancelled");
    }
  }
})();
