(function() {
  "use strict";

  angular.module("marketPlace.dashboard").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard", {
      url: "^/account",
      abstract: true,
      data: {
        requiresLogin: true
      },
      views: {
        "app@": { template: require("./templates/base.html") },
        "header@home.dashboard": {
          template: require("../home/templates/header.html"),
          controller: "HeaderController",
          controllerAs: "vm"
        },
        "dashboard@home.dashboard": {
          template: require("./templates/dashboard.html"),
          controller: "DashboardController",
          controllerAs: "vm"
        }
      }
    });
  }
})();
