(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("gameSection", gameSection);

  function gameSection() {
    return {
      template: require("./templates/game-section.html"),
      scope: {
        item: "=",
        user: "="
      },
      controller: gameController,
      controllerAs: "vm",
      bindToController: true
    };
  }

  gameController.$inject = ["$log", "Game", "toastr", "moment", "CacheFactory"];

  function gameController($log, Game, toastr, moment, CacheFactory) {
    const vm = this;
    let initialEntry;
    let entryCache;

    vm.loadingGame = true;
    vm.errors = {};
    vm.gameStarted = false;
    vm.gameEnded = false;
    vm.gamePlayed = false;

    vm.addEntry = addEntry;
    vm.isDisabled = isDisabled;
    vm.showConfirmation = showConfirmation;
    vm.cancelConfirmation = cancelConfirmation;

    vm.$onInit = function() {
      $log.debug("gameController");

      initialEntry = { item: vm.item.id };

      entryCache = CacheFactory.get("entryCache");
      if (!entryCache) {
        entryCache = CacheFactory("entryCache");
      }
      loadGame();
    };

    function loadGame() {
      Game.get(vm.item.id)
        .then(function(response) {
          $log.debug(response);

          if (response.hasOwnProperty("game") && response.game !== null) {
            vm.gameItem = response;
            vm.nextGame = response.next_game;
            vm.game = response.game;

            initialEntry.game = vm.game.id;
            if (angular.isDefined(vm.user) && vm.user.hasOwnProperty("id")) {
              initialEntry.user = vm.user.id;
            }
            vm.entry = angular.copy(initialEntry);

            if (response.entry !== null) {
              vm.gamePlayed = true;
              vm.isCorrect = response.entry.is_correct;
              return;
            }

            if (response.entry === null) {
              const entry = entryCache.get(vm.item.id);
              if (angular.isDefined(entry)) {
                if (angular.isDefined(vm.user) && !entry.hasOwnProperty("user")) {
                  entryCache.remove(vm.item.id);
                  vm.$onInit();
                  return;
                }
                vm.isCorrect = entry.is_correct;
                vm.gamePlayed = true;
                return;
              }
            }

            vm.gameLabel = vm.gameItem.question ? vm.gameItem.question : vm.game.game_type.answer_field_label;
            let startDate = vm.gameItem.start_date ? vm.gameItem.start_date : vm.game.start_date;
            let endDate = vm.gameItem.end_date ? vm.gameItem.end_date : vm.game.end_date;

            if (startDate !== null) {
              startDate = new Date(startDate);
              vm.gameStarted = startDate <= new Date();

              if (!vm.gameStarted) {
                const start = moment(startDate);
                vm.gameStartFromNow = start.fromNow();
                vm.gameStartStr = start.format("h:mm a MMM Do");
              }
            } else {
              vm.gameStarted = true;
            }

            if (endDate !== null) {
              endDate = new Date(endDate);
              vm.gameEnded = endDate <= new Date();
            }

            if (vm.game.game_type.expiry_condition) {
              if (
                vm.item.hasOwnProperty(vm.game.game_type.expiry_condition) &&
                vm.item[vm.game.game_type.expiry_condition]
              ) {
                vm.gameEnded = true;
              }
            }
          }
        }, angular.noop)
        .finally(function() {
          vm.loadingGame = false;
        });
    }

    function addEntry() {
      if (!vm.entry || !vm.entry.answer || vm.entry.answer.length === 0) {
        return;
      }

      if (vm.form.$invalid) {
        return;
      }

      vm.addingEntry = true;

      // Make vm.entry.answer an object with the proper key
      const answer = vm.entry.answer;
      vm.entry.answer = {};
      vm.entry.answer[vm.game.game_type.answer_field_name] = answer;

      Game.entry(vm.entry)
        .then(
          function(response) {
            $log.debug(response);
            const entry = entryCache.put(vm.item.id, response.data);
            vm.isCorrect = entry.result.is_correct;
            vm.correctAnswer = entry.result.answer;
            vm.gamePlayed = true;
          },
          function(response) {
            if (response.data && response.status !== 500) {
              angular.forEach(response.data, function(errors, field) {
                $log.debug(field + " : " + errors);
                if (field === "non_field_errors" && errors.includes("Only one entry per person")) {
                  vm.gamePlayed = true;
                }

                if (angular.isArray(errors)) {
                  errors = errors.join(", ");
                }
                vm.errors.non_field_errors = errors;
              });
            } else {
              $log.error(response.status + ": " + response.statusText);
              toastr.error("Sorry there was a problem playing the game");
            }
          }
        )
        .finally(function() {
          vm.addingEntry = false;
        });

      vm.entry = angular.copy(initialEntry);
      vm.form.$setPristine();
      vm.form.$setUntouched();
    }

    function isDisabled() {
      return !vm.entry || !vm.entry.answer || vm.entry.answer.length === 0;
    }

    function showConfirmation() {
      if (!isDisabled()) {
        vm.confirmVisible = true;
      }
    }

    function cancelConfirmation() {
      vm.confirmVisible = false;
      delete vm.entry;
    }
  }
})();
