(function() {
  "use strict";

  angular.module("marketPlace.content").component("contactUs", {
    template: require("./templates/contact-us/contact-us.html"),
    bindings: {
      user: "<",
      $transition$: "<"
    },
    controller: contactController
  });

  contactController.$inject = ["$scope", "$log", "$state", "Request", "toastr", "flash", "SITE_NAME", "RequestTypes"];

  function contactController($scope, $log, $state, Request, toastr, flash, SITE_NAME, RequestTypes) {
    const $ctrl = this;

    $ctrl.submit = submit;

    $ctrl.$onInit = function() {
      $ctrl.siteName = SITE_NAME;
      RequestTypes.query(function(resp) {
        $log.debug("Got requestTypes:", resp);
        $ctrl.requestTypes = resp;
        $ctrl.request = initializeRequest();
      });
    };

    function initializeRequest() {
      const stateParams = $ctrl.$transition$.params();
      const request = { request_type: $ctrl.requestTypes[0].id };

      if ($ctrl.user) {
        request["email"] = $ctrl.user.email;
      }

      if (stateParams.subject) {
        request["subject"] = stateParams.subject;
      }

      if (stateParams.requestType) {
        for (let i = $ctrl.requestTypes.length - 1; i >= 0; i--) {
          if ($ctrl.requestTypes[i].reason.toLowerCase().indexOf(stateParams.requestType) !== -1) {
            request.request_type = $ctrl.requestTypes[i].id;
            break;
          }
        }
      }
      $log.debug("Initialized request:", request);
      return request;
    }

    function submit() {
      $scope.$broadcast("show-errors-check-validity");

      if ($ctrl.form.$invalid) {
        return;
      }

      const newRequest = new Request($ctrl.request);
      newRequest.$save(
        function() {
          flash.success("Thank you we have received your request");
          $state.go("home.gallery");
        },
        function(resp) {
          if (resp.data) {
            angular.forEach(resp.data, function(errors, field) {
              $log.debug(field + " : " + errors);
              $ctrl.errors.non_field_errors = errors.join(", ");
            });
            $scope.$broadcast("show-errors-check-validity");
          } else {
            toastr.error("Sorry there was a problem submitting your request");
          }
        }
      );
    }
  }
})();
