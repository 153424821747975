(function() {
  "use strict";
  angular.module("marketPlace.dashboard").controller("BillingController", BillingController);

  BillingController.$inject = [
    "$log",
    "$uibModal",
    "PaymentMethod",
    "Transaction",
    "user",
    "subscription",
    "Features",
    "Payment",
    "toastr"
  ];

  function BillingController(
    $log,
    $uibModal,
    PaymentMethod,
    Transaction,
    user,
    subscription,
    Features,
    Payment,
    toastr
  ) {
    const vm = this;
    vm.user = user;
    vm.subscription = subscription;
    vm.enableBusinessRegistration = Features.businessRegistration;
    vm.openChangePaymentMethod = openChangePaymentMethod;
    vm.getDownloadURL = getDownloadURL;
    vm.downloadUrl = null;
    vm.creatingDownload = false;

    activate();

    function activate() {
      $log.debug("BillingController", vm.user.first_name);

      PaymentMethod.query(
        function(response) {
          vm.paymentMethod = response;
        },
        function() {
          angular.noop();
        }
      );

      Transaction.query(
        function(response) {
          vm.transactions = response.results;
        },
        function() {
          angular.noop();
        }
      );

      Payment.products(
        function(resp) {
          vm.upgradesAvailable = resp.length > 0;
        },
        function() {
          vm.upgradesAvailable = false;
        }
      );
    }

    function openChangePaymentMethod() {
      $uibModal
        .open({
          animation: true,
          template: require("../../payments/templates/change-payment-method-modal.html"),
          controller: "ChangePaymentMethodController",
          controllerAs: "vm",
          size: "md",
          resolve: {
            paymentMethod: function() {
              return vm.paymentMethod;
            }
          }
        })
        .result.catch(angular.noop);
    }

    function getDownloadURL() {
      vm.creatingDownload = true;
      vm.downloadUrl = null;
      Transaction.download(
        function(data) {
          vm.downloadUrl = data.url;
          vm.creatingDownload = false;
        },
        function(error) {
          vm.creatingDownload = false;
          toastr.error(error.data.detail);
        }
      );
    }
  }
})();
