import { PreviousService } from "../../auth/previous.service";

(function() {
  "use strict";

  angular.module("marketPlace.dashboard").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.billing", {
      url: "/subscription",
      template: require("./templates/billing.html"),
      controller: "BillingController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      },
      resolve: {
        subscription: loadSubscription
      }
    });

    $stateProvider.state("home.dashboard.billing.upgrade", {
      url: "^/account/upgrade",
      resolve: {
        $transition$: "$transition$"
      },
      onEnter: [
        "PreviousService",
        "$transition$",
        "$uibModal",
        "user",
        "subscription",
        function(PreviousService: PreviousService, $transition$, $uibModal, user, subscription) {
          const modalInstance = $uibModal.open({
            animation: true,
            component: "upgradeSubModal",
            size: "xl",
            windowClass: "upgradeModal",
            resolve: {
              user: function() {
                return user;
              },
              currentSubscription: function() {
                return subscription;
              },
              includeFree: function() {
                return false;
              }
            }
          });
          PreviousService.invokeModalOnEnter(modalInstance, $transition$, false, false, "home.dashboard.billing");
        }
      ]
    });

    $stateProvider.state("home.dashboard.billing.upgrade-business", {
      url: "^/account/business/upgrade",
      resolve: {
        $transition$: "$transition$"
      },
      onEnter: [
        "PreviousService",
        "$transition$",
        "$log",
        "$uibModal",
        "$state",
        "user",
        "subscription",
        function(PreviousService: PreviousService, $transition$, $log, $uibModal, $state, user, subscription) {
          const modalInstance = $uibModal.open({
            animation: true,
            component: "upgradeSubModal",
            size: "lg",
            windowClass: "upgradeModal",
            resolve: {
              user: function() {
                return user;
              },
              currentSubscription: function() {
                return subscription;
              },
              showGroupChoices: function() {
                return true;
              }
            }
          });
          PreviousService.setTransitionInvoked($transition$);

          modalInstance.result.then(
            function() {
              $log.debug("Upgrade modal closed");
              $state.go("home.dashboard.edit-organisation", null, {
                reload: true
              });
            },
            function() {
              $log.debug("Upgrade modal dismissed");
              PreviousService.undoTransition($transition$, null, "home.dashboard.billing");
            }
          );
        }
      ]
    });
  }

  loadSubscription.$inject = ["$q", "Payment", "moment"];
  function loadSubscription($q, Payment, moment) {
    const deferred = $q.defer();
    Payment.subscription(
      function(response) {
        response.next_billing_date = moment(response.next_billing_date).format("MMMM Do, YYYY");
        deferred.resolve(response);
      },
      function() {
        deferred.resolve({
          status: null,
          price: "0",
          billing_frequency: 1
        });
      }
    );
    return deferred.promise;
  }
})();
