import { IHttpService, IHttpResponse, IRequestConfig, IPromise } from "angular";

export interface LotService {
  get: Function;
  placeBid: Function;
  query: Function;
}

class LotServiceImpl implements LotService {
  baseUrl: string;

  static $inject = ["$http", "API_URL"];

  constructor(public $http: IHttpService, API_URL: string) {
    this.baseUrl = API_URL + "/api/auctions/lots/";
  }

  get(id: number): IPromise<IHttpResponse<any>> {
    return this.$http({
      url: this.baseUrl + id + "/",
      method: "GET"
    });
  }

  placeBid(id: number, amount: string, autoBid: boolean): IPromise<IHttpResponse<any>> {
    return this.$http({
      url: this.baseUrl + id + "/bids/",
      method: "POST",
      data: {
        amount: amount,
        auto: autoBid
      }
    });
  }

  query(params: any): IPromise<IHttpResponse<any>> {
    let config: IRequestConfig = {
      url: this.baseUrl,
      method: "GET"
    };
    if (angular.isDefined(params) && params !== null) {
      config.params = params;
    }
    return this.$http(config);
  }
}

angular.module("marketPlace.analytics").service("Lot", LotServiceImpl);
