(function () {
  "use strict";
  angular.module("marketPlace.auth", ["marketPlace.lodash", "marketPlace.preview"]).run([
    "$templateCache",
    function ($templateCache) {
      // Put in cache the files that are ng-include'd in templates
      $templateCache.put(
        "./notifications/templates/auction_closed_successful.html",
        require("./notifications/templates/auction_closed_successful.html")
      );
      $templateCache.put(
        "./notifications/templates/auction_closed_unsuccessful.html",
        require("./notifications/templates/auction_closed_unsuccessful.html")
      );
      $templateCache.put(
        "./notifications/templates/auction_closed_watchlisted.html",
        require("./notifications/templates/auction_closed_watchlisted.html")
      );
      $templateCache.put(
        "./notifications/templates/auction_closing_soon.html",
        require("./notifications/templates/auction_closing_soon.html")
      );
      $templateCache.put(
        "./notifications/templates/auction_outbid.html",
        require("./notifications/templates/auction_outbid.html")
      );
      $templateCache.put(
        "./notifications/templates/comment_approved.html",
        require("./notifications/templates/comment_approved.html")
      );
      $templateCache.put(
        "./notifications/templates/comment_pending.html",
        require("./notifications/templates/comment_approved.html")
      );
      $templateCache.put(
        "./notifications/templates/game_entry_scored.html",
        require("./notifications/templates/game_entry_scored.html")
      );
      $templateCache.put(
        "./notifications/templates/game_finished.html",
        require("./notifications/templates/game_finished.html")
      );
      $templateCache.put(
        "./notifications/templates/game_new_item.html",
        require("./notifications/templates/game_new_item.html")
      );
      $templateCache.put(
        "./notifications/templates/game_result_updated.html",
        require("./notifications/templates/game_result_updated.html")
      );
      $templateCache.put(
        "./notifications/templates/item_comment.html",
        require("./notifications/templates/item_comment.html")
      );
      $templateCache.put(
        "./notifications/templates/item_liked.html",
        require("./notifications/templates/item_liked.html")
      );
      $templateCache.put(
        "./notifications/templates/pledge_created.html",
        require("./notifications/templates/comment_approved.html")
      );
    },
  ]);
})();
