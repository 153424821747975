import { Transition } from "@uirouter/angularjs";
import { PreviousService } from "../auth/previous.service";

(function () {
  "use strict";
  angular.module("marketPlace.cart").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider.state("home.cart", {
      url: "/cart",
      data: {},
      params: {
        itemName: null,
        productUrl: null,
        quantity: null,
        options: null,
      },
      resolve: {
        user: getUser,
        groups: getGroups,
        addToCart: addToCart
      },
      onEnter: createCartModal,
    });
  }

  createCartModal.$inject = [
    "$uibModal",
    "user",
    "groups",
    "addToCart",
    "PreviousService",
    "$transition$",
  ];
  function createCartModal(
    $uibModal,
    user,
    groups,
    addToCart,
    PreviousService: PreviousService,
    $transition$: Transition,
  ) {
    const modalInstance = $uibModal.open({
      animation: true,
      template: require("./cart-modal.html"),
      controller: "CartController",
      controllerAs: "vm",
      size: "md",
      resolve: {
        user: function () {
          return user;
        },
        groups: function () {
          return groups;
        },
      },
    });

    PreviousService.invokeModalOnEnter(modalInstance, $transition$, true, true);

    return false;
  }

  getUser.$inject = ["AuthService"];
  function getUser(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getCurrentUser();
    }
    return {};
  }

  getGroups.$inject = ["AuthService"];
  function getGroups(AuthService) {
    return AuthService.getGroups();
  }

  addToCart.$inject = ["Cart", "$rootScope", "$log", "$window", "toastr", "$transition$", "DATA_EVENTS", "$q"];
  function addToCart(Cart, $rootScope, $log, $window, toastr, $transition$, DATA_EVENTS, $q) {
    const params = $transition$.params();
    const quantity = params.quantity || 1
    if (!(params.itemName && params.productUrl)) {
      return $q.resolve();
    }
    var deferred = $q.defer();
    Cart.add(params.itemName, params.productUrl, quantity, params.options).then(
      function (resp) {
        // check if response is free download
        if (resp.status === 202 && resp.data.hasOwnProperty("url") && resp.data.url) {
          // open the image in a new tab and close the modal
          $log.debug(resp);
          $window.open(resp.data.url, "_blank", "noreferrer");
          deferred.reject();
          return;
        }
        $rootScope.$broadcast(DATA_EVENTS.cartUpdated);
        toastr.success("Added " + params.itemName + " to cart.");
        deferred.resolve();
      },
      function (error) {
        $log.debug(error);
        if (error.data.reason === "Unavailable") {
          toastr.error("Item currently unavailable");
        } else {
          toastr.error("Unable to add this item to your cart at this time. " + error.data.reason);
        }
        deferred.reject();
      }
    );
    return deferred.promise;
  }
})();
