(function() {
  "use strict";

  angular.module("marketPlace.comments").factory("ItemComment", itemComment);

  itemComment.$inject = ["$http", "API_URL"];

  function itemComment($http, API_URL) {
    const urlPrefix = "/api/comments/items/";

    return {
      get: get,
      create: create
    };

    function getUrl(id) {
      return new URL(urlPrefix + id + "/comment", API_URL);
    }

    function create(id, comment) {
      const url = getUrl(id);
      return $http.post(url.href, comment);
    }

    function get(id, next) {
      let url;
      const nextUrl = new URL(next);
      const cursor = nextUrl.searchParams.get("cursor");

      if (nextUrl.pathname.startsWith(urlPrefix) && cursor) {
        url = nextUrl;
      } else {
        url = getUrl(id);
        if (cursor) {
          url.searchParams.set("cursor", cursor);
        }
      }
      return $http.get(url.href);
    }
  }
})();
