/**
 * @desc directive that is used where a server error may be applied to a form element which will need clearing once the
 *       value is changed
 * @example <input type="email" id="email" name="email" required server-error>
 */

(function() {
  "use strict";

  angular.module("marketPlace.auth").directive("serverError", serverError);

  function serverError() {
    return {
      restrict: "A",
      require: "?ngModel",
      link: function(scope, element, attrs, ctrl) {
        element.on("change", function() {
          scope.$evalAsync(function() {
            ctrl.$setValidity("server", true);
          });
        });
      }
    };
  }
})();
