(function() {
  "use strict";

  angular.module("marketPlace.profile").controller("DashboardProfileController", DashboardProfileController);

  DashboardProfileController.$inject = [
    "$scope",
    "$state",
    "$log",
    "$filter",
    "$timeout",
    "$anchorScroll",
    "Categories",
    "user",
    "Upload",
    "AppConfig",
    "CountryName",
    "_",
    "Address",
    "toastr",
    "SITE_NAME",
    "$uibModal",
    "AuthService"
  ];

  function DashboardProfileController(
    $scope,
    $state,
    $log,
    $filter,
    $timeout,
    $anchorScroll,
    Categories,
    user,
    Upload,
    AppConfig,
    CountryName,
    _,
    Address,
    toastr,
    SITE_NAME,
    $uibModal,
    AuthService
  ) {
    const vm = this;

    vm.saving = false;
    vm.titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
    vm.errors = {};
    vm.photoState = "ready";
    vm.photoUploadProgressPercentage = 0;
    vm.siteName = SITE_NAME;
    vm.isLoaded = false;
    vm.countries = [];

    // pre-resolved dependencies
    vm.user = user;
    vm.display_nameUnset = false;
    if (angular.isDefined(vm.user.display_name_display)) {
      vm.user.display_name = vm.user.display_name_display;
      vm.display_nameUnset = true;
    }

    // functions
    vm.save = save;
    vm.getCategories = getCategories;
    vm.display_nameChanged = display_nameChanged;
    vm.uploadCropped = uploadCropped;
    vm.cancelCrop = cancelCrop;
    vm.onCropLoadBegin = onCropLoadBegin;
    vm.onCropLoadDone = onCropLoadDone;
    vm.onCropLoadError = onCropLoadError;
    vm.ngfBeforeChange = ngfBeforeChange;
    vm.isPermRequired = isPermRequired;
    vm.openChangePassword = openChangePassword;

    // forms
    vm.profileForm = {};
    vm.profileForm.form = {};

    activate();

    function activate() {
      CountryName.present_day().then(function(countries) {
        vm.countries = countries;
        updateLoading();
      });
    }

    function updateLoading() {
      if (vm.countries) {
        if (!vm.user.display_name && vm.user.first_name) {
          vm.user.display_name = vm.user.first_name.replace(/\s+/g, "").toLowerCase();
        }

        padCategories();
        vm.copyUserAddress = !Address.hasPostalAddress(vm.user);
        vm.isLoaded = true;
      } else {
        vm.isLoaded = false;
      }
    }

    function onCropLoadBegin() {
      $log.debug("img crop load begin");
    }

    function onCropLoadDone() {
      $log.debug("img crop load done");
      vm.photoState = "resizing";
    }

    function onCropLoadError(e) {
      $log.debug("img crop load error", e);
      vm.photoState = "ready";
    }

    function ngfBeforeChange() {
      $log.debug("ngf before change");
      $timeout(function() {
        vm.photoState = "loading";
      });
    }

    function display_nameChanged() {
      $log.debug("USERNAME CHANGED BY USER");
      vm.display_nameUnset = false;
    }

    $scope.$watch("vm.user.first_name", function() {
      if (vm.display_nameUnset && vm.user.first_name) {
        vm.user.display_name = (vm.user.first_name + vm.user.last_name).replace(/\s+/g, "").toLowerCase();
      }
    });

    function save(form) {
      $log.debug("saving profile", vm.user.physical_address_country);

      $scope.$broadcast("show-errors-check-validity");

      if (form.$invalid) {
        if (form.photo && form.photo.$invalid) {
          $log.debug("PHOTO INVALID");
          form.photo.$dirty = true;
        }

        if (form.hasOwnProperty("address")) {
          if (!Address.hasPostalAddress(vm.user) && !form.address.$invalid && !Address.hasAddress(vm.user)) {
            form.address.$error.required = true;
            form.address.$invalid = true;
          }
        }
        $log.debug("form is invalid");
        $anchorScroll();
        return;
      } else {
        vm.errors = {};
      }

      vm.saving = true;

      saveUser()
        .then(
          function() {
            form.$setPristine();
            $scope.$broadcast("show-errors-reset");
            toastr.success("Profile updated");
            AuthService.clearCache();
            $state.go("home.gallery", {}, { reload: true });
          },
          function(resp) {
            $log.debug("Error saving tab");

            if (resp.data && resp.status !== 500) {
              angular.forEach(resp.data, function(errors, field) {
                $log.debug(field + " : " + errors);
                try {
                  form[field].$setValidity("server", false);
                  if (angular.isObject(errors)) {
                    angular.forEach(errors, function(subErrors, field) {
                      vm.errors[field] = subErrors.join(", ");
                    });
                  } else {
                    vm.errors[field] = errors.join(", ");
                  }
                } catch (e) {
                  $log.error(resp.status + ": " + resp.statusText);
                  if (angular.isArray(errors)) {
                    errors = errors.join(", ");
                  }
                  vm.errors.non_field_errors = errors;
                }
              });
            } else {
              if (vm.user.associations.length > 0 && !vm.user.associations[vm.user.associations.length - 1].name) {
                vm.user.associations.pop();
              }

              if (vm.user.clubs.length > 0 && !vm.user.clubs[vm.user.clubs.length - 1].name) {
                vm.user.clubs.pop();
              }

              $log.error(resp.status + ": " + resp.statusText);
              vm.errors.non_field_errors = "Sorry there was a problem saving your profile, please try again";
            }
            $scope.$broadcast("show-errors-check-validity");
          }
        )
        .finally(function() {
          padCategories();
          vm.saving = false;
        });
    }

    function saveUser() {
      $log.debug("saving user");

      vm.user.categories = $filter("filter")(vm.user.categories, "!", true);
      vm.user.associations = _.compact(vm.user.associations);
      vm.user.clubs = _.compact(vm.user.clubs);

      return vm.user.$update();
    }

    function getCategories(val) {
      return Categories.query({ q: val, all: 1 }).$promise;
    }

    function addItem(obj) {
      obj.categories.push({ name: "" });
    }

    function padCategories(obj?) {
      if (angular.isUndefined(obj)) {
        if (angular.isDefined(vm.user)) {
          obj = vm.user;
        }
      }
      $log.debug("padCategories", obj);

      // pad categories so there is always one showing
      if (angular.isDefined(obj)) {
        if (angular.isUndefined(obj.categories)) {
          obj.categories = [];
        }

        if (!obj.categories.length) {
          addItem(obj);
        }
      }
    }

    function randKey() {
      return Math.random()
        .toString(36)
        .substr(2, 5);
    }

    function buildS3Key(prefix, id, file_name) {
      const key = "images/" + prefix + "_" + randKey() + "_" + id,
        url = AppConfig.S3Config.s3url + encodeURIComponent(key + "_" + file_name),
        extra = url.length - 100;
      if (extra > 0) {
        const extIdx = file_name.lastIndexOf("."),
          ext = extIdx !== null ? file_name.substring(extIdx) : "",
          name = extIdx !== null ? file_name.substring(0, extIdx) : file_name;
        return key + "_" + name.substring(extra) + ext;
      }
      return key + "_" + file_name;
    }

    function uploadCropped() {
      const file = vm.photo,
        s3key = buildS3Key("profile", vm.user.id, file.name);
      $log.debug("uploading", s3key);
      if (file && vm.uploadFile) {
        file.upload = Upload.upload({
          url: AppConfig.S3Config.s3url,
          method: "POST",
          data: {
            key: s3key,
            AWSAccessKeyId: AppConfig.S3Config.AWSAccessKeyId,
            acl: AppConfig.S3Config.acl,
            policy: AppConfig.S3Config.policy,
            signature: AppConfig.S3Config.signature,
            "Content-Type": file.type != "" ? file.type : "application/octet-stream",
            "Cache-Control": "max-age=315360000",
            Expires: "Thu, 31 Dec 2037 23:55:55 GMT",
            filename: "profile_" + file.name,
            file: Upload.dataUrltoBlob(vm.uploadFile, "profile_" + file.name)
          },
          skipAuthorization: true
        });

        file.upload
          .then(
            function(response) {
              const uploadedPhoto = AppConfig.S3Config.s3url + encodeURIComponent(response.config.data.key);
              $log.debug("uploaded", uploadedPhoto, response);

              // Update just the users photo
              vm.user.photo = uploadedPhoto;
              vm.user.$patch(["photo"]);
            },
            function(response) {
              $log.debug("ERROR", response);
            },
            function(evt) {
              vm.photoUploadProgressPercentage = Math.min(100, Math.floor((100.0 * evt.loaded) / evt.total));
            }
          )
          .finally(function() {
            vm.photo = null;
            vm.photoState = "ready";
            vm.photoUploadProgressPercentage = 0;
          });
      }
    }

    function cancelCrop() {
      vm.photo = null;
      vm.photoState = "ready";
    }

    function isPermRequired(perm) {
      for (let g = 0; g < vm.user.groups.length; g++) {
        if (vm.user.groups[g].permissions.indexOf(perm) >= 0) {
          return true;
        }
      }

      return false;
    }

    function openChangePassword() {
      $uibModal.open({
        animation: true,
        template: require("../auth/change-password/changePassword.html"),
        controller: "ChangePasswordModalController",
        controllerAs: "vm",
        size: "sm"
      });
    }
  }
})();
