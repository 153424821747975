(function() {
  "use strict";

  angular.module("marketPlace.auction").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.my-auction-lots", {
      url: "/auctions",
      component: "auctionDashboard",
      data: {
        requiresLogin: true
      }
    });
  }
})();
