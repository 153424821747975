import { PreviousService } from "../previous.service";

(function() {
  "use strict";
  angular.module("marketPlace.auth").config(config);

  config.$inject = ["$stateProvider"];
  function config($stateProvider) {
    $stateProvider
      .state("reset-password", {
        url: "^/forgot-password",
        data: {
          isAuthState: true,
          requiresLogin: false
        },
        resolve: {
          $transition$: "$transition$"
        },
        onEnter: createResetPasswordModal,
        params: {
          email: ""
        }
      })
      .state("reset-password-confirm", {
        url: "^/auth/reset-password/confirm/:uid/:token",
        data: {
          isAuthState: true,
          requiresLogin: false
        },
        onEnter: createResetPasswordConfirmModal,
        resolve: {
          $transition$: "$transition$"
        }
      });
  }

  createResetPasswordModal.$inject = ["PreviousService", "$uibModal", "$transition$"];
  function createResetPasswordModal(PreviousService: PreviousService, $uibModal, $transition$) {
    const modalInstance = $uibModal.open({
      animation: true,
      template: require("./reset-password-modal.html"),
      controller: "ResetPasswordController",
      controllerAs: "vm",
      size: "mdsm",
      resolve: {
        $transition$: function() {
          return $transition$;
        }
      }
    });
    PreviousService.invokeModalOnEnter(modalInstance, $transition$, true);
  }

  createResetPasswordConfirmModal.$inject = ["$state", "$uibModal", "$transition$"];
  function createResetPasswordConfirmModal($state, $uibModal, $transition$) {
    $uibModal
      .open({
        animation: true,
        template: require("./password-reset-confirm-modal.html"),
        controller: "ResetPasswordConfirmController",
        controllerAs: "vm",
        size: "mdsm",
        resolve: {
          $transition$: function() {
            return $transition$;
          }
        }
      })
      .result.finally(function() {
        $state.go("home.gallery");
      });
  }
})();
