(function () {
  "use strict";
  angular.module("marketPlace.auth").controller("HeaderController", HeaderController);

  HeaderController.$inject = [
    "$state",
    "$log",
    "$scope",
    "$timeout",
    "$window",
    "$aside",
    "AuthService",
    "schedules",
    "user",
    "organisations",
    "SITE_NAME",
    "niches",
    "searches",
    "_",
    "DATA_EVENTS",
    "GalleryData",
    "$anchorScroll",
    "AppConfig",
    "Features",
    "Thumbnail",
    "Categories",
    "Searches"
  ];

  function HeaderController(
    $state,
    $log,
    $scope,
    $timeout,
    $window,
    $aside,
    AuthService,
    schedules,
    user,
    organisations,
    SITE_NAME,
    niches,
    searches,
    _,
    DATA_EVENTS,
    GalleryData,
    $anchorScroll,
    AppConfig,
    Features,
    Thumbnail,
    Categories,
    Searches
  ) {
    const vm = this;
    vm.user = user;
    vm.organisation = organisations.length > 0 ? organisations[0] : null;
    vm.siteName = SITE_NAME;
    vm.sitenameInNavbar = AppConfig.sitenameInNavbar;
    vm.enableShoppingCart = Features.shoppingCart;
    vm.watchlistEnabled = AppConfig.watchlistEnabled;
    vm.searchEnabled = AppConfig.searchEnabled;
    vm.schedules = schedules;
    vm.niches = niches;
    vm.searches = searches;

    // email activation
    vm.sending = false;
    vm.sent = false;

    vm.searchParams = { q: "" };
    vm.asideInstance = null;
    vm.advancedSearchInstance = false;

    vm.cart = { itemsCount: 0 };
    vm.cartTemplate = "cart.html";
    vm.hasAlternateLogo = false;

    vm.search = search;
    vm.toggleAdvancedSearch = toggleAdvancedSearch;
    vm.openMenu = openMenu;
    vm.getDefaultUserLogo = getDefaultUserLogo;
    vm.resendActivation = resendActivation;
    vm.changeMode = changeMode;
    vm.goToTop = goToTop;
    vm.openCart = openCart;
    vm.showMobileAdvancedSearch = showMobileAdvancedSearch;
    vm.getCategories = getCategories;
    vm.openWatchlist = openWatchlist;

    activate();

    function activate() {
      $log.debug("HeaderController", AppConfig.sitenameInNavbar, vm.sitenameInNavbar);
      $log.debug("User", vm.user);
      $log.debug("Shopping Cart enabled:", vm.enableShoppingCart);

      if (AppConfig.S3Config.s3url && AppConfig.customLogo) {
        vm.customLogo = AppConfig.S3Config.s3url + AppConfig.customLogo;
        $log.debug("customLogo: ", vm.customLogo);
      }

      if (vm.user && !vm.user.photoThumb) {
        if (vm.user.photo) {
          if (vm.user.photo.startsWith("http://graph.facebook.com/")) {
            vm.user.photoThumb = vm.user.photo;
          } else {
            vm.user.photoThumb = Thumbnail.getThumbnailURI(vm.user.photo, "-89x89");
          }
        } else {
          vm.user.photoThumb = vm.getDefaultUserLogo();
        }
      }

      vm.mode = GalleryData.get("mode");

      setWatchlist(vm.searches);
    }

    function setWatchlist(searches) {
      if (
        angular.isDefined(searches) && searches !== null && searches.hasOwnProperty("results") &&
        angular.isDefined(searches.results) &&
        searches.results !== null
      ) {
        // eslint-disable-next-line angular/function-type
        const watchlists = _.filter(searches.results, function (o) {
          return o.name === "watchlist";
        });
        vm.watchlist = _.pick(watchlists[0], ["items"]);
      }
    }

    function changeMode(mode: number) {
      GalleryData.set("mode", mode);
    }

    function goToTop() {
      $anchorScroll("app");
    }

    GalleryData.subscribe($scope, function (event, param) {
      // Handle notification
      if (param === "mode") {
        vm.mode = GalleryData.get("mode");
      }
    });

    $scope.$on(DATA_EVENTS.galleryParamsChange, function (event, toParams) {
      if (vm.advancedSearchInstance) {
        vm.advancedSearchInstance.close();
      }

      if (vm.asideInstance) {
        vm.asideInstance.close();
      }

      vm.searchParams.q = toParams.q;
    });

    $scope.$on("set-gallery-header", function (event, data) {
      if (data.galleryHeader) {
        if (data.galleryHeader.hasOwnProperty("alternate_logo") && data.galleryHeader.alternate_logo) {
          vm.hasAlternateLogo = true;
          return;
        }
      }
      vm.hasAlternateLogo = false;
    });

    $scope.$on(DATA_EVENTS.userSearchesChanged, function() {
      $log.debug("Header USER SEARCHES CHANGED");
      loadSearches();
    });

    function loadSearches() {
      $log.debug("Header loading searches");
      Searches.get(function(searches) {
        vm.search = searches;
        setWatchlist(searches);
      });
    }

    function openMenu(size = "sm", placement = "right") {
      $log.debug("ASIDE:", placement);

      vm.asideInstance = $aside.open({
        template: require("./templates/menu.html"),
        controller: "MenuController",
        controllerAs: "vm",
        placement: placement,
        size: size,
        scope: $scope,
        resolve: {
          user: function () {
            return vm.user;
          },
          searches: function () {
            return vm.searches;
          },
        },
      });
      vm.asideInstance.result.catch(angular.noop);
    }

    function openCart() {
      vm.showCart = true;
    }

    function getDefaultUserLogo() {
      if (vm.user && vm.user.$resolved && vm.user.groups.length > 0) {
        return vm.user.groups[0].logo || "/assets/images/user.jpg";
      }
    }

    function focusSearch() {
      $timeout(function () {
        const element = $window.document.getElementById("search-main-input");
        if (element) {
          element.focus();
        }
      }, 100);
    }

    function search() {
      const params = {
        q: vm.searchParams.q,
        id: undefined,
        prompt: true,
      };

      $state.go("home.gallery", params, { inherit: false, reload: true });
      focusSearch();
    }

    function toggleAdvancedSearch() {
      $log.debug("TOGGLING ADVANCED SEARCH", vm.niches);

      vm.advancedSearchInstance = $aside.open({
        template: require("./templates/advanced-search.html"),
        controller: "AdvancedSearchController",
        controllerAs: "vm",
        placement: "right",
        size: "advanced-search",
        scope: $scope,
        resolve: {
          user: function () {
            return vm.user;
          },
          niches: function () {
            return vm.niches;
          },
          schedules: function () {
            return vm.schedules;
          },
        },
      });
      vm.advancedSearchInstance.result.catch(angular.noop);
    }

    function resendActivation() {
      $log.debug("RESEND ACTIVATION");
      vm.sending = true;
      AuthService.resendActivation()
        .then(function () {
          vm.sent = true;
        })
        .finally(function () {
          vm.sending = false;
        });
    }

    function showMobileAdvancedSearch() {
      return (vm.searchEnabled && !$state.current.name.startsWith("home.dashboard"));
    }

    function getCategories(val: string) {
      return Categories.query({ q: val }).$promise;
    }

    function openWatchlist() {
      if (!vm.user) {
        $state.go("login", { showLogin: false });
      } else {
        $state.go("home.gallery", vm.watchlist, { inherit: false, reload: true });
      }
    }
  }
})();
