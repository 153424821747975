(function() {
  "use strict";

  angular.module("marketPlace.invites").factory("Invite", Invite);

  Invite.$inject = ["$resource", "API_URL"];

  function Invite($resource, API_URL) {
    return $resource(API_URL + "/api/invites/:token/", { token: "@token" });
  }
})();
