(function() {
  "use strict";

  angular.module("marketPlace.preview").factory("Thumbnail", Thumbnail);

  Thumbnail.$inject = ["$window"];

  function Thumbnail($window) {
    /**
     * Given a uri a suffix constructs and returns a thumbnail uri
     * @constructor
     * @return {string} - thumbnail uri
     * @param uri - the original uri
     * @param suffix - the thumbnail suffix in the form '-SIZExSIZE' e.g -360x360
     * @param extension - the thumbnail extension e.g .png
     * @param returnKey - if true return object with URL and s3 key
     */
    function getThumbnailURI(uri: string, suffix: string, extension = ".png", returnKey = false) {
      const parser = $window.document.createElement("a");
      parser.href = uri;
      const path = decodeURIComponent(parser.pathname.replace(/\+/g, " "));
      const fileName = path.split("/").reverse()[0];
      const fileNameNoExt = fileName.substr(0, fileName.lastIndexOf("."));
      const thumbnailPath = parser.protocol + "//" + parser.host + "/thumbnails/" + encodeURIComponent(fileNameNoExt);

      if (returnKey) {
        return {
          url: thumbnailPath + suffix + extension,
          key: path
        };
      }
      return thumbnailPath + suffix + extension;
    }

    return {
      getThumbnailURI: getThumbnailURI
    };
  }
})();
