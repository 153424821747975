import { IHttpResponse, ILogService } from "angular";
import { IToastrService } from "angular-toastr";

interface ShareControllerBindings {
  itemId: number;
}

interface ShareController extends ShareControllerBindings {
  $onInit(): void;
}

class ShareControllerImpl implements ShareController {
  sites: any;
  itemId: number;

  static $inject = ["$log", "Sites", "Item", "toastr"];
  constructor(public $log: ILogService, public Sites: any, public Item: any, public toastr: IToastrService) {
    this.$log.debug("<ShareController>");
  }

  $onInit(): void {
    this.Sites.query().then(
      function(response: IHttpResponse<any>) {
        this.sites = response.data;
      }.bind(this)
    );
  }

  share = (siteId: number, siteName: string) => {
    this["sharing" + siteId] = true;
    this.Item.share(
      { id: this.itemId, site_id: siteId },
      function(resp: IHttpResponse<any>) {
        this.$log.debug(resp);
        this.toastr.success("Shared to " + siteName + " <" + resp.id + ">");
      }.bind(this),
      function(resp: IHttpResponse<any>) {
        this.$log.debug(resp);
        let errorMsgs = [];
        for (let field in resp.data) {
          errorMsgs.push(resp.data[field].join(","));
        }
        this.toastr.error(resp.statusText + ": " + errorMsgs.join(","));
      }.bind(this)
    ).$promise.finally(
      function() {
        this["sharing" + siteId] = false;
      }.bind(this)
    );
  };
}

angular.module("marketPlace.items").component("itemShare", {
  bindings: {
    itemId: "<"
  },
  template: require("./templates/item-share.html"),
  controller: ShareControllerImpl
});
