(function() {
  "use strict";
  angular.module("marketPlace.gallery").directive("gallerySort", gallerySort);

  gallerySort.$inject = ["$state"];

  function gallerySort($state) {
    return {
      template: require("./templates/sort.directive.html"),
      scope: {
        search: "=",
        galleryState: "=",
        placement: "<"
      },
      link: linkFunc
    };

    function linkFunc(scope) {
      const defaultSort = "-price,-realisation_price,-created_date";
      scope.sort = {};
      scope.sort[defaultSort] = {
        value: defaultSort,
        label: "Best Match",
        active: true
      };
      scope.sort["-price"] = {
        value: "-price",
        label: "Price High to Low",
        active: false
      };
      scope.sort["price"] = {
        value: "price",
        label: "Price Low to High",
        active: false
      };
      scope.sort["-realisation_price"] = {
        value: "-realisation_price",
        label: "Realisation High to Low",
        active: false
      };
      scope.sort["realisation_price"] = {
        value: "realisation_price",
        label: "Realisation Low to High",
        active: false
      };
      scope.sort["-created_date"] = {
        value: "-created_date",
        label: "Recently Listed",
        active: false
      };
      scope.sort["created_date"] = {
        value: "created_date",
        label: "Oldest Listings",
        active: false
      };
      scope.sort["-realisation_date"] = {
        value: "-realisation_date",
        label: "Realisation Date",
        active: false
      };
      scope.sort["-read_count"] = {
        value: "-read_count",
        label: "Most Views",
        active: false
      };
      scope.sort["-like_count"] = {
        value: "-like_count",
        label: "Most Likes",
        active: false
      };
      scope.sort["lot_number"] = {
        value: "lot_number",
        label: "Lot Number Low to High",
        active: false
      };
      scope.sort["-lot_number"] = {
        value: "-lot_number",
        label: "Lot Number High to Low",
        active: false
      };
      scope.sort["date_start"] = {
        value: "date_start",
        label: "Year Low to High",
        active: false
      };
      scope.sort["-date_start"] = {
        value: "-date_start",
        label: "Year High to Low",
        active: false
      };

      if (
        angular.isDefined(scope.search) &&
        scope.search !== null &&
        scope.search.hasOwnProperty("sort") &&
        angular.isDefined(scope.search.sort) &&
        scope.search.sort !== null
      ) {
        if (scope.sort.hasOwnProperty(scope.search.sort)) {
          scope.sort[scope.search.sort].active = true;
          if (scope.search.sort !== defaultSort) {
            scope.sort[defaultSort].active = false;
          }
        }
      }

      scope.getSortSref = function(sort = "") {
        let params = {};
        if (scope.search) {
          params = angular.copy(scope.search);
        }
        params["sort"] = sort;
        delete params["page"];
        delete params["size"];
        return $state.href($state.current.name, params);
      };
    }
  }
})();
