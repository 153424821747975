(function () {
  "use strict";
  angular.module("marketPlace.searches").factory("UserSearchService", UserSearchService);

  UserSearchService.$inject = ["Searches", "EmailSchedules", "$q", "$rootScope", "DATA_EVENTS", "_"];

  function UserSearchService(Searches, EmailSchedules, $q, $rootScope, DATA_EVENTS, _) {
    let searchPromise = null;
    let userSearches = null;
    let schedules = null;

    function clear() {
      searchPromise = null;
      userSearches = null;
      $rootScope.$broadcast(DATA_EVENTS.userSearchesChanged);
    }

    function getSchedules() {
      const deferred = $q.defer();

      if (!schedules) {
        EmailSchedules.get(
          function (data) {
            schedules = data.results;
            deferred.resolve(schedules);
          },
          function (data) {
            schedules = null;
            deferred.reject(data);
          }
        );
      } else {
        deferred.resolve(schedules);
      }
      return deferred.promise;
    }

    function get() {
      if (!searchPromise) {
        const deferred = $q.defer();

        Searches.get(
          function (data) {
            userSearches = _.filter(data.results, function (search) {
              return search.name !== "watchlist";
            });

            for (let i = 0; i < userSearches.length; i++) {
              userSearches[i].params = {
                niche: userSearches[i].niche,
                section: userSearches[i].section,
                gallery: userSearches[i].gallery,
                caption: userSearches[i].caption,
                category: userSearches[i].categories,
                country: userSearches[i].country,
                uid: userSearches[i].uid,
                q: userSearches[i].q,
                city: userSearches[i].city,
                country_code: userSearches[i].country_code,
                sort: userSearches[i].sort || null,
                organisation: userSearches[i].organisation,
                display_type: userSearches[i].display_type,
                for_sale: userSearches[i].for_sale,
                sold: userSearches[i].sold,
                show_and_tell: userSearches[i].show_and_tell,
                items: userSearches[i].items,
              };
            }
            deferred.resolve(userSearches);
          },
          function (data) {
            deferred.reject(data);
          }
        );

        searchPromise = deferred.promise;
      }

      return searchPromise;
    }

    function getDefault() {
      const deferred = $q.defer();
      let defaultSearch = null;

      get()
        .then(function (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].is_default) {
              defaultSearch = data[i];
              break;
            }
          }
          if (defaultSearch) {
            deferred.resolve(defaultSearch);
          } else {
            deferred.reject();
          }
        })
        .catch(function () {
          deferred.reject();
        });

      return deferred.promise;
    }

    function create(name, search) {
      const deferred = $q.defer();
      search = _.pickBy(search, _.identity);

      search.default = null;
      if (search.display_type === "all" || !search.display_type) {
        search.display_type = null;
      }
      if (search.category && !Array.isArray(search.category)) {
        search.category = [search.category];
      }
      const newSearch = new Searches(search);
      newSearch.name = name;
      newSearch.$save(
        function () {
          deferred.resolve();
          clear();
        },
        function () {
          deferred.reject();
        }
      );

      return deferred.promise;
    }

    function update(search) {
      const deferred = $q.defer();
      Searches.update(
        { id: search.id },
        search,
        function () {
          deferred.resolve();
          clear();
        },
        function () {
          deferred.reject();
        }
      );

      return deferred.promise;
    }

    function remove(search) {
      const deferred = $q.defer();
      Searches.delete(
        { id: search.id },
        function () {
          deferred.resolve();
          clear();
        },
        function () {
          deferred.reject();
        }
      );

      return deferred.promise;
    }

    return {
      get: get,
      getDefault: getDefault,
      create: create,
      update: update,
      remove: remove,
      clear: clear,
      getSchedules: getSchedules,
    };
  }
})();
