import { IHttpService, IHttpPromise, ILogService } from "angular";

class DashboardAPI {
  static $inject = ["API_URL", "$log", "$http"];

  constructor(public API_URL: string, public $log: ILogService, public $http: IHttpService) {}

  _request(method: string, url: string, args: { [key: string]: any }) {
    const params = {
      url: this.API_URL + url,
      method: method
    };

    return this.$http(Object.assign(params, args));
  }

  getStats(): IHttpPromise<any> {
    return this._request("GET", "/api/analytics/dashboard/", {
      isArray: false
    });
  }

  getOrders(): IHttpPromise<any> {
    return this._request("GET", "/api/shop/orders/", {});
  }
}

angular.module("marketPlace.dashboard").service("DashboardAPI", DashboardAPI);
