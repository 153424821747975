(function() {
  "use strict";
  angular.module("marketPlace.auth").factory("User", User);

  User.$inject = ["$resource", "$http", "$filter", "API_URL", "moment", "_"];

  function User($resource, $http, $filter, API_URL, moment, _) {
    function convertBirthdayToObj(obj) {
      if (angular.isDefined(obj) && obj !== null && obj.hasOwnProperty("dependants")) {
        for (let i = 0; i < obj.dependants.length; i++) {
          if (obj.dependants[i].birthday) {
            const bday = moment(obj.dependants[i].birthday);
            obj.dependants[i].birthdayObj = {
              day: 1,
              month: bday.get("month"),
              year: bday.get("year")
            };
          }
        }
      }

      return obj;
    }

    function convertObjToBirthday(obj) {
      // remove empty dependants
      if (angular.isDefined(obj) && obj !== null && obj.hasOwnProperty("dependants")) {
        obj.dependants = $filter("filter")(obj.dependants, function(o) {
          return !_.isEmpty(o);
        });

        // convert month and year to date
        for (let i = 0; i < obj.dependants.length; i++) {
          if (obj.dependants[i].birthdayObj) {
            obj.dependants[i].birthday = moment.utc(obj.dependants[i].birthdayObj).format("YYYY-MM-DD");
            delete obj.dependants[i].birthdayObj;
          }
        }
      }
      return obj;
    }

    const userResource = $resource(API_URL + "/api/auth/user/", null, {
      get: {
        method: "GET",
        isArray: false,
        transformResponse: $http.defaults.transformResponse.concat([convertBirthdayToObj])
      },
      update: {
        method: "PUT",
        transformRequest: [convertObjToBirthday].concat($http.defaults.transformRequest),
        transformResponse: $http.defaults.transformResponse.concat([convertBirthdayToObj])
      },
      patch: {
        method: "PATCH",
        transformRequest: [convertObjToBirthday].concat($http.defaults.transformRequest),
        transformResponse: $http.defaults.transformResponse.concat([convertBirthdayToObj])
      }
    });

    angular.extend(userResource.prototype, {
      $patch: function(params, success, error) {
        if (!angular.isArray(params)) {
          params = [params];
        }

        const patch = _.pick(this, params);

        const result = userResource.patch.call(this, null, patch, success, error);
        return result.$promise || result;
      }
    });
    return userResource;
  }
})();
