(function() {
  "use strict";

  angular.module("marketPlace.home").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home", {
      url: "?referral",
      abstract: true,
      data: {
        requiresLogin: false
      },
      views: {
        app: {
          template: require("./templates/home.html"),
          controller: "HomeController",
          controllerAs: "vm"
        },
        "header@home": {
          template: require("./templates/header.html"),
          controller: "HeaderController",
          controllerAs: "vm"
        },
        "advanced-search@home": {
          template: require("./templates/advanced-search.html"),
          controller: "AdvancedSearchController",
          controllerAs: "vm"
        },
        "niche@home": {
          template:
            '<nicheaccordion niches="vm.niches" selected-niche="vm.selectedNiche" selected-section="vm.selectedSection" selected-gallery="vm.selectedGallery" selected-caption="vm.selectedCaption"></nicheaccordion>',
          controller: "MenuController",
          controllerAs: "vm"
        }
      },
      sticky: true,
      deepStateRedirect: { default: "home.gallery" },
      resolve: {
        user: getUser,
        organisations: getOrganisations,
        niches: getNiches,
        schedules: getSchedules,
        searches: getSearches
      }
    });

    $stateProvider.state("home.not-found", {
      url: "^/not-found",
      views: {
        "content@home": {
          template: require("./templates/not-found.html"),
          controller: [
            "CMS_URL",
            "SITE_NAME",
            function(CMS_URL, SITE_NAME) {
              this.CMS_URL = CMS_URL;
              this.siteName = SITE_NAME;
            }
          ],
          controllerAs: "vm"
        }
      },
      data: {
        requiresLogin: false
      }
    });

    $stateProvider.state("home.permission-denied", {
      url: "^/auth/upgrade-required",
      views: {
        "content@home": {
          template: '<permission-denied-pane user="::vm.user" data-on-close="vm.close()"></permission-denied-pane>',
          controller: [
            "$state",
            "user",
            function($state, user) {
              this.close = close;
              this.user = user;
              function close() {
                $state.go("home.gallery");
              }
            }
          ],
          controllerAs: "vm"
        }
      },
      data: {
        requiresLogin: false,
        meta: {
          title: "Upgrade required"
        }
      }
    });
  }

  getUser.$inject = ["AuthService"];
  function getUser(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getCurrentUser();
    }
  }

  getOrganisations.$inject = ["AuthService"];
  function getOrganisations(AuthService) {
    if (AuthService.isAuthenticated()) {
      return AuthService.getOrganisations();
    }
    return [];
  }

  getNiches.$inject = ["Niches"];
  function getNiches(Niches) {
    return Niches.read.query().$promise;
  }

  getSchedules.$inject = ["AuthService", "EmailSchedules"];
  function getSchedules(AuthService, EmailSchedules) {
    if (AuthService.isAuthenticated()) {
      return EmailSchedules.get().$promise;
    }
  }

  getSearches.$inject = ["AuthService", "Searches"];
  function getSearches(AuthService, Searches) {
    if (AuthService.isAuthenticated()) {
      return Searches.get().$promise;
    }
  }
})();
