(function() {
  "use strict";

  angular.module("marketPlace.category").factory("Categories", categorySearch);

  categorySearch.$inject = ["$resource", "API_URL"];

  function categorySearch($resource, API_URL) {
    const r = $resource(API_URL + "/api/category/search/");

    // Remove extra actions
    delete r.prototype.$get;
    delete r.prototype.$save;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
