class NiceNum {
  static filter() {
    return value => {
      const val = Number(value);

      if (val < 1) {
        return "-";
      }

      if (val < 1000) {
        return val.toString();
      }

      if (val < 1000000) {
        const k = Number(val / 1000).toFixed();
        return `${k}k`;
      }

      if (val < 1000000000) {
        const m = Number(val / 1000000).toFixed();
        return `${m}M`;
      }

      return val.toString();
    };
  }
}

angular.module("marketPlace.common").filter("niceNum", NiceNum.filter);
