(function() {
  "use strict";

  angular.module("marketPlace.moderation").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.moderation", {
      url: "^/moderation",
      abstract: true,
      data: {
        requiresLogin: false,
        isAuthState: true
      }
    });

    $stateProvider.state("home.moderation.approve-comment", {
      url: "/approve/comment/:comment/:token",
      views: {
        "content@home": {
          template: require("./templates/check-token.html"),
          controller: "ApproveCommentController",
          controllerAs: "vm"
        }
      }
    });

    $stateProvider.state("home.moderation.approve-item", {
      url: "/{id:int}/approve/item/{item:int}/:token",
      views: {
        "content@home": {
          template: require("./templates/check-token.html"),
          controller: "ApproveItemController",
          controllerAs: "vm"
        }
      }
    });

    $stateProvider.state("home.invalid-token", {
      url: "^/auth/invalid-token",
      views: {
        "content@home": {
          template: require("./templates/invalid-token.html")
        }
      }
    });
  }
})();
