(function() {
  "use strict";

  angular.module("marketPlace.payments").factory("Transaction", transactionService);

  transactionService.$inject = ["$resource", "API_URL"];

  function transactionService($resource, API_URL) {
    const r = $resource(
      API_URL + "/api/transactions/:service/",
      {},
      {
        query: {
          method: "GET",
          isArray: false,
          params: {
            service: null
          }
        },
        download: {
          method: "GET",
          isArray: false,
          params: {
            service: "download"
          }
        },
        summary: {
          method: "GET",
          isArray: false,
          params: {
            service: "summary"
          }
        }
      }
    );

    // Remove extra actions - transactions is query only
    delete r.prototype.$get;
    delete r.prototype.$save;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
