(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("imagecropupload", ImageCropUpload);

  ImageCropUpload.$inject = [];

  function ImageCropUpload() {
    return {
      replace: "true",
      template: require("./templates/imagecropupload.html"),
      scope: {
        user: "=",
        uploaded: "=",
        crop: "=?",
        aspectRatio: "=?",
        areaType: "=?",
        imageLimit: "=?",
        onUpload: "&", // method on parent that accepts payment details object e.g. {nonce: 'nonce'}
        error: "&", // method on parent that handles errors
        getFilename: "&"
      },
      controller: ImageCropUploadController,
      controllerAs: "vm",
      bindToController: true // because the scope is isolated
    };
  }

  ImageCropUploadController.$inject = ["$scope", "$log", "$timeout", "AppConfig", "Upload"];
  function ImageCropUploadController($scope, $log, $timeout, AppConfig, Upload) {
    const vm = this;

    /*
    STATES:
    -  READY TO ADD
    -  LOADING IMAGE
    -  RESIZING
    -  UPLOADING
    -  LIMIT REACHED
     */

    vm.STATE_READY = "ready";
    vm.STATE_LOADING = "loading";
    vm.STATE_RESIZING = "resizing";
    vm.STATE_UPLOADING = "uploading";
    vm.STATE_LIMIT_REACHED = "limit-reached";

    vm.state = vm.STATE_READY;

    vm.uploadProgressPercentage = 0;
    vm.uploadingCropped = false;
    vm.croppedDataUrl = null;
    vm.s3key = null;
    vm.files = null;
    vm.uploadFile = null;

    vm.onCropLoadBegin = onCropLoadBegin;
    vm.onCropLoadDone = onCropLoadDone;
    vm.onCropLoadError = onCropLoadError;
    vm.ngfBeforeChange = ngfBeforeChange;
    vm.upload = upload;
    vm.cancel = cancel;
    vm.is = is;

    vm.$onInit = function() {
      vm.crop = ifDef("crop", true); // whether to crop or just upload
      vm.aspectRatio = ifDef("aspectRatio", "1:1");
      vm.areaType = ifDef("areaType", "square");
      $log.debug("ImageCropUpload directive");
    };

    function is(...args) {
      // e.g, vm.is('ready', 'loading')
      // returns true is vm.state = 'ready' or 'loading'
      return args.indexOf(vm.state) >= 0;
    }

    function ifDef(param, defaultValue) {
      return angular.isDefined(vm[param]) ? vm[param] : defaultValue;
    }

    // watchers
    $scope.$watch("vm.files", function() {
      $log.debug("vm.files changed", vm.files);
      if (vm.files) {
        if (vm.files.length) {
          for (let i = 0; i < vm.files.length; i++) {
            vm.uploadFile = vm.files[0];
            $log.debug("Cropping?", vm.crop);
            if (!vm.crop) {
              $timeout(function() {
                upload();
              });
            }
          }
        } else {
          vm.uploadFile = vm.files;
          $log.debug("Cropping?", vm.crop);
          if (!vm.crop) {
            upload();
          }
        }
      }
    });

    $scope.$watchCollection(vm.uploaded, function() {
      getInActiveState();
    });

    function onCropLoadBegin() {
      $log.debug("beginning img crop load");
    }

    function onCropLoadDone() {
      $log.debug("img crop load done");
      vm.state = vm.STATE_RESIZING;
    }

    function onCropLoadError(e) {
      $log.debug("img crop load error", e);
      vm.state = vm.getInActiveState();
    }

    function ngfBeforeChange() {
      $log.debug("ngf before change");
      $timeout(function() {
        vm.state = vm.STATE_LOADING;
      });
    }

    function getInActiveState() {
      if (vm.uploaded.length < vm.imageLimit) {
        return vm.STATE_READY;
      } else {
        return vm.STATE_LIMIT_REACHED;
      }
    }

    function onUploadProgress(evt) {
      const progressPercentage = parseInt((100.0 * evt.loaded) / evt.total);
      $log.debug("Progress: " + progressPercentage + "% " + evt.config.data.file.name);
      vm.uploadProgressPercentage = Math.min(100, progressPercentage);
    }

    function onUploadError(data, status, headers) {
      $log.debug("ERROR", data, status, headers);
      vm.uploadProgressPercentage = 0;
      vm.error(data, status, headers);
    }

    function onUploadComplete(resp) {
      vm.uploadProgressPercentage = 0;
      $timeout(function() {
        $log.debug("Image: " + resp.config.data.file.name + ", Response: " + angular.toJson(resp.data));
        const uploadedImage = {
          url: AppConfig.S3Config.s3url + encodeURIComponent(vm.s3key),
          caption: "",
          dealer_viewable: true,
          public_viewable: true,
          media_type: resp.config.data["Content-Type"],
          aspect_ratio: vm.aspectRatio
        };

        vm.uploadFile = null;
        vm.uploadingCropped = false;
        vm.s3key = null;
        vm.uploaded.push(uploadedImage);
        vm.state = getInActiveState();

        vm.onUpload(uploadedImage);
      });
    }

    function getFilename(name) {
      $log.debug("getS3Key", name);
      return (
        "images/" +
        vm.user.id +
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 5) +
        "_cropped_" +
        name
      );
    }

    function cancel() {
      vm.uploadFile = null;
      vm.croppedDataUrl = "";
      vm.files = null;
      vm.state = getInActiveState();
    }

    function getUploadFile() {
      const file = {};
      if (vm.croppedDataUrl) {
        const dataUrl = vm.croppedDataUrl;
        vm.croppedDataUrl = "";
        file.name = "cropped_" + vm.uploadFile.name;
        file.file = Upload.dataUrltoBlob(dataUrl, file.name);
      } else {
        file.name = vm.uploadFile.name;
        file.file = vm.uploadFile;
      }

      return file;
    }

    function upload() {
      const file = getUploadFile();
      $log.debug("about to upload", file);
      if (file.file) {
        vm.state = vm.STATE_UPLOADING;
        vm.uploadingCropped = true;
        vm.uploadFileName = file.name;
        vm.s3key = getFilename(vm.uploadFile.name);
        const s3data = {
          url: AppConfig.S3Config.s3url,
          method: "POST",
          data: {
            key: vm.s3key,
            AWSAccessKeyId: AppConfig.S3Config.AWSAccessKeyId,
            acl: AppConfig.S3Config.acl,
            policy: AppConfig.S3Config.policy,
            signature: AppConfig.S3Config.signature,
            "Content-Type": file.file.type !== "" ? file.file.type : "application/octet-stream",
            "Cache-Control": "max-age=315360000",
            Expires: "Thu, 31 Dec 2037 23:55:55 GMT",
            filename: file.name,
            file: file.file
          },
          skipAuthorization: true
        };
        $log.debug(s3data);
        vm.uploadFile.upload = Upload.upload(s3data).then(onUploadComplete, onUploadError, onUploadProgress);
      }
    }
  }
})();
