(function() {
  "use strict";

  angular.module("marketPlace.common").directive("titleCase", titleCase);

  titleCase.$inject = ["$filter"];

  /* @ngInject */
  function titleCase($filter) {
    const directive = {
      link: link,
      require: "ngModel"
    };
    return directive;

    function link(scope, element, attrs, controller) {
      controller.$parsers.push(function(value) {
        const transformedInput = $filter("titleCase")(value);
        if (transformedInput !== value) {
          const el = element[0];
          el.setSelectionRange(el.selectionStart, el.selectionEnd);
          controller.$setViewValue(transformedInput);
          controller.$render();
        }
        return transformedInput;
      });
    }
  }
})();
