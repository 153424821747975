(function() {
  "use strict";

  angular.module("marketPlace.listings").controller("ListingController", listingController);

  listingController.$inject = ["$log", "$window", "$uibModal", "Item", "$transition$", "user", "DashboardAPI"];

  function listingController($log, $window, $uibModal, Item, $transition$, user, DashboardAPI) {
    const vm = this;

    vm.user = user;
    vm.loading = false;
    vm.currentSort = "";

    vm.sortChanged = sortChanged;
    vm.pageChanged = pageChanged;
    vm.archive = archive;
    vm.resetSearch = resetSearch;
    vm.openReListModal = openReListModal;
    vm.buildStats = buildStats;

    activate();

    function activate() {
      $log.debug("listingController");
      const stateParams = $transition$.params();
      vm.keyword = stateParams.q ? stateParams.q : "";
      buildStats();
      vm.currentPage = 1;
      vm.itemsPerPage = 50;
      pageChanged();
    }

    function sortChanged(value) {
      if (vm.currentSort === value) {
        vm.currentSort = "";
      } else if (vm.currentSort === "-" + value) {
        vm.currentSort = value;
      } else {
        vm.currentSort = "-" + value;
      }

      pageChanged(1);
    }

    function pageChanged(newPage?: number) {
      if (newPage) {
        vm.currentPage = newPage;
      }
      $log.debug("Page changed to: " + vm.currentPage);
      $window.scrollTo(0, 0);
      vm.loading = true;
      const params = {
        user_org: true,
        page: vm.currentPage
      };

      if (vm.keyword) {
        params["q"] = vm.keyword;
      }

      if (vm.currentSort) {
        params["sort"] = vm.currentSort;
      }

      Item.query(params, function(listings) {
        vm.listings = listings;
        vm.loading = false;
      });
    }

    function archive(listing) {
      $log.debug("archiving...");
      const isArchived = listing.status === "archived";
      Item.archive({ id: listing.id, archive: !isArchived }, function() {
        if (listing.searchable) {
          listing.status = "archived";
          listing.searchable = false;
        } else {
          listing.status = "active";
          listing.searchable = true;
        }
      });
    }

    function resetSearch() {
      vm.keyword = "";
      vm.currentSort = "";
      vm.currentPage = 1;
      pageChanged();
    }

    function buildStats() {
      vm.statsLoading = true;

      DashboardAPI.getStats().then(
        function(response) {
          vm.stats = response.data;
          vm.statsLoading = false;
        },
        function() {
          vm.statsLoading = false;
        }
      );
    }

    function openReListModal(listing) {
      const instance = $uibModal.open({
        animation: true,
        template: require("./templates/relist-modal.html"),
        controller: "ListingReListController",
        controllerAs: "vm",
        resolve: {
          listing: function() {
            return listing;
          },
          user: function() {
            return vm.user;
          }
        },
        size: "md"
      });
      instance.result.then(function(success) {
        if (success) {
          listing.status = "active";
          listing.highlight = true;
        }
      });
    }
  }
})();
