import { IHttpService } from "angular";

(function() {
  "use strict";

  angular.module("marketPlace.sites").factory("Sites", Sites);

  Sites.$inject = ["$http", "API_URL"];

  function Sites($http: IHttpService, API_URL: string) {
    function getSites() {
      return $http({
        url: API_URL + "/api/auth/sites/",
        method: "GET"
      });
    }

    return {
      query: getSites
    };
  }
})();
