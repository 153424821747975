(function() {
  "use strict";

  angular.module("marketPlace.content").component("contentPage", {
    template:
      '<update-title title="{{ ::$ctrl.pageTitle }} | {{ ::$ctrl.siteName }}"></update-title>\n' +
      '<div ng-bind-html="$ctrl.pageContent"></div>',
    bindings: {
      $transition$: "<"
    },
    controller: pageController
  });

  pageController.$inject = ["SITE_NAME", "Pages"];

  function pageController(SITE_NAME: string, Pages) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
      $ctrl.siteName = SITE_NAME;
      const stateParams = $ctrl.$transition$.params();

      Pages.get(stateParams.slug).then(function(resp) {
        $ctrl.pageTitle = resp.data.title;
        $ctrl.pageContent = resp.data.content;
      });
    };
  }
})();
