(function() {
  "use strict";

  angular.module("marketPlace.broadstreet").factory("broadstreet", broadstreetFactory);

  broadstreetFactory.$inject = ["$window", "$log"];

  function broadstreetFactory($window, $log) {
    if (!$window.broadstreet) {
      $log.debug("Broadstreet not available on $window, using mock service");
      return {
        clearCampaignLog: angular.noop,
        loadZone: angular.noop
      };
    }
    return $window.broadstreet;
  }
})();
