(function() {
  "use strict";

  angular.module("marketPlace.events").factory("EventForm", EventForm);

  EventForm.$inject = ["$log", "AppConfig", "Niches", "$timeout", "Upload", "Payment", "toastr", "_", "Address"];

  function EventForm($log, AppConfig, Niches, $timeout, Upload, Payment, toastr, _, Address) {
    function getSections(niches, niche_id: number) {
      return niches[niche_id].sections;
    }

    function getGalleries(sections, section_id: number) {
      return sections[section_id].galleries;
    }

    function getWritableNiches() {
      return Niches.write.query({ item_type: "EVENT" }).$promise;
    }

    function getChangeHandlers(vm) {
      function getPrice(): void {
        // reset values
        vm.listing = null;
        vm.price = null;

        Payment.getPrice(
          {
            niche: vm.event.niche_option,
            section: vm.event.section_option,
            gallery: vm.gallery_id
          },
          function(data) {
            $log.debug("Got price", data);
            vm.listing = data;
            vm.price = data.amount;
            if (!vm.initialListing) {
              vm.initialListing = data;
            }
            if (!vm.initialPrice) {
              vm.initialPrice = data.amount;
            }
            $log.debug("price for: ", vm.event.niche_option, vm.event.section_option, vm.gallery_id, "is", vm.price);
          },
          function() {
            const msg = "Error getting listing price";
            $log.debug(msg);
            toastr.error(msg, {
              closeButton: true,
              closeHtml: '<button><i class="mdi mdi-close"></i></button>'
            });
          }
        );
      }

      function priceDecorator(fn: () => void): () => void {
        return function() {
          fn();
          getPrice();
        };
      }

      function setGallery(): void {
        $log.debug("setGallery");
        if (vm.event.gallery_option) {
          vm.event.niche_option = vm.event.gallery_option.niche.id;
          setNiche();
          vm.event.section_option = vm.event.gallery_option.section.id;
          setSection();
          vm.gallery_id = vm.event.gallery_option.id;
        }
      }
      function setSection(): void {
        $log.debug("setSection");
        vm.gallery_id = null;
        vm.galleries = getGalleries(vm.sections, vm.event.section_option);
        if (vm.galleries._order.length === 1) {
          $log.debug("Single gallery");
          vm.gallery_id = vm.galleries._order[0];
          setGalleryOption();
        }
      }
      function setNiche(): void {
        $log.debug("setNiche");
        vm.event.section_option = null;
        vm.sections = getSections(vm.niches, vm.event.niche_option);
        if (vm.sections._order.length === 1) {
          $log.debug("Single section");
          vm.event.section_option = vm.sections._order[0];
          setSection();
        }
      }
      function setGalleryOption(): void {
        vm.event.gallery_option = {
          id: vm.gallery_id,
          niche: { id: vm.event.niche_option },
          section: { id: vm.event.section_option }
        };
      }

      function placeChanged(place, obj) {
        $log.debug("location", place.geometry.location);
        const address = Address.convert(place);

        obj.line1 = address.line1;
        obj.line2 = address.line2;
        obj.city = address.city;
        obj.state = address.state;
        obj.country = address.country;
        obj.postcode = address.postcode;
      }

      function whereChanged() {
        $log.debug(vm.address);
        vm.wherePlace = this.getPlace(); // eslint-disable-line angular/controller-as-vm
        $log.debug(vm.wherePlace);

        placeChanged(vm.wherePlace, vm.event.where);
      }

      return {
        setNiche: priceDecorator(setNiche),
        setSection: priceDecorator(setSection),
        setGallery: priceDecorator(setGallery),
        setGalleryOption: priceDecorator(setGalleryOption),
        whereChanged: whereChanged
      };
    }

    function upload(vm, $scope, files) {
      if (files && files.length) {
        for (let i = 0; i < Math.min(files.length, AppConfig.eventExternalImageLimit); i++) {
          const file = files[i];
          if (!file.$error) {
            vm.uploadFileName = file.name;
            const cleanFileName = file.name.replace(/[^A-Za-z0-9.]+/g, "-");
            const s3key =
              "images/" +
              vm.user.id +
              "_" +
              Math.random()
                .toString(36)
                .substr(2, 5) +
              "_" +
              cleanFileName;
            file.upload = Upload.upload({
              url: AppConfig.S3Config.s3url,
              method: "POST",
              data: {
                key: s3key,
                AWSAccessKeyId: AppConfig.S3Config.AWSAccessKeyId,
                acl: AppConfig.S3Config.acl,
                policy: AppConfig.S3Config.policy,
                signature: AppConfig.S3Config.signature,
                "Content-Type": file.type != "" ? file.type : "application/octet-stream",
                "Cache-Control": "max-age=315360000",
                Expires: "Thu, 31 Dec 2037 23:55:55 GMT",
                filename: cleanFileName,
                file: file
              },
              skipAuthorization: true
            }).then(
              function(resp) {
                vm.uploadProgressPercentage = 0;
                $timeout(function() {
                  $log.debug("Image: " + resp.config.data.file.name + ", Response: " + angular.toJson(resp.data));
                  const uploadedImage = {
                    url: AppConfig.S3Config.s3url + encodeURIComponent(s3key),
                    caption: "",
                    dealer_viewable: true,
                    public_viewable: true,
                    media_type: resp.config.data["Content-Type"],
                    primary: vm.event.external_images.length === 0
                  };
                  vm.event.external_images.push(uploadedImage);
                  $scope.$broadcast("show-errors-reset");
                  vm.eventForm.form.external_images.$setValidity("server", true);
                });
              },
              function(data, status, headers) {
                $log.debug("ERROR", data, status, headers);
                vm.uploadProgressPercentage = 0;
              },
              function(evt) {
                const progressPercentage = Math.floor((100.0 * evt.loaded) / evt.total);
                $log.debug("Progress: " + progressPercentage + "% " + evt.config.data.file.name);
                vm.uploadProgressPercentage = Math.min(100, progressPercentage);
              }
            );
          }
        }
      }
    }

    function getRepeat() {
      return {
        frequency: "",
        daily: {
          frequency: "DAILY",
          every: 1,
          every_options: _.range(1, 31),
          end: "num_occurrences",
          num_occurrences: 1,
          until: null
        },
        weekly: {
          frequency: "WEEKLY",
          every: 1,
          every_options: _.range(1, 53),
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
          end: "num_occurrences",
          num_occurrences: 1,
          until: null
        },
        monthly: {
          frequency: "MONTHLY",
          every: 1,
          every_options: _.range(1, 49),
          day_of: "MONTH",
          end: "num_occurrences",
          num_occurrences: 1,
          until: null
        },
        yearly: {
          frequency: "YEARLY",
          every_options: _.range(1, 11),
          every: 1,
          end: "num_occurrences",
          num_occurrences: 1,
          until: null
        }
      };
    }

    return {
      getWritableNiches: getWritableNiches,
      getChangeHandlers: getChangeHandlers,
      upload: upload,
      getRepeat: getRepeat
    };
  }
})();
