(function() {
  "use strict";

  angular.module("marketPlace.auth").config(config);

  config.$inject = ["$stateProvider"];

  function config($stateProvider) {
    $stateProvider.state("home.dashboard.notifications", {
      url: "/notifications",
      template: require("./templates/notifications.html"),
      controller: "NotificationController",
      controllerAs: "vm",
      data: {
        requiresLogin: true
      }
    });
  }
})();
