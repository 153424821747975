(function() {
  "use strict";

  angular.module("marketPlace.moderation").factory("Moderation", moderationService);

  moderationService.$inject = ["$http", "API_URL"];

  function moderationService($http, API_URL) {
    return {
      approveComment: function(data) {
        const url = new URL("/api/comments/approve/" + data.comment + "/" + data.token + "/", API_URL);
        return $http.get(url.href).then(function(response) {
          return response.data;
        });
      },
      approveItem: function(data) {
        const url = new URL("/api/moderation/" + data.id + "/approve/", API_URL);
        return $http.post(url.href, { item: data.item, token: data.token }).then(function(response) {
          return response.data;
        });
      }
    };
  }
})();
