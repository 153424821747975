import { Transition, StateService } from "@uirouter/angularjs";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { ICacheObject } from "angular";
import ICacheFactory = angular.cache.ICacheFactory;

export interface PreviousService {
  setTransitionInvoked($transition$: Transition);
  goToPreviousState(
    fromState: string,
    options: object,
    defaultState?: string,
    defaultParams?: object,
    invalidPreviousStates?: string[],
    validateStateFn?: Function
  );
  invokeModalOnEnter(
    modalInstance: IModalInstanceService,
    $transition$: Transition,
    loadGalleryBehind?: boolean,
    clearCachedRoutes?: boolean,
    defaultState?: string,
    defaultParams?: object
  );
  undoTransition(
    $transition$: Transition,
    options: object,
    defaultState?: string,
    defaultParams?: object,
    invalidPreviousStates?: string[],
    validateStateFn?: Function
  );
}

interface CachedState {
  name: string;
  params: object;
}

class PreviousServiceImpl implements PreviousService {
  cacheName: string;
  cache: ICacheObject;

  static $inject = ["$state", "CacheFactory", "AuthService"];

  constructor(public $state: StateService, CacheFactory: ICacheFactory, public AuthService: any) {
    this.cacheName = "previousStates";

    if (!CacheFactory.get(this.cacheName)) {
      CacheFactory.createCache("previousStates");
    }
    this.cache = CacheFactory.get(this.cacheName);
  }

  /**
   * Check if a state is a valid previous state
   * @param name
   * @param invalidPreviousStates
   * @returns {boolean}
   */
  private isValidPreviousState(name: string, invalidPreviousStates = []): boolean {
    const invalidStates = ["register", "login", "accept", "reset-password"].concat(invalidPreviousStates);

    if (!name) {
      return false;
    }

    for (let i = 0; i < invalidStates.length; i++) {
      const r = new RegExp(invalidStates[i]);
      if (r.exec(name)) {
        return false;
      }
    }
    return true;
  }

  private getMemoName(name: string): string {
    return "invoked:" + name;
  }

  private hasInvoked(name: string) {
    return this.cache.get(this.getMemoName(name));
  }

  /**
   * Call first in onEnter modal
   * @param $transition$
   */
  setTransitionInvoked($transition$: Transition): void {
    const toStateName = $transition$.to().name;
    const fromStateName = $transition$.from().name;
    const fromStateParams = $transition$.params("from");

    // don't override already invoked
    if (!this.hasInvoked(toStateName)) {
      this.setInvoker(toStateName, {
        name: fromStateName,
        params: fromStateParams
      });
    }
  }

  private getInvoker(name: string): CachedState {
    return this.cache.get(this.getMemoName(name));
  }

  private setInvoker(name: string, state: CachedState): void {
    this.cache.put(this.getMemoName(name), state);
  }

  private forgetInvoker(name: string): void {
    this.cache.remove(this.getMemoName(name));
  }

  /**
   * @param modalInstance
   * @param $transition$ resolved from state config
   * @param loadGalleryBehind if true load the gallery behind this modal if first transition
   * @param clearCachedRoutes call AuthService.clearCachedRoutes
   * @param defaultState
   * @param defaultParams
   */
  invokeModalOnEnter(
    modalInstance: IModalInstanceService,
    $transition$: Transition,
    loadGalleryBehind = false,
    clearCachedRoutes = false,
    defaultState?: string,
    defaultParams?: object
  ) {
    const toName = $transition$.to().name;
    let reload = false;
    let noTransition = false;

    this.setTransitionInvoked($transition$);
    modalInstance.result
      .then(
        function(result) {
          // Reload only on successful closes
          reload = true;
          if (angular.isDefined(result) && result.hasOwnProperty("noTransition") && result.noTransition) {
            noTransition = true;
          }
        },
        () => {}
      )
      .finally(() => {
        if (clearCachedRoutes) {
          this.AuthService.clearCachedRoutes();
        }
        if (noTransition) {
          this.forgetInvoker(toName);
        } else {
          this.goToPreviousState(toName, { reload: reload, inherit: false }, defaultState, defaultParams);
        }
      });

    if (loadGalleryBehind && $transition$.from().name === "") {
      this.$state.go("home.gallery", {}, { location: false });
    }
  }

  /**
   * Go to a previous state
   * @param fromState the state you will be transitioning from
   * @param defaultState the state to go to if there is no previous state
   * @param defaultParams the state params to use if there is no previous state
   * @param options state options e.g. {reload: true}
   * @param invalidPreviousStates array of state names that should not be transitioned to
   * @param validateStateFn function to validate a previous state before transitioning to it
   */
  goToPreviousState(
    fromState: string,
    options: object,
    defaultState = "home.gallery",
    defaultParams = {},
    invalidPreviousStates = [],
    validateStateFn?: (state: CachedState) => boolean
  ) {
    let stateName = defaultState;
    let stateParams = defaultParams;

    if (!validateStateFn) {
      validateStateFn = function(): boolean {
        return true;
      };
    }

    // check if there is a previous state
    const previousState = this.getInvoker(fromState);

    if (
      previousState &&
      fromState !== previousState.name &&
      this.isValidPreviousState(previousState.name, invalidPreviousStates) &&
      validateStateFn(previousState)
    ) {
      stateName = previousState.name;
      stateParams = previousState.params;
    }

    // always forget
    this.forgetInvoker(fromState);

    // transition to it
    this.$state.go(stateName, stateParams, options);
  }

  undoTransition(
    $transition$: Transition,
    options: object,
    defaultState: string,
    defaultParams: object,
    invalidPreviousStates = [],
    validateStateFn?: (state: CachedState) => boolean
  ) {
    this.goToPreviousState(
      $transition$.to().name,
      options,
      defaultState,
      defaultParams,
      invalidPreviousStates,
      validateStateFn
    );
  }
}

angular.module("marketPlace.auth").service("PreviousService", PreviousServiceImpl);
