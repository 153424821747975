(function() {
  "use strict";

  angular
    .module("marketPlace.email")
    .controller("AddEmailNotificationModalController", AddEmailNotificationModalController);

  AddEmailNotificationModalController.$inject = [
    "$scope",
    "$uibModalInstance",
    "$log",
    "Email",
    "schedules",
    "searches",
    "toastr"
  ];

  function AddEmailNotificationModalController($scope, $uibModalInstance, $log, Email, schedules, searches, toastr) {
    const vm = this;
    vm.title = "Add Notification";
    vm.schedules = schedules.results;
    vm.searches = searches.results;
    vm.schedule = null;
    vm.search = null;
    vm.errors = {};
    vm.saving = false;
    vm.form = {};

    $log.debug("SCHEDULES", vm.schedules);
    $log.debug("SEARCHES", vm.searches);

    vm.add = function(schedule, search) {
      $log.debug("ADDING EMAIL NOTIFICATION", schedule, search);
      $scope.$broadcast("show-errors-check-validity");

      if (vm.form.$invalid) {
        return;
      }

      vm.saving = true;

      const en = new Email({
        schedule: schedule ? schedule.id : null,
        search: search.id
      });

      en.$save().then(
        function(resp) {
          $log.debug("Add email notification SUCCESS", resp);
          vm.saving = false;
          $uibModalInstance.result.then(function() {
            toastr.success("Email notification added");
          });
          $uibModalInstance.close(true);
        },
        function(resp) {
          $log.debug("Add email notification FAIL", resp);
          vm.saving = false;
          angular.forEach(resp.data, function(fieldErrors) {
            angular.forEach(fieldErrors, function(value) {
              vm.errors.non_field_errors = value;
            });
          });
        }
      );
    };

    vm.cancel = function() {
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.dismiss("cancel");
    };
  }
})();
