(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("promoteListing", promoteListing);

  function promoteListing() {
    const directive = {
      restrict: "E",
      replace: "true",
      template: require("./templates/promoteListing.html"),
      scope: {
        item: "="
      },
      controller: promoteListingController,
      controllerAs: "vm",
      bindToController: true // because the scope is isolated
    };

    promoteListingController.$inject = ["Item", "Event", "Organisation"];
    function promoteListingController(Item, Event, Organisation) {
      const vm = this;
      vm.promote = promote;
      vm.promoteForm = {};

      function promote() {
        if (vm.promoteForm.$invalid) {
          return;
        }

        const score = {
          id: vm.item.id,
          boost: vm.item.boost
        };

        // Promote the listing
        if (vm.item.item_type === "ITEM") {
          Item.promote(score);
        } else if (vm.item.item_type === "EVENT") {
          Event.promote(score);
        } else if (vm.item.item_type === "ORGANISATION") {
          Organisation.promote(score);
        }
      }
    }
    return directive;
  }
})();
