class NicheAccordion implements ng.IComponentOptions {
  public bindings: any;
  public template: string;

  constructor() {
    this.bindings = {
      niches: "<",
      selectedNiche: "<",
      selectedSection: "<",
      selectedGallery: "<",
      selectedCaption: "<"
    };
    this.template = require("./templates/niche-accordion.html");
  }
}

angular.module("marketPlace.controls").component("nicheaccordion", new NicheAccordion());
