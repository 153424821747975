(function() {
  "use strict";
  angular.module("marketPlace.controls").directive("payment", PaymentScreen);

  PaymentScreen.$inject = [];

  function PaymentScreen() {
    return {
      replace: "true",
      template: require("./templates/payment.html"),
      scope: {
        user: "=",
        amounts: "=?", // optional array of amounts e.g, [{amount: 10, name: 'Pledge'}]
        submitting: "=", // set to true in parent when submit button (on parent) clicked
        submit: "&", // method on parent that accepts payment details object e.g. {nonce: 'nonce'}
        error: "&", // method on parent that handles errors
        onHostedFieldsLoaded: "&?" // Optional callback on parent called when form is loaded
      },
      controller: PaymentController,
      controllerAs: "vm",
      bindToController: true // because the scope is isolated
    };
  }

  PaymentController.$inject = [
    "$scope",
    "$log",
    "AppConfig",
    "Payment",
    "PaymentMethod",
    "braintreeClient",
    "braintreeHostedFields"
  ];
  function PaymentController($scope, $log, AppConfig, Payment, PaymentMethod, braintreeClient, braintreeHostedFields) {
    const vm = this,
      defaultHostedFieldsConfig = {
        styles: {
          input: { "font-size": "12pt" },
          "input.invalid": { color: "red" },
          "input.valid": { color: "green" }
        },
        fields: {
          number: {
            selector: "#card-number",
            placeholder: "•••• •••• •••• ••••"
          },
          cvv: {
            selector: "#cvv",
            placeholder: "•••"
          },
          expirationDate: {
            selector: "#expiration-date",
            placeholder: "MM/YY"
          }
        }
      };
    let hostedFieldsInstance;

    vm.feesIncluded = ifDef("feesIncluded", false);
    vm.hostedFieldsConfig = ifDef("hostedFieldsConfig", defaultHostedFieldsConfig);
    vm.saving = false;
    vm.method = "new-card";
    vm.processing = false;
    vm.loadingHostedFields = false;
    vm.payment = {};
    vm.storedCard = false;
    vm.hasTotal = true; // flag to allow us to use the form without $ for change payment method
    vm.total = 0;
    vm.subtotal = 0;
    vm.creditCardFee = 0;
    vm.METHOD_NEW_CARD = "new-card";
    vm.METHOD_STORED_CARD = "stored-card";
    vm.METHOD_SUBSCRIPTION = "subscription";

    vm.onMethodChanged = onMethodChanged;
    vm.updateTransactionTotals = updateTransactionTotals;

    function ifDef(param, defaultValue) {
      return angular.isDefined(vm[param]) ? vm[param] : defaultValue;
    }

    vm.$onInit = function() {
      $log.debug("PaymentDirective");
      vm.transactionFee = angular.isDefined(AppConfig.TransactionFee) ? AppConfig.TransactionFee : 0.45;
      vm.actualTransactionFee = vm.transactionFee;
      vm.transactionRate = angular.isDefined(AppConfig.TransactionRate) ? AppConfig.TransactionRate : 0.029;
      vm.transactionRatePercentage = vm.transactionRate * 100;
      loadStoredCard();
      vm.onMethodChanged();
    };

    $scope.$watch("vm.submitting", function() {
      if (vm.submitting) {
        $log.debug("about to submit payment");
        checkout();
      }
    });

    $scope.$watchCollection("vm.amounts", function(newValue, oldValue) {
      $log.debug("vm.amounts: ", vm.amounts);
      if (newValue != oldValue) {
        updateTransactionTotals();
      }
    });

    function loadStoredCard(reload = false) {
      if (!vm.storedCard || reload) {
        PaymentMethod.query(
          function(card) {
            vm.storedCard = card;
          },
          function() {
            angular.noop();
          }
        );
      }
    }

    function onHostedFieldsCreate(hostedFieldsErr, instance) {
      $log.debug("PAYMENT", "onHostedFieldsCreate");
      if (hostedFieldsErr) {
        // Handle error in Hosted Fields creation
        $log.debug("PAYMENT", hostedFieldsErr);
        vm.error(hostedFieldsErr);
        return;
      }

      hostedFieldsInstance = instance;

      // Enable button and trigger digest
      $scope.$apply(function() {
        vm.loadingHostedFields = false;
        if (angular.isFunction(vm.onHostedFieldsLoaded)) {
          vm.onHostedFieldsLoaded();
        }
      });
    }

    function onBraintreeClientCreate(clientErr, clientInstance) {
      $log.debug("PAYMENT", "onBraintreeClientCreate");
      if (clientErr) {
        // Handle error in client creation
        vm.error(clientErr);
        return;
      }

      vm.hostedFieldsConfig.client = clientInstance;
      braintreeHostedFields.create(vm.hostedFieldsConfig, onHostedFieldsCreate);
    }

    function onTokenReceived(clientToken) {
      $log.debug("PAYMENT", "onTokenReceived");
      braintreeClient.create({ authorization: clientToken.token }, onBraintreeClientCreate);
    }

    function loadHostedFields() {
      vm.loadingHostedFields = true;
      vm.processing = false;

      Payment.getToken(onTokenReceived);
    }

    function onMethodChanged() {
      if (vm.method === vm.METHOD_NEW_CARD && !hostedFieldsInstance) {
        loadHostedFields();
      }

      if (vm.method === vm.METHOD_STORED_CARD) {
        loadStoredCard(false);
      }

      updateTransactionTotals();
    }

    function submitHostedFields() {
      $log.debug("PAYMENT", "submitHostedFields");
      vm.processing = true;
      hostedFieldsInstance.tokenize(function(tokenizeErr, payload) {
        if (tokenizeErr) {
          let message = "";
          // Handle error in Hosted Fields tokenization
          switch (tokenizeErr.code) {
            case "HOSTED_FIELDS_FIELDS_EMPTY":
              message = "You haven't entered any Credit Card details. Please fill out the form";
              break;
            case "HOSTED_FIELDS_FIELDS_INVALID":
              message =
                "Some of the Credit Card details you have entered are invalid: " + tokenizeErr.details.invalidFieldKeys;
              break;
            case "HOSTED_FIELDS_FAILED_TOKENIZATION":
              message = "We couldn't process your credit card. Is the card still valid?";
              break;
            case "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR":
              message =
                "We are having problems connecting to our payment gateway. Please try again later or try contacting support";
              break;
            default:
              message +=
                " We are experiencing technical difficulties right now. Please try again later or try contacting support";
          }

          $log.debug(tokenizeErr);
          vm.error()(message);
        } else {
          vm.payment.nonce = payload.nonce;
          vm.submit()(vm.payment);
        }

        vm.processing = false;
      });
    }

    function checkout() {
      if (vm.amounts && (vm.amounts.length === 0 || vm.total === 0)) {
        // nothing to pay
        vm.submit()(vm.payment);
        return;
      }

      vm.payment.method = vm.method;
      if (vm.method === vm.METHOD_NEW_CARD) {
        // need to submit hosted fields before calling
        // submit on the parent
        submitHostedFields();
      } else {
        if (vm.method === vm.METHOD_STORED_CARD) {
          vm.payment.pay_now = true;
        }
        vm.submit()(vm.payment);
      }
    }

    /**
     * Update all the transaction totals. vm.amounts is an array of items
     * that the user wishes to purchase. Each item may include transaction
     * costs or exclude transaction costs. For example, buying a listing would
     * include transaction costs. But making a donation would exclude transaction
     * costs which means the total paid by the user will be greater. This method
     * calculates the amount paid by the user excluding transaction costs, the
     * amount of the transaction cost and the total amount that will actually
     * be paid by the user.
     *
     * When the amount will be added to user's subscription, there is no
     * transaction fee only the credit card fee of 2.9%
     */
    function updateTransactionTotals() {
      if (vm.amounts) {
        $log.debug("Updating transaction totals");
        let transactionFee = vm.method === vm.METHOD_SUBSCRIPTION ? 0 : parseFloat(vm.transactionFee),
          includedSubtotal = 0,
          excludedSubtotal = 0,
          amountOfTransactionFeeNotInIncludedSubtotal = parseFloat(transactionFee);

        // calculate subtotals for each amount type (excluded and included)
        // the "actualAmount" is the amount of each item minus transaction
        // costs for that item. For excluded amounts the amount and actualAmount
        // will be the same.

        for (let i = 0; i < vm.amounts.length; i++) {
          if (vm.amounts[i].feesIncluded) {
            // fees included in the amount
            includedSubtotal = parseFloat(includedSubtotal + vm.amounts[i].amount);
            vm.amounts[i].actualAmount = parseFloat(vm.amounts[i].amount * (1 - vm.transactionRate)).toFixed(2);
            if (amountOfTransactionFeeNotInIncludedSubtotal > 0) {
              // for the first included amount we attribute the one-off transaction fee
              // of $0.45 which means we should subtract from the "actualAmount"
              vm.amounts[i].actualAmount -= amountOfTransactionFeeNotInIncludedSubtotal;
              amountOfTransactionFeeNotInIncludedSubtotal = 0;
            }
          } else {
            excludedSubtotal += parseFloat(vm.amounts[i].amount);
            vm.amounts[i].actualAmount = vm.amounts[i].amount;
          }
        }
        $log.debug("vm.amounts: ", vm.amounts);

        const excludedRateMultiplier = parseFloat(vm.transactionRate / (1 - vm.transactionRate));
        const excludedTransactionFee = parseFloat(includedSubtotal > 0 || excludedSubtotal <= 0 ? 0 : transactionFee);
        const includedFee = parseFloat(includedSubtotal * vm.transactionRate);
        const excludedFee = parseFloat((excludedSubtotal + excludedTransactionFee) * excludedRateMultiplier);

        // total paid by user
        vm.total = parseFloat(includedSubtotal + excludedSubtotal + excludedFee + excludedTransactionFee).toFixed(2);
        $log.debug(
          "vm.total: ",
          vm.total,
          excludedRateMultiplier,
          includedSubtotal,
          excludedSubtotal,
          excludedFee,
          excludedTransactionFee
        );
        vm.creditCardFee = includedFee + excludedFee;
        $log.debug("vm.creditCardFee: ", vm.creditCardFee);
        vm.actualTransactionFee = transactionFee;
        $log.debug("vm.actualTransactionFee: ", vm.actualTransactionFee);
      }
    }
  }
})();
