import { IHttpResponse, ILogService, IScope, IWindowService } from "angular";
import { IToastrService } from "angular-toastr";
import { LotService } from "./lot.service";

interface Lot {
  item: number;
  opens: string;
  closes: string;
  current_bid: number;
  estimate_price: string;
  number: number;
  reserve_status: string;
  url: string;
  name: string;
  has_leading_bid: boolean;
}

class AuctionDashboardControllerImpl {
  lots: any;
  defaultCurrency: string;
  sortChanged: Function;
  pageChanged: Function;
  loading = true;
  currentPage = 1;
  itemsPerPage = 50;

  static $inject = ["$log", "$scope", "$window", "toastr", "Lot", "AppConfig", "moment", "DashboardTable"];

  constructor(
    public $log: ILogService,
    public $scope: IScope,
    public $window: IWindowService,
    public toastr: IToastrService,
    public Lot: LotService,
    public AppConfig: any,
    public moment: any,
    public DashboardTable: any
  ) {
    this.defaultCurrency = AppConfig.defaultCurrency;
    this.sortChanged = angular.bind(this, DashboardTable.sortChanged, this.getLots);
    this.pageChanged = angular.bind(this, DashboardTable.pageChanged, this.getLots);
  }

  $onInit(): void {
    this.getLots();
  }

  isAuctionOpen = (lot: Lot, now = this.moment().utc()) => now.isBetween(lot.opens, lot.closes);
  hasAuctionStarted = (lot: Lot, now = this.moment().utc()) => now.isAfter(lot.opens);
  hasAuctionClosed = (lot: Lot, now = this.moment().utc()) => now.isAfter(lot.closes);

  getLots = (params = {}) => {
    this.Lot.query(params)
      .then(
        function(response: IHttpResponse<any>) {
          this.lots = response.data;
          this.$window.scrollTo(0, 0);
        }.bind(this),
        function(error: IHttpResponse<any>) {
          if (error.status !== 404) {
            this.toastr.error("Error getting lots");
            this.$log.debug(error);
          }
        }.bind(this)
      )
      .finally(
        function() {
          this.loading = false;
        }.bind(this)
      );
  };

  reserveStatusClass = (lot: Lot) => {
    let reserveStatus = lot.reserve_status.toLowerCase();
    if (reserveStatus.startsWith("reserve met") || reserveStatus.startsWith("no reserve")) {
      return ["text-green-dark"];
    }

    if (reserveStatus.startsWith("reserve not met")) {
      return ["text-red-dark"];
    }
  };
}

class AuctionDashboard implements ng.IComponentOptions {
  public controller: any;
  public template: string;

  constructor() {
    this.controller = AuctionDashboardControllerImpl;
    this.template = require("./templates/auction-dashboard.html");
  }
}

angular.module("marketPlace.auction").component("auctionDashboard", new AuctionDashboard());
