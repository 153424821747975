import { IHttpResponse, IScope, ILogService } from "angular";
import { StateService } from "@uirouter/core";
import { IToastrService } from "angular-toastr";

(function() {
  "use strict";
  angular.module("marketPlace.cart").controller("CartController", CartController);

  CartController.$inject = [
    "$scope",
    "$state",
    "$uibModalInstance",
    "$log",
    "Cart",
    "toastr",
    "SITE_NAME",
    "user",
    "groups",
  ];

  function CartController(
    $scope: IScope,
    $state: StateService,
    $uibModalInstance,
    $log: ILogService,
    Cart,
    toastr: IToastrService,
    SITE_NAME: string,
    user,
    groups,
  ) {
    const vm = this;
    vm.user = user;
    vm.groups = groups;
    vm.registerGroups = [];
    vm.siteName = SITE_NAME;
    vm.total = null;
    vm.checkout = checkout;
    vm.cancel = cancel;
    vm.back = back;
    vm.closeModal = closeModal;
    vm.isLoading = isLoading;
    vm.isLogin = isLogin;
    vm.isCart = isCart;
    vm.isAddresses = isAddresses;
    vm.isPaymentMethod = isPaymentMethod;
    vm.isPayment = isPayment;
    vm.isComplete = isComplete;
    vm.isShipping = isShipping;
    vm.getAddresses = getAddresses;
    vm.checkShipping = checkShipping;
    vm.choosePaymentMethod = choosePaymentMethod;
    vm.cart = {};
    vm.form = {};
    vm.errors = {};
    vm.mode = "loading";
    vm.paymentFormLoaded = false;
    vm.paymentAmounts = 0;
    vm.paymentMethods = [];

    vm.removeLine = removeLine;

    vm.placeOrder = placeOrder;
    vm.placeOrderNonBraintree = placeOrderNonBraintree;
    vm.handlePayment = handlePayment;
    vm.submitting = false;
    vm.onPaymentError = onPaymentError;
    vm.onPaymentFormLoaded = onPaymentFormLoaded;
    vm.onLogin = onLogin;
    vm.isAddressValid = isAddressValid;

    vm.shippingSame = true;
    vm.showAddressErrors = false;
    vm.newBillingAddress = {
      title: vm.user.title || "",
      first_name: vm.user.first_name || "",
      last_name: vm.user.last_name || "",
      line1: vm.user.postal_address_line1 || "",
      line2: vm.user.postal_address_line2 || "",
      line3: "",
      line4: "",
      city: vm.user.postal_address_city || "",
      postcode: "",
      state: "",
      country: vm.user.postal_address_country || "",
      notes: ""
    };

    vm.newShippingAddress = {
      title: vm.user.title || "",
      first_name: vm.user.first_name || "",
      last_name: vm.user.last_name || "",
      line1: vm.user.physical_address_line1 || "",
      line2: vm.user.physical_address_line2 || "",
      line3: "",
      line4: "",
      city: vm.user.physical_address_city || "",
      postcode: "",
      state: "",
      country: vm.user.physical_address_country || "",
      notes: ""
    };

    const individualType = "I";

    activate();

    function activate() {
      $log.debug("CART controller");
      if (vm.user.email) {
        $log.debug("About to load cart", vm.user);
        loadCart();
      } else {
        $log.debug("About to load register");
        vm.mode = "login";
        vm.registerGroups = getRegisterGroups();
      }
    }

    function getRegisterGroups() {
      const groups = [];
      for (let i = 0; i < vm.groups.length; i++) {
        if (vm.groups[i].type === individualType) {
          groups.push(vm.groups[i].id);
        }
      }
      return groups;
    }

    function isLogin() {
      return vm.mode === "login";
    }

    function isShipping() {
      return vm.mode === "shipping-method";
    }

    function isComplete() {
      return vm.mode === "complete";
    }

    function isPaymentMethod() {
      return vm.mode === "payment-method" && vm.cart.lines.length > 0;
    }

    function isPayment() {
      return vm.mode === "payment";
    }

    function isLoading() {
      return vm.mode === "loading";
    }

    function isCart() {
      return vm.mode === "cart";
    }

    function isAddresses() {
      return vm.mode === "addresses";
    }

    function back() {
      if (isAddresses()) {
        vm.mode = "cart";
      }

      if (isPayment()) {
        vm.mode = "shipping-method";
      }

      if (isShipping()) {
        vm.mode = "addresses";
      }
    }

    function cartLoaded(response: IHttpResponse<any>) {
      vm.cart = response.data;
      vm.mode = "cart";
      $log.debug("CART", vm.cart);
    }

    function cartError() {
      toastr.error("Sorry there was a problem with your shopping cart, please try again later");
      closeModal();
    }

    function loadCart() {
      vm.mode = "loading";
      $log.debug("loadCart", vm.itemName, vm.productUrl);
      Cart.get().then(cartLoaded, cartError);
    }

    function removeLine(index: number) {
      const line = vm.cart.lines[index];
      Cart.removeLine(line).then(function() {
        loadCart();
      });
    }

    function getAddresses() {
      vm.mode = "loading";
      if (!vm.gotAddresess) {
        Cart.getShippingMethods().then(function(response: IHttpResponse<any>) {
          vm.shippingMethods = response.data;
          if (vm.shippingMethods.length > 0) {
            vm.requiresShipping = vm.shippingMethods[0].code !== "free-shipping";
          }

          Cart.getUserAddresses().then(function(response) {
            vm.addresses = response.data.results;
            for (let i = 0; i < vm.addresses.length; i++) {
              if (vm.addresses[i].is_default_for_billing || !vm.billing) {
                vm.billing = vm.addresses[i];
              }
              if (vm.addresses[i].is_default_for_shipping || (!vm.shippingAddress && vm.requiresShipping)) {
                vm.shippingAddress = vm.addresses[i];
              }
            }

            if (!vm.billing) {
              vm.billing = vm.newBillingAddress;
            }

            if (!vm.shippingAddress && vm.requiresShipping) {
              vm.shippingAddress = vm.newShippingAddress;
            }

            vm.gotAddresses = true;
            vm.mode = "addresses";
          });
        }, cartError);
      }
    }

    function checkShipping() {
      $log.debug("BILLING", vm.billing, "SHIPPING", vm.shippingAddress);
      vm.mode = "loading";

      if (!vm.isAddressValid()) {
        vm.showAddressErrors = true;
        vm.mode = "addresses";
        return;
      }

      if (vm.billing && !vm.billing.id) {
        Cart.addAddress(getAddress(vm.billing)).then(function(result: IHttpResponse<any>) {
          $log.debug("Added billing address", result);
          if (result.data && result.data.id) {
            vm.billing = result.data;
          }
        });
      }

      if (vm.shippingAddress && !vm.shippingAddress.id) {
        Cart.addAddress(getAddress(vm.shippingAddress)).then(function(result: IHttpResponse<any>) {
          $log.debug("Added shipping address", result);
          if (result.data && result.data.id) {
            vm.shippingAddress = result.data;
          }
        });
      }

      if (!vm.requiresShipping) {
        return checkout();
      }

      let shippingAddress = getAddress(vm.shippingAddress) || getAddress(vm.billing);
      $log.debug(shippingAddress);
      if (vm.shippingAddress && vm.shippingAddress.country && vm.shippingAddress.line1) {
        shippingAddress = vm.shippingAddress;
      }
      Cart.getShippingMethods(shippingAddress).then(function(response: IHttpResponse<any>) {
        vm.shippingMethods = response.data;
        vm.shippingAddress = shippingAddress;

        if (vm.shippingMethods.length === 1 && vm.shippingMethods[0].code === "no-shipping-required") {
          checkout();
        } else {
          vm.requiresShipping = true;
          vm.mode = "shipping-method";
        }
      }, cartError);
    }

    function isAddressValid() {
      if (vm.requiresShipping) {
        if (vm.shippingSame) {
          vm.shippingAddress = vm.billing;
        }

        if (
          !vm.shippingAddress ||
          !vm.shippingAddress.country ||
          !vm.shippingAddress.postcode ||
          !vm.shippingAddress.line1
        ) {
          return false;
        }
      }

      if (!vm.billing || !vm.billing.country || !vm.billing.postcode || !vm.billing.line1) {
        return false;
      }

      return true;
    }

    function choosePaymentMethod() {
      vm.mode = "loading";
      vm.total = parseFloat(vm.cart.total_incl_tax) + vm.shippingAmount;

      Cart.getSourceTypes().then(function(response: IHttpResponse<any>) {
        vm.paymentMethods = response.data;
        $log.debug(vm.paymentMethods);
        if (vm.paymentMethods.length === 1 && vm.paymentMethods[0].code === "braintree") {
          checkout();
        } else {
          vm.mode = "payment-method";
        }
      });
    }

    function checkout() {
      vm.paymentAmounts = [];
      vm.total = null;
      for (let line = 0; line < vm.cart.lines.length; line++) {
        const amount = parseFloat(vm.cart.lines[line].price_incl_tax);
        $log.debug("AMOUNT", amount, vm.cart.lines[line].price_incl_tax);
        vm.paymentAmounts.push({
          amount: amount,
          name: vm.cart.lines[line].title,
          feesIncluded: true
        });
      }
      vm.shippingAmount = 0;
      if (vm.shippingMethod) {
        vm.shippingAmount = parseFloat(vm.shippingMethod.price.incl_tax);
        vm.paymentAmounts.push({
          amount: vm.shippingAmount,
          name: vm.shippingMethod.name,
          feesIncluded: true
        });
      }
      vm.total = parseFloat(vm.cart.total_incl_tax) + vm.shippingAmount;
      if (vm.total > 0 || vm.shippingAmount > 0) {
        vm.mode = "payment";
      }
    }

    function placeOrder() {
      vm.submitting = true;
    }

    function placeOrderNonBraintree() {
      vm.mode = "loading";
      if (vm.paymentMethod) {
        handlePayment({ source: vm.paymentMethod.code });
      } else {
        toastr.error("Please select a payment method");
      }
    }

    function getAddress(address) {
      if (address && address.line1 && address.country) {
        address.country = Cart.getCountryUrl(address.country);
        return address;
      }
      return null;
    }

    function handlePayment(payment) {
      payment.basket = vm.cart.url;
      payment.billing_address = getAddress(vm.billing);
      const shippingAddress = getAddress(vm.shippingAddress);
      if (shippingAddress) {
        payment.shipping_address = shippingAddress;
      }
      if (vm.shippingMethod) {
        payment.shipping_method_code = vm.shippingMethod.code;
      }
      $log.debug(payment);
      Cart.checkout(payment)
        .then(function(response: IHttpResponse<any>) {
          vm.order = response.data;
          vm.mode = "complete";
          $log.debug(vm.mode, vm.order);
        })
        .catch(function(error: IHttpResponse<any>) {
          $log.debug("Checkout error", error);
          vm.mode = "payment";
          vm.submitting = false;
          toastr.error("Your order could not be placed at this time. Please try again later.");
        });
    }

    function closeModal() {
      $log.debug("CLOSING CART MODAL");
      vm.saving = false;
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.close(true);
    }

    function cancel() {
      $scope.$broadcast("show-errors-reset");
      $uibModalInstance.dismiss("cancel");
    }

    function onPaymentError(error) {
      $log.debug("Payment error: ", error);
      toastr.error(error);
      vm.submitting = false;
    }

    function onPaymentFormLoaded() {
      vm.paymentFormLoaded = true;
    }

    function onLogin(user) {
      $log.debug("onLogin called", user);
      vm.user = user;
      activate();
      $uibModalInstance.result.finally(function() {
        $state.reload();
      });
    }
  }
})();
