(function() {
  "use strict";
  angular.module("marketPlace.home").controller("HomeController", homeController);

  homeController.$inject = [
    "$log",
    "$scope",
    "$state",
    "$timeout",
    "user",
    "Niches",
    "broadStreetZones",
    "GalleryHeader",
    "DATA_EVENTS",
    "Features",
    "GalleryForm",
    "BroadstreetService"
  ];

  function homeController(
    $log,
    $scope,
    $state,
    $timeout,
    user,
    Niches,
    broadStreetZones,
    GalleryHeader,
    DATA_EVENTS,
    Features,
    GalleryForm,
    BroadstreetService
  ) {
    const vm = this;
    vm.searchParams = {};
    vm.prop = "HOME";
    vm.user = user;
    vm.keywords = [];
    vm.currentSection = null;
    vm.currentSectionTimer = null;
    vm.showLeftArrow = false;
    vm.showRightArrow = true;

    vm.showBanner = false;
    vm.isGallery = false;
    vm.advancedSearchOpen = false;
    vm.enableBroadstreet = Features.broadstreet;
    vm.showFooter = false;

    activate();

    function activate() {
      $log.debug("HomeController");

      Niches.read.query(function(niches) {
        vm.niches = niches;
        if (vm.niches._order.length > 0) {
          vm.niche = vm.niches._order[0];
          const niche = vm.niches[vm.niche];
          vm.sections = niche.sections;
        } else {
          vm.sections = null;
        }
        updateParams();
      });

      if (vm.enableBroadstreet) {
        vm.zones = broadStreetZones;
        $timeout(function() {
          vm.showBanner = true;
        });
      }
    }

    $scope.$on(DATA_EVENTS.galleryParamsChange, function(event, toParams) {
      if (vm.searchParams.q !== toParams.q) {
        vm.searchParams.q = toParams.q;
      }
      updateParams();
    });

    $scope.$on(DATA_EVENTS.showFooter, function(event, args) {
      $log.debug("Toggle showFooter to:", args);
      vm.showFooter = args;
    });

    function updateParams() {
      $log.debug("updateParams");
      if (GalleryForm.isGalleryState($state.current.name)) {
        vm.isGallery = true;
        vm.niche = $state.params.niche || vm.niche;
        vm.section = $state.params.section;
        vm.gallery = $state.params.gallery;
        vm.caption = $state.params.caption;
        vm.rawId = $state.params.rawId;

        if (vm.enableBroadstreet) {
          let q = vm.searchParams.q;
          if (!q && $state.params.default && vm.user && vm.user.categories && vm.user.categories.length > 0) {
            q = vm.user.categories[0].name;
          }

          const searchObj = { ...$state.params, q: q };
          vm.keywords = BroadstreetService.getBroadStreetKeywords(searchObj);
          $log.debug("Home keywords: " + vm.keywords);
        }

        if (!vm.rawId) {
          GalleryHeader.get(vm.niche, vm.section, vm.gallery).then(function(resp) {
            vm.galleryHeaderData = resp;
          }, angular.noop);
        }
      } else {
        vm.isGallery = false;
      }
    }
  }
})();
