/**
 * Created by rory on 3/07/17.
 */
(function() {
  "use strict";
  angular.module("marketPlace.email").factory("EmailSchedules", schedules);

  schedules.$inject = ["$resource", "API_URL"];

  function schedules($resource, API_URL) {
    return $resource(API_URL + "/api/marketing-schedules/");
  }
})();
