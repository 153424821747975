import { IHttpService, IHttpResponse, IPromise } from "angular";

export interface VimeoService {
  oEmbed: Function;
}

class VimeoServiceImpl implements VimeoService {
  vimeoUrl: string;

  static $inject = ["$http", "$window"];

  constructor(public $http: IHttpService, public $window) {
    this.vimeoUrl = "https://vimeo.com/api/";
  }

  oEmbed(url: string): IPromise<IHttpResponse<any>> {
    const encodedUrl = this.$window.encodeURIComponent(url);
    return this.$http({
      url: this.vimeoUrl + "oembed.json?responsive=true&url=" + encodedUrl,
      method: "GET",
      // @ts-ignore
      skipAuthorization: true,
    });
  }
}

angular.module("marketPlace.vimeo").service("VimeoAPI", VimeoServiceImpl);
