(function() {
  "use strict";

  angular.module("marketPlace.niches").factory("Niches", niches);

  niches.$inject = ["$resource", "API_URL", "CacheFactory"];
  function niches($resource, API_URL, CacheFactory) {
    if (!CacheFactory.get("readableNicheCache")) {
      CacheFactory.createCache("readableNicheCache", {
        capacity: 1,
        maxAge: 5 * 60 * 1000, // Items added to this cache expire after 5 minutes.
        cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour.
        deleteOnExpire: "aggressive",
        storageMode: "localStorage"
      });
    }

    const readableNicheCache = CacheFactory.get("readableNicheCache");

    return {
      read: $resource(
        API_URL + "/api/niches/readable",
        {},
        {
          query: {
            method: "GET",
            isArray: false,
            cache: readableNicheCache
          }
        }
      ),
      write: $resource(
        API_URL + "/api/niches/writable",
        {},
        {
          query: {
            method: "GET",
            isArray: false
          }
        }
      )
    };
  }
})();
