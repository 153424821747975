(function(StickyStatesPlugin) {
  "use strict";
  angular.module("marketPlace").config(configure);

  configure.$inject = [
    "$locationProvider",
    "uiSelectConfig",
    "$resourceProvider",
    "ENV",
    "$uiRouterProvider",
    "$logProvider",
    "$compileProvider",
    "$uiViewScrollProvider",
    "$animateProvider",
    "$anchorScrollProvider",
    "AppConfig",
    "socialshareConfProvider",
    "$authProvider",
    "API_URL",
    "CacheFactoryProvider",
    "showErrorsConfigProvider",
    "toastrConfig"
  ];

  function configure(
    $locationProvider,
    uiSelectConfig,
    $resourceProvider,
    ENV,
    $uiRouter,
    $logProvider,
    $compileProvider,
    $uiViewScrollProvider,
    $animateProvider,
    $anchorScrollProvider,
    AppConfig,
    socialshareConfProvider,
    $authProvider,
    API_URL,
    CacheFactoryProvider,
    showErrorsConfigProvider,
    toastrConfig
  ) {
    $locationProvider.html5Mode(true);
    $uiRouter.plugin(StickyStatesPlugin);

    // configure ui-select
    uiSelectConfig.theme = "bootstrap";

    // Don't strip trailing slashes from calculated URLs
    $resourceProvider.defaults.stripTrailingSlashes = false;

    // disable debug logging in production
    if (ENV === "production") {
      $logProvider.debugEnabled(false);
      $compileProvider.debugInfoEnabled(false);
    }

    // Enable autoscroll
    $uiViewScrollProvider.useAnchorScroll();
    $anchorScrollProvider.disableAutoScrolling();

    $animateProvider.classNameFilter(/^((?!(fa-spin)).)*$/);

    socialshareConfProvider.configure([
      {
        provider: "facebook",
        conf: {
          via: AppConfig.FacebookAppID
        }
      }
    ]);

    $authProvider.baseUrl = API_URL + "/api";
    $authProvider.loginUrl = "/auth/login/";
    $authProvider.signupUrl = "/auth/register/";

    $authProvider.facebook({
      clientId: AppConfig.FacebookAppID,
      url: "/auth/login/social/jwt/facebook/",
      scope: ["email"] // should match SOCIAL_AUTH_FACEBOOK_SCOPE on server
      // TODO add 'user_birthday' and 'user_location' when Facebook app is authorized
    });

    $authProvider.google({
      clientId: AppConfig.GoogleClientId,
      url: "/auth/login/social/jwt/google-oauth2/",
      scope: ["profile", "email"] // should match SOCIAL_AUTH_GOOGLE_OAUTH2_SCOPE on server
    });

    angular.extend(CacheFactoryProvider.defaults, { maxAge: 15 * 60 * 1000 }); // 15 min default for all caches

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|tel|mailto):/);

    showErrorsConfigProvider.showSuccess(true);

    angular.extend(toastrConfig, {
      preventOpenDuplicates: true
    });
  }
  // eslint-disable-next-line angular/window-service
})(window.StickyStatesPlugin || {});
