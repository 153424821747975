(function() {
  "use strict";

  angular
    .module("marketPlace.organisations")
    .factory("OrganisationUsers", OrganisationUsers)
    .factory("OrganisationUser", OrganisationUser);

  OrganisationUsers.$inject = ["$resource", "API_URL"];
  function OrganisationUsers($resource, API_URL) {
    return $resource(
      API_URL + "/api/organisation-users/:organisationId/",
      { organisationId: "@id" },
      {
        list: {
          method: "GET",
          isArray: false
        },
        create: {
          method: "POST"
        }
      }
    );
  }

  OrganisationUser.$inject = ["$resource", "API_URL"];
  function OrganisationUser($resource, API_URL) {
    return $resource(
      API_URL + "/api/organisation-users/:organisation_id/user/:id",
      { organisationId: "@organisation_id", id: "@id" },
      {
        update: {
          method: "PUT"
        },
        delete: {
          method: "DELETE"
        }
      }
    );
  }
})();
