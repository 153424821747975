(function() {
  "use strict";

  interface Image {
    url: string;
  }

  angular.module("marketPlace.preview").factory("Preview", previewService);

  previewService.$inject = ["$uibModal", "_"];

  function previewService($uibModal, _) {
    /**
     * If primaryImage is defined add it to the start of the images array
     * @param {object[]} images - an array of images
     * @param {object} primaryImage - a primary image object to prepend to the images array
     * @returns {object[]}
     */
    function _getAllImages(images: Image[], primaryImage?: Image): Image[] {
      if (
        primaryImage &&
        primaryImage.url &&
        _.findIndex(images, function(i) {
          return i.url === primaryImage.url;
        }) === -1
      ) {
        const imagesCopy = angular.copy(images);
        imagesCopy.unshift(angular.copy(primaryImage));
        return imagesCopy;
      }
      return images;
    }

    /**
     * Finds the index in the images array of the clicked image
     * @param {object[]} images - an array of images
     * @param {int|object} active - the index in the images array or the image object of the clicked image
     * @returns {int}
     */
    function _getActiveIndex(images: Image[], active: number | Image): number {
      if (!angular.isNumber(active)) {
        return _.findIndex(images, { url: active.url });
      }
      return active;
    }

    /**
     * Convert readOnly to a boolean, defaulting to true if not defined
     * @param {*} [readOnly=true] - determines whether to show edit controls
     * @returns {boolean}
     */
    function _getReadOnly(readOnly?): boolean {
      if (angular.isUndefined(readOnly)) {
        return true;
      }
      return !!readOnly;
    }

    /**
     * Open a preview modal with a carousel of images, open at the image clicked
     * @param {object[]} images - an array of images
     * @param {int|object} activeImage - the index in the images array or the image object of the clicked image
     * @param {boolean} [readOnly=true] - determines whether to show edit controls
     * @param {object} [primaryImage] - a primary image object to prepend to the images array
     * @param {string} [primaryName] - label for primary e.g. logo, default=primary
     */
    function open(
      images: Image[],
      activeImage: number | Image,
      readOnly: any,
      primaryImage?: Image,
      primaryName = "primary"
    ) {
      const allImages = _getAllImages(images, primaryImage);
      const activeIndex = _getActiveIndex(allImages, activeImage);

      $uibModal.open({
        template: require("./templates/preview-modal.html"),
        windowClass: "app-modal-window",
        controller: "PreviewController",
        controllerAs: "vm",
        bindToController: true,
        size: "lg",
        resolve: {
          images: function() {
            return allImages;
          },
          activeIndex: function() {
            return activeIndex;
          },
          readOnly: function() {
            return _getReadOnly(readOnly);
          },
          primaryName: function() {
            return primaryName;
          }
        }
      });
    }

    return {
      open: open,
      _getAllImages: _getAllImages,
      _getActiveIndex: _getActiveIndex,
      _getReadOnly: _getReadOnly
    };
  }
})();
