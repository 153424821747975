import { ICacheObject, IHttpService, ILogService, IQService, IRootScopeService } from "angular";
import ICacheFactory = angular.cache.ICacheFactory;

class GalleryHeader {
  cacheName: string;
  FEATURES_URL: string;
  galleryHeaderCache: ICacheObject;

  static $inject = ["$rootScope", "$http", "$log", "$q", "CacheFactory", "API_URL", "DATA_EVENTS"];

  constructor(
    public $rootScope: IRootScopeService,
    public $http: IHttpService,
    public $log: ILogService,
    public $q: IQService,
    CacheFactory: ICacheFactory,
    public API_URL: string,
    private DATA_EVENTS
  ) {
    this.cacheName = "galleryHeaderCache";
    this.FEATURES_URL = this.API_URL + "/api/features/";

    if (!CacheFactory.get(this.cacheName)) {
      CacheFactory.createCache(this.cacheName, {
        maxAge: 5 * 60 * 1000, // Items added to this cache expire after 5 minutes.
        cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour.
        deleteOnExpire: "aggressive",
        storageMode: "localStorage"
      });
    }
    this.galleryHeaderCache = CacheFactory.get(this.cacheName);
  }

  get(niche: string, section: string, gallery: string) {
    const $log = this.$log;
    const $rootScope = this.$rootScope;

    const deferred = this.$q.defer();
    if (!niche) {
      deferred.reject("No niche");
    } else {
      let url = this.FEATURES_URL + niche;
      if (typeof section !== "undefined" && section) {
        url = url + "/" + section;

        if (typeof gallery !== "undefined" && gallery) {
          url = url + "/" + gallery;
        }
      }

      this.$http.get(url, { cache: this.galleryHeaderCache }).then(
        response => {
          $log.debug(response);
          if (response.status === 200) {
            $rootScope.$broadcast(this.DATA_EVENTS.galleryHeaderEvent, {
              galleryHeader: response.data
            });
            deferred.resolve(response.data);
          } else {
            $rootScope.$broadcast(this.DATA_EVENTS.galleryHeaderEvent, {
              galleryHeader: null
            });
            deferred.reject();
          }
        },
        response => {
          $log.debug(response);
          $rootScope.$broadcast(this.DATA_EVENTS.galleryHeaderEvent, {
            galleryHeader: null
          });
          deferred.reject();
        }
      );
    }

    return deferred.promise;
  }
}

angular.module("marketPlace.home").service("GalleryHeader", GalleryHeader);
