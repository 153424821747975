(function() {
  "use strict";
  angular.module("marketPlace.category").factory("AvailableTags", availableTags);

  availableTags.$inject = ["$resource", "API_URL"];

  function availableTags($resource, API_URL) {
    const r = $resource(API_URL + "/api/:niche/:section/");

    // Remove extra actions - available tags are get only
    delete r.prototype.$query;
    delete r.prototype.$save;
    delete r.prototype.$delete;
    delete r.prototype.$remove;

    return r;
  }
})();
